import React, { useCallback, useEffect, useState } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import DataTable from "../../Table/DataTable";
import axios from "axios";
import { BASE_URL } from "../../../Base_URL/BaseURL";

const ViewDetails = (props) => {
  const { close } = props;
  const [serials, setSerials] = useState([]);

  let token = localStorage.getItem("Web-api-v2-token");

  const fetchSerials = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name:
            props.details === "Box"
              ? "get_box_product_serial_codes"
              : "get_sub_location_product_serial_codes",
          token,
          ...(props.details === "Box" && { box_id: props.box_id }),
          product_id: props.product_id,
          ...(props.details !== "Box" && { sub_location_id: props.box_id }),
          ...(props.details !== "Box" && {
            box: props.details === "BoxLoc" ? 1 : 0,
          }),
        }
      );
      if (data?.status === 1) {
        const updatedSerialCodes = data?.data?.serial_codes.map((serial) => ({
          ...serial,
          qty: 1,
        }));

        setSerials(updatedSerialCodes);
      }
    } catch (err) {
      console.log(err);
    }
  }, [token, props]);

  useEffect(() => {
    fetchSerials();
  }, [fetchSerials]);

  return (
    <>
      <HeadingTitle colorRed={false} title="View Details" closeModal={close} />
      <div style={{ maxHeight: "350px" }}>
        <DataTable
          header={["Picture", "Serial Code", "SKU Code", "Quantity"]}
          viewdetails={serials}
        />
      </div>
    </>
  );
};

export default ViewDetails;
