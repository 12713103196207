import React, { useEffect, useState } from "react";
import "./Login.css";
import Logo from "../../Assets/Login-Bg/Group 2503.png";
import { Button } from "@material-ui/core";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import EyeIconCross from "@material-ui/icons/VisibilityOff";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { useAlert } from "react-alert";
import { BASE_URL } from "../../Base_URL/BaseURL";
import ModalContainer from "../../Components/Modal/Modal";
import LinkIcon from "@mui/icons-material/Link";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import secureLocalStorage from "react-secure-storage";
import MetaData from "../../Components/Helmet/Helmet";

export const url = localStorage.getItem("BaseUrl");

const Login = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [type, setType] = useState("password");
  const [isHide, setIsHide] = useState(false);

  const handlePassword = () => {
    setIsHide(!isHide);
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const [loading, setLoading] = useState(false);

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    let service_name = "login";
    const resp = await axios.post(
      `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
      {
        username,
        password,
        service_name,
      }
    );

    localStorage.setItem("Web-api-v2-token", resp?.data?.token);

    axios
      .post(`${BASE_URL}`, {
        username,
        password,
        service_name,
      })
      .then((response) => {
        let data = response?.data;
        if (data && data?.status !== 0 && data?.msg === "") {
          localStorage.setItem("user", JSON.stringify(data));
          secureLocalStorage.setItem("MNSEXESPS", password);
          secureLocalStorage.setItem("MXESNUSERN", username);
          localStorage.setItem("Printer", "Microsoft Print to PDF");
          setTimeout(() => {
            navigate("/");
          }, 200);
          alert.success("Login Successfully!");
          setLoading(false);
        } else {
          alert.error(data && data?.msg);
          setLoading(false);
        }
      })
      .catch((err) => {
        alert.error(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isAuthenticated && isAuthenticated !== undefined) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const [isIndex, setIsIndex] = useState(null);
  const handleModal = (index) => {
    setIsIndex(index);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
        />
      );
    }
    return null;
  };

  let qz_setup_url = url && url?.replace(/\/[^/]*$/, "");
  return (
    <>
      <MetaData title={`Picklist Management - Login`} />
      <div className="login-screen-container">
        <div className="login-inputs-wrapper-container">
          <Button onClick={() => handleModal(10)} className="server-choose">
            <LinkIcon className="link-icon" />
          </Button>
          <div className="img-logo">
            <img src={Logo} alt="" />
            <h1>Welcome!</h1>
            <p>Please enter your log in details below......</p>
          </div>
          <form onSubmit={handleLogin}>
            <div className="inputs-login-row">
              <label htmlFor="Email">Enter Email</label> <br />
              <input
                type="text"
                id="Email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter UserName"
              />
            </div>
            <div className="inputs-login-row relative-pass">
              <label htmlFor="password">Password</label> <br />
              <input
                type={type}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
              />
              <div className="show-hide-password">
                {isHide ? (
                  <Button onClick={() => handlePassword()}>
                    <EyeIcon />
                  </Button>
                ) : (
                  <Button onClick={() => handlePassword()}>
                    <EyeIconCross />
                  </Button>
                )}
              </div>
            </div>
            <div className="login-submit-btn">
              <Button type="submit">{loading ? <Loader /> : "Submit"}</Button>
            </div>
          </form>
          <Link
            // to={`${
            //   qz_setup_url ? qz_setup_url : "https://test-live.sevenrocks.in"
            // }/qz-local/qz-tray-2.2.3-SNAPSHOT-x86_64.exe`}
            to={`${
              qz_setup_url ? qz_setup_url : "https://test-live.sevenrocks.in"
            }/android/qz-tray.exe`}
            target="_blank"
            className="download-qz-tray"
          >
            <Button>
              <CloudDownloadIcon />
              &nbsp;&nbsp;
              <p>Download QZ Tray</p>
            </Button>
          </Link>
          <p className="uri">{url}</p>
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default Login;
