import React, { useCallback, useEffect, useState } from "react";
import qz from "qz-tray";
import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import Loader from "../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  MarkedPlacedMultiLabelGeneratorActions,
  MarkedPlacedSingleLabelGeneratorActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import {
  MARKED_PLACED_MULTI_LABEL_GENERATOR_RESET,
  MARKED_PLACED_SINGLE_LABEL_GENERATOR_RESET,
} from "../../Constants/PickerMgmt/PickerManagementConstants";
import {
  getBagManifestPdfActions,
  printLabelBagActions,
} from "../../Actions/OrderHandoverActions/OrderHandoverActions";
import { useMemo } from "react";
import LabelIcon from "../../Assets/Icons/Order Handover/Label 4.png";

function QzTray(props) {
  let printer = localStorage.getItem("Printer");
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  // single marked state
  const { marked_single_placed } = useSelector(
    (state) => state.markedSingleLabel
  );
  // multi marked state
  const { marked_multi_placed } = useSelector(
    (state) => state.markedMultiLabel
  );

  // bag manifest state
  const { bag_manifest, loading: manifest_loading } = useSelector(
    (state) => state.bagManifestPdf
  );

  // label bag state
  const { label_bag, loading: label_loading } = useSelector(
    (state) => state.labelBag
  );

  const {
    pdf_all,
    multi_pdf,
    multi_pdf_label,
    tote_code,
    serial_code,
    handover_id,
    isok,
    bag_id,
    label,
    closePrint,
    close_bags,
  } = props;

  // console.log("ClosePrint: ", closePrint);

  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  let pdfUrls = useMemo(() => {
    if (pdf_all && pdf_all.length > 0) {
      return [];
    } else if (isok && !label && !close_bags) {
      return bag_manifest?.data !== null &&
        bag_manifest?.data !== "" &&
        bag_manifest?.data !== undefined
        ? [bag_manifest?.data]
        : [];
    } else if (label && !isok && close_bags) {
      return label_bag?.data !== null &&
        label_bag?.data !== "" &&
        label_bag?.data !== undefined
        ? [label_bag?.data]
        : closePrint !== null && closePrint !== "" && closePrint !== undefined
        ? [closePrint]
        : [];
    } else {
      return multi_pdf !== null && multi_pdf_label !== null
        ? [multi_pdf, multi_pdf_label]
        : multi_pdf !== null
        ? [multi_pdf]
        : multi_pdf_label !== null
        ? [multi_pdf_label]
        : [];
    }
  }, [
    pdf_all,
    isok,
    bag_manifest?.data,
    multi_pdf,
    multi_pdf_label,
    label,
    label_bag?.data,
    closePrint,
    close_bags,
  ]);

  pdf_all &&
    pdf_all?.length > 0 &&
    pdf_all.forEach((i) => {
      if (
        !pdfUrls.includes(i.invoice) &&
        i.invoice !== "" &&
        i.invoice !== null &&
        i.invoice !== undefined
      ) {
        pdfUrls.push(i.invoice);
      }
      if (
        !pdfUrls.includes(i.label) &&
        i.label !== "" &&
        i.label !== null &&
        i.label !== undefined
      ) {
        pdfUrls.push(i.label);
      }
    });

  // console.log("ARRAY: ", pdfUrls);

  const showEmptyWarning = () => {
    if (tote_code === "") {
      return alert.error("Please Scan Tote First...");
    }
    if (serial_code === "") {
      return alert.error("Please Scan Serial Code First...");
    }
  };

  const [isActive, setIsActive] = useState(false);
  // console.log(pdfUrls.length);

  // const printPDFs = useCallback(async () => {
  //   if (
  //     pdfUrls.includes(null) ||
  //     pdfUrls.includes(undefined) ||
  //     pdfUrls.length === 0
  //   ) {
  //     return alert.error("No PDF Found...");
  //   }
  //   setIsActive(true);
  //   setLoading(true);
  //   if (qz.websocket.isActive()) {
  //     if (qz.websocket.isActive()) {
  //       let config = qz.configs.create(
  //         printer !== null || printer !== undefined
  //           ? printer
  //           : props.selectedPrinter
  //       );

  //       const printPromises = pdfUrls.map((pdfUrl) => {
  //         return qz.print(config, [
  //           {
  //             type: "pixel",
  //             format: "pdf",
  //             flavor: "file",
  //             data: pdfUrl,
  //           },
  //         ]);
  //       });

  //       return Promise.all(printPromises)
  //         .then(() => {
  //           setLoading(false);
  //           // return qz.websocket.disconnect();
  //         })
  //         .then(() => {
  //           if (
  //             pdf_all &&
  //             pdf_all?.length > 0 &&
  //             tote_code !== "" &&
  //             tote_code !== null &&
  //             tote_code !== undefined
  //           ) {
  //             dispatch(
  //               MarkedPlacedSingleLabelGeneratorActions(
  //                 "single_order_picklist_mark_label_generated",
  //                 token,
  //                 tote_code
  //               )
  //             );
  //             pdfUrls.values = [];
  //           } else if (
  //             multi_pdf !== "" &&
  //             multi_pdf !== null &&
  //             multi_pdf !== undefined &&
  //             serial_code !== "" &&
  //             serial_code !== null &&
  //             serial_code !== undefined
  //           ) {
  //             dispatch(
  //               MarkedPlacedMultiLabelGeneratorActions(
  //                 "multi_order_mark_label_generated",
  //                 token,
  //                 serial_code
  //               )
  //             );
  //           }
  //           alert.success("All PDFs Printed Successfully...");
  //           setLoading(false);
  //           // All PDFs printed successfully
  //         })
  //         .catch((err) => {
  //           alert.error(err.message);
  //           setLoading(false);
  //         });
  //     } else {
  //       alert.error("QZ Tray Is Not Connected, Please Select Printer First...");
  //       setLoading(false);
  //     }
  //   } else {
  //     alert.error("QZ Tray Is Not Connected, Please Select Printer First...");
  //     setLoading(false);
  //   }
  // }, [
  //   alert,
  //   dispatch,
  //   multi_pdf,
  //   pdfUrls,
  //   pdf_all,
  //   printer,
  //   props.selectedPrinter,
  //   serial_code,
  //   token,
  //   tote_code,
  // ]);

  const printPDFs = useCallback(async () => {
    if (
      pdfUrls.includes(null) ||
      pdfUrls.includes(undefined) ||
      pdfUrls.length === 0
    ) {
      return alert.error("No PDF Found...");
    }
    setLoading(true);

    if (qz.websocket.isActive()) {
      let config = qz.configs.create(
        printer !== null || printer !== undefined
          ? printer
          : props.selectedPrinter
      );

      try {
        await pdfUrls.reduce(async (previousPromise, pdfUrl) => {
          await previousPromise; // Wait for the previous promise to resolve
          await qz.print(config, [
            {
              type: "pixel",
              format: "pdf",
              flavor: "file",
              data: pdfUrl,
            },
          ]);
        }, Promise.resolve());

        setLoading(false);
        setIsActive(true);

        if (
          pdf_all &&
          pdf_all?.length > 0 &&
          tote_code !== "" &&
          tote_code !== null &&
          tote_code !== undefined
        ) {
          dispatch(
            MarkedPlacedSingleLabelGeneratorActions(
              "single_order_picklist_mark_label_generated",
              token,
              tote_code
            )
          );
          pdfUrls.values = [];
        } else if (
          multi_pdf !== "" &&
          multi_pdf !== null &&
          multi_pdf !== undefined &&
          serial_code !== "" &&
          serial_code !== null &&
          serial_code !== undefined
        ) {
          dispatch(
            MarkedPlacedMultiLabelGeneratorActions(
              "multi_order_mark_label_generated",
              token,
              serial_code
            )
          );
        }

        alert.success("All PDFs Printed Successfully...");
      } catch (err) {
        alert.error(err.message);
        setLoading(false);
      }
    } else {
      alert.error("QZ Tray Is Not Connected, Please Select Printer First...");
      setLoading(false);
    }
  }, [
    alert,
    dispatch,
    multi_pdf,
    pdfUrls,
    pdf_all,
    printer,
    props.selectedPrinter,
    serial_code,
    token,
    tote_code,
  ]);

  useEffect(() => {
    if (
      marked_single_placed &&
      marked_single_placed?.status === 1 &&
      isActive &&
      tote_code
    ) {
      setIsActive(false);
      alert.success("Marked Packed Single Order Succesfully...");
      dispatch({
        type: MARKED_PLACED_SINGLE_LABEL_GENERATOR_RESET,
      });
    } else if (
      marked_single_placed &&
      marked_single_placed?.status === 0 &&
      isActive &&
      tote_code
    ) {
      setIsActive(false);
      alert.error(marked_single_placed && marked_single_placed?.msg);
      dispatch({
        type: MARKED_PLACED_SINGLE_LABEL_GENERATOR_RESET,
      });
    }
  }, [marked_single_placed, alert, isActive, dispatch, tote_code]);

  useEffect(() => {
    if (
      marked_multi_placed &&
      marked_multi_placed?.status === 1 &&
      isActive &&
      serial_code
    ) {
      setIsActive(false);
      alert.success("Marked Packed Multi Order Succesfully...");
      dispatch({
        type: MARKED_PLACED_MULTI_LABEL_GENERATOR_RESET,
      });
    } else if (
      marked_multi_placed &&
      marked_multi_placed?.status === 0 &&
      isActive &&
      serial_code
    ) {
      setIsActive(false);
      alert.error(marked_multi_placed && marked_multi_placed?.msg);
      dispatch({
        type: MARKED_PLACED_MULTI_LABEL_GENERATOR_RESET,
      });
    }
  }, [marked_multi_placed, alert, isActive, dispatch, serial_code]);

  // console.log("Single Marked: ", marked_single_placed);
  // console.log("Multi Marked: ", marked_multi_placed);

  const [isManifest, setIsManifest] = useState(false);
  const [isLabel, setIsLabel] = useState(true);

  // const [clickT, setClickT] = useState(1);
  const handleBagManifest = () => {
    if (isok === true) {
      setIsManifest(true);
      // setClickT(0);
      dispatch(
        getBagManifestPdfActions(
          "courier_manifest",
          token,
          isAuthenticated?.User?.id,
          handover_id
        )
      );
    }
  };

  const handleLabel = useCallback(() => {
    if (label === true) {
      // onHandle(false);
      setIsLabel(true);
      dispatch(printLabelBagActions("print_bag_lable_pdf", token, bag_id));
    } else {
      setIsLabel(true);
      dispatch(printLabelBagActions("print_bag_lable_pdf", token, bag_id));
    }
  }, [label, dispatch, bag_id, token]);

  useEffect(() => {
    if (bag_manifest && bag_manifest?.status === 1 && isManifest) {
      printPDFs();
      setIsManifest(false);
    } else if (bag_manifest && bag_manifest?.status === 0 && isManifest) {
      alert.error(bag_manifest && bag_manifest?.msg);
      setIsManifest(false);
    }
  }, [bag_manifest, alert, printPDFs, isManifest]);

  useEffect(() => {
    if (
      label_bag &&
      label_bag?.status === 1 &&
      isLabel &&
      pdfUrls?.length > 0 &&
      label &&
      !isok
    ) {
      setIsLabel(false);
    } else if (label_bag && label_bag?.status === 0 && isLabel) {
      setIsLabel(false);
      alert.error(label_bag && label_bag?.msg);
    }
    // eslint-disable-next-line
  }, [label_bag, alert, printPDFs, isLabel]);

  useEffect(() => {
    if (
      closePrint !== null &&
      closePrint !== undefined &&
      closePrint !== "" &&
      close_bags &&
      close_bags !== undefined
    ) {
      printPDFs();
      // alert.show("YES");
    }
    // eslint-disable-next-line
  }, [closePrint]);

  return (
    <>
      <div
        className={
          isok
            ? "manifest-cta"
            : props.label
            ? "label-bag"
            : "download-invoice-lable-cta"
        }
      >
        {isok ? (
          <Button
            disabled={manifest_loading || loading ? true : false}
            onClick={handleBagManifest}
          >
            {" "}
            {manifest_loading || loading ? (
              <>
                Processing...&nbsp;&nbsp; <Loader />
              </>
            ) : (
              props.btnTitle
            )}
          </Button>
        ) : props.label ? (
          <Button
            disabled={label_loading || loading ? true : false}
            onClick={handleLabel}
          >
            {" "}
            {label_loading || loading ? (
              <Loader />
            ) : (
              <>
                <img src={LabelIcon} alt="" />
                &nbsp;&nbsp;Print Label
              </>
            )}
          </Button>
        ) : (
          <Button
            disabled={loading ? true : false}
            onClick={
              pdfUrls?.length >= 0
                ? printPDFs
                : pdfUrls === null || pdfUrls === "" || pdfUrls === undefined
                ? showEmptyWarning
                : showEmptyWarning
            }
          >
            {loading ? (
              <>
                Processing...&nbsp;&nbsp; <Loader />
              </>
            ) : (
              props.btnTitle
            )}
          </Button>
        )}
      </div>
    </>
  );
}

export default QzTray;
