import DataTable from "../../../Components/Table/DataTable";
import Select from "react-select";
import InputField from "../../../Components/InputField/InputField";
import { customStylesSKU } from "../../../Components/DropdownStyles/DropdownStyles";
import ModalContainer from "../../../Components/Modal/Modal";
import { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import axios from "axios";
import { BASE_URL } from "../../../Base_URL/BaseURL";
import Loader from "../../../Components/Loader/Loader";

const SubLocationToSubLocationTransfer = () => {
  const alert = useAlert();
  const [sublocation, setSublocation] = useState("");
  const [sublocation2, setSublocation2] = useState("");
  const [sublocationData, setSublocationData] = useState([]);
  const [sublocationData2, setSublocationData2] = useState([]);
  const [locationType, setLocationType] = useState("sub_location_1");
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [totalqty, setTotalQty] = useState(0);
  const [totalqty2, setTotalQty2] = useState(0);
  const [loading, setLoading] = useState(false);

  let token = localStorage.getItem("Web-api-v2-token");

  const fetchSublocationDetails = useCallback(
    async (sub_location_code, type) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "get_sub_location_non_boxed_details",
            token,
            sub_location_code,
          }
        );
        if (data.status === 1) {
          const updatedItems = data?.data
            .map((item) => ({
              ...item,
              details: "NonBoxLoc",
              image: item.url,
              qty: parseInt(item.qty, 10),
            }))
            .map(({ url, ...rest }) => rest);

          const totalQty = updatedItems.reduce(
            (sum, item) => sum + item.qty,
            0
          );
          if (type === 1) {
            setSublocationData(updatedItems);
            setTotalQty(totalQty);
            setError("");
          } else {
            setSublocationData2(updatedItems);
            setTotalQty2(totalQty);
            setError2("");
          }
        } else {
          if (type === 1) {
            setError(data.msg);
          } else {
            setError2(data.msg);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  useEffect(() => {
    fetchSublocationDetails(sublocation, 1);
  }, [sublocation, fetchSublocationDetails]);

  useEffect(() => {
    fetchSublocationDetails(sublocation2, 2);
  }, [sublocation2, fetchSublocationDetails]);

  const handleChange = (name, value) => {
    if (name === "sublocation") {
      setSublocation(value);
    } else {
      setSublocation2(value);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "sub_location_to_sub_location",
          token,
          sub_location_code1: sublocation,
          sub_location_code2: sublocation2,
          type: locationType,
        }
      );
      setLoading(false);
      if (data.status === 1) {
        alert.success("Submitted Successfully");
        setSublocation("");
        setSublocation2("");
        setSublocationData([]);
        setSublocationData2([]);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert.error("Something went wrong");
    }
  };

  const [message, setMessage] = useState("");
  const [isopen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(16);
  const [modalDetail, setModalDetail] = useState("NonBoxLoc");
  const [modalBoxId, setModalBoxId] = useState("");
  const [modalProductId, setModalProductId] = useState("");

  const handleModal = (i) => {
    setModalIndex(i);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const renderModal = () => {
    if (isopen) {
      return (
        <ModalContainer
          Isopen={isopen}
          index={modalIndex}
          closeModal={closeModal}
          details={modalDetail}
          box_id={modalBoxId}
          product_id={modalProductId}
          title={"Alert"}
          message={message}
          handleSubmit={handleSubmit}
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderModal()}
      <div className="summary-box">
        <div className="grid-delivery-partner-info">
          <div>
            <label htmlFor="courierName">Choose Session Id</label>
            <Select
              placeholder={`Session Id`}
              //   options={
              //     carriers &&
              //     carriers.map((option) => ({
              //       value: option.name,
              //       label: option.name,
              //       id: option.id,
              //     }))
              //   }
              className="select"
              id="courierName"
              //value={carier_id}
              styles={customStylesSKU}
              // onChange={(selectedOption) => handleCourierName(selectedOption)}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={"Rohit_1"}
              label="Created By"
              placeholder="Created By"
              id="created_by"
              darked={true}
              // handover={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={"Jun 15, 2024 12:34 PM"}
              label="Start Date and Time"
              placeholder="Date & Time"
              id="datetime"
              darked={true}
              // handover={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={sublocation}
              label="Scan Sub Location"
              placeholder="Scan Sub Location"
              id="scanbox"
              scan={true}
              readOnly={false}
              onChange={handleChange}
              field={"sublocation"}
              error={sublocation !== "" && error}
            />
          </div>
        </div>

        <div className="table-container-pagination overflow-hidden">
          <div className="overflow-auto">
            <DataTable
              header={["SKU Code", "Qty", "Action"]}
              boxboxtransfer={sublocationData}
              onData={handleModal}
              onDetail={setModalDetail}
              onHeadingIndex={setModalBoxId}
              assign_Picklist_id={setModalProductId}
            />
          </div>
        </div>
      </div>

      <div className="summary-box mt-3">
        <div className="grid-delivery-partner-info">
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={sublocation2}
              label="Scan Sub Location"
              placeholder="Scan Sub Location"
              id="scanbox"
              scan={true}
              readOnly={false}
              onChange={handleChange}
              field={"sublocation2"}
              error={sublocation2 !== "" && error2}
            />
          </div>
        </div>

        <div className="table-container-pagination overflow-hidden">
          <div className="overflow-auto">
            <DataTable
              header={["SKU Code", "Qty", "Action"]}
              boxboxtransfer={sublocationData2}
              onData={handleModal}
              onDetail={setModalDetail}
              onHeadingIndex={setModalBoxId}
              assign_Picklist_id={setModalProductId}
            />
          </div>
        </div>
      </div>

      <div className="mt-3 btn-container">
        <button
          className="submit-btn"
          style={{
            margin: "0",
            maxWidth: "240px",
            backgroundColor: "#26A6FE",
          }}
          disabled={sublocation === "" || sublocation2 === ""}
          onClick={() => {
            setIsOpen(true);
            setModalIndex(19);
            setLocationType("sub_location_2");
            setMessage(
              `Are you sure you want to move <strong>${totalqty}</strong> units from sub-location <strong>${sublocation}</strong> to sub-location <strong>${sublocation2}</strong>, resulting in a total of <strong>${
                Number(totalqty) + Number(totalqty2)
              }</strong> units in <strong>${sublocation2}</strong>?`
            );
          }}
        >
          Move Serial Code from Sublocation-1 to Sublocation-2{" "}
          {loading && <Loader />}
        </button>
        <button
          className="submit-btn"
          disabled={sublocation === "" || sublocation2 === ""}
          style={{ margin: "0", maxWidth: "240px" }}
          onClick={() => {
            setIsOpen(true);
            setModalIndex(19);
            setLocationType("sub_location_1");
            setMessage(
              `Are you sure you want to move <strong>${totalqty2}</strong> units from sub-location <strong>${sublocation2}</strong> to sub-location <strong>${sublocation}</strong>, resulting in a total of <strong>${
                Number(totalqty) + Number(totalqty2)
              }</strong> units in <strong>${sublocation}</strong>?`
            );
          }}
        >
          Move Serial Code from Sublocation-2 to Sublocation-1{" "}
          {loading && <Loader />}
        </button>
      </div>
    </>
  );
};

export default SubLocationToSubLocationTransfer;
