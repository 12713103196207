import DataTable from "../../../Components/Table/DataTable";
import Select from "react-select";
import InputField from "../../../Components/InputField/InputField";
import { customStylesSKU } from "../../../Components/DropdownStyles/DropdownStyles";
import KeyPairs from "../../../Components/KeyPairs/KeyPairs";
import ModalContainer from "../../../Components/Modal/Modal";
import { useCallback, useEffect, useState } from "react";
import { BASE_URL } from "../../../Base_URL/BaseURL";
import NoImage from "../../../Assets/no-image.jpg";
import axios from "axios";
import { useAlert } from "react-alert";

const BoxQuantityAddition = () => {
  const alert = useAlert();
  const [boxName, setBoxName] = useState("");
  const [serialCode, setSerialCode] = useState("");
  const [serialData, setSerialData] = useState("");
  const [box1Data, setBox1Data] = useState({});
  const [counter, setCounter] = useState(0);
  const [error, setError] = useState("");
  const [serialerror, setSerialError] = useState("");

  let token = localStorage.getItem("Web-api-v2-token");

  const fetchBoxDetails = useCallback(
    async (box_name) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "get_box_details",
            token,
            box_name,
          }
        );
        if (data.status === 1) {
          setBox1Data(data.data);
          setError("");
        } else {
          setError(data.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  useEffect(() => {
    fetchBoxDetails(boxName);
  }, [boxName, fetchBoxDetails]);

  const submitSerialCode = useCallback(
    async (serial_code) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "merge_bbsc_to_box",
            token,
            box_no: boxName,
            serial_code,
          }
        );
        if (data.status === 1) {
          if (data.data) {
            setSerialData(data?.data);
            setCounter((prev) => prev + 1);
            alert.success("Serial Code Merged Successfully");
          }
          setSerialError("");
          if (data.msg && !data.data) {
            setSerialError(data.msg);
          }
        } else {
          setSerialError(data.msg);
        }
        if (boxName === "") {
          setSerialError("Box not found");
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token, boxName, alert]
  );

  useEffect(() => {
    submitSerialCode(serialCode);
  }, [serialCode, submitSerialCode]);

  const handleChange = (name, value) => {
    if (name === "boxName") {
      setBoxName(value);
    } else {
      setSerialCode(value);
    }
  };

  const fetchBoxLabel = async (boxName) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "print_box_label",
          token,
          box_no: boxName,
        }
      );
      if (data.status === 1) {
        const link = document.createElement("a");
        link.href = BASE_URL.replace("picker-portal-api", "") + data.data;
        link.download = "Box-" + boxName + ".pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      alert.error("Something went wrong");
    }
  };

  const [isopen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(16);
  const [modalDetail, setModalDetail] = useState("Box");
  const [modalBoxId, setModalBoxId] = useState("");
  const [modalProductId, setModalProductId] = useState("");

  const handleModal = (i) => {
    setModalIndex(i);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const renderModal = () => {
    if (isopen) {
      return (
        <ModalContainer
          Isopen={isopen}
          index={modalIndex}
          closeModal={closeModal}
          details={modalDetail}
          box_id={modalBoxId}
          product_id={modalProductId}
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderModal()}
      <div className="summary-box">
        <div className="grid-delivery-partner-info">
          <div>
            <label htmlFor="courierName">Choose Session Id</label>
            <Select
              placeholder={`Session Id`}
              //   options={
              //     carriers &&
              //     carriers.map((option) => ({
              //       value: option.name,
              //       label: option.name,
              //       id: option.id,
              //     }))
              //   }
              className="select"
              id="courierName"
              //value={carier_id}
              styles={customStylesSKU}
              // onChange={(selectedOption) => handleCourierName(selectedOption)}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={"Rohit_1"}
              label="Created By"
              placeholder="Created By"
              id="created_by"
              darked={true}
              // handover={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={"Jun 15, 2024 12:34 PM"}
              label="Start Date and Time"
              placeholder="Date & Time"
              id="datetime"
              darked={true}
              // handover={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={boxName}
              label="Scan Box Id"
              placeholder="Scan Box Id"
              id="scanbox"
              scan={true}
              readOnly={false}
              onChange={handleChange}
              field={"boxName"}
              error={boxName !== "" && error}
            />
          </div>
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <KeyPairs
            data={[
              {
                key: "Location",
                value: box1Data?.box_info?.Location
                  ? box1Data?.box_info?.Location
                  : "Scan Box First",
              },
              {
                key: "Sub Location",
                value: box1Data?.box_info?.SubLocation
                  ? box1Data?.box_info?.SubLocation
                  : "Scan Box First",
              },
              {
                key: "Current Quantity",
                value: box1Data?.box_info?.current_qty
                  ? box1Data?.box_info?.current_qty
                  : "Scan Box First",
              },
              {
                key: "Initial Quantity",
                value: box1Data?.box_info?.qty
                  ? box1Data?.box_info?.qty
                  : "Scan Box First",
              },
              {
                key: "Complete Location",
                value: box1Data?.box_info?.SubLocation
                  ? `${box1Data?.box_info?.SubLocation}>>${box1Data?.box_info?.Location}`
                  : "Scan Box First",
              },
            ]}
          />
        </div>
        <div className="table-container-pagination overflow-hidden">
          <div className="overflow-auto">
            {box1Data?.products && (
              <DataTable
                header={["SKU Code", "Qty", "Action"]}
                boxboxtransfer={Object.entries(box1Data?.products).map(
                  ([product_id, product]) => ({
                    product_id,
                    details: "Box",
                    box_id: box1Data?.box_info?.id,
                    ...product,
                  })
                )}
                onData={handleModal}
                onDetail={setModalDetail}
                onHeadingIndex={setModalBoxId}
                assign_Picklist_id={setModalProductId}
              />
            )}
          </div>
        </div>
        <div className="mt-3">
          <div className="grid-delivery-partner-info">
            <div className="mt-56">
              <InputField
                // loading={handoverNameLoading}
                value={serialCode}
                label="Add Serial Code"
                placeholder="Add Serial Code"
                id="scanbox"
                scan={true}
                readOnly={false}
                onChange={handleChange}
                field={"serialCode"}
                error={serialCode !== "" && serialerror}
              />
            </div>

            <div className="text-center mb-3">
              <img
                src={serialData ? serialData : NoImage}
                alt="pic"
                style={{ maxWidth: "160px" }}
              />
              <p className="para">
                {serialData ? serialCode : "Add Code First"}
              </p>
            </div>

            <div>
              <div className="card card1">
                <h1 className="title">
                  {box1Data?.box_info?.current_qty
                    ? Number(box1Data?.box_info?.current_qty) + counter
                    : "Scan Box First"}
                </h1>
                <p className="description">Current Box Qty</p>
              </div>
              <div className="card card2 mt-3">
                <h1 className="title"> {counter}</h1>
                <p className="description">Total Quantity Newly Scanned</p>
              </div>
            </div>
          </div>
          <div className="mt-3 btn-container">
            <button
              className="submit-btn"
              style={{ margin: "0", maxWidth: "200px" }}
              onClick={() => {
                setSerialCode("");
                setBoxName("");
                setBox1Data({});
                setSerialData("");
              }}
            >
              Clear
            </button>
            <button
              className="submit-btn"
              style={{
                backgroundColor: "#26A6FE",
                margin: "0",
                maxWidth: "200px",
              }}
              disabled={boxName === ""}
              onClick={() => fetchBoxLabel(boxName)}
            >
              Create Box Label
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoxQuantityAddition;
