import React from "react";
import Not_Found_Icon from "../../Assets/404.gif";
import "./NotFound.css";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <img src={Not_Found_Icon} alt="" />
      <h1 className="not-found-header">
        Oops you land onto The Wrong Route...
      </h1>
    </div>
  );
};

export default NotFound;
