import React from "react";
import { HeadingTitle } from "../../Modal/Modal";
import Select from "react-select";
import { Button } from "@material-ui/core";
import { customStylesSKU } from "../../DropdownStyles/DropdownStyles";
import { useState } from "react";
import { urls } from "../../../DummyArray/TableArray/TableData";

const BaseUrl = (props) => {
  const { close } = props;

  const [value, setValue] = useState("");
  const [url_value, setUrlValue] = useState("");

  const handleUrl = (option) => {
    setValue(option?.label);
    setUrlValue(option?.url);
  };

  const [pass, setPass] = useState("");

  const handleSubmitUrl = async () => {
    await localStorage.setItem("BaseUrl", url_value);
    await close();
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  return (
    <>
      <HeadingTitle title="Change Base URL" closeModal={close} />
      {pass === process.env.REACT_APP_PASS ? (
        <div className="printers-available">
          <label
            style={{
              fontSize: "14px",
              color: "#000",
              fontFamily: "Poppins_SemiBold",
            }}
            htmlFor="url"
          >
            Select URL
          </label>
          <Select
            noOptionsMessage={() => "No Printer Available..."}
            options={
              urls &&
              urls.map((option) => ({
                value: option.label,
                label: option.label,
                url: option.url,
              }))
            }
            placeholder="Choose URL"
            className="select"
            id="url"
            styles={customStylesSKU}
            onChange={(selectedOption) => handleUrl(selectedOption)}
          />
          <div className="printer-cta">
            <Button
              disabled={value !== "" && url_value !== "" ? false : true}
              onClick={handleSubmitUrl}
            >
              Submit
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <label
            style={{
              fontSize: "14px",
              color: "#000",
              fontFamily: "Poppins_SemiBold",
            }}
            htmlFor="password"
          >
            Please Enter Password
          </label>
          <div className="date">
            <input
              type="text"
              id="password"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BaseUrl;
