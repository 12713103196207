import DataTable from "../../../Components/Table/DataTable";
import Select from "react-select";
import InputField from "../../../Components/InputField/InputField";
import { customStylesSKU } from "../../../Components/DropdownStyles/DropdownStyles";
import KeyPairs from "../../../Components/KeyPairs/KeyPairs";
import { useCallback, useEffect, useState } from "react";
import ModalContainer from "../../../Components/Modal/Modal";
import axios from "axios";
import Loader from "../../../Components/Loader/Loader";
import { BASE_URL } from "../../../Base_URL/BaseURL";
import { useAlert } from "react-alert";

const SubLocationToBoxTransfer = () => {
  const alert = useAlert();
  const [boxType, setBoxType] = useState("");
  const [boxName, setBoxName] = useState("");
  const [sublocation, setSublocation] = useState("");
  const [sublocation2, setSublocation2] = useState("");
  const [boxName2, setBoxName2] = useState("");
  const [box1Data, setBox1Data] = useState({});
  const [sublocationData, setSublocationData] = useState([]);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [totalqty, setTotalQty] = useState(0);
  const [loading, setLoading] = useState(false);

  let token = localStorage.getItem("Web-api-v2-token");

  const fetchBoxDetails = useCallback(
    async (box_name) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "get_box_details",
            token,
            box_name,
          }
        );
        if (data.status === 1) {
          setBox1Data(data.data);
          setError("");
        } else {
          setError(data.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  useEffect(() => {
    fetchBoxDetails(boxName);
  }, [boxName, fetchBoxDetails]);

  const fetchSublocationDetails = useCallback(
    async (sub_location_code) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "get_sub_location_non_boxed_details",
            token,
            sub_location_code,
          }
        );
        if (data.status === 1) {
          const updatedItems = data?.data
            .map((item) => ({
              ...item,
              details: "NonBoxLoc",
              image: item.url,
              qty: parseInt(item.qty, 10),
            }))
            .map(({ url, ...rest }) => rest); // Remove the 'url' field

          const totalQty = updatedItems.reduce(
            (sum, item) => sum + item.qty,
            0
          );
          setSublocationData(updatedItems);
          setTotalQty(totalQty);
          setError2("");
        } else {
          setError2(data.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  useEffect(() => {
    fetchSublocationDetails(sublocation);
  }, [sublocation, fetchSublocationDetails]);

  const fetchBoxNo = useCallback(
    async (sub_location_code) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "ajax_new_box_label",
            token,
            sub_location_code,
          }
        );
        if (data.status === 1) {
          setBoxName2(data?.data?.unique_box_no);
          setError3("");
        } else {
          setBoxName2("");
          setError3(data.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  useEffect(() => {
    fetchBoxNo(sublocation2);
  }, [sublocation2, fetchBoxNo]);

  const handleChange = (name, value) => {
    if (name === "boxName") {
      setBoxName(value);
    } else if (name === "sublocation") {
      setSublocation(value);
    } else {
      setSublocation2(value);
    }
  };

  const fetchBoxLabel = async (boxName) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "print_box_label",
          token,
          box_no: boxName,
        }
      );
      if (data.status === 1) {
        const link = document.createElement("a");
        link.href = BASE_URL.replace("picker-portal-api", "") + data.data;
        link.download = "Box-" + boxName + ".pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      alert.error("Something went wrong");
    }
  };

  const handleSubmitNew = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "sub_location_to_box",
          token,
          sub_location_code: sublocation,
          box_no: boxName,
          sub_location_id: sublocation2,
          type: "new",
        }
      );
      setLoading(false);
      if (data.status === 1) {
        alert.success("Submitted Successfully");
        setBoxName("");
        setSublocation("");
        setSublocation2("");
        setBox1Data({});
        setSublocationData([]);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert.error("Something went wrong");
    }
  };

  const handleSubmitExisting = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
        {
          service_name: "sub_location_to_box",
          token,
          sub_location_code: sublocation,
          box_no: boxName,
          type: "box",
        }
      );
      setLoading(false);
      if (data.status === 1) {
        alert.success("Submitted Successfully");
        setBoxName("");
        setSublocation("");
        setSublocation2("");
        setBox1Data({});
        setSublocationData([]);
      } else {
        alert.error(data.msg);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert.error("Something went wrong");
    }
  };

  const [message, setMessage] = useState("");
  const [isopen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(16);
  const [modalDetail, setModalDetail] = useState("Box");
  const [modalBoxId, setModalBoxId] = useState("");
  const [modalProductId, setModalProductId] = useState("");

  const handleModal = (i) => {
    setModalIndex(i);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const renderModal = () => {
    if (isopen) {
      return (
        <ModalContainer
          Isopen={isopen}
          index={modalIndex}
          closeModal={closeModal}
          details={modalDetail}
          box_id={modalBoxId}
          product_id={modalProductId}
          title={"Alert"}
          message={message}
          handleSubmit={
            boxType === "New" ? handleSubmitNew : handleSubmitExisting
          }
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderModal()}
      <div className="summary-box">
        <div className="grid-delivery-partner-info">
          <div>
            <label htmlFor="courierName">Choose Session Id</label>
            <Select
              placeholder={`Session Id`}
              //   options={
              //     carriers &&
              //     carriers.map((option) => ({
              //       value: option.name,
              //       label: option.name,
              //       id: option.id,
              //     }))
              //   }
              className="select"
              id="courierName"
              //value={carier_id}
              styles={customStylesSKU}
              // onChange={(selectedOption) => handleCourierName(selectedOption)}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={"Rohit_1"}
              label="Created By"
              placeholder="Created By"
              id="created_by"
              darked={true}
              // handover={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={"Jun 15, 2024 12:34 PM"}
              label="Start Date and Time"
              placeholder="Date & Time"
              id="datetime"
              darked={true}
              // handover={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={sublocation}
              label="Scan Sub Location"
              placeholder="Scan Sub Location"
              id="scanbox"
              scan={true}
              readOnly={false}
              onChange={handleChange}
              field={"sublocation"}
              error={sublocation !== "" && error2}
            />
          </div>
        </div>

        <div className="table-container-pagination overflow-hidden">
          <div className="overflow-auto">
            <DataTable
              header={["SKU Code", "Qty", "Action"]}
              boxboxtransfer={sublocationData}
              onData={handleModal}
              onDetail={setModalDetail}
              onHeadingIndex={setModalBoxId}
              assign_Picklist_id={setModalProductId}
            />
          </div>
        </div>

        <div className="mt-3 btn-container">
          <button
            className="submit-btn"
            style={{ margin: "0", maxWidth: "200px" }}
            onClick={() => setBoxType("New")}
          >
            Create New Box
          </button>
          <button
            className="submit-btn"
            style={{
              margin: "0",
              maxWidth: "200px",
              backgroundColor: "#26A6FE",
            }}
            onClick={() => setBoxType("Existing")}
          >
            Existing Box
          </button>
        </div>
      </div>

      {boxType === "New" && (
        <div className="summary-box mt-3">
          <div className="grid-delivery-partner-info">
            <div className="mt-56">
              <InputField
                // loading={handoverNameLoading}
                value={sublocation2}
                label="Scan Sub Location"
                placeholder="Scan Sub Location"
                id="scanbox"
                scan={true}
                readOnly={false}
                onChange={handleChange}
                field={"sublocation2"}
                error={sublocation2 !== "" && error3}
              />
            </div>
            <div className="mt-56">
              <InputField
                // loading={handoverNameLoading}
                value={boxName2}
                label="Box Number"
                placeholder="Box Number"
                darked={true}
              />
            </div>
          </div>

          <div className="mt-3 btn-container">
            <button
              className="submit-btn"
              style={{ margin: "0", maxWidth: "200px" }}
              disabled={sublocation === "" || sublocation2 === ""}
              onClick={() => {
                setIsOpen(true);
                setModalIndex(19);
                setMessage(
                  `Box <strong>${boxName2}</strong> has <strong>${totalqty}</strong> units from the sub-location <strong>${sublocation}</strong>. Do you want to continue?`
                );
              }}
            >
              {loading ? <Loader /> : "Submit"}
            </button>
          </div>
        </div>
      )}

      {boxType === "Existing" && (
        <div className="summary-box mt-3">
          <div className="grid-delivery-partner-info">
            <div className="mt-56">
              <InputField
                // loading={handoverNameLoading}
                value={boxName}
                label="Scan Box Id"
                placeholder="Scan Box Id"
                id="scanbox"
                scan={true}
                readOnly={false}
                onChange={handleChange}
                field={"boxName"}
                error={boxName !== "" && error}
              />
            </div>
          </div>
          <div style={{ marginBottom: "2rem" }}>
            <KeyPairs
              data={[
                {
                  key: "Location",
                  value: box1Data?.box_info?.Location
                    ? box1Data?.box_info?.Location
                    : "Scan Box First",
                },
                {
                  key: "Sub Location",
                  value: box1Data?.box_info?.SubLocation
                    ? box1Data?.box_info?.SubLocation
                    : "Scan Box First",
                },
                {
                  key: "Current Quantity",
                  value:
                    box1Data?.box_info?.current_qty ||
                    box1Data?.box_info?.current_qty === 0
                      ? box1Data?.box_info?.current_qty
                      : "Scan Box First",
                },
                {
                  key: "Initial Quantity",
                  value:
                    box1Data?.box_info?.qty || box1Data?.box_info?.qty === 0
                      ? box1Data?.box_info?.qty
                      : "Scan Box First",
                },
                {
                  key: "Complete Location",
                  value: box1Data?.box_info?.SubLocation
                    ? `${box1Data?.box_info?.SubLocation}>>${box1Data?.box_info?.Location}`
                    : "Scan Box First",
                },
              ]}
            />
          </div>
          <div className="table-container-pagination overflow-hidden">
            <div className="overflow-auto">
              {box1Data?.products && (
                <DataTable
                  header={["SKU Code", "Qty", "Action"]}
                  boxboxtransfer={Object.entries(box1Data?.products).map(
                    ([product_id, product]) => ({
                      product_id,
                      details: "Box",
                      box_id: box1Data?.box_info?.id,
                      ...product,
                    })
                  )}
                  onData={handleModal}
                  onDetail={setModalDetail}
                  onHeadingIndex={setModalBoxId}
                  assign_Picklist_id={setModalProductId}
                />
              )}
            </div>
          </div>

          <div className="mt-3 btn-container">
            <button
              className="submit-btn"
              style={{ margin: "0", maxWidth: "200px" }}
              disabled={sublocation === "" || boxName === ""}
              onClick={() => {
                setIsOpen(true);
                setModalIndex(19);
                setMessage(
                  `The existing Box <strong>${boxName}</strong> has <strong>${
                    box1Data?.box_info?.qty
                  }</strong> units. With an additional <strong>${totalqty}</strong> units from the sub-location <strong>${sublocation}</strong>, the box will now have a total of <strong>${
                    Number(totalqty) + Number(box1Data?.box_info?.current_qty)
                  }</strong> units.`
                );
              }}
            >
              {loading ? <Loader /> : "Submit"}
            </button>
            <button
              className="submit-btn"
              style={{
                margin: "0",
                maxWidth: "200px",
                backgroundColor: "#26A6FE",
              }}
              disabled={boxName === ""}
              onClick={() => {
                fetchBoxLabel(boxName);
              }}
            >
              Create Box Label
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SubLocationToBoxTransfer;
