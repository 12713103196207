// import React from "react";

// import { Bar } from "react-chartjs-2";
// import {
//   Chart,
//   LinearScale,
//   CategoryScale,
//   BarElement,
//   Legend,
// } from "chart.js";
// import "./Chartstyles.css";

// Chart.register(LinearScale, CategoryScale, BarElement, Legend);

// const GroupedBar = ({ stats_data }) => {
//   const labels = stats_data && stats_data.map((i) => i.name);
//   const datasets = [];

//   const getRandomColor = () => {
//     const letters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//     let color = "#";
//     for (let i = 0; i < 6; i++) {
//       color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
//   };

//   Object.keys(stats_data[0]).forEach((key) => {
//     if (key !== "name") {
//       datasets.push({
//         label: key,
//         data: stats_data && stats_data.map((item) => item[key]),
//         backgroundColor: stats_data.map(() => getRandomColor()),
//         borderRadius: 100,
//         barPercentage: 0.3,
//         categoryPercentage: 0.1,
//       });
//     }
//   });

//   const data = {
//     labels: labels,
//     datasets: datasets,
//   };

//   const options = {
//     scales: {
//       x: {
//         stacked: true,
//       },
//       y: {
//         stacked: true,
//       },
//     },
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "bottom",
//         labels: {
//           font: {
//             family: "Poppins_SemiBold", // Add your font here to change the font of your legend label
//           },
//           usePointStyle: true,
//           boxWidth: 6,
//           padding: 20,
//           filter: function (legendItem) {
//             return legendItem.datasetIndex < 20; // Display only the first three legend items
//           },
//         },
//       },
//       title: {
//         display: true,
//       },
//     },
//   };
//   return (
//     <div className="doughnut-wrappers">
//       <Bar data={data} options={options} />
//     </div>
//   );
// };

// export default GroupedBar;

import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  LinearScale,
  CategoryScale,
  BarElement,
  Legend,
} from "chart.js";

Chart.register(LinearScale, CategoryScale, BarElement, Legend);

const GroupedBar = ({ stats_data }) => {
  // console.log(stats_data);
  // Transform the input data into the format expected by Chart.js
  const getRandomColor = () => {
    const letters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const data = {
    labels: stats_data && stats_data.map((item) => item.name),
    datasets: [
      {
        label: "Single Order Picked",
        data:
          stats_data &&
          stats_data.map((item) => Number(item.single_order_picked)),
        backgroundColor: stats_data.map(() => getRandomColor()),
        stack: "stack1",
        borderRadius: 100,
        barPercentage: 0.5,
        categoryPercentage: 0.2,
      },
      {
        label: "Single Order Handover",
        data:
          stats_data &&
          stats_data.map((item) => Number(item.single_order_handover)),
        backgroundColor: stats_data.map(() => getRandomColor()),
        stack: "stack1",
        borderRadius: 100,
        barPercentage: 0.5,
        categoryPercentage: 0.2,
      },
      {
        label: "Single Order Pending",
        data:
          stats_data &&
          stats_data.map((item) => Number(item.single_order_pending)),
        backgroundColor: stats_data.map(() => getRandomColor()),
        stack: "stack1",
        borderRadius: 100,
        barPercentage: 0.5,
        categoryPercentage: 0.2,
      },
      {
        label: "Multi Order Picked",
        data:
          stats_data &&
          stats_data.map((item) => Number(item.multi_order_picked)),
        backgroundColor: stats_data.map(() => getRandomColor()),
        stack: "stack2",
        borderRadius: 100,
        barPercentage: 0.5,
        categoryPercentage: 0.2,
      },
      {
        label: "Multi Order Handover",
        data:
          stats_data &&
          stats_data.map((item) => Number(item.multi_order_handover)),
        backgroundColor: stats_data.map(() => getRandomColor()),
        stack: "stack2",
        borderRadius: 100,
        barPercentage: 0.5,
        categoryPercentage: 0.2,
      },
      {
        label: "Multi Order Pending",
        data:
          stats_data &&
          stats_data.map((item) => Number(item.multi_order_pending)),
        backgroundColor: stats_data.map(() => getRandomColor()),
        stack: "stack2",
        borderRadius: 100,
        barPercentage: 0.5,
        categoryPercentage: 0.2,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        indexAxis: "category",
        stacked: true,
      },
      y: {
        beginAtZero: true,
        stacked: true,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            family: "Poppins_SemiBold", // Add your font here to change the font of your legend label
          },
          usePointStyle: true,
          boxWidth: 6,
          padding: 20,
          filter: function (legendItem) {
            return legendItem.datasetIndex < 20; // Display only the first three legend items
          },
        },
      },
      title: {
        display: true,
      },
    },
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default GroupedBar;
