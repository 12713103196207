import React, { Fragment, useState } from "react";
import MetaData from "../../Components/Helmet/Helmet";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import DataTable from "../../Components/Table/DataTable";
import ModalContainer from "../../Components/Modal/Modal";
import { AdvanceSubLocationTransferSummaryHeader } from "../../DummyArray/TableArray/TableData";

const AdvanceSubLocationTransferSummary = () => {
  const [isIndex, setIsIndex] = useState(null);

  // const handleModal = (index) => {
  //   setIsIndex(index);
  // };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer Isopen={true} index={isIndex} closeModal={closeModal} />
      );
    }
    return null;
  };

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <Fragment>
      <div
        className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
      >
        <MetaData title={`Advance SubLocation Transfer Summary`} />
        <div>
          <SideBar handleIsToggle={handleIsToggle} />
        </div>
        <div>
          <Header isToggle={isToggle} />
          <div className="wrapper-picker-container">
            {/* Header Title Component row starts here */}
            <Headline
              title="Advance Sub Location Transfer Summary"
              hide={true}
            />
            {/* Header Title Component row ends here */}

            {/* Table Component starts here */}
            <div className="table-wrapper-pagination">
              <div className="table-container-pagination">
                <DataTable
                  header={AdvanceSubLocationTransferSummaryHeader}
                  sublocationTransferSummary={[
                    {
                      session_id: "12345",
                      createdby: "Kevin",
                      date: "July 15, 2024",
                      type: "Box to Box",
                      name: "Box 1234",
                      sublocation: "-",
                      name2: "-",
                      sublocation2: "-",
                      description: "BX123456",
                      descriptionSublocation: "-",
                    },
                  ]}
                  // handleRequestCount={handleModal}
                />
              </div>
            </div>
            {/* Table Component ends here */}
          </div>
        </div>
      </div>
      {renderModal()}
    </Fragment>
  );
};

export default AdvanceSubLocationTransferSummary;
