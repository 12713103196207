import React, { useCallback, useEffect, useState } from "react";
import "./Home.css";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import CounterContainer from "../../Components/CountersContainer/CounterContainer";
import ActiveUsers from "../../Components/ActiveUsers/ActiveUsers";
import GroupedBar from "../../Components/Bars/GroupedBar";
import DoughnutChart from "../../Components/Bars/DoughnutChart";
import Select from "react-select";
import { home_date } from "../../DummyArray/TableArray/TableData";
import { RadiuscustomStyles } from "../../Components/DropdownStyles/DropdownStyles";
import { useDispatch, useSelector } from "react-redux";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  clearErrors,
  getDashboardAllDataActions,
  getDashboardUserActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import { useMemo } from "react";
import { useAlert } from "react-alert";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import { Button } from "@material-ui/core";
import MetaData from "../../Components/Helmet/Helmet";
import DatePicker from "../../Components/HeadlineSpecial/DatePicker";
// import secureLocalStorage from "react-secure-storage";

const Home = () => {
  // let pass = secureLocalStorage.getItem("MNSEXESPS");
  // console.log(pass);
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { Users, loading } = useSelector((state) => state.dashboardUsers);
  const { dashboard_all_data, loading: dashboardLoading } = useSelector(
    (state) => state.dashboardAllData
  );
  const [from_time, setfrom_time] = useState(
    localStorage.getItem("metrics_date_cache") !== null
      ? localStorage.getItem("metrics_date_cache")
      : home_date && home_date[0]?.id
  );
  const [from_time_label, setfrom_time_label] = useState(
    localStorage.getItem("metrics_date_cache_line") !== null
      ? localStorage.getItem("metrics_date_cache_line")
      : home_date && home_date[0]?.name
  );

  // filter from_date & to_date starts here
  const [dashboard_filter_data, Setdashboard_filter_data] = useState([
    new Date(),
    new Date(),
  ]);

  const start_date = dashboard_filter_data && dashboard_filter_data[0];
  const end_date = dashboard_filter_data && dashboard_filter_data[1];

  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // Format the date as "dd-MM-yyyy"
  const from_date = start_date ? formatDateToYYYYMMDD(start_date) : null;
  const to_date = end_date ? formatDateToYYYYMMDD(end_date) : null;

  const handleDashboard_dataFilter = (val) => {
    Setdashboard_filter_data(val);

    const start_date = val ? val[0] : null;
    const end_date = val ? val[1] : null;

    const from_date = start_date ? formatDateToYYYYMMDD(start_date) : null;
    const to_date = end_date ? formatDateToYYYYMMDD(end_date) : null;

    let filters = {
      from_date: from_date,
      to_date: to_date,
    };

    // console.log("Function Filters: ", filters);

    dispatch(getDashboardAllDataActions("dashboard", token, filters));

    // console.log("FROM DATE: ", from_date);
    // console.log("TO DATE: ", to_date);
  };
  // filter from_date & to_date ends here

  const [hoverOffset, setHoverOffset] = useState(10);
  const [padding, setPadding] = useState(10);

  const [filterTrue, setFilterTrue] = useState(false);
  const newDateTime = new Date().toString();

  const handleDateChange = async (selectedOption) => {
    await localStorage.removeItem("Metrics_cache");
    localStorage.setItem("metrics_date_cache", selectedOption.value);
    localStorage.setItem("metrics_date_cache_line", selectedOption.label);
    setfrom_time(selectedOption.value);
    setfrom_time_label(selectedOption.label);

    localStorage.setItem("Last_cache", newDateTime);
  };

  useEffect(() => {
    if (window.innerWidth <= 992) {
      setHoverOffset(5);
      setPadding(5);
    } else {
      setHoverOffset(10);
      setPadding(10);
    }
  }, []);

  // cache data for metrics pie & bar
  let User_list_cache = JSON.parse(localStorage.getItem("User_List_cache"));

  useEffect(() => {
    if (Users && Users?.status === 1 && !filterTrue) {
      localStorage.setItem("User_List_cache", JSON.stringify(Users?.data));
    }
  }, [Users, filterTrue]);

  const filters_present = useMemo(() => {
    return {
      is_present: 1,
    };
  }, []);

  useEffect(() => {
    if (User_list_cache === null && !filterTrue) {
      localStorage.setItem("Last_cache", newDateTime);
      dispatch(
        getDashboardUserActions(
          "picker_management",
          1,
          token,
          6,
          filters_present
        )
      );
    }
  }, [
    dispatch,
    token,
    User_list_cache,
    filterTrue,
    newDateTime,
    filters_present,
  ]);

  //   pagination code
  const [user_list, setUser_list] = useState(
    User_list_cache !== null ? User_list_cache : []
  );
  const [pages, setPages] = useState(
    localStorage.getItem("Pages") ? localStorage.getItem("Pages") : 2
  );
  const [paginationLoader, setPaginationLoader] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(
    localStorage.getItem("NextPage") !== null
      ? localStorage.getItem("NextPage")
      : true
  );
  let limit = 6;

  // console.log(user_list, "sss");

  // Function to save user_list to localStorage
  const saveUserListToLocalStorage = (data) => {
    localStorage.setItem("User_List_cache", JSON.stringify(data));
  };

  const handleNextUsers = () => {
    // localStorage.removeItem("Pages");
    setFilterTrue(true);
    setPages(pages + 1);
    setPaginationLoader(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "picker_management",
        page: pages === 1 ? 2 : pages,
        token,
        limit,
        filters_present,
      })
      .then((response) => {
        if (response?.data?.data?.length === 0) {
          setHasNextPage(false);
          setPaginationLoader(false);
          localStorage.setItem("NextPage", false);
        } else {
          const mergedAgents = [...user_list, ...response?.data?.data];
          const uniqueArr = Array.from(
            new Set(mergedAgents.map((obj) => JSON.stringify(obj)))
          ).map((str) => JSON.parse(str));
          setUser_list(uniqueArr);
          setPaginationLoader(false);
          saveUserListToLocalStorage(uniqueArr);
          localStorage.setItem("Pages", pages);
          localStorage.setItem("Last_cache", newDateTime);
        }
      })
      .catch((err) => {
        console.log(err);
        setPaginationLoader(false);
      });
  };

  let filters = useMemo(() => {
    const dataToSend = {
      from_time,
      from_date,
      to_date,
    };

    // Filtering empty value
    const filter_data = Object.fromEntries(
      Object.entries(dataToSend).filter(
        ([_, value]) =>
          value !== "" && value !== null && value !== "Invalid date"
      )
    );

    return filter_data;
  }, [from_time, from_date, to_date]);

  // console.log("Filters: ", filters);

  // cache data for metrics pie & bar
  let Metrics_cache = JSON.parse(localStorage.getItem("Metrics_cache"));

  useEffect(() => {
    if (dashboard_all_data && dashboard_all_data?.status === 1) {
      localStorage.setItem("Metrics_cache", JSON.stringify(dashboard_all_data));
    }
  }, [dashboard_all_data]);

  useEffect(() => {
    if (Metrics_cache === null) {
      dispatch(getDashboardAllDataActions("dashboard", token, filters));
      localStorage.setItem("Last_cache", newDateTime);
    }
  }, [dispatch, token, filters, Metrics_cache, newDateTime]);

  // console.log("Dashboard Datas: ", dashboard_all_data);

  let dashboard_all_data_metrics =
    Metrics_cache && Metrics_cache !== null
      ? Metrics_cache?.data
      : dashboard_all_data && dashboard_all_data?.data;

  const fetchUsers = useCallback(() => {
    if (User_list_cache === null) {
      setUser_list(Users && Users?.data);
    }
    // eslint-disable-next-line
  }, [Users]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  let Total_picklist =
    dashboard_all_data_metrics &&
    parseInt(dashboard_all_data_metrics?.picklist_total);

  let Total_order_picked =
    dashboard_all_data_metrics &&
    dashboard_all_data_metrics?.order_picked !== "" &&
    dashboard_all_data_metrics?.order_picked !== null
      ? dashboard_all_data_metrics?.order_picked
      : 0;

  let Total_orders =
    dashboard_all_data_metrics &&
    dashboard_all_data_metrics?.total_orders !== "" &&
    dashboard_all_data_metrics?.total_orders !== null
      ? dashboard_all_data_metrics?.total_orders
      : 0;

  let label_orders_count =
    dashboard_all_data_metrics &&
    dashboard_all_data_metrics?.label_orders_count !== "" &&
    dashboard_all_data_metrics?.label_orders_count !== null
      ? dashboard_all_data_metrics?.label_orders_count
      : 0;

  let handover_orders_count =
    dashboard_all_data_metrics &&
    dashboard_all_data_metrics?.handover_orders_count !== "" &&
    dashboard_all_data_metrics?.handover_orders_count !== null
      ? dashboard_all_data_metrics?.handover_orders_count
      : 0;

  let multi_order_counts =
    dashboard_all_data_metrics &&
    dashboard_all_data_metrics?.multiorders !== "" &&
    dashboard_all_data_metrics?.multiorders !== null
      ? dashboard_all_data_metrics?.multiorders
      : 0;

  let multiorder_units_counts =
    dashboard_all_data_metrics &&
    dashboard_all_data_metrics?.multiorder_units !== "" &&
    dashboard_all_data_metrics?.multiorder_units !== null
      ? dashboard_all_data_metrics?.multiorder_units
      : 0;

  let total_serial_codes =
    dashboard_all_data_metrics &&
    dashboard_all_data_metrics?.total_serial_codes !== "" &&
    dashboard_all_data_metrics?.total_serial_codes !== null
      ? dashboard_all_data_metrics?.total_serial_codes
      : 0;

  const OperationalMetrics = [
    {
      id: 1,
      count:
        Total_picklist && Total_picklist !== "" && Total_picklist !== null
          ? Total_picklist
          : 0,
      title: "Total Picklist",
      color: "#9DE4F2",
    },
    {
      id: 19,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.picklist_active !== "" &&
        dashboard_all_data_metrics?.picklist_active !== null
          ? dashboard_all_data_metrics?.picklist_active
          : 0,
      title: "Active Picklist",
      color: "#FFE17B",
    },
    {
      id: 2,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.picklist_completed !== "" &&
        dashboard_all_data_metrics?.picklist_completed !== null
          ? dashboard_all_data_metrics?.picklist_completed
          : 0,
      title: "Completed Picklist",
      color: "#F2AE24",
    },
    {
      id: 3,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.picklist_pending !== "" &&
        dashboard_all_data_metrics?.picklist_pending !== null
          ? dashboard_all_data_metrics?.picklist_pending
          : 0,
      title: "Pending Picklist",
      color: "#F28D85",
    },
    {
      id: 19,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.picklist_cancelled !== "" &&
        dashboard_all_data_metrics?.picklist_cancelled !== null
          ? dashboard_all_data_metrics?.picklist_cancelled
          : 0,
      title: "Cancelled Picklist",
      color: "#C70039",
    },
    {
      id: 4,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.picklist_hold !== "" &&
        dashboard_all_data_metrics?.picklist_hold !== null
          ? dashboard_all_data_metrics?.picklist_hold
          : 0,
      title: "Hold Picklist",
      color: "#F23079",
    },
    {
      id: 5,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.picklist_closed !== "" &&
        dashboard_all_data_metrics?.picklist_closed !== null
          ? dashboard_all_data_metrics?.picklist_closed
          : 0,
      title: "Closed Picklist",
      color: "#F2BEB6",
    },
    {
      id: 6,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.logged_pickers !== "" &&
        dashboard_all_data_metrics?.logged_pickers !== null
          ? dashboard_all_data_metrics?.logged_pickers
          : 0,
      title: "Logged Pickers",
      comment: "Today's Data",
      color: "#A3D9CC",
    },
    {
      id: 7,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.total_serial_codes !== "" &&
        dashboard_all_data_metrics?.total_serial_codes !== null
          ? dashboard_all_data_metrics?.total_serial_codes
          : 0,
      title: "Total Serial Codes",
      color: "#C396E5",
    },
    {
      id: 8,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.picked_serial_codes !== "" &&
        dashboard_all_data_metrics?.picked_serial_codes !== null
          ? dashboard_all_data_metrics?.picked_serial_codes
          : 0,
      title: "Picked Serial Codes",
      color: "#B8F39E",
    },
    {
      id: 9,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.pending_serial_codes !== "" &&
        dashboard_all_data_metrics?.pending_serial_codes !== null
          ? dashboard_all_data_metrics?.pending_serial_codes
          : 0,
      title: "Pending Serial Codes",
      color: "#8FC8EB",
    },
    {
      id: 10,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.avg_picking_time !== "" &&
        dashboard_all_data_metrics?.avg_picking_time !== null
          ? dashboard_all_data_metrics?.avg_picking_time
          : 0,
      title: "Average Picking Time",
      color: "#8FA1BF",
    },
    {
      id: 11,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.total_orders !== "" &&
        dashboard_all_data_metrics?.total_orders !== null
          ? dashboard_all_data_metrics?.total_orders
          : 0,
      title: "Total Orders Count",
      color: "#FAC585",
    },
    {
      id: 12,
      count: Total_order_picked + "/" + Total_orders,
      title: "Total Order Picked",
      color: "#B8BFD6",
    },
    {
      id: 13,
      count: label_orders_count + "/" + Total_order_picked,
      title: "Order Label Generate",
      color: "#86AFB2",
    },
    {
      id: 14,
      count: handover_orders_count + "/" + label_orders_count,
      title: "Order Handed Over",
      color: "#CCAC8F",
    },
    {
      id: 15,
      count: multi_order_counts + "/" + Total_orders,
      title: "Multi Order Count",
      color: "#00ECC2",
    },
    {
      id: 16,
      count: multiorder_units_counts + "/" + total_serial_codes,
      title: "Multi Order Units Count",
      color: "#83E5D4",
    },
    {
      id: 17,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.bag_handover_count !== "" &&
        dashboard_all_data_metrics?.bag_handover_count !== null
          ? dashboard_all_data_metrics?.bag_handover_count
          : 0,
      title: "Total bags handover",
      color: "#00ECC2",
    },
    {
      id: 18,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.item_handover_count !== "" &&
        dashboard_all_data_metrics?.item_handover_count !== null
          ? dashboard_all_data_metrics?.item_handover_count
          : 0,
      title: "Total items handover",
      color: "#83E5D4",
    },
  ];

  const GeneralMetrics = [
    {
      id: 1,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.available_manpower !== "" &&
        dashboard_all_data_metrics?.available_manpower !== null
          ? dashboard_all_data_metrics?.available_manpower
          : 0,
      title: "Total Available Manpower",
      color: "#D9ACF5",
    },
    {
      id: 2,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.avg_in_time_manpower !== "" &&
        dashboard_all_data_metrics?.avg_in_time_manpower !== null
          ? dashboard_all_data_metrics?.avg_in_time_manpower
          : 0,
      title: "Avg in time manpower",
      color: "#ABDBF5",
    },
    {
      id: 3,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.absent_manpower !== "" &&
        dashboard_all_data_metrics?.absent_manpower !== null
          ? dashboard_all_data_metrics?.absent_manpower
          : 0,
      title: "Total absent manpower",
      color: "#FFCCDE",
    },
    {
      id: 4,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.totes_total !== "" &&
        dashboard_all_data_metrics?.totes_total !== null
          ? dashboard_all_data_metrics?.totes_total
          : 0,
      title: "total totes count",
      color: "#F5C4C4",
    },
    {
      id: 5,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.totes_vacant !== "" &&
        dashboard_all_data_metrics?.totes_vacant !== null
          ? dashboard_all_data_metrics?.totes_vacant
          : 0,
      title: "vacant totes",
      color: "#ABF5BC",
    },
    {
      id: 6,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.not_found_sublocation !== "" &&
        dashboard_all_data_metrics?.not_found_sublocation !== null
          ? dashboard_all_data_metrics?.not_found_sublocation
          : 0,
      title: "Not found sublocation count",
      color: "#ABC0F5",
    },
    {
      id: 7,
      count:
        dashboard_all_data_metrics &&
        dashboard_all_data_metrics?.not_found_stock !== "" &&
        dashboard_all_data_metrics?.not_found_stock !== null
          ? dashboard_all_data_metrics?.not_found_stock
          : 0,
      title: "Not found stock count",
      color: "#F5D7AB",
    },
  ];

  // console.log("Dashboard Data: ", dashboard_all_data_metrics);

  let stats_total_orders =
    Metrics_cache && Metrics_cache !== null
      ? Metrics_cache?.data
      : dashboard_all_data && dashboard_all_data?.data;

  let statistics_total_order =
    stats_total_orders && stats_total_orders?.party_wise_statistics;
  let courier_wise_order =
    stats_total_orders && stats_total_orders?.courier_wise_statistics;

  const stats_list = [
    {
      name: "No Data",
      multi_order_handover: 0,
      pending_handover: 0,
    },
  ];

  let total_stats =
    stats_total_orders && stats_total_orders?.stats_total_orders;

  useEffect(() => {
    if (dashboard_all_data && dashboard_all_data?.status === 0) {
      alert.error(dashboard_all_data && dashboard_all_data?.msg);
      dispatch(clearErrors());
    }
  }, [dispatch, dashboard_all_data, alert]);

  const handleRemoveCache = () => {
    localStorage.setItem("Last_cache", newDateTime);
    localStorage.removeItem("metrics_date_cache");
    localStorage.removeItem("Metrics_cache");
    localStorage.removeItem("User_List_cache");
    localStorage.removeItem("metrics_date_cache_line");
    localStorage.removeItem("NextPage");
    localStorage.removeItem("Pages");

    dispatch(getDashboardAllDataActions("dashboard", token, filters));
    dispatch(
      getDashboardUserActions("picker_management", 1, token, 6, filters_present)
    );

    setfrom_time("today");
    setfrom_time_label("Today");
    setHasNextPage(true);
  };

  let last_cache_updated_at = localStorage.getItem("Last_cache");

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <>
      <MetaData title={`Dashboard`} />
      {/* 70 30 % grid col starts here */}
      <div
        className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
        // style={{ gridTemplateColumns: isToggle && "1fr" }}
      >
        <div>
          <SideBar handleIsToggle={handleIsToggle} />
        </div>
        <div>
          <Header isToggle={isToggle} />
          <div className="wrapper-container">
            <div className="main-home-container">
              {dashboardLoading || loading ? (
                <LoaderAlone />
              ) : (
                <>
                  {dashboard_all_data && dashboard_all_data?.status === 0 ? (
                    <NoDataFromApi
                      height={true}
                      title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                    />
                  ) : (
                    <>
                      <div className="ind-d-flex">
                        <h1>Operational Metric</h1>
                        <div className="date-time-updated">
                          <p>
                            {new Date(last_cache_updated_at).toLocaleString()}
                          </p>
                          <Select
                            placeholder={`${
                              from_time_label !== "" && from_time_label
                            }`}
                            options={
                              home_date &&
                              home_date.map((option) => ({
                                value: option.id,
                                label: option.name,
                              }))
                            }
                            value={from_time_label}
                            styles={RadiuscustomStyles}
                            onChange={handleDateChange}
                          />
                          <DatePicker
                            dashboard_filter_data={dashboard_filter_data}
                            dataRange={handleDashboard_dataFilter}
                          />
                        </div>
                      </div>
                      <Button className="show-l-data">
                        <p
                          className="latest-data"
                          onClick={() => handleRemoveCache()}
                        >
                          <RefreshIcon />
                          &nbsp; Latest Data
                        </p>
                      </Button>
                      {/* grid count row starts here */}
                      <div
                        className={`grid-opertional-row ${
                          isToggle && "metric-grid-operation-row"
                        }`}
                        // style={{
                        //   gridTemplateColumns: isToggle
                        //     ? "repeat(5, 1fr)"
                        //     : "repeat(4, 1fr)",
                        // }}
                      >
                        {OperationalMetrics &&
                          OperationalMetrics.map((i) => {
                            return (
                              <CounterContainer
                                count={i.count}
                                color={i.color}
                                title={i.title}
                                key={i.id}
                                comment={i.comment}
                              />
                            );
                          })}
                      </div>
                      {/* grid count row ends here */}
                      <h2>General Metric</h2>
                      <div className="grid-opertional-row">
                        {GeneralMetrics &&
                          GeneralMetrics.map((i) => {
                            return (
                              <CounterContainer
                                key={i.id}
                                count={i.count}
                                color={i.color}
                                title={i.title}
                                uniqueKey={i.id}
                              />
                            );
                          })}
                      </div>
                      {/* statistics doughnut chart */}
                      <div className="doughnut-chart-grid-row">
                        <div>
                          <p>Statistics</p>
                          <h3>Total Orders</h3>
                          <div className="doughnut-wrapper">
                            <DoughnutChart
                              hoverOffset={hoverOffset}
                              padding={padding}
                              data_list={
                                statistics_total_order &&
                                statistics_total_order?.length > 0
                                  ? statistics_total_order
                                  : stats_list && stats_list.length > 0
                                  ? stats_list
                                  : null
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <p>Statistics</p>
                          <h3>Courier Wise</h3>
                          <div className="doughnut-wrapper">
                            <DoughnutChart
                              hoverOffset={hoverOffset}
                              padding={padding}
                              data_list={
                                courier_wise_order &&
                                courier_wise_order?.length > 0
                                  ? courier_wise_order
                                  : stats_list && stats_list.length > 0
                                  ? stats_list
                                  : null
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {/* statistics doughnut chart */}
                      <div className="groupedBar-chart">
                        {/* total orders */}
                        <div className="statistics-headline">
                          <div>
                            <p>Statistics</p>
                            <p>Total Orders</p>
                          </div>
                          <div>
                            {/* <Select
                              placeholder={`${date !== "" ? date : dateArr[0]}`}
                              options={dateArr.map((option) => ({
                                value: option,
                                label: option,
                              }))}
                              value={date}
                              styles={RadiuscustomStyles}
                              onChange={handleDateChange}
                            /> */}
                          </div>
                        </div>
                        {/* total orders statistics */}

                        {/* Grouped Bar */}
                        <GroupedBar
                          stats_data={
                            total_stats && total_stats?.length > 0
                              ? total_stats
                              : stats_list && stats_list.length > 0
                              ? stats_list
                              : null
                          }
                        />
                        {/* Grouped Bar */}
                      </div>
                      {/* active users */}
                      <ActiveUsers data={user_list && user_list} />
                      {paginationLoader ? (
                        <LoaderAlone />
                      ) : (
                        <>
                          {user_list?.length > 0 && (
                            <div className="view-more-wrapper">
                              {hasNextPage === true && (
                                <Button
                                  type="submit"
                                  onClick={handleNextUsers}
                                  className="view-more-users"
                                >
                                  View More
                                </Button>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            {/* active users */}
          </div>
        </div>
      </div>
      {/* 70 30 % grid col ends here */}
    </>
  );
};

export default Home;
