import React, { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowIcon from "../../Assets/Icons/SideBar-Icons/arrow-left.png";
import "./PicklistView.css";
import {
  PickListViewHeadArr,
  order_item_header,
  picklist_details_header,
} from "../../DummyArray/TableArray/TableData";
import UserInfo from "../../Components/UserInfo/UserInfo";
import DataTable from "../../Components/Table/DataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  add_permissionActions,
  clearErrors,
  getPicklistViewDetailsActions,
  picklistAnotherDetailsActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import { useAlert } from "react-alert";
import MetaData from "../../Components/Helmet/Helmet";

const colors = {
  btnColor: "#26A6FE",
  btnColor1: "#46CC8D",
  btnColor2: "#FF0000",
};

const PickListView = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { picklist_view, loading } = useSelector(
    (state) => state.picklistViewDetails
  );

  // picklists Detail Table
  const { picklist_another_details, loading: picklistDetails_loading } =
    useSelector((state) => state.picklistAnotherDetails);

  const { id } = useParams();

  // permission state
  const { permission_list_data } = useSelector(
    (state) => state.permissions_data
  );

  useEffect(() => {
    dispatch(add_permissionActions("permissions", token));
  }, [dispatch, token]);

  let permission_list_arr =
    permission_list_data && Array.isArray(permission_list_data?.data)
      ? permission_list_data?.data
      : [];

  const obj = {
    label1: "User ID",
    label2: "Username",
    label3: "Picklist Id",
    label4: "Picklist Type",
    label5: "Quantity Required",
    label6: "Quantity Picked",
    label7: "Quantity Pending",
    label8: "Portal",
    label9: "Create Date",
    blabel10: "Picklist Provide Type",
    blabel11: "Lost Marked Sublocation",
    label11:
      picklist_view && picklist_view?.data?.assigned === 1
        ? "Reassign"
        : "Assign",
    label12: "Download",
    label13:
      (picklist_view && picklist_view?.data?.is_cancel === 1) ||
      (picklist_view && picklist_view?.data?.completed === 1)
        ? "Cancelled"
        : permission_list_arr &&
          permission_list_arr.find((item) => item.name === "cancel_picklist")
        ? "Cancel"
        : "Cancelled",
  };

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (id && id !== "") {
      dispatch(getPicklistViewDetailsActions("picklist_view", token, id));
      dispatch(
        picklistAnotherDetailsActions("inventory_picklist_detail", token, id)
      );
    }
  }, [dispatch, token, id]);

  let picklist_viewer = picklist_view && picklist_view?.data;
  let products = picklist_viewer && picklist_viewer?.products;
  let order_items_list = picklist_viewer && picklist_viewer?.order_items;

  useEffect(() => {
    setShowError(true);
    if (picklist_view && picklist_view?.status === 0 && showError) {
      alert.error(picklist_view && picklist_view?.msg);
      dispatch(clearErrors());
      setShowError(false);
    }
    // eslint-disable-next-line
  }, [dispatch, picklist_view, alert]);

  // console.log("Picklist Viewer: ", picklist_another_details);

  let picklisT_details_data =
    picklist_another_details && picklist_another_details?.data?.details;

  let text = "Not Assigned";

  const inventory_detail_card_arr = [
    {
      id: 1,
      count:
        picklist_viewer?.request_qty !== null ||
        picklist_viewer?.request_qty !== "" ||
        picklist_viewer?.request_qty !== undefined
          ? picklist_viewer?.request_qty
          : 0,
      title: "Request Qty.",
      color: "rgba(255, 178, 178, 1)",
    },
    {
      id: 2,
      count:
        picklist_viewer?.required_qty !== null ||
        picklist_viewer?.required_qty !== "" ||
        picklist_viewer?.required_qty !== undefined
          ? picklist_viewer?.required_qty
          : 0,
      title: "Required Qty.",
      color: "rgba(219, 196, 240, 1)",
    },
    {
      id: 4,
      count:
        picklist_viewer?.found_qty !== null ||
        picklist_viewer?.found_qty !== "" ||
        picklist_viewer?.found_qty !== undefined
          ? picklist_viewer?.found_qty
          : 0,
      title: "Found Qty.",
      color: "rgba(255, 198, 172, 1)",
    },
    {
      id: 6,
      count:
        picklist_viewer?.picked_qty !== null ||
        picklist_viewer?.picked_qty !== "" ||
        picklist_viewer?.picked_qty !== undefined
          ? picklist_viewer?.picked_qty
          : 0,
      title: "Picked Qty.",
      color: "rgba(172, 177, 214, 1)",
    },
    {
      id: 7,
      count:
        picklist_viewer?.pending_qty !== null ||
        picklist_viewer?.pending_qty !== "" ||
        picklist_viewer?.pending_qty !== undefined
          ? picklist_viewer?.pending_qty
          : 0,
      title: "Pending Qty.",
      color: "rgba(255, 242, 204, 1)",
    },
  ];

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  // console.log("Picklist Inventory Details: ", picklist_another_details);

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData
        title={`${
          loading || picklistDetails_loading
            ? "Loading..."
            : picklist_viewer && picklist_viewer?.user_name
            ? picklist_viewer && picklist_viewer?.user_name + " - Picklist View"
            : text + " - Picklist View"
        }`}
      />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          {loading || picklistDetails_loading ? (
            <LoaderAlone />
          ) : (
            <>
              <div className="header-flex-row">
                <div>
                  <Link onClick={() => navigate(-1)} className="go-back-row">
                    <div>
                      <img src={ArrowIcon} alt="" />
                    </div>
                    <div>
                      <h1>Picklist View</h1>
                    </div>
                  </Link>
                </div>
                <div>{/* Something right side goes here */}</div>
              </div>
              {/* userInfo component */}
              <UserInfo
                border={true}
                obj={obj}
                obj1={colors}
                UserUri={picklist_viewer && picklist_viewer?.user_img}
                userId={picklist_viewer && picklist_viewer?.user_id}
                userName={picklist_viewer && picklist_viewer?.user_name}
                currentSpeed={picklist_viewer && picklist_viewer?.picklist_id}
                totalPicked={picklist_viewer && picklist_viewer?.type}
                Pending={picklist_viewer && picklist_viewer?.required_qty}
                Required={picklist_viewer && picklist_viewer?.picked_qty}
                lastscan={picklist_viewer && picklist_viewer?.pending_qty}
                idleSince={picklist_viewer && picklist_viewer?.portal}
                currentLocation={picklist_viewer && picklist_viewer?.created_on}
                pklistType={picklist_viewer && picklist_viewer?.provide_type}
                sublocation={
                  picklist_viewer && picklist_viewer?.lost_marked_sublocation
                }
                hideMinutes={true}
                picklist={true}
              />

              {/* picklist details */}
              <div className="table-wrapper-pagination">
                <div className="table-container-pagination">
                  <h3 className="sku-headings" style={{ marginBottom: "10px" }}>
                    Inventory Details
                  </h3>
                  {/* card box container */}
                  <div className="grid-inventory-row-container">
                    {inventory_detail_card_arr &&
                      inventory_detail_card_arr.map((i) => (
                        <div style={{ backgroundColor: i.color }}>
                          <h3>{i.count}</h3>
                          <p>{i.title}</p>
                        </div>
                      ))}
                  </div>
                  {/* card box container */}
                  <DataTable
                    header={picklist_details_header}
                    picklist_detail_table_data={
                      picklisT_details_data && picklisT_details_data
                    }
                  />
                </div>
              </div>

              {/* product table */}
              <div className="table-wrapper-pagination">
                <div className="table-container-pagination">
                  <h3 className="sku-headings">Product Details</h3>
                  <DataTable
                    header={PickListViewHeadArr}
                    picklistView={products && products}
                  />
                </div>
              </div>

              <div className="table-wrapper-pagination">
                <div className="table-container-pagination">
                  <h3 className="sku-headings">Order Details</h3>
                  <DataTable
                    header={order_item_header}
                    order_items_list={order_items_list && order_items_list}
                  />
                </div>
              </div>

              {/* location display component */}
              <div className="location-display-wrapper">
                <div className="location-container-grid-row">
                  <div>
                    <label htmlFor="userid">Current Location</label> <br />
                    <input
                      style={{
                        backgroundColor: "white",
                        border: "1px solid #E6E6E6",
                      }}
                      type="text"
                      id="userid"
                      value={
                        picklist_viewer && picklist_viewer?.current_location
                      }
                      readOnly
                    />
                  </div>
                  <div>
                    <label htmlFor="userid">Next Location</label> <br />
                    <input
                      style={{
                        backgroundColor: "white",
                        border: "1px solid #E6E6E6",
                      }}
                      type="text"
                      id="userid"
                      value={picklist_viewer && picklist_viewer?.next_location}
                      readOnly
                    />
                  </div>
                  <div>
                    <label htmlFor="userid">Last Scan</label> <br />
                    <input
                      style={{
                        backgroundColor: "white",
                        border: "1px solid #E6E6E6",
                      }}
                      type="text"
                      id="userid"
                      value={picklist_viewer && picklist_viewer?.last_location}
                      readOnly
                    />
                  </div>
                  <div>
                    <label htmlFor="userid">Mark Lost Sublocation</label> <br />
                    <input
                      style={{
                        backgroundColor: "white",
                        border: "1px solid #E6E6E6",
                      }}
                      type="text"
                      id="userid"
                      value={
                        picklist_viewer && picklist_viewer?.not_found_location
                      }
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PickListView;
