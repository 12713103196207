import KeyPairs from "../../../Components/KeyPairs/KeyPairs";
import InputField from "../../../Components/InputField/InputField";

const BoxUnitAudit = () => {
  return (
    <>
      <div className="summary-box">
        <div style={{ marginBottom: "2rem" }}>
          <KeyPairs
            data={[
              { key: "Total No of Sub Locations", value: "438" },
              {
                key: "Total No of Boxes in this Sub Location",
                value: "5000 (2000000 Units)",
              },
            ]}
          />
        </div>
        <div className="grid-delivery-partner-info">
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={"300"}
              label="Total Number of Boxes which unit audit to be done"
              placeholder="Enter boxes"
              id="scanbox"
              // handover={true}
            />
          </div>
        </div>
        <div className="mt-3 btn-container">
          <button
            className="submit-btn"
            style={{ margin: "0", maxWidth: "200px" }}
          >
            Start Audit Transactions
          </button>
        </div>
      </div>
    </>
  );
};

export default BoxUnitAudit;
