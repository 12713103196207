import React, { useCallback, useMemo, useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import "./PortalManagement.css";
import {
  PortalManagementHeader,
  home_date,
} from "../../DummyArray/TableArray/TableData";
import DataTable from "../../Components/Table/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPortalWiseActions } from "../../Actions/PickerMgmt/PickerMgmtActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import { Pagination } from "../../Components/Pagination/Pagination";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import MetaData from "../../Components/Helmet/Helmet";

const PortalManagement = () => {
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { portal_wise_orders, loading, status } = useSelector(
    (state) => state.portalwise
  );

  const [from_time, setfrom_time] = useState(home_date && home_date[0]?.id);
  const [from_time_label, setfrom_time_label] = useState(
    home_date && home_date[0]?.name
  );

  let filters = useMemo(() => {
    const dataToSend = {
      from_time,
    };

    return dataToSend;
  }, [from_time]);

  const onChangeFilter = (val, label) => {
    setfrom_time(val);
    setfrom_time_label(label);
  };

  useEffect(() => {
    dispatch(
      getPortalWiseActions("portal_wise_orders_list", 1, token, 5, filters)
    );
  }, [dispatch, token, filters]);

  // pagination code
  const [portal_orders, setPortal_orders] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  let limit = 5;

  const fetchNextPortals = (page) => {
    setIsLoading(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "portal_wise_orders_list",
        page,
        token,
        limit,
        filters,
      })
      .then((response) => {
        setPortal_orders(response?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handlePage = (page) => {
    setPage(page);
    fetchNextPortals(page);
  };

  const fetchPortalOrders = useCallback(() => {
    portal_wise_orders &&
      setPortal_orders(portal_wise_orders && portal_wise_orders?.data);
  }, [portal_wise_orders]);

  useEffect(() => {
    fetchPortalOrders();
  }, [fetchPortalOrders]);

  const [loadingCSV, setLoadingCsv] = useState(false);

  const handlePendency = async () => {
    setLoadingCsv(true);
    try {
      const response = await axios.post(`${BASE_URL}`, {
        service_name: "csv_portal_wise_orders_list",
        token,
        filters,
      });
      const csvFile = response?.data?.file;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
      }

      setLoadingCsv(false);
    } catch (err) {
      alert.error(err);
      setLoadingCsv(false);
    }
  };

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Portal Management`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          {loading ? (
            <LoaderAlone />
          ) : (
            <>
              {status === 0 ? (
                <NoDataFromApi
                  height={true}
                  title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                />
              ) : (
                <>
                  <p className="portal-management">Portals Management</p>
                  <Headline
                    title="Order Wise"
                    btnTitle="Download Total Pendency"
                    select={true}
                    from_time_label={from_time_label}
                    home_date={home_date}
                    onChangeFilter={onChangeFilter}
                    handleData={handlePendency}
                    loadingCSV={loadingCSV}
                    width={true}
                  />

                  {/* order wise table */}
                  <div className="table-wrapper">
                    <div className="table-container">
                      <DataTable
                        header={PortalManagementHeader}
                        PortalManagementArr={portal_orders && portal_orders}
                      />
                    </div>
                  </div>

                  <div className="rendering-pagination">
                    <Pagination
                      uniqueKey={`ScreenA-${page}`}
                      page={page}
                      isLoading={isLoading}
                      totalPage={
                        portal_wise_orders && portal_wise_orders?.total_pages
                      }
                      onPageChange={handlePage}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortalManagement;
