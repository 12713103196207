import React, { createContext, useContext, useState } from "react";

const DataContext = createContext();

export const useData = () => {
  return useContext(DataContext);
};

export const DataProvider = ({ children }) => {
  const [handoverData, setHandoverData] = useState(null);
  const [carrierData, setCarrierData] = useState(null);

  const [order_Summary, setOrder_Summary] = useState(null);

  const [handover_id, setHandover_id] = useState(null);
  const [unique_reversal_handover_name, setunique_reversal_handover_name] =
    useState(null);

  const [user, setUser] = useState(null);

  return (
    <DataContext.Provider
      value={{
        handoverData,
        setHandoverData,
        carrierData,
        setCarrierData,
        order_Summary,
        setOrder_Summary,
        handover_id,
        setHandover_id,
        unique_reversal_handover_name,
        setunique_reversal_handover_name,
        user,
        setUser,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
