import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { useData } from "../../DataContext/DataContext";
import { useNavigate } from "react-router-dom";
// import { useAlert } from "react-alert";
import axios from "axios";
import { BASE_URL, IMG_DEFAULT } from "../../Base_URL/BaseURL";
import ModalContainer from "../Modal/Modal";
import { useAlert } from "react-alert";
import Loader from "../Loader/Loader";

const UserInfo = (props) => {
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  // const alert = useAlert();
  const navigate = useNavigate();
  const { setUser } = useData();

  const [loadingPDF, setLoadingPDF] = useState(false);

  const [modalNo, setModalNo] = useState();
  const [isIndex, setIsIndex] = useState(null);
  const handleModal = (index) => {
    setIsIndex(index);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          picklist_id={props.currentSpeed}
          modalNo={modalNo}
        />
      );
    }
    return null;
  };

  const redirectCommunication = async (assign, modalNo) => {
    // console.log(assign);
    if (props.picklist && assign === 1) {
      if (modalNo === "Reassign") {
        setModalNo(1);
        return handleModal(2, 1, props.currentSpeed);
      } else if (modalNo === "Assign") {
        setModalNo(0);
        return handleModal(2, 0, props.currentSpeed);
      }
    } else if (props.picklist && assign === 2) {
      setLoadingPDF(true);
      return axios
        .post(`${BASE_URL}`, {
          service_name: "get_picklist_pdf",
          token,
          user_id: props.userId,
          picklist_id: props.currentSpeed,
          force_new: 0,
        })
        .then((response) => {
          if (response && response?.data?.status === 1) {
            let pdfUrl = response && response?.data?.data?.file;
            window.open(pdfUrl, "_blank", "noopener,noreferrer");
          } else if (response && response?.data?.status === 0) {
            alert.error(response && response?.data?.msg);
          }
          setLoadingPDF(false);
        })
        .catch((err) => {
          setLoadingPDF(false);
          console.log(err);
        });
    } else if (props.picklist && assign === 3) {
      return handleModal(9);
    }
    const userId = { id_user: props.userId };
    setUser(userId);
    navigate("/communication-alert");
  };

  // console.log(props.obj);

  console.log("props:", props.picklist);

  console.log(props.Comment);

  return (
    <div className="user-info">
      <div className="user-img">
        <img
          onError={(e) => (e.target.src = IMG_DEFAULT)}
          src={props.UserUri}
          alt=""
        />
      </div>
      {/* user input grid */}
      <div className="input-grid-row">
        <div>
          <label htmlFor="userid">{props.obj.label1}</label> <br />
          <input
            style={{
              backgroundColor: props.border && "white",
              border: props.border && "1px solid #E6E6E6",
            }}
            type="text"
            id="userid"
            value={props.userId}
            readOnly
          />
        </div>
        <div>
          <label htmlFor="username">{props.obj.label2}</label> <br />
          <input
            style={{
              backgroundColor: props.border && "white",
              border: props.border && "1px solid #E6E6E6",
            }}
            type="text"
            id="username"
            value={props.userName}
            readOnly
          />
        </div>
        <div>
          <label htmlFor="currentSpeed">{props.obj.label3}</label> <br />
          <input
            style={{
              backgroundColor: props.border && "white",
              color: props.border && "#1C1C1C",
              border: props.border && "1px solid #E6E6E6",
            }}
            type="text"
            id="currentSpeed"
            value={
              props.hideMinutes
                ? props.currentSpeed
                : props.currentSpeed + " Minutes"
            }
            readOnly
          />
        </div>
        <div>
          <label htmlFor="totalPicked">{props.obj.label4}</label> <br />
          <input
            style={{
              backgroundColor: props.border && "white",
              border: props.border && "1px solid #E6E6E6",
            }}
            type="text"
            id="totalPicked"
            value={props.totalPicked}
            readOnly
          />
        </div>
        <div>
          <label htmlFor="Pending">{props.obj.label5}</label> <br />
          <input
            style={{
              backgroundColor: props.border && "white",
              border: props.border && "1px solid #E6E6E6",
            }}
            type="text"
            id="Pending"
            value={props.Pending}
            readOnly
          />
        </div>
        <div>
          <label htmlFor="Required">{props.obj.label6}</label> <br />
          <input
            style={{
              backgroundColor: props.border && "white",
              border: props.border && "1px solid #E6E6E6",
            }}
            type="text"
            id="Required"
            value={props.Required}
            readOnly
          />
        </div>
        <div>
          <label htmlFor="lastScan">{props.obj.label7}</label> <br />
          <input
            style={{
              backgroundColor: props.border && "white",
              border: props.border && "1px solid #E6E6E6",
            }}
            type="text"
            id="lastScan"
            value={props.lastscan}
            readOnly
          />
        </div>
        <div>
          <label htmlFor="idleSince">{props.obj.label8}</label> <br />
          <input
            style={{
              backgroundColor: props.border && "white",
              border: props.border && "1px solid #E6E6E6",
            }}
            type="text"
            id="idleSince"
            value={props.idleSince}
            readOnly
          />
        </div>
        <div>
          <label htmlFor="currentLocation">{props.obj.label9}</label> <br />
          <textarea
            style={{
              backgroundColor: props.border && "white",
              border: props.border && "1px solid #E6E6E6",
            }}
            type="text"
            id="currentLocation"
            value={props.currentLocation}
            readOnly
          />
        </div>
        {!props.obj.blabel10 && (
          <div>
            <label htmlFor="nextLocation">{props.obj.label10}</label> <br />
            <textarea
              style={{
                backgroundColor: props.border && "white",
                border: props.border && "1px solid #E6E6E6",
              }}
              type="text"
              id="nextLocation"
              value={props.nextLocation}
              readOnly
            />
          </div>
        )}
      </div>
      {/* another level props */}
      {props.obj.blabel10 && (
        <div className="another-level-props-grid">
          <div>
            <label htmlFor="idleSince">{props.obj.blabel10}</label> <br />
            <input
              style={{
                backgroundColor: props.border && "white",
                border: props.border && "1px solid #E6E6E6",
              }}
              type="text"
              id="idleSince"
              value={props.pklistType}
              readOnly
            />
          </div>
          <div>
            <label htmlFor="idleSince">{props.obj.blabel11}</label> <br />
            <input
              style={{
                backgroundColor: props.border && "white",
                border: props.border && "1px solid #E6E6E6",
              }}
              type="text"
              id="idleSince"
              value={props.sublocation}
              readOnly
            />
          </div>
        </div>
      )}
      {/* another level props */}
      {/* media action */}
      <div className="media-action-grid-row">
        <Button
          onClick={() => redirectCommunication(1, props.obj.label11)}
          style={{
            backgroundColor: props.obj1.btnColor && props.obj1.btnColor,
          }}
        >
          <img src={props.MicroPhoneIcon} alt="" />
          &nbsp;
          <p>{props.obj.label11}</p>
        </Button>
        <Button
          onClick={() => redirectCommunication(2)}
          style={{
            backgroundColor: props.obj1.btnColor1 && props.obj1.btnColor1,
          }}
        >
          {loadingPDF ? (
            <Loader />
          ) : (
            <>
              <img src={props.Comment} alt="" />
              &nbsp;
              <p>{props.obj.label12}</p>
            </>
          )}
        </Button>
        <Button
          disabled={props.obj.label13 === "Cancelled" ? true : false}
          onClick={() => redirectCommunication(3)}
          style={{
            backgroundColor: props.obj1.btnColor2 && props.obj1.btnColor2,
          }}
        >
          <img src={props.Gallery} alt="" />
          &nbsp;
          <p>{props.obj.label13}</p>
        </Button>
      </div>
      {/* media action */}
      {renderModal()}
    </div>
  );
};

export default UserInfo;
