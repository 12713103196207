import React, { useMemo } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import DataTable from "../../Components/Table/DataTable";
import { activityHeader } from "../../DummyArray/TableArray/TableData";
import "./Activity.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import {
  getAllFiltersPickingModuleActions,
  getPickerActivityActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import { Pagination } from "../../Components/Pagination/Pagination";
import { useState } from "react";
import { useCallback } from "react";
import moment from "moment";
import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import MetaData from "../../Components/Helmet/Helmet";

const Activity = () => {
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { pActivity, loading, status } = useSelector(
    (state) => state.pickerActivity
  );

  let service_name = "picker_activity";

  // pagination code starts here
  const [picker_activity, setpicker_activity] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [filtersTrue, setFilterTrue] = useState(false);
  const [pagination, setPagination] = useState(0);

  const fetch = useCallback(
    (page) => {
      setIsLoading(true);
      axios
        .post(`${BASE_URL}`, { service_name, page, token })
        .then((response) => {
          setpicker_activity(response?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    },
    [service_name, token]
  );

  const handlePage = useCallback(
    (page, paginate) => {
      setPagination(page);
      setPage(page);
      if (!filtersTrue && pagination >= 0 && paginate) {
        fetch(page);
      }
    },
    [fetch, filtersTrue, pagination]
  );

  const fetchActivity = useCallback(() => {
    pActivity && setpicker_activity(pActivity && pActivity?.data);
  }, [pActivity]);

  useEffect(() => {
    fetchActivity();
  }, [fetchActivity]);

  // filter code starts here
  const [filterTotal_pages, setFilter_total_pages] = useState(0);
  const [filter_Loading, setFilter_loading] = useState(false);

  const [user_id, setUser_id] = useState("");
  const [picker_name, setPicker_name] = useState("");

  const [date_entry, setDate_entry] = useState(null);

  const [zone_id, setZone_id] = useState("");
  const [zone_name, setZone_name] = useState("");

  const [aisle_id, setaisle_id] = useState("");
  const [aisle_name, setaisle_name] = useState("");

  const [sublocation_id, setsublocation_id] = useState("");
  const [sublocation_name, setsublocation_name] = useState("");

  const [product_id, setProduct_id] = useState("");
  const [product_name, setProduct_name] = useState("");

  const [picklist_id, setpicklist_id] = useState("");
  const [serial_code, setserial_code] = useState("");

  const handlePickers = (val) => {
    setFilterTrue(true);
    setUser_id(val?.id);
    setPicker_name(val?.value);
    handlePage(1, false);
  };

  const handleDate = (date) => {
    setFilterTrue(true);
    setDate_entry(date);
    handlePage(1, false);
  };

  const handleZone = (val) => {
    setFilterTrue(true);
    setZone_id(val?.id);
    setZone_name(val?.value);
    handlePage(1, false);
  };

  const handleAisle = (val) => {
    setFilterTrue(true);
    setaisle_id(val?.id);
    setaisle_name(val?.value);
    handlePage(1, false);
  };

  const handleSubLocation = (val) => {
    setFilterTrue(true);
    setsublocation_id(val?.id);
    setsublocation_name(val?.value);
    handlePage(1, false);
  };

  const handleSkuProduct = (val) => {
    setFilterTrue(true);
    setProduct_id(val?.id);
    setProduct_name(val?.value);
    handlePage(1, false);
  };

  const handlePicklistId = (e) => {
    setFilterTrue(true);
    setpicklist_id(e.target.value);
    handlePage(1, false);
  };

  const handleSerialCode = (e) => {
    setFilterTrue(true);
    setserial_code(e.target.value);
    handlePage(1, false);
  };

  let date = date_entry !== "" ? moment(date_entry).format("yyyy-MM-DD") : null;

  let filters = useMemo(() => {
    const dataToSend = {
      user_id,
      date,
      zone_id,
      aisle_id,
      sublocation_id,
      product_id,
      picklist_id,
      serial_code,
    };
    // Filtering empty value
    const filter_data = Object.fromEntries(
      Object.entries(dataToSend).filter(
        ([_, value]) =>
          value !== "" && value !== null && value !== "Invalid date"
      )
    );
    return filter_data;
  }, [
    user_id,
    date,
    zone_id,
    aisle_id,
    sublocation_id,
    product_id,
    picklist_id,
    serial_code,
  ]);

  const fetchFilters = useCallback(() => {
    setFilter_loading(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "picker_activity",
        token,
        page,
        filters,
      })
      .then((response) => {
        if (response && response?.data?.status === 0) {
          alert.error(response && response?.data?.msg);
        } else {
          setFilter_total_pages(response?.data?.total_pages);
          setpicker_activity(response?.data?.data);
          setFilter_loading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setFilter_loading(false);
      });
  }, [token, page, filters, alert]);
  // filter code ends here

  useEffect(() => {
    if (filtersTrue === true) {
      fetchFilters();
    } else if (filtersTrue === false && pagination === 0) {
      dispatch(getPickerActivityActions(service_name, 1, token));
    }
  }, [dispatch, filtersTrue, pagination, service_name, token, fetchFilters]);

  // All Filter data for dropdown
  let All_Filters = useMemo(() => {
    const dataToSend = ["pickers", "zones", "aisles", "sublocations", "sku"];
    return dataToSend;
  }, []);

  const { all_filters, loading: allFilter_loading } = useSelector(
    (state) => state.allPickingModuleFilters
  );

  useEffect(() => {
    dispatch(getAllFiltersPickingModuleActions("filters", token, All_Filters));
  }, [dispatch, token, All_Filters]);

  let pickers_list = all_filters && all_filters?.pickers;
  let zones_list = all_filters && all_filters?.zones;
  let aisles_list = all_filters && all_filters?.aisles;
  let sub_locations_list = all_filters && all_filters?.sublocations;
  let sku_list = all_filters && all_filters?.sku;

  const resteFilter = () => {
    setUser_id("");
    setPicker_name("");
    setDate_entry("");
    setZone_id("");
    setZone_name("");
    setaisle_id("");
    setaisle_name("");
    setsublocation_id("");
    setsublocation_name("");
    setProduct_id("");
    setProduct_name("");
    setpicklist_id("");
    setserial_code("");
  };

  const [loadingCSV, setLoadingCsv] = useState(false);

  const handleLogs = async () => {
    setLoadingCsv(true);
    try {
      const response = await axios.post(`${BASE_URL}`, {
        service_name: "csv_picker_activity",
        token,
        filters,
      });
      const csvFile = response?.data?.file;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
      }

      setLoadingCsv(false);
    } catch (err) {
      alert.error(err);
      setLoadingCsv(false);
    }
  };

  // useEffect(() => {
  //   if (
  //     user_id ||
  //     picker_name ||
  //     date_entry ||
  //     zone_id ||
  //     zone_name ||
  //     aisle_id ||
  //     aisle_name ||
  //     sublocation_id ||
  //     product_id ||
  //     product_name ||
  //     picklist_id ||
  //     serial_code
  //   ) {
  //     handlePage(1);
  //   }
  // }, [
  //   user_id,
  //   picker_name,
  //   date_entry,
  //   zone_id,
  //   zone_name,
  //   aisle_id,
  //   aisle_name,
  //   sublocation_id,
  //   product_id,
  //   product_name,
  //   picklist_id,
  //   serial_code,
  //   handlePage,
  // ]);

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Activity`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          {loading ? (
            <LoaderAlone />
          ) : (
            <>
              {status === 0 ? (
                <NoDataFromApi
                  height={true}
                  title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                />
              ) : (
                <>
                  <Headline
                    handleData={handleLogs}
                    loadingCSV={loadingCSV}
                    width_new={true}
                    title="Activity"
                    btnTitle="Download Log"
                  />

                  {/* dropdown grid container */}
                  <div className="dropdown-grid-row dropwon">
                    <div>
                      <Dropdown
                        placeholder="User Id"
                        dropdown={pickers_list && pickers_list}
                        value={picker_name}
                        onData={handlePickers}
                        showId={true}
                        noShow={false}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <DatePicker
                        className="date-picker"
                        placeholderText="Date"
                        selected={date_entry}
                        onChange={(date) => handleDate(date)}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Zone"
                        dropdown={zones_list && zones_list}
                        value={zone_name}
                        onData={handleZone}
                        noShow={false}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Aisle"
                        dropdown={aisles_list && aisles_list}
                        value={aisle_name}
                        onData={handleAisle}
                        noShow={false}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Sub Location"
                        dropdown={sub_locations_list && sub_locations_list}
                        value={sublocation_name}
                        onData={handleSubLocation}
                        noShow={false}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="SKU"
                        dropdown={sku_list && sku_list}
                        value={product_name}
                        noShow={false}
                        onData={handleSkuProduct}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div className="date_handover">
                      <input
                        type="text"
                        name="picklist_id"
                        id="picklist_id"
                        value={picklist_id}
                        onChange={(e) => handlePicklistId(e)}
                        placeholder="Picklist Id"
                        // onKeyPress={handleKeyPress}
                      />
                    </div>
                    <div className="date_handover">
                      <input
                        type="text"
                        name="serial_code"
                        id="serial_code"
                        value={serial_code}
                        onChange={(e) => handleSerialCode(e)}
                        placeholder="Serial Code"
                        // onKeyPress={handleKeyPress}
                      />
                    </div>
                    <div>
                      <div className="clear-filter">
                        <Button onClick={() => resteFilter()}>
                          Reset Filter
                        </Button>
                      </div>
                    </div>
                  </div>

                  {/* table */}
                  <div className="table-wrapper">
                    <div className="table-container">
                      <DataTable
                        header={activityHeader}
                        activityData={picker_activity && picker_activity}
                      />
                    </div>
                  </div>

                  <div className="rendering-pagination">
                    <Pagination
                      uniqueKey={`ScreenA-${page}`}
                      page={page}
                      isLoading={isLoading ? isLoading : filter_Loading}
                      totalPage={
                        filtersTrue
                          ? filterTotal_pages
                          : pActivity && pActivity?.total_pages
                      }
                      onPageChange={handlePage}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Activity;
