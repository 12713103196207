import React from "react";
import "./ImageModal.css";
import NoDataFromApi from "../../Loader/NoDataFromApi";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import "react-awesome-slider/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ReadCommunicationCountActions } from "../../../Actions/PickerMgmt/PickerMgmtActions";
import { useState } from "react";
import { READ_COMMUNICATION_MODAL_RESET } from "../../../Constants/PickerMgmt/PickerManagementConstants";
import { useAlert } from "react-alert";
import ImgLoader from "../../Loader/ImgLoader";

const ImageModal = (props) => {
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { imageuri, id } = props;

  const [show, setShow] = useState(true);

  const [isImage_loaded, setIsimage_loaded] = useState(true);

  const { communication_read_count } = useSelector(
    (state) => state.readCommunication
  );

  useEffect(() => {
    dispatch(
      ReadCommunicationCountActions(
        "read_communication_alerts",
        token,
        id,
        "image"
      )
    );
  }, [dispatch, token, id]);

  // console.log(id, "Id");

  useEffect(() => {
    if (
      communication_read_count &&
      communication_read_count?.status === 0 &&
      show
    ) {
      alert.error(communication_read_count && communication_read_count?.msg);
      setShow(false);
      dispatch({
        type: READ_COMMUNICATION_MODAL_RESET,
      });
    }
  }, [communication_read_count, alert, dispatch, show]);
  return (
    <>
      <AwesomeSlider animation="cubeAnimation">
        {imageuri && imageuri.length > 0 ? (
          imageuri.map((i, index) => (
            <div className="slider-image-container" key={index}>
              <img onLoad={() => setIsimage_loaded(false)} src={i} alt="" />
              <div className="is-image-loaded">
                {isImage_loaded ? <ImgLoader /> : null}
              </div>
            </div>
          ))
        ) : (
          <NoDataFromApi height={true} title="No Image Here To Show..." />
        )}
      </AwesomeSlider>
    </>
  );
};

export default ImageModal;
