import React, { useEffect } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import "./Audio.css";
import { useState } from "react";
import pauseIcon from "../../../Assets/Icons/pause.png";
import { ReadCommunicationCountActions } from "../../../Actions/PickerMgmt/PickerMgmtActions";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { READ_COMMUNICATION_MODAL_RESET } from "../../../Constants/PickerMgmt/PickerManagementConstants";

const Audio = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  const { communication_read_count } = useSelector(
    (state) => state.readCommunication
  );

  const { close, audio, id } = props;

  const [show, setShow] = useState(true);

  const audioRef = React.useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    dispatch(
      ReadCommunicationCountActions(
        "read_communication_alerts",
        token,
        id,
        "audio"
      )
    );
  }, [dispatch, token, id]);

  // console.log(id, "Id");

  useEffect(() => {
    if (
      communication_read_count &&
      communication_read_count?.status === 0 &&
      show
    ) {
      alert.error(communication_read_count && communication_read_count?.msg);
      setShow(false);
      dispatch({
        type: READ_COMMUNICATION_MODAL_RESET,
      });
    }
  }, [communication_read_count, alert, dispatch, show]);

  return (
    <>
      <HeadingTitle colorRed={true} title="Audio Message" closeModal={close} />
      <div className="audio">
        {!audio && audio === "" ? (
          <h3>NO PRODUCT FOUND</h3>
        ) : (
          <>
            <div className="audio-btn" onClick={handlePlay}>
              {!isPlaying ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="46"
                  height="46"
                  viewBox="0 0 46 46"
                  fill="none"
                >
                  <path
                    d="M36.7456 23.7754L16.9936 36.9435C16.5654 37.229 15.9869 37.1133 15.7014 36.685C15.5994 36.532 15.5449 36.3522 15.5449 36.1682V9.83221C15.5449 9.31758 15.9621 8.90039 16.4767 8.90039C16.6607 8.90039 16.8406 8.95485 16.9936 9.0569L36.7456 22.2249C37.1737 22.5104 37.2895 23.0889 37.004 23.5171C36.9357 23.6194 36.848 23.7072 36.7456 23.7754Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <img style={{ width: "32px" }} src={pauseIcon} alt="" />
              )}
            </div>
            <audio ref={audioRef} onEnded={handleAudioEnded}>
              <source src={audio} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </>
        )}
      </div>
    </>
  );
};

export default Audio;
