import React from "react";
import Select from "react-select";
import { RadiuscustomStyles } from "../DropdownStyles/DropdownStyles";
import { Button } from "@material-ui/core";
import BagIcon from "../../Assets/Icons/Order Handover/Shopping bag 1.png";
import CloseBagIcon from "../../Assets/Icons/Order Handover/Vector.png";
import PrinterIcon from "../../Assets/Icons/Order Handover/Printer 1.png";
import "./Headline.css";
import Loader from "../Loader/Loader";
import QzTray from "../QZTray/QzTray";
import { useState } from "react";

const Headline = (props) => {
  const { closePrint, loadingCSV, handleMarkLostSync, syncMark_loading } =
    props;
  const handleDateChange = (selectedOption) => {
    props.onChangeFilter(selectedOption.value, selectedOption.label);
  };

  const handleCreationBag = () => {
    props.addBag();
  };

  const [close_bags, setclose_bags] = useState(false);

  const HandleCloseBag = () => {
    setclose_bags(true);
    props.closeBag();
  };

  const handleModal = () => {
    props.onData();
  };

  const handleData = () => {
    props.handleData();
  };

  const handleSec = () => {
    props.handleDatas();
  };

  const handleReset = () => {
    props.handleData();
  };

  const handleSyncLost = () => {
    handleMarkLostSync();
  };

  return (
    <div
      className={`${
        props.newClass
          ? "bags-into-col"
          : props.newCta && props.select
          ? "side-by-label"
          : "header-pk-management-flex-row"
      }`}
    >
      <div>
        <h1>{props.title}</h1>
      </div>
      <div
        className={`${
          props.responsive ? "d-flex-date-selectors" : "d-flex-date-selector"
        }`}
      >
        {props.fromtorange && (
          <Select
            placeholder={`${
              props.fromtorange?.from !== ""
                ? `${props.fromtorange.from} - ${props.fromtorange.to}`
                : "Date From & To"
            }`}
            options={[]}
            // value={props.fromtorange?.from}
            styles={RadiuscustomStyles}
            onChange={handleDateChange}
          />
        )}
        {props.days && (
          <Select
            placeholder={`${props.daysValue ? props.daysValue : "Days"}`}
            options={[
              { value: "Days", label: "Days" },
              { value: "Weeks", label: "Weeks" },
              { value: "Months", label: "Months" },
            ]}
            value={props.daysValue}
            styles={RadiuscustomStyles}
            onChange={handleDateChange}
          />
        )}
        {props.syncLost && (
          <Button onClick={handleSyncLost} className="sync-lost-cta">
            {syncMark_loading ? <Loader /> : "Sync Lost"}
          </Button>
        )}
        {props.onData ? (
          <Button
            style={{
              width:
                props.loading || props.loadingDetailcsv ? "280px" : "initial",
              height:
                props.loading || props.loadingDetailcsv ? "38px" : "initial",
            }}
            onClick={() => handleModal()}
          >
            {props.loading ? <Loader /> : props.btnTitle}
          </Button>
        ) : (
          !props.hide && (
            <Button
              style={{
                width: props.width ? "250px" : props.width_new && "152px",
              }}
              onClick={handleData}
            >
              {loadingCSV ? <Loader /> : props.btnTitle}
            </Button>
          )
        )}
        {props.createBtn && (
          <div>
            <Button
              style={{
                width: props.width ? "250px" : props.width_new && "152px",
              }}
              className="createNewBtn"
              //onClick={}
            >
              {props.createBtn}
            </Button>
          </div>
        )}
        {props.select && !props.newCta ? (
          <Select
            placeholder={`${
              props.from_time_label !== "" && props.from_time_label
            }`}
            options={
              props.home_date &&
              props.home_date.map((option) => ({
                value: option.id,
                label: option.name,
              }))
            }
            value={props.from_time_label}
            styles={RadiuscustomStyles}
            onChange={handleDateChange}
          />
        ) : props.newCta && props.select ? (
          <Button
            style={{
              width: props.width && "250px",
            }}
            className="btn-sec"
            onClick={handleSec}
          >
            {props.loadingDetailcsv ? <Loader /> : props.btnSec}
          </Button>
        ) : null}

        {props.newContainer && (
          <div className="container-bags">
            <Button
              disabled={props.bagLoading ? true : false}
              onClick={handleCreationBag}
            >
              {props.bagLoading ? (
                <Loader />
              ) : (
                <>
                  <img src={BagIcon} alt="" />
                  &nbsp;&nbsp;Add Bag
                </>
              )}
            </Button>
            <Button
              disabled={props.closeBag_loading ? true : false}
              onClick={() => HandleCloseBag()}
            >
              {props.closeBag_loading ? (
                <Loader />
              ) : (
                <>
                  <img src={CloseBagIcon} alt="" />
                  &nbsp;&nbsp;Close Bag
                </>
              )}
            </Button>
            <QzTray
              selectedPrinter={props.selectedPrinter}
              btnTitle="Print Label"
              // onHandle={onHandle}
              bag_id={props.bag_id}
              label={true}
              closePrint={closePrint}
              close_bags={close_bags}
              // printFirst={printFirst}
            />
            {/* <Button onClick={() => handleLabel()}>
              {props.label_loading ? (
                <Loader />
              ) : (
                <>
                  <img src={LabelIcon} alt="" />
                  &nbsp;&nbsp;Print Label
                </>
              )}
            </Button> */}
            <Button onClick={() => handleReset()}>
              <img src={PrinterIcon} alt="" />
              &nbsp;&nbsp;Printer Reset
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Headline;
