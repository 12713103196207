// get all couriers name
export const GET_COURIER_NAME_REQUEST = "GET_COURIER_NAME_REQUEST";
export const GET_COURIER_NAME_SUCCESS = "GET_COURIER_NAME_SUCCESS";
export const GET_COURIER_NAME_FAILURE = "GET_COURIER_NAME_FAILURE";

// get unique handover name
export const GET_UNIQUE_HANDOVER_NAME_REQUEST =
  "GET_UNIQUE_HANDOVER_NAME_REQUEST";
export const GET_UNIQUE_HANDOVER_NAME_SUCCESS =
  "GET_UNIQUE_HANDOVER_NAME_SUCCESS";
export const GET_UNIQUE_HANDOVER_NAME_FAILURE =
  "GET_UNIQUE_HANDOVER_NAME_FAILURE";

// save Delivery Partner Info
export const SAVE_DELIVERY_PARTNER_REQUEST = "SAVE_DELIVERY_PARTNER_REQUEST";
export const SAVE_DELIVERY_PARTNER_SUCCESS = "SAVE_DELIVERY_PARTNER_SUCCESS";
export const SAVE_DELIVERY_PARTNER_FAILURE = "SAVE_DELIVERY_PARTNER_FAILURE";
export const SAVE_DELIVERY_PARTNER_RESET = "SAVE_DELIVERY_PARTNER_RESET";

// get order summary
export const GET_ORDER_SUMMARY_REQUEST = "GET_ORDER_SUMMARY_REQUEST";
export const GET_ORDER_SUMMARY_SUCCESS = "GET_ORDER_SUMMARY_SUCCESS";
export const GET_ORDER_SUMMARY_FAILURE = "GET_ORDER_SUMMARY_FAILURE";

// get Courier Name BY ID
export const GET_COURIER_NAME_BY_ID_REQUEST = "GET_COURIER_NAME_BY_ID_REQUEST";
export const GET_COURIER_NAME_BY_ID_SUCCESS = "GET_COURIER_NAME_BY_ID_SUCCESS";
export const GET_COURIER_NAME_BY_ID_FAILURE = "GET_COURIER_NAME_BY_ID_FAILURE";

// get Scan Item List
export const GET_SCAN_ITEM_LIST_REQUEST = "GET_SCAN_ITEM_LIST_REQUEST";
export const GET_SCAN_ITEM_LIST_SUCCESS = "GET_SCAN_ITEM_LIST_SUCCESS";
export const GET_SCAN_ITEM_LIST_FAILURE = "GET_SCAN_ITEM_LIST_FAILURE";

// create Handover Bag Constants
export const CREATE_NEW_HANDOVER_BAG_REQUEST =
  "CREATE_NEW_HANDOVER_BAG_REQUEST";
export const CREATE_NEW_HANDOVER_BAG_SUCCESS =
  "CREATE_NEW_HANDOVER_BAG_SUCCESS";
export const CREATE_NEW_HANDOVER_BAG_FAILURE =
  "CREATE_NEW_HANDOVER_BAG_FAILURE";
export const CREATE_NEW_HANDOVER_BAG_RESET = "CREATE_NEW_HANDOVER_BAG_RESET";

// get Current Bag Quantity Constants
export const GET_CURRENT_BAG_QUANTITY_REQUEST =
  "GET_CURRENT_BAG_QUANTITY_REQUEST";
export const GET_CURRENT_BAG_QUANTITY_SUCCESS =
  "GET_CURRENT_BAG_QUANTITY_SUCCESS";
export const GET_CURRENT_BAG_QUANTITY_FAILURE =
  "GET_CURRENT_BAG_QUANTITY_FAILURE";

// get picker view Details
export const GET_PICKER_VIEW_DETAILS_REQUEST =
  "GET_PICKER_VIEW_DETAILS_REQUEST";
export const GET_PICKER_VIEW_DETAILS_SUCCESS =
  "GET_PICKER_VIEW_DETAILS_SUCCESS";
export const GET_PICKER_VIEW_DETAILS_FAILURE =
  "GET_PICKER_VIEW_DETAILS_FAILURE";

// Get All Bag Constants
export const GET_ALL_BAG_NAME_REQUEST = "GET_ALL_BAG_NAME_REQUEST";
export const GET_ALL_BAG_NAME_SUCCESS = "GET_ALL_BAG_NAME_SUCCESS";
export const GET_ALL_BAG_NAME_FAILURE = "GET_ALL_BAG_NAME_FAILURE";

// Get count of Total bag scan
export const GET_COUNT_OF_TOTAL_BAG_SCAN_REQUEST =
  "GET_COUNT_OF_TOTAL_BAG_SCAN_REQUEST";
export const GET_COUNT_OF_TOTAL_BAG_SCAN_SUCCESS =
  "GET_COUNT_OF_TOTAL_BAG_SCAN_SUCCESS";
export const GET_COUNT_OF_TOTAL_BAG_SCAN_FAILURE =
  "GET_COUNT_OF_TOTAL_BAG_SCAN_FAILURE";

// Get count of Total bag scan
export const GET_HANDOVER_ORDER_AWB_REQUEST = "GET_HANDOVER_ORDER_AWB_REQUEST";
export const GET_HANDOVER_ORDER_AWB_SUCCESS = "GET_HANDOVER_ORDER_AWB_SUCCESS";
export const GET_HANDOVER_ORDER_AWB_FAILURE = "GET_HANDOVER_ORDER_AWB_FAILURE";
export const GET_HANDOVER_ORDER_AWB_RESET = "GET_HANDOVER_ORDER_AWB_RESET";

// Get count of Total bag scan
export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILURE = "GET_USER_LIST_FAILURE";

// Reverse Handover Item By Id Constant
export const REVERSE_HANDOVER_BY_ITEM_REQUEST =
  "REVERSE_HANDOVER_BY_ITEM_REQUEST";
export const REVERSE_HANDOVER_BY_ITEM_SUCCESS =
  "REVERSE_HANDOVER_BY_ITEM_SUCCESS";
export const REVERSE_HANDOVER_BY_ITEM_FAILURE =
  "REVERSE_HANDOVER_BY_ITEM_FAILURE";

// Edit Handover Data Constant
export const EDIT_HANDOVER_ITEM_BY_BOTH_ID_REQUEST =
  "EDIT_HANDOVER_ITEM_BY_BOTH_ID_REQUEST";
export const EDIT_HANDOVER_ITEM_BY_BOTH_ID_SUCCESS =
  "EDIT_HANDOVER_ITEM_BY_BOTH_ID_SUCCESS";
export const EDIT_HANDOVER_ITEM_BY_BOTH_ID_FAILURE =
  "EDIT_HANDOVER_ITEM_BY_BOTH_ID_FAILURE";
export const EDIT_HANDOVER_ITEM_BY_BOTH_ID_RESET =
  "EDIT_HANDOVER_ITEM_BY_BOTH_ID_RESET";

// get Reverse Handover Order Summary Constant
export const GET_REVERSE_ORDER_HANDOVER_SUMMARY_REQUEST =
  "GET_REVERSE_ORDER_HANDOVER_SUMMARY_REQUEST";
export const GET_REVERSE_ORDER_HANDOVER_SUMMARY_SUCCESS =
  "GET_REVERSE_ORDER_HANDOVER_SUMMARY_SUCCESS";
export const GET_REVERSE_ORDER_HANDOVER_SUMMARY_FAILURE =
  "GET_REVERSE_ORDER_HANDOVER_SUMMARY_FAILURE";

// get Unique Reverse Handover Constant
export const GET_UNIQUE_REVERSE_HANDOVER_NAME_REQUEST =
  "GET_UNIQUE_REVERSE_HANDOVER_NAME_REQUEST";
export const GET_UNIQUE_REVERSE_HANDOVER_NAME_SUCCESS =
  "GET_UNIQUE_REVERSE_HANDOVER_NAME_SUCCESS";
export const GET_UNIQUE_REVERSE_HANDOVER_NAME_FAILURE =
  "GET_UNIQUE_REVERSE_HANDOVER_NAME_FAILURE";

// get Unique Reverse Handover Constant
export const SAVE_REVERSAL_HANDOVER_REQUEST = "SAVE_REVERSAL_HANDOVER_REQUEST";
export const SAVE_REVERSAL_HANDOVER_SUCCESS = "SAVE_REVERSAL_HANDOVER_SUCCESS";
export const SAVE_REVERSAL_HANDOVER_FAILURE = "SAVE_REVERSAL_HANDOVER_FAILURE";
export const SAVE_REVERSAL_HANDOVER_RESET = "SAVE_REVERSAL_HANDOVER_RESET";

// scan order with awb order no constants
export const SCAN_ORDER_AWB_ORDER_NO_REQUEST =
  "SCAN_ORDER_AWB_ORDER_NO_REQUEST";
export const SCAN_ORDER_AWB_ORDER_NO_SUCCESS =
  "SCAN_ORDER_AWB_ORDER_NO_SUCCESS";
export const SCAN_ORDER_AWB_ORDER_NO_FAILURE =
  "SCAN_ORDER_AWB_ORDER_NO_FAILURE";

// get all handover name (order handover summary) constants
export const GET_ALL_HANDOVER_NAME_FILTER_REQUEST =
  "GET_ALL_HANDOVER_NAME_FILTER_REQUEST";
export const GET_ALL_HANDOVER_NAME_FILTER_SUCCESS =
  "GET_ALL_HANDOVER_NAME_FILTER_SUCCESS";
export const GET_ALL_HANDOVER_NAME_FILTER_FAILURE =
  "GET_ALL_HANDOVER_NAME_FILTER_FAILURE";

// close Bag (order handover summary) constants
export const CLOSE_HANDOVER_BAG_REQUEST = "CLOSE_HANDOVER_BAG_REQUEST";
export const CLOSE_HANDOVER_BAG_SUCCESS = "CLOSE_HANDOVER_BAG_SUCCESS";
export const CLOSE_HANDOVER_BAG_FAILURE = "CLOSE_HANDOVER_BAG_FAILURE";
export const CLOSE_HANDOVER_BAG_RESET = "CLOSE_HANDOVER_BAG_RESET";

// get all handover name (order reverse handover summary) constants
export const GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_REQUEST =
  "GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_REQUEST";
export const GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_SUCCESS =
  "GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_SUCCESS";
export const GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_FAILURE =
  "GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_FAILURE";

// Get Reversed Scanned Item List Constants
export const GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_REQUEST =
  "GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_REQUEST";
export const GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_SUCCESS =
  "GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_SUCCESS";
export const GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_FAILURE =
  "GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_FAILURE";

// Edit Reverse Handover Order Constants
export const EDIT_REVERSE_HANDOVER_ORDER_REQUEST =
  "EDIT_REVERSE_HANDOVER_ORDER_REQUEST";
export const EDIT_REVERSE_HANDOVER_ORDER_SUCCESS =
  "EDIT_REVERSE_HANDOVER_ORDER_SUCCESS";
export const EDIT_REVERSE_HANDOVER_ORDER_FAILURE =
  "EDIT_REVERSE_HANDOVER_ORDER_FAILURE";
export const EDIT_REVERSE_HANDOVER_ORDER_RESET =
  "EDIT_REVERSE_HANDOVER_ORDER_RESET";

// Close Handover Order Constants
export const CLOSE_HANDOVER_ORDER_REQUEST = "CLOSE_HANDOVER_ORDER_REQUEST";
export const CLOSE_HANDOVER_ORDER_SUCCESS = "CLOSE_HANDOVER_ORDER_SUCCESS";
export const CLOSE_HANDOVER_ORDER_FAILURE = "CLOSE_HANDOVER_ORDER_FAILURE";
export const CLOSE_HANDOVER_ORDER_RESET = "CLOSE_HANDOVER_ORDER_RESET";


// Download Order Handover Summary By Id Constants
export const DOWNLOAD_ORDER_HANDOVER_SUMMARY_BY_ID_REQUEST = "DOWNLOAD_ORDER_HANDOVER_SUMMARY_BY_ID_REQUEST";
export const DOWNLOAD_ORDER_HANDOVER_SUMMARY_BY_ID_SUCCESS = "DOWNLOAD_ORDER_HANDOVER_SUMMARY_BY_ID_SUCCESS";
export const DOWNLOAD_ORDER_HANDOVER_SUMMARY_BY_ID_FAILURE = "DOWNLOAD_ORDER_HANDOVER_SUMMARY_BY_ID_FAILURE";


// Closed Reversal Handover
export const CLOSE_REVERSAL_HANDOVER_BY_ID_REQUEST = "CLOSE_REVERSAL_HANDOVER_BY_ID_REQUEST";
export const CLOSE_REVERSAL_HANDOVER_BY_ID_SUCCESS = "CLOSE_REVERSAL_HANDOVER_BY_ID_SUCCESS";
export const CLOSE_REVERSAL_HANDOVER_BY_ID_FAILURE = "CLOSE_REVERSAL_HANDOVER_BY_ID_FAILURE";
export const CLOSE_REVERSAL_HANDOVER_BY_ID_RESET = "CLOSE_REVERSAL_HANDOVER_BY_ID_RESET";


// Get Bag Manifest constants
export const GET_BAG_MANIFEST_REQUEST = "GET_BAG_MANIFEST_REQUEST";
export const GET_BAG_MANIFEST_SUCCESS = "GET_BAG_MANIFEST_SUCCESS";
export const GET_BAG_MANIFEST_RESET = "GET_BAG_MANIFEST_RESET";
export const GET_BAG_MANIFEST_FAILURE = "GET_BAG_MANIFEST_FAILURE";

// Get Inventory List constants
export const GET_INVENTORY_LIST_REQUEST = "GET_INVENTORY_LIST_REQUEST";
export const GET_INVENTORY_LIST_SUCCESS = "GET_INVENTORY_LIST_SUCCESS";
export const GET_INVENTORY_LIST_FAILURE = "GET_INVENTORY_LIST_FAILURE";

// Get Inventory List constants
export const GET_REVERSE_INVENTORY_LIST_REQUEST = "GET_REVERSE_INVENTORY_LIST_REQUEST";
export const GET_REVERSE_INVENTORY_LIST_SUCCESS = "GET_REVERSE_INVENTORY_LIST_SUCCESS";
export const GET_REVERSE_INVENTORY_LIST_FAILURE = "GET_REVERSE_INVENTORY_LIST_FAILURE";

// Get Invoice List constants
export const GET_INVOICE_LIST_FOR_MATCH_REQUEST = "GET_INVOICE_LIST_FOR_MATCH_REQUEST";
export const GET_INVOICE_LIST_FOR_MATCH_SUCCESS = "GET_INVOICE_LIST_FOR_MATCH_SUCCESS";
export const GET_INVOICE_LIST_FOR_MATCH_FAILURE = "GET_INVOICE_LIST_FOR_MATCH_FAILURE";

// Print Label Constants constants
export const PRINT_LABEL_HANDOVER_REQUEST = "PRINT_LABEL_HANDOVER_REQUEST";
export const PRINT_LABEL_HANDOVER_SUCCESS = "PRINT_LABEL_HANDOVER_SUCCESS";
export const PRINT_LABEL_HANDOVER_FAILURE = "PRINT_LABEL_HANDOVER_FAILURE";