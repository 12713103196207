import React, { Fragment, useState } from "react";
import MetaData from "../../Components/Helmet/Helmet";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import DataTable from "../../Components/Table/DataTable";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { AuditSummaryHeader } from "../../DummyArray/TableArray/TableData";

const BoxAuditTransactionSummary = () => {
  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <Fragment>
      <div
        className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
      >
        <MetaData title={`Box Audit Transaction Summary`} />
        <div>
          <SideBar handleIsToggle={handleIsToggle} />
        </div>
        <div>
          <Header isToggle={isToggle} />
          <div className="wrapper-picker-container">
            {/* Header Title Component row starts here */}
            <Headline
              title="Box Audit Transaction Summary"
              btnTitle={"Export CSV"}
            />
            {/* Header Title Component row ends here */}

            <div className="dropdown-grid-row dropwon">
              <div>
                <Dropdown placeholder="Audit Transactions" />
              </div>
              <div>
                <Dropdown placeholder="Location" />
              </div>
              <div>
                <Dropdown placeholder="Type" />
              </div>
              <div>
                <Dropdown placeholder="Created By" />
              </div>
              <div>
                <Dropdown placeholder="Created From" />
              </div>
              <div>
                <Dropdown placeholder="Created To" />
              </div>

              {/* <div>
                <div className="clear-filter">
                  <Button onClick={() => resteFilter()}>Reset Filter</Button>
                </div>
              </div> */}
            </div>

            {/* Table Component starts here */}
            <div className="table-wrapper-pagination">
              <div className="table-container-pagination">
                <DataTable
                  header={AuditSummaryHeader}
                  auditTransactionSummary={[
                    {
                      transaction_no: "TN12345",
                      location: "Ground Floor",
                      boxes: "200 / 300",
                      sublocation: "100 / 150",
                      type: "Box Qty",
                      created_by: "BA-Aman_4802 _06082024-1",
                      date: "July 15, 2024",
                      qtyAudit: "50% (100/200)",
                      unitqtyAudit: "50% (100/200)",
                    },
                  ]}
                  // handleRequestCount={handleModal}
                />
              </div>
            </div>
            {/* Table Component ends here */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BoxAuditTransactionSummary;
