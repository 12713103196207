import React, { useEffect, useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import "./ToteManagement.css";
import DataTable from "../../Components/Table/DataTable";
import {
  toteOrderWiseHeader,
  tote_status,
} from "../../DummyArray/TableArray/TableData";
import { useDispatch, useSelector } from "react-redux";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import {
  VacantToteActions,
  getAllFiltersPickingModuleActions,
  getToteListActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import ModalContainer from "../../Components/Modal/Modal";
import { Pagination } from "../../Components/Pagination/Pagination";
import { useCallback } from "react";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import { useAlert } from "react-alert";
import { VACANT_TOTE_RESET } from "../../Constants/PickerMgmt/PickerManagementConstants";
import MetaData from "../../Components/Helmet/Helmet";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { useMemo } from "react";
import { Button } from "@material-ui/core";

const ToteManagement = () => {
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const {
    tote_list,
    loading,
    status: log_status,
  } = useSelector((state) => state.toteList);

  // vacant tote
  const { vacant_tote, loading: vacant_tote_loading } = useSelector(
    (state) => state.vacantTote
  );

  const IsOpenHandler = () => {
    handleModal(0);
  };

  const [tote_id, settote_id] = useState("");
  const [isFetchActive, setIsFetchActive] = useState(false);

  // filter usestate
  const [filtersTrue, setFilterTrue] = useState(false);
  const [code, setCode] = useState("");
  const [status_id, setStatus_id] = useState("");
  const [status, setStatus] = useState("");
  const [picklist_id, setpicklist_id] = useState("");

  const [user_id, setUser_id] = useState("");
  const [user, setUser] = useState("");

  const handleCode = (e) => {
    setFilterTrue(true);
    setCode(e.target.value);
    handlePage(1);
  };

  const handle_code = (event) => {
    if (event.key === "Enter") {
      fetchNextToteList();
      handlePage(1);
    }
  };

  const handlePicklistId = (e) => {
    setFilterTrue(true);
    setpicklist_id(e.target.value);
    handlePage(1);
  };

  const handle_Picklist_Id = (event) => {
    if (event.key === "Enter") {
      fetchNextToteList();
      handlePage(1);
    }
  };

  const handleStatus = (val) => {
    setFilterTrue(true);
    setStatus(val?.id);
    setStatus_id(val?.value);
    handlePage(1);
  };

  const handlePickerName = (val) => {
    setFilterTrue(true);
    setUser_id(val?.id);
    setUser(val?.value);
    handlePage(1);
  };

  const deleteTote = (child) => {
    settote_id(child);
    handleModal(1);
  };

  let filters = useMemo(() => {
    const dataToSend = {
      code,
      status,
      picklist_id,
      user_id,
    };
    // Filtering empty value
    const filter_data = Object.fromEntries(
      Object.entries(dataToSend).filter(
        ([_, value]) =>
          value !== "" && value !== null && value !== "Invalid date"
      )
    );
    return filter_data;
  }, [code, status, picklist_id, user_id]);

  const [isVacant, setIsVacant] = useState(false);

  const handleVacantTote = (code) => {
    setIsVacant(true);
    dispatch(VacantToteActions("manage_tote", token, "vacant", code));
  };

  const [isIndex, setIsIndex] = useState(null);
  const handleModal = (index) => {
    setIsIndex(index);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          tote_id={tote_id}
        />
      );
    }
    return null;
  };

  // pagination api will starts here
  const [tote_list_all, setTote_list_all] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [filterTotal_pages, setFilter_total_pages] = useState(0);

  const fetchNextToteList = useCallback(
    (page) => {
      setIsLoading(true);
      setIsFetchActive(true);
      axios
        .post(`${BASE_URL}`, {
          service_name: "manage_tote",
          token,
          action: "list",
          code: "test_tote_1",
          page,
          filters,
        })
        .then((response) => {
          setTote_list_all(response?.data?.data);
          setIsLoading(false);
          setFilter_total_pages(response?.data?.total_pages);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    },
    [token, filters]
  );

  const handlePage = useCallback(
    (page) => {
      setPage(page);
      fetchNextToteList(page);
    },
    [fetchNextToteList]
  );

  const toteFetcher = useCallback(() => {
    tote_list && setTote_list_all(tote_list && tote_list?.data);
  }, [tote_list]);

  useEffect(() => {
    toteFetcher();
  }, [toteFetcher]);

  useEffect(() => {
    if (filtersTrue) {
      fetchNextToteList();
    } else if (isIndex === null && !isFetchActive) {
      dispatch(getToteListActions("manage_tote", token, "list", "test_tote_1"));
    }
  }, [dispatch, token, isIndex, isFetchActive, filtersTrue, fetchNextToteList]);

  useEffect(() => {
    if (vacant_tote && vacant_tote?.status === 1 && isVacant) {
      alert.success("Tote Vacant Successfully...");
      setIsVacant(false);
      // dispatch(getToteListActions("manage_tote", token, "list", "test_tote_1"));
      dispatch({
        type: VACANT_TOTE_RESET,
      });
      fetchNextToteList(page);
    } else if (vacant_tote && vacant_tote?.status === 0 && isVacant) {
      alert.error(vacant_tote && vacant_tote?.msg);
      setIsVacant(false);
      dispatch({
        type: VACANT_TOTE_RESET,
      });
    }
  }, [dispatch, vacant_tote, alert, isVacant, token, page, fetchNextToteList]);

  let All_Filters = useMemo(() => {
    const dataToSend = ["pickers"];
    return dataToSend;
  }, []);

  const { all_filters, loading: allFilter_loading } = useSelector(
    (state) => state.allPickingModuleFilters
  );

  let pickers_list = all_filters && all_filters?.pickers;

  // console.log("Page: ", pickers_list);

  // xxxxxx
  const resteFilter = () => {
    setCode("");
    setStatus("");
    setStatus_id("");
    setpicklist_id("");
    setUser("");
    setUser_id("");
  };

  useEffect(() => {
    if (!filtersTrue && !isFetchActive) {
      dispatch(
        getAllFiltersPickingModuleActions("filters", token, All_Filters)
      );
    }
  }, [dispatch, token, All_Filters, filtersTrue, isFetchActive]);

  const [DownloadCsv, setDownloadCsv] = useState(false);

  const downloadPendency = async () => {
    setDownloadCsv(true);
    try {
      const response = await axios.post(`${BASE_URL}`, {
        service_name: "csv_manage_tote_list",
        token,
        filters,
      });
      const csvFile = response?.data?.file;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
      }

      setDownloadCsv(false);
    } catch (err) {
      alert.error(err);
      setDownloadCsv(false);
    }
  };

  // useEffect(() => {
  //   if (code || status || status_id || picklist_id || user || user_id) {
  //     handlePage(1);
  //   }
  // }, [code, status, status_id, picklist_id, user, user_id, handlePage]);

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <>
      <MetaData title={`Tote Management`} />
      <div
        className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
      >
        <div>
          {renderModal()}
          <SideBar handleIsToggle={handleIsToggle} />
        </div>
        <div>
          <Header />
          <div className="wrapper-picker-container">
            {loading ? (
              <LoaderAlone />
            ) : (
              <>
                {log_status === 0 ? (
                  <NoDataFromApi
                    height={true}
                    title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                  />
                ) : (
                  <>
                    <Headline
                      onData={IsOpenHandler}
                      title="Tote Management"
                      btnTitle="Add Tote"
                      newCta={true}
                      btnSec="Download Logs"
                      width_new={true}
                      select={true}
                      handleDatas={downloadPendency}
                      loadingDetailcsv={DownloadCsv}
                    />

                    <div className="dropdown-grid-row">
                      <div className="date_handover">
                        <input
                          type="text"
                          name="code"
                          id="code"
                          value={code}
                          onChange={(e) => handleCode(e)}
                          placeholder="Tote Code"
                          onKeyPress={handle_code}
                        />
                      </div>
                      <div>
                        <Dropdown
                          placeholder="Status"
                          dropdown={tote_status && tote_status}
                          value={status_id}
                          onData={handleStatus}
                          noShow={true}
                        />
                      </div>
                      <div className="date_handover">
                        <input
                          type="text"
                          name="picklist_id"
                          id="picklist_id"
                          value={picklist_id}
                          onChange={(e) => handlePicklistId(e)}
                          placeholder="Picklist Id"
                          onKeyPress={handle_Picklist_Id}
                        />
                      </div>
                      <div>
                        <Dropdown
                          placeholder="User Id"
                          dropdown={pickers_list && pickers_list}
                          value={user}
                          onData={handlePickerName}
                          noShow={true}
                          showId={true}
                          loading={allFilter_loading}
                        />
                      </div>
                      <div>
                        <div className="clear-filter">
                          <Button onClick={() => resteFilter()}>
                            Reset Filter
                          </Button>
                        </div>
                      </div>
                    </div>

                    {/* tote table */}
                    <div className="table-wrapper">
                      <div className="table-container">
                        <DataTable
                          onDelete={deleteTote}
                          header={toteOrderWiseHeader}
                          toteData={tote_list_all && tote_list_all}
                          onVacant={handleVacantTote}
                          loading_vacant={vacant_tote_loading}
                        />
                      </div>
                    </div>

                    <div className="rendering-pagination">
                      <Pagination
                        uniqueKey={`ScreenB-${page}`}
                        page={page}
                        isLoading={isLoading}
                        totalPage={
                          filtersTrue
                            ? filterTotal_pages
                            : tote_list && tote_list?.total_pages
                        }
                        onPageChange={handlePage}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ToteManagement;
