import React from "react";

const CounterContainer = ({
  title,
  color,
  count,
  uniqueKey,
  bColor,
  comment,
}) => {
  return (
    <div
      className="comment-relative"
      style={{ backgroundColor: color, border: `1px solid ${bColor}` }}
      key={uniqueKey}
    >
      <p>{count}</p>
      <p>{title}</p>
      {comment !== null && comment !== undefined ? (
        <h6 className="comment-as-today-s">
          (<span style={{ color: "red" }}>*</span>{comment})
        </h6>
      ) : null}
    </div>
  );
};

export default CounterContainer;
