import React, { useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import TabModule from "../../Components/TabModule/TabModule";
import "./LabelGenerator.css";
import { useAlert } from "react-alert";
import InputField from "../../Components/InputField/InputField";
import DataTable from "../../Components/Table/DataTable";
import { single_label_generator_heading } from "../../DummyArray/TableArray/TableData";
import { useDispatch, useSelector } from "react-redux";
import {
  VacantToteActions,
  getSingleOrderLabelGeneratorActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import { useEffect } from "react";
import QzTray from "../../Components/QZTray/QzTray";
import qz from "qz-tray";
import ModalContainer from "../../Components/Modal/Modal";
import { useCallback } from "react";
import axios from "axios";
import { Button } from "@material-ui/core";
import Loader from "../../Components/Loader/Loader";
import MetaData from "../../Components/Helmet/Helmet";

const LabelGenerator = () => {
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  const alert = useAlert();
  const { single_label, loading } = useSelector((state) => state.singleLabel);

  // vacant tote
  const { vacant_tote, loading: vacant_tote_loading } = useSelector(
    (state) => state.vacantTote
  );

  const dispatch = useDispatch();
  const [tote_code, settote_code] = useState("");

  const [hit, setHit] = useState(false);

  const handleToteCode = (event) => {
    if (event.key === "Enter") {
      setHit(true);
      dispatch(
        getSingleOrderLabelGeneratorActions(
          "single_order_picklist_label_generator",
          token,
          tote_code
        )
      );
    }
  };

  useEffect(() => {
    if (single_label && single_label?.status === 1 && hit) {
      alert.success("Data Fetched Successfully...");
    } else if (single_label && single_label?.status === 0 && hit) {
      alert.error(single_label && single_label?.msg);
    }
  }, [single_label, alert, hit]);

  let all_single_label_order_data = single_label && single_label?.data;
  let products_items =
    all_single_label_order_data && all_single_label_order_data?.items;

  // console.log("Product Items: ", products_items);

  const [printer, setPrinter] = useState([]);
  const [isIndex, setIsIndex] = useState(null);

  const closeModal = useCallback(() => {
    setIsIndex(null);
  }, []);

  const handleData = () => {
    // if (!qz.websocket.isActive()) {
    //   qz.websocket
    //     .connect()
    //     .then(() => {
    //       return qz.printers.find();
    //     })
    //     .then((printers) => {
    //       if (!printer.includes(printers)) {
    //         setPrinter(printers);
    //       }
    //     })
    //     .then(() => {
    //       setIsIndex(7);
    //     })
    //     .catch((err) => {
    //       alert.error(err.message);
    //     });
    // }
    if (qz.websocket.isActive()) {
      return qz.websocket.disconnect().then(() => {
        qz.websocket
          .connect()
          .then(() => {
            return qz.printers.find();
          })
          .then((printers) => {
            if (!printer.includes(printers)) {
              setPrinter(printers);
            }
          })
          .then(() => {
            setIsIndex(7);
          })
          .catch((err) => {
            alert.error(err.message);
          });
      });
    }
  };

  const [selectedPrinter, setSelectedPrinter] = useState("");

  const sendDatafromChild = (data) => {
    setSelectedPrinter(data);
    localStorage.setItem("Printer", data);
  };

  const [isVacant, setIsVacant] = useState(false);

  const handleVacantTote = () => {
    setIsVacant(true);
    dispatch(VacantToteActions("manage_tote", token, "vacant", tote_code));
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          printer={printer}
          sendDataFromParent={sendDatafromChild}
        />
      );
    }
    return null;
  };

  const handlePdf = () => {};

  useEffect(() => {
    // signed certificate for qz tray
    qz.security.setCertificatePromise(function (resolve, reject) {
      //Preferred method - from server
      fetch("digital-certificate.txt", {
        cache: "no-store",
        headers: { "Content-Type": "text/plain" },
      }).then(function (data) {
        data.ok ? resolve(data.text()) : reject(data.text());
      });

      //Alternate method 1 - anonymous
      //        resolve();  // remove this line in live environment

      //Alternate method 2 - direct
      resolve(
        "-----BEGIN CERTIFICATE-----\n" +
          "MIID+TCCAuGgAwIBAgIUFJx1QkZbDOHgHa+ec24vw3o1txIwDQYJKoZIhvcNAQEL\n" +
          "BQAwgYoxCzAJBgNVBAYTAklOMQ8wDQYDVQQIDAZQdW5qYWIxETAPBgNVBAcMCEx1\n" +
          "ZGhpYW5hMQ8wDQYDVQQKDAZTZWVrZXgxEzARBgNVBAsMClNldmVucm9ja3MxDDAK\n" +
          "BgNVBAMMA0dydjEjMCEGCSqGSIb3DQEJARYUZ3J2LndlYmRldkBnbWFpbC5jb20w\n" +
          "IBcNMjMwOTIzMDUwNjMxWhgPMjA1NTAzMTgwNTA2MzFaMIGKMQswCQYDVQQGEwJJ\n" +
          "TjEPMA0GA1UECAwGUHVuamFiMREwDwYDVQQHDAhMdWRoaWFuYTEPMA0GA1UECgwG\n" +
          "U2Vla2V4MRMwEQYDVQQLDApTZXZlbnJvY2tzMQwwCgYDVQQDDANHcnYxIzAhBgkq\n" +
          "hkiG9w0BCQEWFGdydi53ZWJkZXZAZ21haWwuY29tMIIBIjANBgkqhkiG9w0BAQEF\n" +
          "AAOCAQ8AMIIBCgKCAQEAjit0o5WvhQInxccqj7/Xtqgroq6X61gWI/gSQRfpbdMM\n" +
          "6U1MqGW8qzzR5bX+VBURXcdEFokpcxY3/oz17VjPQ0D/bMensZ+EqQubQqmM56zS\n" +
          "BdMlrITCoSbT+cNvA0gB4U4d8UQ58vzZHY0zj6lNFjCZuDfHO+DG+rD9EwjngZzU\n" +
          "+rS8jOb2y7xMu5tuncku/EeEESv1LndGhAmRAfidOUDAL4zbTdLQvz9zP8xcGzas\n" +
          "UhsTBfQ9r4e1b2LKgyIm08oY/eg2qjGWyfPfbrXrzsWpnyJwdHldHMUAAnvdcCxH\n" +
          "bcXSl1upy1wAq1WO4OpPkRlnRLqC09qGWnRAyU4fUQIDAQABo1MwUTAdBgNVHQ4E\n" +
          "FgQU/AtLKKkV14bzAAu99sUVO64c3DYwHwYDVR0jBBgwFoAU/AtLKKkV14bzAAu9\n" +
          "9sUVO64c3DYwDwYDVR0TAQH/BAUwAwEB/zANBgkqhkiG9w0BAQsFAAOCAQEAAA8B\n" +
          "aLZoOZ1w8SVNVDlpSSgq4+Py917Wp1Vp7D6GN6QL0PIO0Y+k6osFXQuWCNzjjSYp\n" +
          "BO7xnr4eHxSlJ9xh4s8d5RlVhCG+F8oRNeIAFsUjj4RLy/kvJWnJI5jcmjZCKhsS\n" +
          "zKGAiki6D4vhePGU+kzezOubGN0YJC5pVpwvTdJLXdiu77IwfTYSMRuxOu9wvhhk\n" +
          "oq/HKpBIYDr5b6frej6H9/o4mCN92qZulhF0bsYhDkvE/4S9cwDaX0i9bV7Ojt17\n" +
          "FCabjHwxjgycLavqJiXCFyfRoF8CyckAVzqMyuVcCP8/3wCM603KB41+LP+c6Hds\n" +
          "D1DnIqq9EEUabJvMyQ==\n" +
          "-----END CERTIFICATE-----\n"
      );
    });

    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        // Preferred method - from server
        axios
          .get(
            "https://erp.sevenrocks.in/app/webroot/qz-local/sign-message.php",
            {
              params: { request: toSign },
              headers: { "Content-Type": "text/plain" },
            }
          )
          .then(function (response) {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch(function (error) {
            reject(error);
          });

        // Alternate method - unsigned
        // resolve(); // remove this line in the live environment
      };
    });
  }, []);

  // signed certificate for qz tray

  useEffect(() => {
    // Check if a connection is already open
    if (!qz.websocket.isActive()) {
      qz.websocket.connect().then(() => {
        return qz.printers.find().catch((err) => alert.error(err.message));
      });
    } else {
      // Handle the case when a connection is already open
      // You can choose to do nothing, show a message, or perform other actions here
      // alert.error("A connection with QZ Tray is already open.");
    }
  }, [alert]);

  useEffect(() => {
    if (vacant_tote && vacant_tote?.status === 0 && isVacant) {
      alert.error(vacant_tote && vacant_tote?.msg);
    } else if (vacant_tote && vacant_tote?.status === 1 && isVacant) {
      alert.success("Tote Vacant Successfully...");
    }
  }, [vacant_tote, alert, isVacant]);

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Single Label Generator`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          <Headline
            title="Label Generator"
            btnTitle="Select Printers"
            handleData={handleData}
          />

          {/* Tab Module */}
          <div className="Tab-module">
            <TabModule ind={0} label={true} />
          </div>
          <div className="summary-box">
            <div className="grid-delivery-partner-info">
              <div className="date">
                <label htmlFor="tote_code">Scan Tote</label>
                <input
                  type="text"
                  value={tote_code}
                  id="tote_code"
                  onChange={(e) => settote_code(e.target.value)}
                  onKeyPress={handleToteCode}
                />
              </div>
              <div className="date">
                <InputField
                  darked={true}
                  label="Portal"
                  value={
                    all_single_label_order_data &&
                    all_single_label_order_data?.portal !== ""
                      ? all_single_label_order_data?.portal
                      : 0
                  }
                />
              </div>
              <div className="date">
                <InputField
                  darked={true}
                  label="Picklist ID"
                  value={
                    all_single_label_order_data &&
                    all_single_label_order_data?.picklist_id !== ""
                      ? all_single_label_order_data?.picklist_id
                      : 0
                  }
                />
              </div>
              <div className="date">
                <InputField
                  darked={true}
                  label="Total Qty."
                  value={
                    all_single_label_order_data &&
                    all_single_label_order_data?.qty !== ""
                      ? all_single_label_order_data?.qty
                      : 0
                  }
                />
              </div>
            </div>
            <div className="invoice-download-tote">
              <div>
                <QzTray
                  selectedPrinter={selectedPrinter}
                  btnTitle="Download Label and Invoice"
                  onHandle={handlePdf}
                  pdf_all={products_items}
                  tote_code={tote_code}
                />
              </div>
              <div>
                <Button
                  disabled={vacant_tote_loading ? true : false}
                  onClick={() => handleVacantTote()}
                >
                  {vacant_tote_loading ? <Loader /> : "Vacant Tote"}
                </Button>
              </div>
            </div>
          </div>

          {/* table component starts here */}
          {loading ? (
            <LoaderAlone />
          ) : (
            <div
              className="table-wrapper-pagination"
              style={{ marginTop: "20px" }}
            >
              <div className="table-container-pagination">
                <DataTable
                  header={single_label_generator_heading}
                  products_items={products_items && products_items}
                />
              </div>
            </div>
          )}
          {/* table component ends here */}
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default LabelGenerator;
