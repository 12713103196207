import React, { Fragment, useEffect, useState } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import "./AuditAlerts.css";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  ApproveRejectMarkLostWithoutAuditActions,
  CompleteAuditRequestActions,
} from "../../../Actions/PickerMgmt/PickerMgmtActions";
import Loader from "../../Loader/Loader";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";

const AuditAlerts = ({
  close,
  sku_code,
  Sku_Img,
  sku_Units,
  prod_id,
  subLoca_id,
  title,
  audit_id,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  // Mark lost Without Audit state
  const { mark_lost_appRject, loading } = useSelector(
    (state) => state.ApproveRejectMarkLostWithoutAudit
  );

  const [isApproved, setIsApproved] = useState(false);

  const handleApprove = () => {
    setIsApproved(true);
    dispatch(
      ApproveRejectMarkLostWithoutAuditActions(
        "approve_reject_picklist_requests",
        token,
        prod_id,
        subLoca_id,
        "accept"
      )
    );
  };

  useEffect(() => {
    if (mark_lost_appRject && mark_lost_appRject?.status === 1 && isApproved) {
      alert.success("Approved Success");
      setIsApproved(false);
      close();
    } else if (
      mark_lost_appRject &&
      mark_lost_appRject?.status === 0 &&
      isApproved
    ) {
      alert.error(mark_lost_appRject?.message);
      setIsApproved(false);
    }
    // eslint-disable-next-line
  }, [mark_lost_appRject, alert, isApproved]);

  // console.log("APPROVE MESSAGE: ", mark_lost_appRject);

  // Complete Audit Code

  // Complete Audit State
  const { complete_audit, loading: complete_audit_loading } = useSelector(
    (state) => state.CompleteAudit
  );

  const [isCompleted, setIsCompleted] = useState(false);

  const handleCompleteAudit = () => {
    setIsCompleted(true);
    dispatch(
      CompleteAuditRequestActions(
        "picklist_request_audit_complete",
        token,
        audit_id
      )
    );
  };

  useEffect(() => {
    if (complete_audit && complete_audit?.status === 1 && isCompleted) {
      alert.success("Success");
      setIsCompleted(false);
      navigate("/mark-lost-request");
      close();
    }
    if (complete_audit && complete_audit?.status === 0 && isCompleted) {
      alert.error(complete_audit && complete_audit?.msg);
      setIsCompleted(false);
    }
    // eslint-disable-next-line
  }, [complete_audit, isCompleted, alert]);

  return (
    <Fragment>
      <HeadingTitle
        colorRed={true}
        title={`${title === "Complete Audit" ? title : "Alerts"}`}
        closeModal={close}
      />

      <div className="SKU-container">
        <div className="sku-img">
          <img src={Sku_Img} alt="" />
        </div>
        <h3>{sku_code}</h3>
        <p>
          {title === "Complete Audit"
            ? `After completing this audit, ${sku_Units} units will be marked as lost. Are you sure you want to continue?`
            : `Are you sure you want to mark ${sku_Units} units as lost?`}
        </p>

        <div className="sku-action-cta">
          <div>
            <Button onClick={close}>No</Button>
          </div>
          <div>
            {title === "Complete Audit" ? (
              <Button onClick={() => handleCompleteAudit()}>
                {complete_audit_loading ? <Loader /> : "Yes"}
              </Button>
            ) : (
              <Button onClick={() => handleApprove()}>
                {loading ? <Loader /> : "Yes"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AuditAlerts;
