import React, { useEffect, useMemo, useRef, useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Headline from "../../Components/HeadlineSpecial/Headline";
import Header from "../../Components/Header/Header";
import {
  Assigned_list,
  secondaryPickListHeader,
} from "../../DummyArray/TableArray/TableData";
import DataTable from "../../Components/Table/DataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFiltersPickingModuleActions,
  getSecondaryPicklistManagementTable,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import { useCallback } from "react";
import { Button } from "@material-ui/core";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import { Pagination } from "../../Components/Pagination/Pagination";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import ModalContainer from "../../Components/Modal/Modal";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { useAlert } from "react-alert";
import MetaData from "../../Components/Helmet/Helmet";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const yesNoArr = [
  {
    id: 1,
    name: "Yes",
  },
  {
    id: 0,
    name: "No",
  },
];

const SecondaryPicklist = () => {
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { secondaryPicklistMgmt, loading, status } = useSelector(
    (state) => state.secondaryPicklistManagement
  );

  let service_name = "secondary_picklist_orders";

  // courier name list state
  const { courierName } = useSelector((state) => state.carriers);

  const [limit, setLimit] = useState(10);
  const [changeLimit, setChangeLimit] = useState(false);

  const handleLimit = (event) => {
    setChangeLimit(true);
    if (event.key === "Enter") {
      setPage(1);
      // dispatch(getPicklistManagementTable(service_name, 1, token, limit));
      fetchFilters();
    }
  };

  const handleLimitTag = (e) => {
    setChangeLimit(true);
    setLimit(e.target.value);
  };

  //   pagination code starts here
  const [secondaryPicklist, setSecondaryPicklist] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [isIndex, setIsIndex] = useState(null);

  const [filtersTrue, setFilterTrue] = useState(false);
  const [pagination, setPagination] = useState(0);

  const fetch = useCallback(
    (page) => {
      setIsLoading(true);
      axios
        .post(`${BASE_URL}`, {
          service_name: "secondary_picklist_orders",
          page,
          token,
          limit,
        })
        .then((response) => {
          setSecondaryPicklist(response?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    },
    [token, limit]
  );

  const handlePage = useCallback(
    (page, paginate) => {
      setPagination(page);
      setPage(page);
      if (!filtersTrue && pagination >= 0 && paginate) {
        fetch(page);
      }
    },
    [fetch, filtersTrue, pagination]
  );

  const fetchSecondaryPicklist = useCallback(() => {
    secondaryPicklistMgmt && setSecondaryPicklist(secondaryPicklistMgmt?.data);
  }, [secondaryPicklistMgmt]);

  useEffect(() => {
    fetchSecondaryPicklist();
  }, [fetchSecondaryPicklist]);

  // filters code starts here
  const [filterTotal_pages, setFilter_total_pages] = useState(0);
  const [filter_Loading, setFilter_loading] = useState(false);

  const [user_id, setUser_id] = useState("");
  const [user_name, setUser_name] = useState("");

  const [portal_id, setPortal_id] = useState("");
  const [portal_name, setPortal_name] = useState("");

  const [tote_id, settote_id] = useState("");
  const [tote_name, settote_name] = useState("");

  const [label_generated, setlabel_generated] = useState("");
  const [label_name, setLabel_name] = useState("");

  const [assigned, setAssigned] = useState("");
  const [assigned_name, setAssigned_name] = useState("");

  const [picked, setPicked] = useState("");
  const [picked_name, setPicked_name] = useState("");

  const [courier, setCourier] = useState("");
  const [tracking_no, settracking_no] = useState("");
  const [order_no, setOrder_no] = useState("");

  const [fromDate, setFromDate] = useState(null);
  const [ToDate, setToDate] = useState(null);

  const handlePortal = (val) => {
    setFilterTrue(true);
    setPortal_id(val?.id);
    setPortal_name(val?.value);
    handlePage(1, false);
  };

  const handleTote = (val) => {
    setFilterTrue(true);
    settote_id(val?.id);
    settote_name(val?.value);
    handlePage(1, false);
  };

  const handleLabel = (val) => {
    setFilterTrue(true);
    setlabel_generated(val?.id);
    setLabel_name(val?.value);
    handlePage(1, false);
  };

  const handlePickers = (val) => {
    setFilterTrue(true);
    setUser_id(val?.id);
    setUser_name(val?.value);
    handlePage(1, false);
  };

  const handleAssigned = (val) => {
    setFilterTrue(true);
    setAssigned(val?.id);
    setAssigned_name(val?.value);
    handlePage(1, false);
  };

  const handlePicked = (val) => {
    setFilterTrue(true);
    setPicked(val?.id);
    setPicked_name(val?.value);
    handlePage(1, false);
  };

  const handleCourier = (val) => {
    setFilterTrue(true);
    setCourier(val?.value);
    console.log(val);
    handlePage(1, false);
    // setCourier(e.target.value);
  };

  // const handleKeyPressCourier = (event) => {
  //   if (event.key === "Enter") {
  //     fetchFilters();
  //   }
  // };

  const handleTrackingNo = (e) => {
    setFilterTrue(true);
    settracking_no(e.target.value);
    handlePage(1, false);
  };

  const handleFromDate = (date) => {
    setFilterTrue(true);
    setFromDate(date);
    handlePage(1, false);
  };

  const handleToDate = (date) => {
    setFilterTrue(true);
    setToDate(date);
    handlePage(1, false);
  };

  const handleKeyPressTracking = (event) => {
    if (event.key === "Enter") {
      fetchFilters();
      handlePage(1, false);
    }
  };

  const handleOrderNo = (e) => {
    setFilterTrue(true);
    setOrder_no(e.target.value);
    handlePage(1, false);
  };

  const handleKeyPressOrder = (event) => {
    if (event.key === "Enter") {
      fetchFilters();
      handlePage(1, false);
    }
  };

  let from_date =
    fromDate !== "" ? moment(fromDate).format("yyyy-MM-DD") : null;
  let to_date = ToDate !== "" ? moment(ToDate).format("yyyy-MM-DD") : null;
  let filters = useMemo(() => {
    const dataToSend = {
      user_id,
      portal_id,
      tote_id,
      label_generated,
      assigned,
      picked,
      courier,
      tracking_no,
      order_no,
      from_date,
      to_date,
    };
    // Filtering empty value
    const filter_data = Object.fromEntries(
      Object.entries(dataToSend).filter(
        ([_, value]) =>
          value !== "" && value !== null && value !== "Invalid date"
      )
    );
    return filter_data;
  }, [
    user_id,
    portal_id,
    tote_id,
    label_generated,
    assigned,
    picked,
    courier,
    tracking_no,
    order_no,
    from_date,
    to_date,
  ]);

  const fetchFilters = useCallback(() => {
    setFilter_loading(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "secondary_picklist_orders",
        token,
        page,
        filters,
        limit,
      })
      .then((response) => {
        if (response && response?.data?.status === 0) {
          alert.error(response && response?.data?.msg);
        } else {
          setFilter_total_pages(response?.data?.total_pages);
          setSecondaryPicklist(response?.data?.data);
          setFilter_loading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setFilter_loading(false);
      });
  }, [token, page, filters, alert, limit]);

  // filters code ends here

  // label code
  const [isIndexs, setIsindexs] = useState([]);
  const [isOk, setIsOk] = useState(true);
  const [isMultiSec, setIsMultiSec] = useState(false);
  const [oldIndex, setOldIndex] = useState([]);
  let order_ids = [...new Set([...isIndexs, ...oldIndex])];

  const handleIndex = (index) => {
    setOldIndex((prev) => [...prev, index]);
    if (isIndexs.includes(index) || oldIndex.includes(index)) {
      setIsindexs((prevNum) => prevNum.filter((num) => num !== index));
      setOldIndex((prevNum) => prevNum.filter((num) => num !== index));
    } else {
      setIsindexs((prev) => [...prev, index]);
      setOldIndex((prev) => [...prev, index]);
    }
  };

  const handleIndexAll = () => {
    setIsOk(!isOk);
    if (isOk) {
      secondaryPicklist &&
        secondaryPicklist.forEach((i) => {
          if (i.is_cancel === 0) {
            if (isIndexs.includes(i.id)) {
              setIsindexs((prevNum) => prevNum.filter((num) => num !== i.id));
            } else {
              setIsindexs((prev) => [...prev, i.id]);
            }
          }
        });
    } else {
      setIsindexs([]);
      setOldIndex([]);
    }
  };

  const handleNull = () => {
    setIsindexs([]);
    setOldIndex([]);
    setIsOk(!isOk);
  };

  useEffect(() => {
    if (filtersTrue === true) {
      fetchFilters();
    } else if (
      filtersTrue === false &&
      pagination === 0 &&
      changeLimit === false
    ) {
      if (isIndex === null && changeLimit === false) {
        dispatch(
          getSecondaryPicklistManagementTable(service_name, 1, token, limit)
        );
        setIsindexs([]);
        setOldIndex([]);
      }
    }
  }, [
    dispatch,
    filtersTrue,
    isIndex,
    fetchFilters,
    pagination,
    service_name,
    token,
    changeLimit,
    limit,
  ]);

  const [modalNo, setModalNo] = useState(null);
  const [order_id, setOrder_id] = useState();

  const [orderId, setOrderId] = useState("");

  const closeModal = () => {
    setIsIndex(null);
  };

  const handleSecondaryIndex = (i, mN, ix) => {
    setIsIndex(i);
    setOrder_id(ix);
    setModalNo(mN);
  };

  const handlePicklistDetails = (data, index) => {
    setIsIndex(index);
    setOrderId(data);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          modalNo={modalNo}
          order_id={isMultiSec ? order_ids : order_id}
          orderId={orderId}
        />
      );
    }
    return null;
  };

  // All Filter data for dropdown
  let All_Filters = useMemo(() => {
    const dataToSend = ["pickers", "portals", "totes"];
    return dataToSend;
  }, []);

  const { all_filters, loading: allFilter_loading } = useSelector(
    (state) => state.allPickingModuleFilters
  );

  let pickers_list = all_filters && all_filters?.pickers;
  let portals_list = all_filters && all_filters?.portals;
  let totes_list = all_filters && all_filters?.totes;

  const changeKeyName = (totes_list, code, name) => {
    return (
      totes_list &&
      totes_list.map((obj) => {
        const newObj = { ...obj };
        newObj[name] = newObj[code];
        delete newObj[code];
        return newObj;
      })
    );
  };

  // Call the function to change the key name
  const new_totes_list = changeKeyName(totes_list, "code", "name");

  useEffect(() => {
    dispatch(getAllFiltersPickingModuleActions("filters", token, All_Filters));
  }, [dispatch, token, All_Filters]);

  // handle Multiple Secondary Picklist Assignment
  const handleMultiplePicklistAssignment = (index, i) => {
    setIsMultiSec(true);
    if (order_ids && order_ids.length <= 0) {
      return alert.error("Please Select Checkbox...");
    } else {
      setIsIndex(index);
      setModalNo(i);
    }
  };

  let ActiveOrders = [];
  secondaryPicklist &&
    secondaryPicklist.forEach((i) => {
      if (i.is_cancel === 0 && !ActiveOrders.includes(i.id)) {
        ActiveOrders.push(i);
      } else {
        return null;
      }
    });

  const resteFilter = () => {
    setPortal_id("");
    setPortal_name("");
    settote_id("");
    settote_name("");
    setlabel_generated("");
    setLabel_name("");
    setUser_id("");
    setUser_name("");
    setAssigned("");
    setAssigned_name("");
    setPicked("");
    setPicked_name("");
    setCourier("");
    settracking_no("");
    setOrder_no("");
    setFromDate("");
    setToDate("");
  };

  // console.log("Courier Name: ", courierName);

  const [loadingCSV, setLoadingCsv] = useState(false);

  const downloadCSV = async () => {
    setLoadingCsv(true);
    try {
      const response = await axios.post(`${BASE_URL}`, {
        service_name: "csv_secondary_picklist_orders",
        token,
        filters,
      });
      const csvFile = response?.data?.file;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
      }

      setLoadingCsv(false);
    } catch (err) {
      alert.error(err);
      setLoadingCsv(false);
    }
  };

  // useEffect(() => {
  //   if (
  //     portal_id ||
  //     portal_name ||
  //     tote_id ||
  //     tote_name ||
  //     label_generated ||
  //     label_name ||
  //     user_id ||
  //     user_name ||
  //     assigned ||
  //     assigned_name ||
  //     picked ||
  //     picked_name ||
  //     courier ||
  //     tracking_no ||
  //     order_no
  //   ) {
  //     handlePage(1);
  //   }
  // }, [
  //   portal_id,
  //   portal_name,
  //   tote_id,
  //   tote_name,
  //   label_generated,
  //   label_name,
  //   user_id,
  //   user_name,
  //   assigned,
  //   assigned_name,
  //   picked,
  //   picked_name,
  //   courier,
  //   tracking_no,
  //   order_no,
  //   handlePage,
  // ]);

  const scrollContainerRef = useRef(null);

  const handleScroll = (scrollOffset) => {
    scrollContainerRef.current.scrollBy({
      left: scrollOffset,
      behavior: "smooth",
    });
  };

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  console.log("FROM DATE: ", fromDate);
  console.log("TO DATE: ", ToDate);

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Secondary Picklist`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          {loading ? (
            <LoaderAlone />
          ) : (
            <>
              {status === 0 ? (
                <NoDataFromApi
                  height={true}
                  title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                />
              ) : (
                <>
                  <Headline
                    title="Secondary Orders"
                    btnTitle="Download Total Label"
                    handleData={downloadCSV}
                    loadingCSV={loadingCSV}
                    width={true}
                  />

                  <Button
                    className="back-icon-cta"
                    onClick={() => handleScroll(-100)}
                  >
                    <ArrowBackIosNewIcon />
                  </Button>
                  <Button
                    className="forward-icon-cta"
                    onClick={() => handleScroll(100)}
                  >
                    <ArrowForwardIosIcon />
                  </Button>

                  {/* dropdown grid container */}
                  <div className="dropdown-grid-row">
                    <div>
                      <Dropdown
                        placeholder="User Id"
                        dropdown={pickers_list && pickers_list}
                        value={user_name}
                        onData={handlePickers}
                        showId={true}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Portal Name"
                        dropdown={portals_list && portals_list}
                        value={portal_name}
                        onData={handlePortal}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Totes Id"
                        dropdown={new_totes_list && new_totes_list}
                        value={tote_name}
                        onData={handleTote}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Label Generated"
                        dropdown={Assigned_list && Assigned_list}
                        value={label_name}
                        onData={handleLabel}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Assigned"
                        dropdown={Assigned_list && Assigned_list}
                        value={assigned_name}
                        onData={handleAssigned}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Picked"
                        dropdown={Assigned_list && Assigned_list}
                        value={picked_name}
                        onData={handlePicked}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Courier Name"
                        dropdown={courierName && courierName?.data}
                        value={courier}
                        onData={handleCourier}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Primary Picked"
                        dropdown={yesNoArr}
                        // value={courier}
                        // onData={handleCourier}
                        noShow={true}
                        // loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="ByPass Order"
                        dropdown={yesNoArr}
                        // value={courier}
                        // onData={handleCourier}
                        noShow={true}
                        // loading={allFilter_loading}
                      />
                    </div>
                    {/* <div className="date_handover">
                      <input
                        type="text"
                        name="courier_name"
                        id="courier_name"
                        value={courier}
                        onChange={(e) => handleCourier(e)}
                        placeholder="Courier Name"
                        onKeyPress={handleKeyPressCourier}
                      />
                    </div> */}
                    <div className="date_handover">
                      <input
                        type="text"
                        name="tracking_no"
                        id="tracking_no"
                        value={tracking_no}
                        onChange={(e) => handleTrackingNo(e)}
                        placeholder="Tracking No"
                        onKeyPress={handleKeyPressTracking}
                      />
                    </div>
                    <div className="date_handover">
                      <input
                        type="text"
                        name="order_no"
                        id="order_no"
                        value={order_no}
                        onChange={(e) => handleOrderNo(e)}
                        placeholder="Order No"
                        onKeyPress={handleKeyPressOrder}
                      />
                    </div>
                    <div>
                      <DatePicker
                        className="date-picker"
                        placeholderText="From Date"
                        selected={fromDate}
                        onChange={(date) => handleFromDate(date)}
                      />
                    </div>
                    <div>
                      <DatePicker
                        className="date-picker"
                        placeholderText="To Date"
                        selected={ToDate}
                        onChange={(date) => handleToDate(date)}
                      />
                    </div>
                    <div>
                      <div className="clear-filter">
                        <Button onClick={() => resteFilter()}>
                          Reset Filter
                        </Button>
                      </div>
                    </div>
                  </div>

                  {/* product table */}
                  <div
                    className="table-wrapper-pagination"
                    ref={scrollContainerRef}
                  >
                    <div className="table-container-pagination">
                      <div className="header-limit-checkbox">
                        <div>
                          <div>
                            <input
                              type="checkbox"
                              readOnly
                              id="checkbox"
                              checked={
                                ActiveOrders && ActiveOrders?.length > 0
                                  ? ActiveOrders.every((i) =>
                                      i.is_cancel === 0 &&
                                      order_ids.includes(i.id)
                                        ? true
                                        : false
                                    )
                                  : false
                              }
                            />
                            <label
                              onClick={() => handleIndexAll()}
                              htmlFor="checkbox"
                            ></label>
                          </div>
                          <p className="lable-select-all">Select all</p>
                          <Button
                            type="submit"
                            onClick={() =>
                              handleMultiplePicklistAssignment(2, 2)
                            }
                            className="create-picklist-requirements"
                          >
                            Assign all
                          </Button>
                          <Button
                            type="submit"
                            className="create-picklist-requirements bypass-all"
                          >
                            ByPass All
                          </Button>
                        </div>
                        <div className="result-flex-grid">
                          <p className="result-out">
                            *Result {page} in{" "}
                            {filtersTrue
                              ? filterTotal_pages
                              : secondaryPicklistMgmt &&
                                secondaryPicklistMgmt?.total_pages}
                          </p>
                          <div className="limit">
                            <input
                              type="text"
                              name="limit"
                              id="limit"
                              value={limit}
                              onKeyPress={handleLimit}
                              onChange={(e) => handleLimitTag(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <DataTable
                        header={secondaryPickListHeader}
                        picklistData={secondaryPicklist && secondaryPicklist}
                        isIndexSelectAll={order_ids}
                        onSelect={handleIndex}
                        isOk={isOk}
                        onModalIndex={handleSecondaryIndex}
                        handleOrder={handlePicklistDetails}
                      />
                    </div>
                  </div>

                  <div className="rendering-pagination">
                    <Pagination
                      uniqueKey={`ScreenA-${page}`}
                      page={page}
                      isLoading={isLoading ? isLoading : filter_Loading}
                      totalPage={
                        filtersTrue
                          ? filterTotal_pages
                          : secondaryPicklistMgmt &&
                            secondaryPicklistMgmt?.total_pages
                      }
                      onPageChange={handlePage}
                      onHandleCheckbox={handleNull}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default SecondaryPicklist;
