import React from "react";
import "./Loader.css";
import Emoiji from '../../Assets/no-data.gif'

const NoDataFromApi = (props) => {
  return (
    <div className="no-categories" style={{height: props.height ? 'auto' : '100vh'}}>
      <img src={Emoiji} alt="" />
      <p>{props.title}...</p>
    </div>
  );
};

export default NoDataFromApi;