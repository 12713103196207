import React from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  AddPredefinedProblemActions,
  EditPredefinedProblemActions,
} from "../../../Actions/PickerMgmt/PickerMgmtActions";
import { useAlert } from "react-alert";
import Loader from "../../Loader/Loader";
import { ADD_PREDEFINED_PROBLEMS_RESET } from "../../../Constants/PickerMgmt/PickerManagementConstants";

const AddPredefinedProblem = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  // add problems state
  const { add_predefined_problem, loading } = useSelector(
    (state) => state.addProblem
  );

  // edit problems state
  const { edit_predefined_problem, loading: edit_loading } = useSelector(
    (state) => state.editProblem
  );

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  const { close, probText, problemId } = props;

  const [ishit, setIshit] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [text, setText] = useState(probText);

  const inputRef = useRef(null);

  useEffect(() => {
    // Focus the input field when the modal is opened
    inputRef.current.focus();
  }, []);

  const addProblem = (e) => {
    setIshit(true);
    e.preventDefault();
    if (text !== "") {
      dispatch(
        AddPredefinedProblemActions(
          "manage_predefined_problems",
          token,
          "add",
          text
        )
      );
    } else {
      alert.error("Please Fill The Problem Field...");
    }
  };

  const editProblem = (e) => {
    setIsEdit(true);
    e.preventDefault();
    if (text !== "") {
      dispatch(
        EditPredefinedProblemActions(
          "manage_predefined_problems",
          token,
          "edit",
          text,
          problemId
        )
      );
    } else {
      alert.error("Please Fill The Problem Field...");
    }
  };

  useEffect(() => {
    if (
      add_predefined_problem &&
      add_predefined_problem?.status === 1 &&
      ishit
    ) {
      alert.success("Problem Added Successfully...");
      close();
      dispatch({
        type: ADD_PREDEFINED_PROBLEMS_RESET,
      });
      setIshit(false);
    } else if (
      add_predefined_problem &&
      add_predefined_problem?.status === 0 &&
      ishit
    ) {
      alert.error(add_predefined_problem && add_predefined_problem?.msg);
      dispatch({
        type: ADD_PREDEFINED_PROBLEMS_RESET,
      });
      setIshit(false);
    }
  }, [dispatch, add_predefined_problem, close, alert, ishit]);

  useEffect(() => {
    if (
      edit_predefined_problem &&
      edit_predefined_problem?.status === 1 &&
      isEdit
    ) {
      alert.success("Problem Edited Successfully...");
      close();
      setIsEdit(false);
    } else if (
      edit_predefined_problem &&
      edit_predefined_problem?.status === 0 &&
      isEdit
    ) {
      alert.error(edit_predefined_problem && edit_predefined_problem?.msg);
      setIsEdit(false);
    }
  }, [edit_predefined_problem, close, alert, isEdit]);

  // console.log("Add: ", props.probText);
  return (
    <>
      <HeadingTitle
        title={`${
          props.probText !== null &&
          props.probText !== undefined &&
          props.probText !== ""
            ? "Edit Problem"
            : "Add Problem"
        }`}
        closeModal={close}
      />
      <form
        onSubmit={
          props.probText !== null &&
          props.probText !== undefined &&
          props.probText !== ""
            ? editProblem
            : addProblem
        }
      >
        <div className="input-field mt">
          <label htmlFor="problem">
            {props.probText !== null &&
            props.probText !== undefined &&
            props.probText !== ""
              ? "Edit Problem"
              : "Add Problem"}
          </label>{" "}
          <br />
          <input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            id="problem"
            ref={inputRef}
          />
        </div>
        <div className="tote-cta">
          <Button type="submit">
            {loading || edit_loading ? (
              <Loader />
            ) : props.probText !== null &&
              props.probText !== undefined &&
              props.probText !== "" ? (
              "Edit"
            ) : (
              "Add"
            )}
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddPredefinedProblem;
