import React, { Fragment, useEffect, useState } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../Table/DataTable";
import { WithoutAuditHeader } from "../../../DummyArray/TableArray/TableData";
import { getWithWoutAuditActions } from "../../../Actions/OrderHandoverActions/OrderHandoverActions";
import LoaderAlone from "../../Loader/LoaderAlone";

const AcceptWithoutAudit = ({
  close,
  subLocation_id,
  product_id,
  handleAuditAlert,
}) => {
  const dispatch = useDispatch();

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  //   Without Table Data Audit state
  const { Audit_data, loading } = useSelector((state) => state.getAudit);

  const [Audit_row, setAudit_row] = useState([]);

  useEffect(() => {
    dispatch(
      getWithWoutAuditActions(
        "mark_lost_request_without_audit",
        token,
        product_id,
        subLocation_id
      )
    );
  }, [dispatch, token, product_id, subLocation_id]);

  useEffect(() => {
    if (Audit_data && Audit_data?.status === 1) {
      // console.log("AUDIT DATA: ", [Audit_data?.data]);
      setAudit_row([Audit_data?.data]);
    }
  }, [Audit_data]);

  // console.log("AUDIT DATA: ", Audit_row);

  const handleConfirmation = (i, j, k, product_id, subLocation_id) => {
    handleAuditAlert(i, j, k, product_id, subLocation_id);
  };

  return (
    <Fragment>
      <HeadingTitle title={`Accept Request without Audit`} closeModal={close} />

      {/* Table Component starts here */}
      <div className="table-wrapper-pagination">
        <div className="table-container-pagination">
          {loading ? (
            <LoaderAlone />
          ) : (
            <DataTable
              header={WithoutAuditHeader}
              handleConfirmation={handleConfirmation}
              auditData={Audit_row}
            />
          )}
        </div>
      </div>
      {/* Table Component ends here */}
    </Fragment>
  );
};

export default AcceptWithoutAudit;
