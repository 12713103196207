import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import SideBar from "../../Components/SideBar/SideBar";
import MetaData from "../../Components/Helmet/Helmet";
import Headline from "../../Components/HeadlineSpecial/Headline";
import Dropdown from "../../Components/Dropdown/Dropdown";
import "./PackingMaterial.css";
import DataTable from "../../Components/Table/DataTable";

const AddIdealConsumption = () => {
  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  const [activeTab, setActiveTab] = useState(0);
  const [rowsData, setRowsData] = useState([
    { name: "Poly Bag", sku: "SKU123", qty: 10 },
    { name: "Label", sku: "SKU123", qty: 10 },
  ]);

  const handleQty = (value, i) => {
    if (Number(value)) {
      setRowsData((prevData) =>
        prevData.map((item, index) =>
          index === i ? { ...item, qty: value } : item
        )
      );
    }
  };

  const handleSKU = (value, i) => {
    setRowsData((prevData) =>
      prevData.map((item, index) =>
        index === i ? { ...item, sku: value } : item
      )
    );
  };

  const tabs = [
    "1 Qty Package",
    "2 Qty Package",
    "3 Qty Package",
    "4 Qty Package",
    "5 Qty Package",
    "6 Qty Package",
    "7 Qty Package",
    "8 Qty Package",
    "9 Qty Package",
    "10 Qty Package",
    "10+ Qty Package",
  ];

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Add Ideal Consumptions`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header isToggle={isToggle} />
        <div className="wrapper-picker-container">
          <Headline title="Add Ideal Consumptions" hide={true} />

          {/* Dropdown Row Starts here */}
          <div className="dropdown-container">
            <Dropdown
              placeholder="Select Portal"
              showId={true}
              noShow={false}
            />
          </div>
          {/* Dropdown Row ends here */}

          {/* Tab Module Component starts here */}
          <div className="Tab-module">
            <nav
              className="update-tab-module-grid-row"
              aria-label="Tabs"
              role="tablist"
              aria-orientation="horizontal"
            >
              {tabs.map((tab, index) => (
                <button
                  key={index}
                  type="button"
                  className={`tab-button ${
                    activeTab === index ? "active" : ""
                  }`}
                  id={`card-type-tab-item-${index + 1}`}
                  aria-selected={activeTab === index}
                  role="tab"
                  onClick={() => setActiveTab(index)}
                >
                  {tab}
                </button>
              ))}
            </nav>
          </div>

          <div className="summary-box">
            <div
              id={`card-type-tab-1`}
              role="tabpanel"
              aria-labelledby={`card-type-tab-item-1`}
              // className={activeTab === index ? "" : "hidden"}
              className="text-center table-container-pagination"
            >
              <DataTable
                header={[
                  "Packing Item Unit",
                  "Packing Item SKU",
                  "Packing Item Qty",
                ]}
                qtyConsumptionData={rowsData}
                handleQty={handleQty}
                handleSKU={handleSKU}
              />
              <button className="submit-btn">Submit</button>
            </div>
          </div>
          {/* Tab Module Component ends here */}
        </div>
      </div>
    </div>
  );
};

export default AddIdealConsumption;
