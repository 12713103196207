import React from "react";
import { HeadingTitle } from "../../Modal/Modal";
import DataTable from "../../Table/DataTable";
import {
  order_details,
  serial_code_wise_header_arr,
  tote_wise_header_arr,
} from "../../../DummyArray/TableArray/TableData";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  SecondaryPicklistDetailsActions,
  listCreateOrderDetailsActions,
} from "../../../Actions/PickerMgmt/PickerMgmtActions";
import LoaderAlone from "../../Loader/LoaderAlone";

const CreateOrderTable = (props) => {
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();

  const { secondary_picklist_details, loading } = useSelector(
    (state) => state.secondary_details
  );

  const { list_order_details_data, loading: list_order_loading } = useSelector(
    (state) => state.listCreateOrderDetails
  );

  const { close, isData, orderId } = props;

  useEffect(() => {
    if (orderId !== null && orderId !== undefined && orderId !== "") {
      dispatch(
        SecondaryPicklistDetailsActions(
          "secondary_order_details",
          token,
          orderId
        )
      );
    } else if (isData !== null && isData !== undefined && isData !== "") {
      dispatch(
        listCreateOrderDetailsActions("get_order_details", token, isData)
      );
    }
  }, [orderId, dispatch, token, isData]);

  // console.log(secondary_picklist_details);
  return (
    <>
      <HeadingTitle title="Order Details" closeModal={close} />
      {orderId !== null && orderId !== undefined && orderId !== "" ? (
        <>
          {loading ? (
            <LoaderAlone />
          ) : (
            <>
              <div className="table-wrapper">
                <h3 className="sku-heading mt-2">Tote Wise SKU</h3>
                <div className="table-container mt-x">
                  <DataTable
                    tote_wise_details={
                      secondary_picklist_details &&
                      secondary_picklist_details?.data?.tote_details
                    }
                    header={tote_wise_header_arr}
                  />
                </div>
              </div>
              <div className="table-wrapper-pagination">
                <h3 className="sku-heading">Serial Code Wise SKU</h3>
                <div className="table-container-pagination">
                  <DataTable
                    serial_code_wise_data={
                      secondary_picklist_details &&
                      secondary_picklist_details?.data?.order_details
                    }
                    header={serial_code_wise_header_arr}
                  />
                </div>
              </div>
            </>
          )}
        </>
      ) : list_order_loading ? (
        <LoaderAlone />
      ) : (
        <div className="table-wrapper">
          <div className="table-container">
            <DataTable
              orderDetails_data={
                list_order_details_data && list_order_details_data?.data
              }
              header={order_details}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CreateOrderTable;
