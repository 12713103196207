import React from "react";

const ImgLoader = () => {
  return (
    <div className="img-loader-modal">
      <div className="loader-image-modal"></div>
    </div>
  );
};

export default ImgLoader;
