import React, { useEffect, useMemo, useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import "./PickerView.css";
import ArrowIcon from "../../Assets/Icons/SideBar-Icons/arrow-left.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import CounterContainer from "../../Components/CountersContainer/CounterContainer";
import MicroPhoneIcon from "../../Assets/Icons/SideBar-Icons/microphone-2.png";
import Comment from "../../Assets/Icons/SideBar-Icons/message-text.png";
import Gallery from "../../Assets/Icons/SideBar-Icons/gallery.png";
import DataTable from "../../Components/Table/DataTable";
import { home_date } from "../../DummyArray/TableArray/TableData";
import UserInfo from "../../Components/UserInfo/UserInfo";
import Select from "react-select";
import { RadiuscustomStyles } from "../../Components/DropdownStyles/DropdownStyles";
import { useDispatch, useSelector } from "react-redux";
import { getPickerViewDetailsActions } from "../../Actions/PickerMgmt/PickerMgmtActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import MetaData from "../../Components/Helmet/Helmet";

const labels = {
  label1: "User Id",
  label2: "Username",
  label3: "Current Speed",
  label4: "Total Picked",
  label5: "Pending",
  label6: "Required",
  label7: "Last Scan",
  label8: "Idle Since",
  label9: "Current Location",
  label10: "Next Location",
  label11: "View Voice Records",
  label12: "View Comments",
  label13: "View Media",
};

const colors = {
  backgroundColor: "#46CC8D",
  color: "white",
  btnColor: "#46CC8D",
  btnColor1: "#26A6FE",
  btnColor2: "#F7B940",
};

const PickerView = () => {
  const navigate = useNavigate();
  const { user_id } = useParams();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { picker_details, loading, status } = useSelector(
    (state) => state.pickerDetails
  );

  const [from_time, setfrom_time] = useState(home_date && home_date[0]?.id);
  const [from_time_label, setfrom_time_label] = useState(
    home_date && home_date[0]?.name
  );
  const handleDateChange = (selectedOption) => {
    setfrom_time(selectedOption.value);
    setfrom_time_label(selectedOption.label);
  };

  const header = [
    "Pick List Id",
    "Picklist Name",
    "Created On",
    "Portal",
    "Picklist Type",
    "Total Qty",
    "Picked",
    "Pending",
    "Picklist Provide Type",
    "Picklist Status",
    "Download",
  ];
  const secondaryHeader = [
    "Order No.",
    "AWB No.",
    "Total Qty",
    "Picked Qty",
    "Courier Name",
    "Portal Name",
    "Download",
  ];

  let filters = useMemo(() => {
    const dataToSend = {
      from_time,
    };

    return dataToSend;
  }, [from_time]);

  useEffect(() => {
    if (user_id && user_id !== undefined) {
      dispatch(
        getPickerViewDetailsActions("picker_details", token, user_id, filters)
      );
    }
  }, [dispatch, user_id, token, filters]);

  let picker_view = picker_details && picker_details?.data;

  let picker_tiles = picker_view && picker_view?.user;

  let secondary_order = picker_view && picker_view?.secondary_orders;

  let picklist = picker_view && picker_view?.picklists;

  const PickerMetrics = [
    {
      id: 1,
      count: picker_view && picker_view?.total,
      title: "Total Picklist",
      color: "#24CA991A",
      bColor: "#24CA9966",
    },
    {
      id: 2,
      count: picker_view && picker_view?.completed,
      title: "Completed Picklist",
      color: "#ED54541A",
      bColor: "#ED545466",
    },
    {
      id: 3,
      count: picker_view && picker_view?.avg_picking_time,
      title: "Average Picking Time",
      color: "#006FE31A",
      bColor: "#006FE366",
    },
    {
      id: 4,
      count: picker_view && picker_view?.pending_time,
      title: "Pending Time",
      color: "#7C74FE1A",
      bColor: "#7C74FE66",
    },
    {
      id: 5,
      count: picker_view && picker_view?.closed,
      title: "Closed Picklist",
      color: "#FD7F351A",
      bColor: "#FD7F3566",
    },
    {
      id: 6,
      count: picker_view && picker_view?.hold,
      title: "Hold Picklist",
      color: "#5C5C5C1A",
      bColor: "#5C5C5C66",
    },
    {
      id: 7,
      count: picker_view && picker_view?.pending_pieces,
      title: "Pending Pieces",
      color: "#52B2D21A",
      bColor: "#52B2D266",
    },
    {
      id: 8,
      count: picker_view && picker_view?.multi_order,
      title: "Multi Order",
      color: "#F7B9401A",
      bColor: "#F7B94066",
    },
  ];

  // console.log("Picker View: ", picker_view);

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData
        title={`${
          loading
            ? "Loading..."
            : picker_tiles && picker_tiles?.name + " - Picker View"
        }`}
      />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        {/* go back  */}
        <div className="wrapper-picker-container">
          {loading ? (
            <LoaderAlone />
          ) : (
            <>
              {status === 0 ? (
                <NoDataFromApi
                  height={true}
                  title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                />
              ) : (
                <>
                  <div className="picklist-details-header">
                    <Link onClick={() => navigate(-1)} className="go-back-row">
                      <div>
                        <img src={ArrowIcon} alt="" />
                      </div>
                      <div>
                        <p>Picker View</p>
                      </div>
                    </Link>
                    <Select
                      placeholder={`${
                        from_time_label !== "" && from_time_label
                      }`}
                      options={
                        home_date &&
                        home_date.map((option) => ({
                          value: option.id,
                          label: option.name,
                        }))
                      }
                      value={from_time_label}
                      styles={RadiuscustomStyles}
                      onChange={handleDateChange}
                    />
                  </div>
                  {/* grid count row starts here */}
                  <div
                    className={`grid-opertional-row ${
                      isToggle && "metric-grid-operation-row"
                    }`}
                  >
                    {PickerMetrics &&
                      PickerMetrics.map((i) => {
                        return (
                          <CounterContainer
                            bColor={i.bColor}
                            count={i.count}
                            color={i.color}
                            title={i.title}
                            key={i.id}
                          />
                        );
                      })}
                  </div>
                  {/* grid count row ends here */}
                  {/* user-info  */}
                  <UserInfo
                    obj={labels}
                    obj1={colors}
                    UserUri={picker_tiles && picker_tiles?.user_img}
                    userId={picker_tiles && picker_tiles?.user_id}
                    userName={picker_tiles && picker_tiles?.name}
                    currentSpeed={picker_tiles && picker_tiles?.current_speed}
                    totalPicked={picker_tiles && picker_tiles?.picked_qty}
                    Pending={picker_tiles && picker_tiles?.pending_qty}
                    Required={picker_tiles && picker_tiles?.required_qty}
                    lastscan={picker_tiles && picker_tiles?.last_scan}
                    idleSince={picker_tiles && picker_tiles?.idle_since}
                    currentLocation={
                      picker_tiles && picker_tiles?.current_location
                    }
                    nextLocation={picker_tiles && picker_tiles?.next_location}
                    MicroPhoneIcon={MicroPhoneIcon}
                    Comment={Comment}
                    Gallery={Gallery}
                  />
                  {/* user-info */}
                  {/* picklist details */}
                  <div className="headline-db">
                    <div>
                      <p>Picklist Details</p>
                    </div>
                    <div className="d-flex-select-date">
                      <button>Download Total Pendency</button>
                      {/* <Select
                        placeholder={`${date !== "" ? date : dateArr[0]}`}
                        options={dateArr.map((option) => ({
                          value: option,
                          label: option,
                        }))}
                        value={date}
                        styles={RadiuscustomStyles}
                        onChange={handleDateChange}
                      /> */}
                    </div>
                  </div>
                  {/* picklist details */}
                  <div className="table-wrapper">
                    <div className="table-container">
                      <DataTable data={picklist && picklist} header={header} />
                    </div>
                  </div>

                  {/* secondary orders */}
                  <div className="headline-db">
                    <div>
                      <p>Secondary Orders</p>
                    </div>
                    <div>
                      {/* <Select
                        placeholder={`${date !== "" ? date : dateArr[0]}`}
                        options={dateArr.map((option) => ({
                          value: option,
                          label: option,
                        }))}
                        value={date}
                        styles={RadiuscustomStyles}
                        onChange={handleDateChange}
                      /> */}
                    </div>
                  </div>

                  <div className="table-wrapper">
                    <div className="table-container">
                      <DataTable
                        dataOrders={secondary_order && secondary_order}
                        header={secondaryHeader}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PickerView;
