import React, { useCallback } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import "./Alert.css";
// import { useDispatch, useSelector } from "react-redux";
// import Loader from "../../Loader/Loader";
// import { useAlert } from "react-alert";
// import { DeletePredefinedProblemActions } from "../../../Actions/PickerMgmt/PickerMgmtActions";
// import { useEffect } from "react";
// import { useState } from "react";
// import { DELETE_PREDEFINED_PROBLEMS_RESET } from "../../../Constants/PickerMgmt/PickerManagementConstants";
// import { deleteToteActions } from "../../../Actions/PickerMgmt/PickerMgmtActions";
// import { useEffect } from "react";

const AlertPopup = (props) => {
  const { close, handleSubmit } = props;

  const closeModal = useCallback(() => {
    close(null);
  }, [close]);

  const handleModalSubmit = useCallback(() => {
    handleSubmit();
    closeModal();
  }, [handleSubmit, closeModal]);

  return (
    <>
      <HeadingTitle colorRed={true} title={props.title} closeModal={close} />

      <div className="text-center">
        <p
          className="para"
          dangerouslySetInnerHTML={{ __html: props.message }}
        ></p>

        {props.boxLabel ? (
          <div className="btn-container mt-3">
            <button type="submit" className="labelBtn">
              Submit
            </button>
            <button
              type="submit"
              className="labelBtn"
              style={{ backgroundColor: "#26A6FE" }}
            >
              Create Box Label
            </button>
          </div>
        ) : (
          <div className="btn-container mt-3">
            <button
              type="submit"
              className="labelBtn"
              onClick={closeModal}
              style={{ backgroundColor: "#ff0000" }}
            >
              No
            </button>
            <button
              className="labelBtn"
              type="submit"
              onClick={handleModalSubmit}
            >
              Yes
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default AlertPopup;
