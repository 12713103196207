import React, { useEffect } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import { useDispatch, useSelector } from "react-redux";
import { getAllPredefinedProblemActions } from "../../Actions/PickerMgmt/PickerMgmtActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import DataTable from "../../Components/Table/DataTable";
import { predefinedProblems_header } from "../../DummyArray/TableArray/TableData";
import ModalContainer from "../../Components/Modal/Modal";
import { useState } from "react";
import MetaData from "../../Components/Helmet/Helmet";

const PredefinedProblems = () => {
  const dispatch = useDispatch();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  const { predefined_problems_data, loading } = useSelector(
    (state) => state.predefinedProblems
  );

  const [probText, setProbText] = useState("");
  const [problemId, setProblemId] = useState("");

  const IsOpenHandler = (pro, id) => {
    handleModal(12);
    setProbText(pro);
    setProblemId(id);
  };

  const [id, setId] = useState("");

  // console.log("Problems: ", predefined_problems_data);

  const [isIndex, setIsIndex] = useState(null);
  const handleModal = (index) => {
    setIsIndex(index);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          id={id}
          probText={probText}
          problemId={problemId}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (isIndex === null) {
      dispatch(
        getAllPredefinedProblemActions(
          "manage_predefined_problems",
          token,
          "list",
          1
        )
      );
    }
  }, [dispatch, token, isIndex]);

  const handleDeleteProblem = (child) => {
    setId(child);
    handleModal(1);
  };

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Predefined Problems`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          {loading ? (
            <LoaderAlone />
          ) : (
            <>
              <Headline
                onData={IsOpenHandler}
                title="Pre Defined Problems"
                btnTitle="Add Problems"
              />

              <div className="table-wrapper">
                <div className="table-container">
                  <DataTable
                    deleteProblems={handleDeleteProblem}
                    header={predefinedProblems_header}
                    edit_problem={IsOpenHandler}
                    problems_data={
                      Array.isArray(predefined_problems_data?.data) &&
                      predefined_problems_data?.data?.length > 0
                        ? predefined_problems_data?.data
                        : []
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default PredefinedProblems;
