import React from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { customStylesSKU } from "../../DropdownStyles/DropdownStyles";
import Select from "react-select";
import { Button } from "@material-ui/core";
import { useState } from "react";

const Printers = (props) => {
  const { close, printer, sendDataFromParent } = props;

  const [isEmpty, setIsEmpty] = useState("");

  const handlePrinter = (selectedOption) => {
    sendDataFromParent(selectedOption.value);
    setIsEmpty(selectedOption.value);
  };
  return (
    <>
      <HeadingTitle title="Select Printers" closeModal={close} />
      <div className="printers-available">
        <label
          style={{
            fontSize: "14px",
            color: "#000",
            fontFamily: "Poppins_SemiBold",
          }}
          htmlFor="courierName"
        >
          Select Printers
        </label>
        <Select
          noOptionsMessage={() => "No Printer Available..."}
          options={
            printer &&
            printer.map((option) => ({
              value: option,
              label: option,
            }))
          }
          className="select"
          id="printers"
          styles={customStylesSKU}
          onChange={(selectedOption) => handlePrinter(selectedOption)}
        />
        <div className="printer-cta">
          <Button disabled={isEmpty !== "" ? false : true} onClick={close}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default Printers;
