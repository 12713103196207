import React from "react";
import "./Header.css";
// import searchIcon from "../../Assets/Icons/Header/search.png";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { url } from "../../Screens/Login/Login";

const Header = ({ isToggle }) => {
  const navigate = useNavigate();
  // const [keyword, setKeyword] = useState();
  const { communication_list_count } = useSelector(
    (state) => state.communicationCount
  );

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("metrics_date_cache");
    localStorage.removeItem("Metrics_cache");
    localStorage.removeItem("User_List_cache");
    localStorage.removeItem("metrics_date_cache_line");
    localStorage.removeItem("NextPage");
    localStorage.removeItem("Pages");
    localStorage.removeItem("Last_cache");
    secureLocalStorage.removeItem("MNSEXESPS");
    secureLocalStorage.removeItem("MXESNUSERN");
    localStorage.removeItem("Printer");
    navigate("/login");
  };

  let count = communication_list_count && communication_list_count?.data;

  // console.log("Communication Count: ", count);

  return (
    <div className={`header-wrapper ${isToggle ? "header-full" : ""}`}>
      {/* grid 2 col starts here */}
      <div className="header-grid-row">
        <div>
          <div className="search-area">
            <p className="uri-header">{url}</p>
            {/* <input
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Search by Email, Mobile Number and Order Number...."
            />
            <button className="search-btn">
              <img src={searchIcon} alt="searchIcon" />
            </button> */}
          </div>
        </div>
        <div>
          <div
            className="count-aler-c"
            onClick={() => navigate("/communication-alert")}
          >
            <div>
              <p>{count && count?.count ? count?.count : 0}</p>
            </div>
            <NotificationsIcon />
          </div>
          <div className="avatar-flex-row">
            <div>
              {" "}
              {isAuthenticated && isAuthenticated?.User?.name?.slice(0, 1)}
              {isAuthenticated &&
                isAuthenticated?.User?.name.split(" ")[1]?.charAt(0)}
            </div>
            <div>
              <p>{isAuthenticated && isAuthenticated?.User?.name}</p>
              <Button onClick={logout}>Logout</Button>
            </div>
          </div>
        </div>
      </div>
      {/* grid 2 col ends here */}
    </div>
  );
};

export default Header;
