import React, { Fragment, useState } from "react";
import MetaData from "../../Components/Helmet/Helmet";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import DataTable from "../../Components/Table/DataTable";
import Dropdown from "../../Components/Dropdown/Dropdown";

const SessionSummary = () => {
  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <Fragment>
      <div
        className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
      >
        <MetaData title={`Session Summary`} />
        <div>
          <SideBar handleIsToggle={handleIsToggle} />
        </div>
        <div>
          <Header isToggle={isToggle} />
          <div className="wrapper-picker-container">
            {/* Header Title Component row starts here */}
            <Headline
              title="Session Summary"
              hide={true}
              createBtn={"Create New Session"}
              newPath={"/new"}
            />
            {/* Header Title Component row ends here */}

            <div className="dropdown-grid-row dropwon">
              <div>
                <Dropdown placeholder="Name" />
              </div>
              <div>
                <Dropdown placeholder="ID" />
              </div>
              <div>
                <Dropdown placeholder="Created By" />
              </div>
              <div>
                <Dropdown placeholder="From Date" />
              </div>
              <div>
                <Dropdown placeholder="To Date" />
              </div>

              {/* <div>
                <div className="clear-filter">
                  <Button onClick={() => resteFilter()}>Reset Filter</Button>
                </div>
              </div> */}
            </div>

            {/* Table Component starts here */}
            <div className="table-wrapper-pagination">
              <div className="table-container-pagination">
                <DataTable
                  header={[
                    "Session ID",
                    "Created By",
                    "Created On",
                    "Total Transaction in this session",
                    "Action",
                  ]}
                  sessionSummary={[
                    {
                      session_id: "12345",
                      createdby: "Kevin",
                      date: "July 15, 2024",
                      total: "1300",
                    },
                  ]}
                  // handleRequestCount={handleModal}
                />
              </div>
            </div>
            {/* Table Component ends here */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SessionSummary;
