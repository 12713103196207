import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import {
  CLOSE_HANDOVER_BAG_REQUEST,
  CLOSE_HANDOVER_BAG_SUCCESS,
  CLOSE_HANDOVER_ORDER_FAILURE,
  CLOSE_HANDOVER_ORDER_REQUEST,
  CLOSE_HANDOVER_ORDER_SUCCESS,
  CLOSE_REVERSAL_HANDOVER_BY_ID_FAILURE,
  CLOSE_REVERSAL_HANDOVER_BY_ID_REQUEST,
  CLOSE_REVERSAL_HANDOVER_BY_ID_SUCCESS,
  CREATE_NEW_HANDOVER_BAG_FAILURE,
  CREATE_NEW_HANDOVER_BAG_REQUEST,
  CREATE_NEW_HANDOVER_BAG_SUCCESS,
  EDIT_HANDOVER_ITEM_BY_BOTH_ID_FAILURE,
  EDIT_HANDOVER_ITEM_BY_BOTH_ID_REQUEST,
  EDIT_HANDOVER_ITEM_BY_BOTH_ID_SUCCESS,
  EDIT_REVERSE_HANDOVER_ORDER_FAILURE,
  EDIT_REVERSE_HANDOVER_ORDER_REQUEST,
  EDIT_REVERSE_HANDOVER_ORDER_SUCCESS,
  GET_ALL_BAG_NAME_FAILURE,
  GET_ALL_BAG_NAME_REQUEST,
  GET_ALL_BAG_NAME_SUCCESS,
  GET_ALL_HANDOVER_NAME_FILTER_FAILURE,
  GET_ALL_HANDOVER_NAME_FILTER_REQUEST,
  GET_ALL_HANDOVER_NAME_FILTER_SUCCESS,
  GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_FAILURE,
  GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_REQUEST,
  GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_SUCCESS,
  GET_BAG_MANIFEST_FAILURE,
  GET_BAG_MANIFEST_REQUEST,
  GET_BAG_MANIFEST_SUCCESS,
  GET_COUNT_OF_TOTAL_BAG_SCAN_FAILURE,
  GET_COUNT_OF_TOTAL_BAG_SCAN_REQUEST,
  GET_COUNT_OF_TOTAL_BAG_SCAN_SUCCESS,
  GET_COURIER_NAME_BY_ID_FAILURE,
  GET_COURIER_NAME_BY_ID_REQUEST,
  GET_COURIER_NAME_BY_ID_SUCCESS,
  GET_COURIER_NAME_FAILURE,
  GET_COURIER_NAME_REQUEST,
  GET_COURIER_NAME_SUCCESS,
  GET_CURRENT_BAG_QUANTITY_FAILURE,
  GET_CURRENT_BAG_QUANTITY_REQUEST,
  GET_CURRENT_BAG_QUANTITY_SUCCESS,
  GET_HANDOVER_ORDER_AWB_FAILURE,
  GET_HANDOVER_ORDER_AWB_REQUEST,
  GET_HANDOVER_ORDER_AWB_SUCCESS,
  GET_INVENTORY_LIST_FAILURE,
  GET_INVENTORY_LIST_REQUEST,
  GET_INVENTORY_LIST_SUCCESS,
  GET_INVOICE_LIST_FOR_MATCH_FAILURE,
  GET_INVOICE_LIST_FOR_MATCH_REQUEST,
  GET_INVOICE_LIST_FOR_MATCH_SUCCESS,
  GET_ORDER_SUMMARY_FAILURE,
  GET_ORDER_SUMMARY_REQUEST,
  GET_ORDER_SUMMARY_SUCCESS,
  GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_FAILURE,
  GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_REQUEST,
  GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_SUCCESS,
  GET_REVERSE_INVENTORY_LIST_FAILURE,
  GET_REVERSE_INVENTORY_LIST_REQUEST,
  GET_REVERSE_INVENTORY_LIST_SUCCESS,
  GET_REVERSE_ORDER_HANDOVER_SUMMARY_FAILURE,
  GET_REVERSE_ORDER_HANDOVER_SUMMARY_REQUEST,
  GET_REVERSE_ORDER_HANDOVER_SUMMARY_SUCCESS,
  GET_SCAN_ITEM_LIST_FAILURE,
  GET_SCAN_ITEM_LIST_REQUEST,
  GET_SCAN_ITEM_LIST_SUCCESS,
  GET_UNIQUE_HANDOVER_NAME_FAILURE,
  GET_UNIQUE_HANDOVER_NAME_REQUEST,
  GET_UNIQUE_HANDOVER_NAME_SUCCESS,
  GET_UNIQUE_REVERSE_HANDOVER_NAME_FAILURE,
  GET_UNIQUE_REVERSE_HANDOVER_NAME_REQUEST,
  GET_UNIQUE_REVERSE_HANDOVER_NAME_SUCCESS,
  GET_USER_LIST_FAILURE,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  PRINT_LABEL_HANDOVER_FAILURE,
  PRINT_LABEL_HANDOVER_REQUEST,
  PRINT_LABEL_HANDOVER_SUCCESS,
  REVERSE_HANDOVER_BY_ITEM_FAILURE,
  REVERSE_HANDOVER_BY_ITEM_REQUEST,
  REVERSE_HANDOVER_BY_ITEM_SUCCESS,
  SAVE_DELIVERY_PARTNER_FAILURE,
  SAVE_DELIVERY_PARTNER_REQUEST,
  SAVE_DELIVERY_PARTNER_SUCCESS,
  SAVE_REVERSAL_HANDOVER_FAILURE,
  SAVE_REVERSAL_HANDOVER_REQUEST,
  SAVE_REVERSAL_HANDOVER_SUCCESS,
  SCAN_ORDER_AWB_ORDER_NO_FAILURE,
  SCAN_ORDER_AWB_ORDER_NO_REQUEST,
  SCAN_ORDER_AWB_ORDER_NO_SUCCESS,
} from "../../Constants/OrderHandoverConstants/GetCourierNameConstants";
import {
  WITH_WITHOUT_AUDIT_DATA_FAILURE,
  WITH_WITHOUT_AUDIT_DATA_REQUEST,
  WITH_WITHOUT_AUDIT_DATA_SUCCESS,
} from "../../Constants/PickerMgmt/PickerManagementConstants";

export const getOrderHandoverActions =
  (service_name, token) => async (dispatch) => {
    try {
      dispatch({
        type: GET_COURIER_NAME_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
      });
      dispatch({
        type: GET_COURIER_NAME_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_COURIER_NAME_FAILURE,
        payload: error.message,
      });
    }
  };

export const getUniqueHandoverNameActions =
  (service_name, token, carier_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_UNIQUE_HANDOVER_NAME_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        carier_id,
      });
      dispatch({
        type: GET_UNIQUE_HANDOVER_NAME_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIQUE_HANDOVER_NAME_FAILURE,
        payload: error.message,
      });
    }
  };

export const saveDeliveryPartnerActions =
  (
    service_name,
    token,
    carier_id,
    unique_handover_name,
    handover_date,
    tab_name,
    remarks,
    inventory_ids
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SAVE_DELIVERY_PARTNER_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        carier_id,
        unique_handover_name,
        handover_date,
        tab_name,
        remarks,
        inventory_ids,
      });
      dispatch({
        type: SAVE_DELIVERY_PARTNER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SAVE_DELIVERY_PARTNER_FAILURE,
        payload: error.message,
      });
    }
  };

export const getOrderHandoverSummaryActions =
  (service_name, token) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ORDER_SUMMARY_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
      });
      dispatch({
        type: GET_ORDER_SUMMARY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ORDER_SUMMARY_FAILURE,
        payload: error.message,
      });
    }
  };

export const getCourierNameByIdActions =
  (service_name, token, carier_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_COURIER_NAME_BY_ID_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        carier_id,
      });
      dispatch({
        type: GET_COURIER_NAME_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_COURIER_NAME_BY_ID_FAILURE,
        payload: error.message,
      });
    }
  };

export const getScanItemListActions =
  (service_name, token, handover_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_SCAN_ITEM_LIST_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        handover_id,
      });
      dispatch({
        type: GET_SCAN_ITEM_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_SCAN_ITEM_LIST_FAILURE,
        payload: error.message,
      });
    }
  };

export const createHandoverBagActions =
  (service_name, token, handover_id) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_NEW_HANDOVER_BAG_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        handover_id,
      });
      dispatch({
        type: CREATE_NEW_HANDOVER_BAG_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_NEW_HANDOVER_BAG_FAILURE,
        payload: error.message,
      });
    }
  };

export const getCurrentBagQtyActions =
  (service_name, token, bag_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_CURRENT_BAG_QUANTITY_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        bag_id,
      });
      dispatch({
        type: GET_CURRENT_BAG_QUANTITY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CURRENT_BAG_QUANTITY_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAllHandoverBagNameActions =
  (service_name, token, handover_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_BAG_NAME_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        handover_id,
      });
      dispatch({
        type: GET_ALL_BAG_NAME_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_BAG_NAME_FAILURE,
        payload: error.message,
      });
    }
  };

export const getTotalBagsScanActions =
  (service_name, token, handover_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_COUNT_OF_TOTAL_BAG_SCAN_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        handover_id,
      });
      dispatch({
        type: GET_COUNT_OF_TOTAL_BAG_SCAN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_COUNT_OF_TOTAL_BAG_SCAN_FAILURE,
        payload: error.message,
      });
    }
  };

export const getOrderAwbHandoverActions =
  (
    service_name,
    token,
    bag_id,
    carier_id,
    scanned_code,
    handover_id,
    bag_name
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_HANDOVER_ORDER_AWB_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        bag_id,
        carier_id,
        scanned_code,
        handover_id,
        bag_name,
      });
      dispatch({
        type: GET_HANDOVER_ORDER_AWB_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_HANDOVER_ORDER_AWB_FAILURE,
        payload: error.message,
      });
    }
  };

export const getUserListActions = (service_name, token) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_LIST_REQUEST,
    });

    const { data } = await axios.post(`${BASE_URL}`, {
      service_name,
      token,
    });
    dispatch({
      type: GET_USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_LIST_FAILURE,
      payload: error.message,
    });
  }
};

export const ReverseHandoverByIdAction =
  (service_name, token, handovered_item_id) => async (dispatch) => {
    try {
      dispatch({
        type: REVERSE_HANDOVER_BY_ITEM_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        handovered_item_id,
      });
      dispatch({
        type: REVERSE_HANDOVER_BY_ITEM_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REVERSE_HANDOVER_BY_ITEM_FAILURE,
        payload: error.message,
      });
    }
  };

export const editHandoverItemAction =
  (service_name, token, handover_id) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_HANDOVER_ITEM_BY_BOTH_ID_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        handover_id,
      });
      dispatch({
        type: EDIT_HANDOVER_ITEM_BY_BOTH_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EDIT_HANDOVER_ITEM_BY_BOTH_ID_FAILURE,
        payload: error.message,
      });
    }
  };

export const getReversedHandoverOrderActions =
  (service_name, token, page, limit) => async (dispatch) => {
    try {
      dispatch({
        type: GET_REVERSE_ORDER_HANDOVER_SUMMARY_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        page,
        limit,
      });
      dispatch({
        type: GET_REVERSE_ORDER_HANDOVER_SUMMARY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_REVERSE_ORDER_HANDOVER_SUMMARY_FAILURE,
        payload: error.message,
      });
    }
  };

export const getUniqueHandoverReversalNameActions =
  (service_name, token) => async (dispatch) => {
    try {
      dispatch({
        type: GET_UNIQUE_REVERSE_HANDOVER_NAME_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
      });
      dispatch({
        type: GET_UNIQUE_REVERSE_HANDOVER_NAME_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIQUE_REVERSE_HANDOVER_NAME_FAILURE,
        payload: error.message,
      });
    }
  };

export const saveReversalHandoverActions =
  (
    service_name,
    token,
    unique_reversal_handover_name,
    tab_name,
    remarks,
    inventory_ids
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SAVE_REVERSAL_HANDOVER_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        unique_reversal_handover_name,
        tab_name,
        remarks,
        inventory_ids,
      });
      dispatch({
        type: SAVE_REVERSAL_HANDOVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SAVE_REVERSAL_HANDOVER_FAILURE,
        payload: error.message,
      });
    }
  };

export const scanOrderWithAwbNoRevarsalActions =
  (service_name, token, reverse_handover_id, scanned_code) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SCAN_ORDER_AWB_ORDER_NO_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        reverse_handover_id,
        scanned_code,
      });
      dispatch({
        type: SCAN_ORDER_AWB_ORDER_NO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SCAN_ORDER_AWB_ORDER_NO_FAILURE,
        payload: error.message,
      });
    }
  };

export const getHandoverNameAction =
  (service_name, token) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_HANDOVER_NAME_FILTER_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
      });
      dispatch({
        type: GET_ALL_HANDOVER_NAME_FILTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_HANDOVER_NAME_FILTER_FAILURE,
        payload: error.message,
      });
    }
  };

export const closeHandoverBagActions =
  (service_name, token, bag_id) => async (dispatch) => {
    try {
      dispatch({
        type: CLOSE_HANDOVER_BAG_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        bag_id,
      });
      dispatch({
        type: CLOSE_HANDOVER_BAG_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_HANDOVER_NAME_FILTER_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAllHandoverNameReverseOrderSummaryActions =
  (service_name, token) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
      });
      dispatch({
        type: GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_FAILURE,
        payload: error.message,
      });
    }
  };

export const getScannedItemReversedListActions =
  (service_name, token, reverse_handover_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        reverse_handover_id,
      });
      dispatch({
        type: GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_FAILURE,
        payload: error.message,
      });
    }
  };

export const editReverseHandoverSummaryActions =
  (service_name, token, reverse_handover_id) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_REVERSE_HANDOVER_ORDER_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        reverse_handover_id,
      });
      dispatch({
        type: EDIT_REVERSE_HANDOVER_ORDER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EDIT_REVERSE_HANDOVER_ORDER_FAILURE,
        payload: error.message,
      });
    }
  };

export const closeHandoverOrderActions =
  (service_name, token, handover_id) => async (dispatch) => {
    try {
      dispatch({
        type: CLOSE_HANDOVER_ORDER_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        handover_id,
      });
      dispatch({
        type: CLOSE_HANDOVER_ORDER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLOSE_HANDOVER_ORDER_FAILURE,
        payload: error.message,
      });
    }
  };

// export const DownloadHandoverCSVByIdActions =
//   (service_name, token, handover_id) => async (dispatch) => {
//     try {
//       dispatch({
//         type: DOWNLOAD_ORDER_HANDOVER_SUMMARY_BY_ID_REQUEST,
//       });

//       const { data } = await axios.post(`${BASE_URL}`, {
//         service_name,
//         token,
//         handover_id,
//       });
//       dispatch({
//         type: DOWNLOAD_ORDER_HANDOVER_SUMMARY_BY_ID_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: DOWNLOAD_ORDER_HANDOVER_SUMMARY_BY_ID_FAILURE,
//         payload: error.message,
//       });
//     }
//   };

export const closeReversalHandoverByIdActions =
  (service_name, token, reverse_handover_id) => async (dispatch) => {
    try {
      dispatch({
        type: CLOSE_REVERSAL_HANDOVER_BY_ID_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        reverse_handover_id,
      });
      dispatch({
        type: CLOSE_REVERSAL_HANDOVER_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLOSE_REVERSAL_HANDOVER_BY_ID_FAILURE,
        payload: error.message,
      });
    }
  };

export const getBagManifestPdfActions =
  (service_name, token, user_id, handover_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_BAG_MANIFEST_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        user_id,
        handover_id,
      });
      dispatch({
        type: GET_BAG_MANIFEST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BAG_MANIFEST_FAILURE,
        payload: error.message,
      });
    }
  };

export const getInventoryListActions =
  (service_name, token, search_keyword) => async (dispatch) => {
    try {
      dispatch({
        type: GET_INVENTORY_LIST_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        search_keyword,
      });
      dispatch({
        type: GET_INVENTORY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_INVENTORY_LIST_FAILURE,
        payload: error.message,
      });
    }
  };

export const printLabelBagActions =
  (service_name, token, courier_bag_id) => async (dispatch) => {
    try {
      dispatch({
        type: PRINT_LABEL_HANDOVER_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        courier_bag_id,
      });
      dispatch({
        type: PRINT_LABEL_HANDOVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PRINT_LABEL_HANDOVER_FAILURE,
        payload: error.message,
      });
    }
  };

export const getInvoiceListForMatchActions =
  (service_name, token, handover_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_INVOICE_LIST_FOR_MATCH_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        handover_id,
      });
      dispatch({
        type: GET_INVOICE_LIST_FOR_MATCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_INVOICE_LIST_FOR_MATCH_FAILURE,
        payload: error.message,
      });
    }
  };

export const getReverse_inventory_invoiceListActions =
  (service_name, token, search_keyword) => async (dispatch) => {
    try {
      dispatch({
        type: GET_REVERSE_INVENTORY_LIST_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        search_keyword,
      });
      dispatch({
        type: GET_REVERSE_INVENTORY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_REVERSE_INVENTORY_LIST_FAILURE,
        payload: error.message,
      });
    }
  };

export const getWithWoutAuditActions =
  (service_name, token, product_id, sublocation_id) => async (dispatch) => {
    try {
      dispatch({
        type: WITH_WITHOUT_AUDIT_DATA_REQUEST,
      });

      const { data } = await axios.post(`${BASE_URL}`, {
        service_name,
        token,
        product_id,
        sublocation_id,
      });
      dispatch({
        type: WITH_WITHOUT_AUDIT_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: WITH_WITHOUT_AUDIT_DATA_FAILURE,
        payload: error.message,
      });
    }
  };
