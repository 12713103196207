import React, { Fragment, useCallback, useMemo, useRef, useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import "./PickList.css";
import DataTable from "../../Components/Table/DataTable";
import {
  Assigned_list,
  PickListManagementHeaderArr,
  mark_lost_arr,
  mark_lost_not_found,
} from "../../DummyArray/TableArray/TableData";
import Headline from "../../Components/HeadlineSpecial/Headline";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  add_permissionActions,
  getAllFiltersPickingModuleActions,
  getPicklistManagementTable,
  syncNotfoundMarkLostActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import { Pagination } from "../../Components/Pagination/Pagination";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import ModalContainer from "../../Components/Modal/Modal";
import { useAlert } from "react-alert";
import { SYNC_NOT_FOUND_MARK_LOST_RESET } from "../../Constants/PickerMgmt/PickerManagementConstants";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Loader from "../../Components/Loader/Loader";
import MetaData from "../../Components/Helmet/Helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import moment from "moment";

const PickList = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { picklistMgmt, loading, status } = useSelector(
    (state) => state.picklistManagement
  );

  // permission state
  const { permission_list_data, loading: permission_loading } = useSelector(
    (state) => state.permissions_data
  );

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  useEffect(() => {
    if (token) {
      dispatch(add_permissionActions("permissions", token));
    }
  }, [dispatch, token]);

  const [limit, setLimit] = useState(10);
  const [changeLimit, setChangeLimit] = useState(false);

  const handleLimit = (event) => {
    setChangeLimit(true);
    if (event.key === "Enter") {
      setPage(1);
      // dispatch(getPicklistManagementTable(service_name, 1, token, limit));
      fetchFilters();
    }
  };

  const handleLimitTag = (e) => {
    setChangeLimit(true);
    setLimit(e.target.value);
  };

  let service_name = "picklist_management";

  // useEffect(() => {
  //   dispatch(getPicklistManagementTable(service_name, 1, token));
  // }, [dispatch, service_name, token]);

  // pagination code starts here
  const [picklist, setPicklist] = useState([]);

  let Pagex = JSON.parse(localStorage.getItem("page_Picklist_X"));

  const [page, setPage] = useState(Pagex !== null ? Pagex : 1);
  const [isLoading, setIsLoading] = useState(false);
  const [isIndex, setIsIndex] = useState(null);

  const [filtersTrue, setFilterTrue] = useState(false);
  const [pagination, setPagination] = useState(0);

  const fetch = useCallback(
    (page) => {
      setIsLoading(true);
      axios
        .post(`${BASE_URL}`, { service_name, page, token, limit })
        .then((response) => {
          setPicklist(response?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    },
    [limit, service_name, token]
  );

  const handlePage = useCallback(
    (page, paginate) => {
      localStorage.setItem("page_Picklist_X", JSON.stringify(page));
      setPagination(page);
      setPage(page);
      if (!filtersTrue && pagination >= 0 && paginate) {
        fetch(page);
      }
    },
    [fetch, filtersTrue, pagination]
  );

  const fetchPicklist = useCallback(() => {
    picklistMgmt && setPicklist(picklistMgmt && picklistMgmt?.data);
  }, [picklistMgmt]);

  useEffect(() => {
    fetchPicklist();
  }, [fetchPicklist]);

  // filter code starts here
  const [filterTotal_pages, setFilter_total_pages] = useState(0);
  const [filter_Loading, setFilter_loading] = useState(false);

  const [user_id, setUser_id] = useState("");
  const [picker_name, setPicker_name] = useState("");

  const [generated_type, setgenerated_type] = useState("");
  const [generated_type_name, setgenerated_type_name] = useState("");

  const [order_type, setOrder_type] = useState("");
  const [order_type_name, setOrder_type_name] = useState("");

  const [sku_id, setSku_id] = useState("");
  const [sku_name, setSku_name] = useState("");

  const [parent_sku_id, setparent_sku_id] = useState("");
  const [parent_sku_name, setparent_sku_name] = useState("");

  const [assigned_name, setAssigned_name] = useState("");
  const [assigned, setAssigned] = useState("");

  const [picklist_ids, setPicklists_id] = useState("");

  const [portal_name, setPortal_name] = useState("");
  const [portal_id, setPortal_id] = useState("");

  const [mark_data, setMark_data] = useState("");
  const [mark_lost, setmark_lost] = useState("");
  const [not_found, setnot_found] = useState("");
  const [mark_not_found_data, setmark_not_found_data] = useState("");

  const [status_name, setStatus_name] = useState("");
  const [statuss, setStatuss] = useState("");

  const [fromDate, setFromDate] = useState(null);
  const [ToDate, setToDate] = useState(null);

  const [from_variations, setfrom_variations] = useState("");
  const [to_variations, setto_variations] = useState("");

  const [from_net_pendency, setfrom_net_pendency] = useState("");
  const [to_net_pendency, setto_net_pendency] = useState("");

  const handlePickers = (val) => {
    setFilterTrue(true);
    setUser_id(val?.id);
    setPicker_name(val?.value);
    handlePage(1, false);
  };

  const handleFromDate = (date) => {
    setFilterTrue(true);
    setFromDate(date);
    handlePage(1, false);
  };

  const handleToDate = (date) => {
    setFilterTrue(true);
    setToDate(date);
    handlePage(1, false);
  };

  const handleGeneratedType = (val) => {
    setFilterTrue(true);
    setgenerated_type(val?.id);
    setgenerated_type_name(val?.value);
    handlePage(1, false);
  };

  const handleOrderType = (val) => {
    setFilterTrue(true);
    setOrder_type(val?.id);
    setOrder_type_name(val?.value);
    handlePage(1, false);
  };

  const handleSkuId = (val) => {
    setFilterTrue(true);
    setSku_id(val?.id);
    setSku_name(val?.value);
    handlePage(1, false);
  };

  const handleParentSkuId = (val) => {
    setFilterTrue(true);
    setparent_sku_id(val?.id);
    setparent_sku_name(val?.value);
    handlePage(1, false);
  };

  const handleAssigned = (val) => {
    setFilterTrue(true);
    setAssigned_name(val?.value);
    setAssigned(val?.id);
    handlePage(1, false);
  };

  const handlePortal = (val) => {
    setFilterTrue(true);
    setPortal_id(val?.id);
    setPortal_name(val?.value);
    handlePage(1, false);
  };

  const handlePicklistId = (e) => {
    setFilterTrue(true);
    setPicklists_id(e.target.value);
    handlePage(1, false);
  };

  const handleMarkLostNotFound = (val) => {
    setMark_data(val?.value);
    setFilterTrue(true);
    setmark_lost(val?.id);
    handlePage(1, false);
  };

  const handleMarkLost = (val) => {
    setmark_not_found_data(val?.value);
    setFilterTrue(true);
    setnot_found(val?.id);
    handlePage(1, false);
  };

  const handleStatus = (val) => {
    setStatuss(val?.id);
    setFilterTrue(true);
    setStatus_name(val?.value);
    handlePage(1, false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      fetchFilters();
      handlePage(1, false);
    }
  };

  const handlefromVariation = (e) => {
    setFilterTrue(true);
    setfrom_variations(e.target.value);
    handlePage(1, false);
  };

  const handle_from_Variation = (event) => {
    if (event.key === "Enter") {
      handlePage(1, false);
      fetchFilters();
    }
  };

  const handleToVariation = (e) => {
    setFilterTrue(true);
    setto_variations(e.target.value);
    handlePage(1, false);
  };

  const handle_to_Variation = (event) => {
    if (event.key === "Enter") {
      fetchFilters();
      handlePage(1, false);
    }
  };

  // net pendency
  const handlefromNetPendency = (e) => {
    setFilterTrue(true);
    setfrom_net_pendency(e.target.value);
    handlePage(1, false);
  };

  const handle_fromnet_pendency = (event) => {
    if (event.key === "Enter") {
      fetchFilters();
      handlePage(1, false);
    }
  };

  const handleToNetPendency = (e) => {
    setFilterTrue(true);
    setto_net_pendency(e.target.value);
    handlePage(1, false);
  };

  const handle_tonet_pendency = (event) => {
    if (event.key === "Enter") {
      fetchFilters();
      handlePage(1, false);
    }
  };

  let from_date =
    fromDate !== "" ? moment(fromDate).format("yyyy-MM-DD") : null;
  let to_date = ToDate !== "" ? moment(ToDate).format("yyyy-MM-DD") : null;
  let filters = useMemo(() => {
    let picklist_id = picklist_ids !== "" ? picklist_ids : null;
    let status = statuss !== "" ? statuss : null;
    const dataToSend = {
      user_id,
      generated_type,
      order_type,
      sku_id,
      parent_sku_id,
      assigned,
      picklist_id,
      portal_id,
      mark_lost,
      not_found,
      status,
      from_date,
      to_date,
      from_variations,
      to_variations,
      from_net_pendency,
      to_net_pendency,
    };
    // Filtering empty value
    const filter_data = Object.fromEntries(
      Object.entries(dataToSend).filter(
        ([_, value]) =>
          value !== "" && value !== null && value !== "Invalid date"
      )
    );
    return filter_data;
  }, [
    user_id,
    generated_type,
    order_type,
    sku_id,
    parent_sku_id,
    assigned,
    picklist_ids,
    portal_id,
    mark_lost,
    not_found,
    statuss,
    from_date,
    to_date,
    from_variations,
    to_variations,
    from_net_pendency,
    to_net_pendency,
  ]);

  const fetchFilters = useCallback(() => {
    setFilter_loading(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "picklist_management",
        token,
        page,
        filters,
        limit,
      })
      .then((response) => {
        if (response && response?.data?.status === 0) {
          alert.error(response && response?.data?.msg);
          setFilter_loading(false);
        } else {
          setFilter_total_pages(response?.data?.total_pages);
          setPicklist(response?.data?.data);
          setFilter_loading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setFilter_loading(false);
      });
  }, [token, page, filters, alert, limit]);
  // filter code ends here

  const [modalNo, setModalNo] = useState(null);
  const [picklist_id, setpicklist_id] = useState();

  const handleModal = (index) => {
    setIsIndex(index);
  };

  const pickListId = (j, index) => {
    setIsIndex(9);
    setpicklist_id(j);
  };

  const picklist_assign_id = (Id) => {
    setpicklist_id(Id);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  // useEffect(() => {
  //   if (isIndex === null && changeLimit === false) {
  //     dispatch(getPicklistManagementTable(service_name, page, token, limit));
  //   }
  // }, [dispatch, service_name, token, isIndex, changeLimit, limit, page]);

  // label code
  const [isIndexs, setIsindexs] = useState([]);
  const [isOk, setIsOk] = useState(true);
  const [isMulti, setIsMulti] = useState(false);
  const [oldIndex, setOldIndex] = useState([]);
  let order_ids = [...new Set([...isIndexs, ...oldIndex])];

  const handleIndex = (index) => {
    setOldIndex((prev) => [...prev, index]);
    if (isIndexs.includes(index) || oldIndex.includes(index)) {
      setIsindexs((prevNum) => prevNum.filter((num) => num !== index));
      setOldIndex((prevNum) => prevNum.filter((num) => num !== index));
    } else {
      setIsindexs((prev) => [...prev, index]);
      setOldIndex((prev) => [...prev, index]);
    }
  };

  const onHeadingIndex = (i) => {
    setModalNo(i);
    setIsMulti(false);
  };

  const [per_pages_id, setPer_pages_id] = useState([]);

  const handleIndexAll = () => {
    // console.log("Validate: ", isOk);
    setIsOk(!isOk);
    if (isOk) {
      picklist &&
        picklist.forEach((i) => {
          if (i.is_cancel === 1 || i.completed === 1) {
            return null;
          } else {
            if (isIndexs.includes(i.id)) {
              setIsindexs((prevNum) =>
                prevNum.filter((num) => num !== i.picklist_id)
              );
            } else {
              setIsindexs((prev) => [...prev, i.picklist_id]);
              setPer_pages_id((prev) => [...prev, i.picklist_id]);
            }
          }
        });
    } else {
      // setIsindexs([]);
      // setOldIndex([]);
      per_pages_id &&
        per_pages_id.forEach((i) => {
          setIsindexs((prevNum) => prevNum.filter((num) => num !== i));
        });
    }
  };

  const handleNull = () => {
    // setIsindexs([]);  // latest changes when pagination order ids value is null (if needed please uncomment this only no more changes)
    // setOldIndex([]); // latest changes when pagination order ids value is null (if needed please uncomment this only no more changes)
    setIsOk(!isOk);
    setPer_pages_id([]);
  };

  useEffect(() => {
    if (filtersTrue === true) {
      fetchFilters();
    } else if (
      filtersTrue === false &&
      pagination === 0 &&
      changeLimit === false
    ) {
      if (isIndex === null && changeLimit === false) {
        dispatch(getPicklistManagementTable(service_name, page, token, limit));
      }
    }
    // else {
    //   if (isIndex === null && changeLimit === false) {
    //     dispatch(getPicklistManagementTable(service_name, page, token, limit));
    //   }
    // }
  }, [
    dispatch,
    service_name,
    token,
    filtersTrue,
    pagination,
    isIndex,
    fetchFilters,
    changeLimit,
    limit,
    page,
  ]);

  // All Filter data for dropdown
  let All_Filters = useMemo(() => {
    const dataToSend = [
      "pickers",
      "picklist_generated_type",
      "picklist_order_type",
      "sku",
      "parent_sku",
      "portals",
      "picklist_status",
    ];
    return dataToSend;
  }, []);

  const { all_filters, loading: allFilter_loading } = useSelector(
    (state) => state.allPickingModuleFilters
  );

  // console.log("All Filters: ", all_filters);

  let pickers_list = all_filters && all_filters?.pickers;
  let picklist_generated_type_list =
    all_filters && all_filters?.picklist_generated_type;
  let picklist_order_type = all_filters && all_filters?.picklist_order_type;
  let sku_list = all_filters && all_filters?.sku;
  let parent_sku_list = all_filters && all_filters?.parent_sku;
  let portals_list = all_filters && all_filters?.portals;
  let picklist_status_list = all_filters && all_filters?.picklist_status;

  useEffect(() => {
    dispatch(getAllFiltersPickingModuleActions("filters", token, All_Filters));
  }, [dispatch, token, All_Filters]);

  // handle Multiple Picklist Assignment
  const handleMultiplePicklistAssignment = (index, i) => {
    setIsMulti(true);
    if (order_ids && order_ids.length <= 0) {
      return alert.error("Please Select Checkbox...");
    } else {
      setIsIndex(index);
      setModalNo(i);
    }
  };

  let ActiveOrders = [];
  picklist &&
    picklist.forEach((i) => {
      if (
        i.is_cancel === 1 ||
        (i.completed === 1 && ActiveOrders.includes(i.id))
      ) {
        return null;
      } else {
        ActiveOrders.push(i);
      }
    });

  // Buggy Code
  const handleCheckBoxRefresh = async () => {
    // setIsRefreshed(true);
    await dispatch(
      getPicklistManagementTable("picklist_management", page, token, limit)
    );
    fetchFilters();
    setIsindexs([]);
    setOldIndex([]);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          modalNo={modalNo}
          picklist_id={picklist_id}
          order_ids={order_ids}
          isMulti={isMulti}
          status={picklist_status}
          handleCheckBoxRefresh={handleCheckBoxRefresh}
        />
      );
    }
    return null;
  };

  const resteFilter = () => {
    setUser_id("");
    setPicker_name("");
    setgenerated_type("");
    setgenerated_type_name("");
    setOrder_type("");
    setOrder_type_name("");
    setSku_id("");
    setSku_name("");
    setparent_sku_id("");
    setparent_sku_name("");
    setAssigned("");
    setAssigned_name("");
    setPicklists_id("");
    setPortal_id("");
    setPortal_name("");
    setMark_data("");
    setmark_lost("");
    setnot_found("");
    setmark_not_found_data("");
    setStatus_name("");
    setStatuss("");
    setFromDate("");
    setToDate("");
    setfrom_variations("");
    setto_variations("");
    setfrom_net_pendency("");
    setto_net_pendency("");
  };

  const [picklist_status, setpicklist_status] = useState("");

  // const { picklisT_status } = useSelector((state) => state.picklist_status);

  const handlePicklistStatus = (status, picklist_id) => {
    setpicklist_status(status.value);
    setpicklist_id(picklist_id);
    setIsIndex(11);
  };

  // recalculate state
  const { not_found_recalculate, loading: recalculate_loading } = useSelector(
    (state) => state.mark_lost_not_found
  );

  const [isHitCaluclated, setIsHitCalculated] = useState(false);

  const handleRecaluculate = () => {
    setIsHitCalculated(true);
    if (order_ids && order_ids?.length > 0) {
      dispatch(
        syncNotfoundMarkLostActions(
          "sync_not_found_inventory_details",
          token,
          order_ids
        )
      );
    } else {
      alert.error("Please Select Checkbox...");
    }
  };

  useEffect(() => {
    if (
      not_found_recalculate &&
      not_found_recalculate?.status === 1 &&
      isHitCaluclated
    ) {
      alert.success("Recalculated Success...");
      dispatch({
        type: SYNC_NOT_FOUND_MARK_LOST_RESET,
      });
      dispatch(
        getPicklistManagementTable("picklist_management", 1, token, limit)
      );
      setIsindexs([]);
      setOldIndex([]);
      setIsHitCalculated(false);
    } else if (
      not_found_recalculate &&
      not_found_recalculate?.status === 0 &&
      isHitCaluclated
    ) {
      alert.error(not_found_recalculate && not_found_recalculate?.msg);
      dispatch({
        type: SYNC_NOT_FOUND_MARK_LOST_RESET,
      });
      setIsHitCalculated(false);
    }
  }, [not_found_recalculate, isHitCaluclated, alert, dispatch, token, limit]);

  // console.log("Order IDS: ", order_ids);
  // console.log("Per Page Ids: ", page);

  const [loadingcsv, setLoadingCsv] = useState(false);
  const [loadingDetailcsv, setLoadingDetailCsv] = useState(false);

  const downloadCSV = async () => {
    setLoadingCsv(true);
    try {
      const response = await axios.post(`${BASE_URL}`, {
        service_name: "csv_picklist_order_details_wise",
        token,
        filters,
      });
      const csvFile = response?.data?.file;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
      }

      setLoadingCsv(false);
    } catch (err) {
      alert.error(err);
      setLoadingCsv(false);
    }
  };

  const downloadSkuWiseCSV = async () => {
    setLoadingDetailCsv(true);
    try {
      const response = await axios.post(`${BASE_URL}`, {
        service_name: "csv_picklist_sku_wise",
        token,
        filters,
      });
      const csvFile = response?.data?.file;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
      }

      setLoadingDetailCsv(false);
    } catch (err) {
      alert.error(err);
      setLoadingDetailCsv(false);
    }
  };

  // useEffect(() => {
  //   if (
  //     user_id ||
  //     picker_name ||
  //     generated_type ||
  //     generated_type_name ||
  //     order_type ||
  //     order_type_name ||
  //     sku_id ||
  //     sku_name ||
  //     parent_sku_id ||
  //     parent_sku_name ||
  //     assigned ||
  //     assigned_name ||
  //     picklist_ids ||
  //     portal_id ||
  //     portal_name ||
  //     mark_data ||
  //     mark_lost ||
  //     not_found ||
  //     mark_not_found_data ||
  //     status_name ||
  //     statuss ||
  //     fromDate ||
  //     ToDate ||
  //     from_variations ||
  //     to_variations ||
  //     from_net_pendency ||
  //     to_net_pendency
  //   ) {
  //     handlePage(1);
  //   }
  // }, [
  //   ToDate,
  //   assigned,
  //   assigned_name,
  //   fromDate,
  //   from_net_pendency,
  //   from_variations,
  //   generated_type,
  //   generated_type_name,
  //   handlePage,
  //   mark_data,
  //   mark_lost,
  //   mark_not_found_data,
  //   not_found,
  //   order_type,
  //   order_type_name,
  //   parent_sku_id,
  //   parent_sku_name,
  //   picker_name,
  //   picklist_ids,
  //   portal_id,
  //   sku_id,
  //   portal_name,
  //   sku_name,
  //   status_name,
  //   statuss,
  //   to_net_pendency,
  //   to_variations,
  //   user_id,
  // ]);

  const scrollContainerRef = useRef(null);

  const handleScroll = (scrollOffset) => {
    scrollContainerRef.current.scrollBy({
      left: scrollOffset,
      behavior: "smooth",
    });
  };

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  const [LoadingPDF, setLoadingPDF] = useState(false);
  const handlePDFDownload = (userId, picklist_id) => {
    setLoadingPDF(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "get_picklist_pdf",
        token,
        user_id: userId,
        picklist_id: picklist_id,
        force_new: 0,
      })
      .then((response) => {
        if (response && response?.data?.status === 1) {
          let pdfUrl = response && response?.data?.data?.file;
          window.open(pdfUrl, "_blank", "noopener,noreferrer");
        } else if (response && response?.data?.status === 0) {
          alert.error(response && response?.data?.msg);
        }
        setLoadingPDF(false);
      })
      .catch((err) => {
        setLoadingPDF(false);
        console.log(err);
      });
  };

  console.log("Pagex: ", page);

  console.log("INDEX II", isIndexs, oldIndex);

  console.log("Permission: ", permission_list_data?.data);

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Picklist Management`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          {/* header */}
          {/* {loading ? (
            <LoaderAlone />
          ) : ( */}
          <Fragment>
            {status === 0 ? (
              <NoDataFromApi
                height={true}
                title="Something Went Wrong, Please Try Logout & ReLogin Again..."
              />
            ) : (
              <>
                <Headline
                  title="Picklist Management"
                  btnTitle="Download Order Detail CSV"
                  handleData={downloadCSV}
                  loadingCSV={loadingcsv}
                  width={true}
                  select={true}
                  newCta={true}
                  btnSec="Download SKU Wise CSV"
                  handleDatas={downloadSkuWiseCSV}
                  loadingDetailcsv={loadingDetailcsv}
                />

                <div className="dropdown-grid-row">
                  <div>
                    <Dropdown
                      placeholder="User Id"
                      dropdown={pickers_list && pickers_list}
                      value={picker_name}
                      onData={handlePickers}
                      showId={true}
                      noShow={true}
                      loading={allFilter_loading}
                    />
                  </div>
                  <div>
                    <Dropdown
                      placeholder="Generated Type"
                      dropdown={
                        picklist_generated_type_list &&
                        picklist_generated_type_list
                      }
                      value={generated_type_name}
                      onData={handleGeneratedType}
                      noShow={true}
                      loading={allFilter_loading}
                    />
                  </div>
                  <div>
                    <Dropdown
                      placeholder="Order Type"
                      dropdown={picklist_order_type && picklist_order_type}
                      value={order_type_name}
                      onData={handleOrderType}
                      noShow={true}
                      loading={allFilter_loading}
                    />
                  </div>
                  <div>
                    <Dropdown
                      placeholder="Sku Name"
                      dropdown={sku_list && sku_list}
                      value={sku_name}
                      onData={handleSkuId}
                      noShow={true}
                      loading={allFilter_loading}
                    />
                  </div>
                  <div>
                    <Dropdown
                      placeholder="Parent Sku Name"
                      dropdown={parent_sku_list && parent_sku_list}
                      value={parent_sku_name}
                      onData={handleParentSkuId}
                      noShow={true}
                      loading={allFilter_loading}
                    />
                  </div>
                  <div>
                    <Dropdown
                      placeholder="Assigned"
                      dropdown={Assigned_list && Assigned_list}
                      value={assigned_name}
                      onData={handleAssigned}
                      noShow={true}
                    />
                  </div>
                  <div>
                    <Dropdown
                      placeholder="Portal"
                      dropdown={portals_list && portals_list}
                      value={portal_name}
                      onData={handlePortal}
                      noShow={true}
                      loading={allFilter_loading}
                    />
                  </div>
                  <div>
                    <Dropdown
                      placeholder="Mark Lost"
                      dropdown={mark_lost_not_found && mark_lost_not_found}
                      value={mark_data}
                      onData={handleMarkLostNotFound}
                      noShow={true}
                    />
                  </div>
                  <div>
                    <Dropdown
                      placeholder="Not Found"
                      dropdown={mark_lost_arr && mark_lost_arr}
                      value={mark_not_found_data}
                      onData={handleMarkLost}
                      noShow={true}
                    />
                  </div>
                  <div>
                    <Dropdown
                      placeholder="Picklist Status"
                      dropdown={picklist_status_list && picklist_status_list}
                      value={status_name}
                      onData={handleStatus}
                      noShow={true}
                      loading={allFilter_loading}
                    />
                  </div>
                  <div>
                    <DatePicker
                      className="date-picker"
                      placeholderText="From Date"
                      selected={fromDate}
                      onChange={(date) => handleFromDate(date)}
                    />
                  </div>
                  <div>
                    <DatePicker
                      className="date-picker"
                      placeholderText="To Date"
                      selected={ToDate}
                      onChange={(date) => handleToDate(date)}
                    />
                  </div>
                  <div className="date_handover">
                    <input
                      type="text"
                      name="picklist_id"
                      id="picklist_id"
                      value={picklist_ids}
                      onChange={(e) => handlePicklistId(e)}
                      placeholder="Picklist Id"
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  <div className="date_handover">
                    <input
                      type="number"
                      min={0}
                      name="from_variations"
                      id="from_variations"
                      value={from_variations}
                      onChange={(e) => handlefromVariation(e)}
                      placeholder="From Variations"
                      onKeyPress={handle_from_Variation}
                    />
                  </div>
                  <div className="date_handover">
                    <input
                      type="number"
                      min={0}
                      name="to_variations"
                      id="to_variations"
                      value={to_variations}
                      onChange={(e) => handleToVariation(e)}
                      placeholder="To Variations"
                      onKeyPress={handle_to_Variation}
                    />
                  </div>
                  <div className="date_handover">
                    <input
                      type="number"
                      min={0}
                      name="from_net_pendency"
                      id="from_net_pendency"
                      value={from_net_pendency}
                      onChange={(e) => handlefromNetPendency(e)}
                      placeholder="From Net Pendency"
                      onKeyPress={handle_fromnet_pendency}
                    />
                  </div>
                  <div className="date_handover">
                    <input
                      type="number"
                      min={0}
                      name="to_net_pendency"
                      id="to_net_pendency"
                      value={to_net_pendency}
                      onChange={(e) => handleToNetPendency(e)}
                      placeholder="To Net Pendency"
                      onKeyPress={handle_tonet_pendency}
                    />
                  </div>
                  <div>
                    <div className="clear-filter">
                      <Button onClick={() => resteFilter()}>
                        Reset Filter
                      </Button>
                    </div>
                  </div>
                </div>

                {/* Table component */}

                <Button
                  className="back-icon-cta"
                  onClick={() => handleScroll(-100)}
                >
                  <ArrowBackIosNewIcon />
                </Button>
                <Button
                  className="forward-icon-cta"
                  onClick={() => handleScroll(100)}
                >
                  <ArrowForwardIosIcon />
                </Button>

                <div
                  ref={scrollContainerRef}
                  className="table-wrapper-pagination"
                >
                  <div className="table-container-pagination">
                    <div className="header-limit-checkbox picklist-header-limit-checkbox">
                      <div>
                        <div>
                          <input
                            type="checkbox"
                            readOnly
                            id="checkbox"
                            checked={
                              ActiveOrders && ActiveOrders?.length > 0
                                ? ActiveOrders.every((i) =>
                                    i.is_cancel === 0 &&
                                    order_ids.includes(i.picklist_id)
                                      ? true
                                      : false
                                  )
                                : false
                            }
                          />
                          <label
                            onClick={() => handleIndexAll()}
                            htmlFor="checkbox"
                          ></label>
                        </div>
                        <p className="lable-select-all">Select all</p>
                        <Button
                          type="submit"
                          onClick={() => handleMultiplePicklistAssignment(2, 2)}
                          className="create-picklist-requirements"
                        >
                          Assign all
                        </Button>
                        <Button
                          onClick={handleRecaluculate}
                          type="submit"
                          className="re-calculate-order"
                          disabled={recalculate_loading ? true : false}
                        >
                          {recalculate_loading ? (
                            <Loader isSmall={true} />
                          ) : (
                            "Recalculate"
                          )}
                        </Button>
                      </div>
                      <div className="result-flex-grid">
                        <p className="result-out">
                          *Result {page} in{" "}
                          {filtersTrue
                            ? filterTotal_pages
                            : picklistMgmt && picklistMgmt?.total_pages}
                        </p>
                        <div className="limit">
                          <input
                            type="text"
                            name="limit"
                            id="limit"
                            value={limit}
                            onKeyPress={handleLimit}
                            onChange={(e) => handleLimitTag(e)}
                          />
                        </div>
                      </div>
                    </div>
                    {loading && !isLoading ? (
                      <LoaderAlone />
                    ) : (
                      <DataTable
                        onData={handleModal}
                        PickListManagement={picklist && picklist}
                        header={PickListManagementHeaderArr}
                        onHeadingIndex={onHeadingIndex}
                        PickListId={pickListId}
                        isIndexSelectAll={order_ids}
                        onSelect={handleIndex}
                        isOk={isOk}
                        assign_Picklist_id={picklist_assign_id}
                        handleStatus={handlePicklistStatus}
                        picklist_status={picklist_status}
                        handlePDFDownload={handlePDFDownload}
                        LoadingPDF={LoadingPDF}
                        permission_list_arr={permission_list_data?.data}
                        permission_loading={permission_loading}
                      />
                    )}
                  </div>
                </div>
                <div className="rendering-pagination">
                  <Pagination
                    uniqueKey={`ScreenA-${page}`}
                    page={page}
                    isLoading={isLoading ? isLoading : filter_Loading}
                    totalPage={
                      filtersTrue
                        ? filterTotal_pages
                        : picklistMgmt && picklistMgmt?.total_pages
                    }
                    onPageChange={handlePage}
                    onHandleCheckbox={handleNull}
                  />
                </div>
              </>
            )}
          </Fragment>
          {/* )} */}
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default PickList;
