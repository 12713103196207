import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import SideBar from "../../Components/SideBar/SideBar";
import MetaData from "../../Components/Helmet/Helmet";
import Headline from "../../Components/HeadlineSpecial/Headline";
import Dropdown from "../../Components/Dropdown/Dropdown";
import "./BoxAudit.css";
import BoxUnitAudit from "./TabContents/BoxUnitAudit";
import AuditUnitTransactions from "./TabContents/AuditUnitTransactions";

const BoxUnitQuantityAudit = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["Box Audit", "Audit Transaction"];
  const tabContent = [<BoxUnitAudit />, <AuditUnitTransactions />];

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Box Unit Quantity Audit`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header isToggle={isToggle} />
        <div className="wrapper-picker-container">
          <Headline title="Box Unit Quantity Audit" btnTitle="Export CSV" />

          {/* Dropdown Row Starts here */}
          <div className="dropdown-grid-row dropwon">
            <Dropdown
              placeholder="Box Audit Trans. No"
              showId={true}
              noShow={false}
            />
            <Dropdown
              placeholder="Choose Location"
              showId={true}
              noShow={false}
            />
          </div>
          {/* Dropdown Row ends here */}

          {/* Tab Module Component starts here */}
          <div className="Tab-module">
            <nav
              className="tab-module-grid-row"
              aria-label="Tabs"
              role="tablist"
              aria-orientation="horizontal"
            >
              {tabs.map((tab, index) => (
                <button
                  key={index}
                  type="button"
                  className={`tab-button ${
                    activeTab === index ? "active" : ""
                  }`}
                  id={`card-type-tab-item-${index + 1}`}
                  aria-selected={activeTab === index}
                  role="tab"
                  onClick={() => setActiveTab(index)}
                >
                  {tab}
                </button>
              ))}
            </nav>
          </div>

          <div>
            {tabContent.map((content, index) => (
              <div
                id={`card-type-tab-${activeTab}`}
                role="tabpanel"
                aria-labelledby={`card-type-tab-item-${activeTab}`}
                className={activeTab === index ? "" : "hidden"}
              >
                {content}
              </div>
            ))}
          </div>
          {/* Tab Module Component ends here */}
        </div>
      </div>
    </div>
  );
};

export default BoxUnitQuantityAudit;
