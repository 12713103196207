import React, { useCallback } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import "./DeleteTote.css";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { useAlert } from "react-alert";
import { DeletePredefinedProblemActions } from "../../../Actions/PickerMgmt/PickerMgmtActions";
import { useEffect } from "react";
import { useState } from "react";
import { DELETE_PREDEFINED_PROBLEMS_RESET } from "../../../Constants/PickerMgmt/PickerManagementConstants";
// import { deleteToteActions } from "../../../Actions/PickerMgmt/PickerMgmtActions";
// import { useEffect } from "react";

const DeleteTote = (props) => {
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const alert = useAlert();
  const dispatch = useDispatch();
  // const { delete_tote, loading } = useSelector((state) => state.deleteTote);
  // const { close, tote_id } = props;
  const { delete_predefined_problem, loading } = useSelector(
    (state) => state.deleteProblem
  );
  const { close, id } = props;

  const closeModal = useCallback(() => {
    close(null);
  }, [close]);

  const [isHit, setIsHit] = useState(false);

  const deleteProblem = () => {
    setIsHit(true);
    dispatch(
      DeletePredefinedProblemActions(
        "manage_predefined_problems",
        token,
        "delete",
        id
      )
    );
  };

  // const deleteTote = () => {
  //   dispatch(deleteToteActions("manage_tote", token, "delete", tote_id));
  // };

  // useEffect(() => {
  //   if (delete_tote && delete_tote?.status === 1) {
  //     alert.success("Tote Deleted Successfully");
  //     closeModal();
  //   } else if (delete_tote && delete_tote?.status === 0) {
  //     alert.error("Something Went Wrong...");
  //     closeModal();
  //   }
  // }, [delete_tote, alert, closeModal]);

  useEffect(() => {
    if (
      delete_predefined_problem &&
      delete_predefined_problem?.status === 1 &&
      isHit
    ) {
      alert.success("Problem Deleted Successfully");
      closeModal();
      setIsHit(false);
      dispatch({
        type: DELETE_PREDEFINED_PROBLEMS_RESET,
      });
    } else if (
      delete_predefined_problem &&
      delete_predefined_problem?.status === 0 &&
      isHit
    ) {
      alert.error(delete_predefined_problem && delete_predefined_problem?.msg);
      closeModal();
      setIsHit(false);
      dispatch({
        type: DELETE_PREDEFINED_PROBLEMS_RESET,
      });
    }
  }, [delete_predefined_problem, alert, closeModal, isHit, dispatch]);

  return (
    <>
      <HeadingTitle colorRed={true} title="Delete Problem" closeModal={close} />
      {/* <div className="delete-tote-message">
        <p>Are you absolutely sure that you want to clear the tote?</p>
        <Button type="submit" onClick={closeModal}>
          No
        </Button>
        <Button type="submit" onClick={deleteTote}>
          {loading ? <Loader /> : "Yes"}
        </Button>
      </div> */}

      <div className="delete-tote-message">
        <p>Are you absolutely sure that you want to Delete The Problem?</p>
        <Button type="submit" onClick={deleteProblem}>
          {loading ? <Loader /> : "Yes"}
        </Button>
        <Button type="submit" onClick={closeModal}>
          No
        </Button>
      </div>
    </>
  );
};

export default DeleteTote;
