import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import MetaData from "../../Components/Helmet/Helmet";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { Button } from "@material-ui/core";
import DataTable from "../../Components/Table/DataTable";
import { MarkLostRequestHeader } from "../../DummyArray/TableArray/TableData";
import ModalContainer from "../../Components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFiltersPickingModuleActions,
  MarkLostDataActions,
  syncMarkLostRequestActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import { Pagination } from "../../Components/Pagination/Pagination";
import DatePicker from "react-datepicker";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import { useAlert } from "react-alert";
import moment from "moment";

const aging_list = [
  {
    id: 1,
    label: "1_to_7_days",
    name: "1_to_7_days",
  },
  {
    id: 2,
    label: "7_to_15_days",
    name: "7_to_15_days",
  },
  {
    id: 3,
    label: "15_to_30_days",
    name: "15_to_30_days",
  },
  {
    id: 4,
    label: "30_to_60_days",
    name: "30_to_60_days",
  },
  {
    id: 5,
    label: "more_than_60_days",
    name: "more_than_60_days",
  },
];

const MarkLostRequest = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  const [isTyping, setIsTyping] = useState(false);

  // Picklist Mark Lost Request State
  const { mark_lost, loading } = useSelector((state) => state.MarkLost);

  const [page, setPage] = useState(1);

  const [MarkLostArr, setMarkLostArr] = useState([]);

  const [isIndex, setIsIndex] = useState(null);

  const [filter_Loading, setFilter_loading] = useState(false);
  const [filterTotal_pages, setFilter_total_pages] = useState(0);

  const [product_id, setProduct_id] = useState("");
  const [subLocation_id, setSubLocation_id] = useState("");
  const [Inventory_Picklist_id, setInventory_Picklist_id] = useState("");

  const handleModal = (index, i, j, k) => {
    setIsIndex(index);
    setSubLocation_id(i);
    setProduct_id(j);
    setInventory_Picklist_id(k);
  };

  const [sku_code, setSku_code] = useState("");
  const [Sku_Img, setSku_Img] = useState("");
  const [sku_Units, setSKU_Units] = useState("");

  const [prod_id, setProd_id] = useState("");
  const [subLoca_id, setSubLocId] = useState("");

  const handleAuditAlert = (i, j, k, prod_id, subLoc_id) => {
    // console.log("PRODUCT ID (MARK LOST REQUEST): ", prod_id);
    // console.log("SUB LOCATION ID (MARK LOST REQUEST): ", subLoc_id);
    setSku_Img(i);
    setSku_code(j);
    setSKU_Units(k);
    setProd_id(prod_id);
    setSubLocId(subLoc_id);
    handleModal(15);
  };

  // console.log("SKU CODE: ", sku_code);
  // console.log("SKU IMAGE: ", Sku_Img);

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          product_id={product_id}
          subLocation_id={subLocation_id}
          Inventory_Picklist_id={Inventory_Picklist_id}
          handleAuditAlert={handleAuditAlert}
          sku_code={sku_code}
          Sku_Img={Sku_Img}
          sku_Units={sku_Units}
          prod_id={prod_id}
          subLoca_id={subLoca_id}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (mark_lost?.status === 1) {
      setMarkLostArr(mark_lost?.data);
    }
  }, [mark_lost]);

  // console.log("MARK LOST: ", mark_lost?.total_pages);

  // Dropdown Data Code Starts here
  const { all_filters, loading: allFilter_loading } = useSelector(
    (state) => state.allPickingModuleFilters
  );

  let All_Filters = useMemo(() => {
    const dataToSend = [
      "locations",
      "sublocations",
      "sku",
      "aisles",
      "zones",
      "product_groups",
      "product_sub_groups",
      "racks",
      "shelves",
      "pickers",
    ];
    return dataToSend;
  }, []);

  let sub_locations_list = all_filters && all_filters?.sublocations;
  let sku_list = all_filters && all_filters?.sku;
  let zones_list = all_filters && all_filters?.zones;
  let aisles_list = all_filters && all_filters?.aisles;
  let locations_list = all_filters && all_filters?.locations;
  let product_group_list = all_filters && all_filters?.product_groups;
  let style_color_list = all_filters && all_filters?.product_sub_groups;
  let rack_list = all_filters && all_filters?.racks;
  let shelf_list = all_filters && all_filters?.shelves;
  let pickers_list = all_filters && all_filters?.pickers;

  useEffect(() => {
    dispatch(getAllFiltersPickingModuleActions("filters", token, All_Filters));
  }, [dispatch, token, All_Filters]);

  // Dropdown Data Code ends here

  // State Name
  const [location, setLocation] = useState("");
  const [location_name, setLocation_name] = useState("");

  const [sub_location, setSub_location] = useState("");
  const [sub_location_name, setSub_location_name] = useState("");

  const [product_group, setProduct_group] = useState("");
  const [product_group_name, setProduct_group_name] = useState("");

  const [sku, setSku] = useState("");
  const [sku_name, setSKUName] = useState("");

  const [style_color, setStyle_Color] = useState("");
  const [style_color_name, setStyleColorName] = useState("");

  const [aisle, setAisle] = useState("");
  const [aisle_name, setAisle_name] = useState("");

  const [rack, setRack] = useState("");
  const [rack_Name, setRackName] = useState("");

  const [shelves, setShelves] = useState("");
  const [shelves_name, setShelvesName] = useState("");

  const [zone, setZone] = useState("");
  const [zone_name, setZoneName] = useState("");

  const [request_date_from, setRequest_date_from] = useState(null);
  const [request_date_to, setRequest_date_to] = useState(null);

  const [request_by, setRequestBy] = useState("");
  const [request_by_name, setRequest_by_name] = useState("");

  const [aging, setAging] = useState("");

  const [request_count, setRequest_count] = useState("");

  const handleLocation = (val) => {
    setFilterTrue(true);
    console.log("LOCATION: ", val);
    setLocation_name(val?.value);
    setLocation(val?.id);
    handlePage(1, false);
  };

  const handleSubLocation = (val) => {
    setFilterTrue(true);
    console.log("SUB LOCATION: ", val);
    setSub_location(val?.id);
    setSub_location_name(val?.value);
    handlePage(1, false);
  };

  const handleProductGroup = (val) => {
    setFilterTrue(true);
    console.log("PRODUCT GROUP: ", val);
    setProduct_group(val?.id);
    setProduct_group_name(val?.value);
    handlePage(1, false);
  };

  const handleSKU = (val) => {
    setFilterTrue(true);
    console.log("SKU: ", val);
    setSku(val?.id);
    setSKUName(val?.value);
    handlePage(1, false);
  };

  const handleStyleColor = (val) => {
    setFilterTrue(true);
    console.log("STYLE COLOR: ", val);
    setStyleColorName(val?.value);
    setStyle_Color(val?.id);
    handlePage(1, false);
  };

  // xxxxxxxx

  const handleAisle = (val) => {
    setFilterTrue(true);
    console.log("AISLE: ", val);
    setAisle(val?.id);
    setAisle_name(val?.value);
    handlePage(1, false);
  };

  const handleRack = (val) => {
    setFilterTrue(true);
    console.log("RACK: ", val);
    setRack(val?.id);
    setRackName(val?.value);
    handlePage(1, false);
  };

  const handleShelves = (val) => {
    setFilterTrue(true);
    console.log("SHELVES: ", val);
    setShelves(val?.id);
    setShelvesName(val?.value);
    handlePage(1, false);
  };

  const handleZone = (val) => {
    setFilterTrue(true);
    console.log("ZONE: ", val);
    setZone(val?.id);
    setZoneName(val?.value);
    handlePage(1, false);
  };

  const handleRequestDateFrom = (date) => {
    setFilterTrue(true);
    setRequest_date_from(date);
    handlePage(1, false);
  };

  const handleRequestDateTo = (date) => {
    setFilterTrue(true);
    setRequest_date_to(date);
    handlePage(1, false);
  };

  const handleAging = (val) => {
    setFilterTrue(true);
    console.log("AGING: ", val);
    setAging(val?.value);
    handlePage(1, false);
  };

  const handleRequestCount = (event) => {
    if (event.key === "Enter") {
      setIsTyping(false);
      setFilterTrue(true);
      console.log("COUNT: ", request_count);
    }
  };

  const handleRequestBy = (val) => {
    setFilterTrue(true);
    console.log("REQUEST BY: ", val);
    setRequestBy(val?.id);
    setRequest_by_name(val?.value);
  };

  // State Name

  console.log(
    "VALUE: ",
    location,
    sub_location,
    product_group,
    sku,
    style_color,
    aisle,
    rack,
    shelves,
    zone,
    request_date_from,
    request_date_to,
    aging,
    request_count
  );

  const resetFilter = () => {
    setLocation("");
    setLocation_name("");

    setSub_location("");
    setSub_location_name("");

    setProduct_group("");
    setProduct_group_name("");

    setSku("");
    setSKUName("");

    setStyle_Color("");
    setStyleColorName("");

    setAisle("");
    setAisle_name("");

    setRack("");
    setRackName("");

    setShelves("");
    setShelvesName("");

    setZone("");
    setZoneName("");

    setRequest_date_from("");
    setRequest_date_to("");

    setAging("");

    setRequest_count("");
    setRequestBy("");
    setRequest_by_name("");
  };

  // Filter Code starts here
  let from_date =
    request_date_from !== ""
      ? moment(request_date_from).format("yyyy-MM-DD")
      : null;
  let to_date =
    request_date_to !== ""
      ? moment(request_date_to).format("yyyy-MM-DD")
      : null;
  let filters = useMemo(() => {
    const dataToSend = {
      location,
      sub_location,
      product_group,
      sku,
      style_color,
      aisle,
      rack,
      shelves,
      zone,
      request_date_from: from_date,
      request_date_to: to_date,
      request_by,
      aging,
      request_count,
    };
    // Filtering empty value
    const filter_data = Object.fromEntries(
      Object.entries(dataToSend).filter(
        ([_, value]) =>
          value !== "" && value !== null && value !== "Invalid date"
      )
    );
    return filter_data;
  }, [
    location,
    sub_location,
    product_group,
    sku,
    style_color,
    aisle,
    rack,
    shelves,
    zone,
    // request_date_from,
    // request_date_to,
    request_by,
    aging,
    request_count,
    from_date,
    to_date,
  ]);

  const fetchFilters = useCallback(() => {
    setFilter_loading(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "get_picklist_requests",
        page,
        token,
        filters,
      })
      .then((response) => {
        if (response && response?.data?.status === 0) {
          alert.error(response && response?.data?.msg);
        } else {
          setFilter_total_pages(response?.data?.total_pages);
          setMarkLostArr(response?.data?.data);
          setFilter_loading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setFilter_loading(false);
      });
  }, [token, page, filters, alert]);
  // Filter Code ends here

  // Pagination Code starts here
  const [pagination, setPagination] = useState(0);
  const [filtersTrue, setFilterTrue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(
    (page) => {
      // setIsFetchActive(true);
      setIsLoading(true);
      axios
        .post(`${BASE_URL}`, {
          service_name: "get_picklist_requests",
          page,
          token,
        })
        .then((response) => {
          if (response?.data?.status === 1) {
            console.log("RESPONSE: ", response?.data?.data);
            setMarkLostArr(response?.data?.data);
            setIsLoading(false);
          } else {
            alert.error(response?.data?.msg);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
          setIsLoading(false);
        });
    },
    [alert, token]
  );

  useEffect(() => {
    if (filtersTrue === true) {
      fetchFilters();
    } else if (filtersTrue === false && pagination === 0 && !isTyping) {
      dispatch(MarkLostDataActions("get_picklist_requests", token));
    }
  }, [dispatch, token, filtersTrue, fetchFilters, pagination, isTyping]);

  const handlePage = useCallback(
    (page, paginate) => {
      setPagination(page);
      setPage(page);
      if (!filtersTrue && pagination >= 0 && paginate) {
        fetchData(page);
      }
    },
    [fetchData, filtersTrue, pagination]
  );
  // Pagination Code ends here

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  const handleRequestCountChange = (val) => {
    setIsTyping(true);
    setRequest_count(val);
  };

  // sync Mark Lost Request State
  const { syncMark_Lost, loading: syncMark_loading } = useSelector(
    (state) => state.syncMarkLost
  );

  const [isSync, setIsSync] = useState(false);

  const handleMarkLostSync = () => {
    setIsSync(true);
    dispatch(syncMarkLostRequestActions("settle_psl_mark_lost", token));
  };

  useEffect(() => {
    if (syncMark_Lost && syncMark_Lost?.status === 1 && isSync) {
      setIsSync(false);
      alert.success(syncMark_Lost && syncMark_Lost?.msg);
    }
    if (syncMark_Lost && syncMark_Lost?.status === 0 && isSync) {
      setIsSync(false);
      alert.error(syncMark_Lost && syncMark_Lost?.msg);
    }
  }, [syncMark_Lost, isSync, alert]);

  console.log("SYNC MARK LOST: ", syncMark_Lost);

  return (
    <Fragment>
      <div
        className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
      >
        <MetaData title={`Mark Lost Request`} />
        <div>
          <SideBar handleIsToggle={handleIsToggle} />
        </div>
        <div>
          <Header />
          <div className="wrapper-picker-container">
            {/* Header Title Component row starts here */}
            <Headline
              title="Mark Lost Request"
              syncLost={true}
              hide={true}
              handleMarkLostSync={handleMarkLostSync}
              syncMark_loading={syncMark_loading}
            />
            {/* Header Title Component row ends here */}

            {/* dropdown grid container */}
            <div className="dropdown-grid-row dropwon">
              <div>
                <Dropdown
                  placeholder="Location"
                  loading={allFilter_loading}
                  dropdown={locations_list && locations_list}
                  value={location_name}
                  onData={handleLocation}
                />
              </div>
              <div>
                <Dropdown
                  placeholder="Sublocation"
                  loading={allFilter_loading}
                  dropdown={sub_locations_list && sub_locations_list}
                  onData={handleSubLocation}
                  value={sub_location_name}
                />
              </div>
              <div>
                <Dropdown
                  placeholder="Product Group"
                  loading={allFilter_loading}
                  dropdown={product_group_list && product_group_list}
                  onData={handleProductGroup}
                  value={product_group_name}
                />
              </div>
              <div>
                <Dropdown
                  placeholder="SKU"
                  loading={allFilter_loading}
                  dropdown={sku_list && sku_list}
                  onData={handleSKU}
                  value={sku_name}
                />
              </div>
              <div>
                <Dropdown
                  placeholder="Style Color"
                  loading={allFilter_loading}
                  dropdown={style_color_list && style_color_list}
                  value={style_color_name}
                  onData={handleStyleColor}
                />
              </div>
              <div>
                <Dropdown
                  placeholder="Aisle"
                  loading={allFilter_loading}
                  dropdown={aisles_list && aisles_list}
                  onData={handleAisle}
                  value={aisle_name}
                />
              </div>
              <div>
                <Dropdown
                  placeholder="Rack"
                  loading={allFilter_loading}
                  dropdown={rack_list && rack_list}
                  onData={handleRack}
                  value={rack_Name}
                />
              </div>
              <div>
                <Dropdown
                  placeholder="Shelves"
                  loading={allFilter_loading}
                  dropdown={shelf_list && shelf_list}
                  onData={handleShelves}
                  value={shelves_name}
                />
              </div>
              <div>
                <Dropdown
                  placeholder="Zone"
                  loading={allFilter_loading}
                  dropdown={zones_list && zones_list}
                  value={zone_name}
                  onData={handleZone}
                />
              </div>
              <div>
                <Dropdown
                  placeholder="Request By"
                  loading={allFilter_loading}
                  dropdown={pickers_list && pickers_list}
                  onData={handleRequestBy}
                  value={request_by_name}
                />
              </div>
              <div>
                <DatePicker
                  className="date-picker"
                  placeholderText="Request Date From"
                  selected={request_date_from}
                  onChange={(date) => handleRequestDateFrom(date)}
                />
              </div>
              <div>
                <DatePicker
                  className="date-picker"
                  placeholderText="Request Date To"
                  selected={request_date_to}
                  onChange={(date) => handleRequestDateTo(date)}
                />
              </div>
              <div className="date_handover">
                <input
                  type="number"
                  name="request_count"
                  id="request_count"
                  min={0}
                  onChange={(e) => handleRequestCountChange(e.target.value)}
                  placeholder="Request Count"
                  onKeyPress={handleRequestCount}
                  value={request_count}
                />
              </div>
              <div>
                <Dropdown
                  placeholder="Aging"
                  dropdown={aging_list}
                  onData={handleAging}
                  value={aging}
                />
              </div>
              <div>
                <div className="clear-filter">
                  <Button onClick={() => resetFilter()}>Reset Filter</Button>
                </div>
              </div>
            </div>
            {/* Dropdown ends here */}

            {/* Table Component starts here */}
            <div className="table-wrapper-pagination">
              <div className="table-container-pagination">
                {loading ? (
                  <LoaderAlone />
                ) : (
                  <DataTable
                    header={MarkLostRequestHeader}
                    mark_lost_arr={MarkLostArr}
                    handleRequestCount={handleModal}
                    handleWithoutAudit={handleModal}
                  />
                )}
              </div>
            </div>

            <div className="rendering-pagination">
              <Pagination
                uniqueKey={`ScreenA-${page}`}
                page={page}
                isLoading={isLoading ? isLoading : filter_Loading}
                totalPage={
                  filtersTrue
                    ? filterTotal_pages
                    : mark_lost && mark_lost?.total_pages
                }
                onPageChange={handlePage}
              />
            </div>
            {/* Table Component ends here */}
          </div>
        </div>
      </div>
      {renderModal()}
    </Fragment>
  );
};

export default MarkLostRequest;
