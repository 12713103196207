import React, { useCallback, useEffect, useState } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { Button } from "@material-ui/core";
import "./AddTote.css";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { addToteActions } from "../../../Actions/PickerMgmt/PickerMgmtActions";
import Loader from "../../Loader/Loader";
import { ADD_TOTE_RESET } from "../../../Constants/PickerMgmt/PickerManagementConstants";
import { useRef } from "react";

const AddTote = (props) => {
  const { close } = props;
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  const alert = useAlert();
  const dispatch = useDispatch();
  const { new_tote, loading } = useSelector((state) => state.addTote);

  const [code, setCode] = useState("");
  const [hitAddTote, setHitAddTote] = useState(false);

  const closeModal = useCallback(() => {
    close(null);
  }, [close]);

  const inputRef = useRef(null);

  useEffect(() => {
    // Focus the input field when the modal is opened
    inputRef.current.focus();
  }, []);

  const addTote = (e) => {
    e.preventDefault();
    setHitAddTote(true);
    if (code !== "") {
      dispatch(addToteActions("manage_tote", token, "add", code));
    } else {
      alert.error("Please Add Tote Code...");
    }
  };

  useEffect(() => {
    if (new_tote && new_tote?.status === 1 && hitAddTote) {
      alert.success("Tote Created Successfully");
      setHitAddTote(false);
      dispatch({
        type: ADD_TOTE_RESET,
      });
      closeModal();
    } else if (new_tote && new_tote?.status === 0 && hitAddTote) {
      alert.error(new_tote && new_tote?.msg);
      setHitAddTote(false);
      closeModal();
    }
  }, [new_tote, alert, closeModal, dispatch, hitAddTote]);

  return (
    <>
      <HeadingTitle title="Add Tote" closeModal={close} />
      <form onSubmit={addTote}>
        <div className="input-field mt">
          <label htmlFor="totenam">Tote Code</label> <br />
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            id="totenam"
            ref={inputRef}
          />
        </div>
        <div className="tote-cta">
          <Button disabled={loading ? true : false} type="submit">
            {loading ? <Loader /> : "Add"}
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddTote;
