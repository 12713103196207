import React from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Headline from "../../Components/HeadlineSpecial/Headline";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import {
  editReverseHandoverSummaryActions,
  getAllHandoverNameReverseOrderSummaryActions,
  getReversedHandoverOrderActions,
  getUserListActions,
} from "../../Actions/OrderHandoverActions/OrderHandoverActions";
import { reverse_handover_order_header } from "../../DummyArray/TableArray/TableData";
import DataTable from "../../Components/Table/DataTable";
import { useState } from "react";
import { useCallback } from "react";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import { useMemo } from "react";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import { Pagination } from "../../Components/Pagination/Pagination";
import { useData } from "../../DataContext/DataContext";
import { useAlert } from "react-alert";
import { EDIT_REVERSE_HANDOVER_ORDER_RESET } from "../../Constants/OrderHandoverConstants/GetCourierNameConstants";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Button } from "@material-ui/core";

const ReverseHandoverSummary = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const { setHandover_id, setunique_reversal_handover_name } = useData();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { reversed_handover_data, loading, status } = useSelector(
    (state) => state.reverseHandoverSummary
  );

  // handover name state
  const {
    reverse_order_summary_handover_name,
    loading: handover_name_loading,
  } = useSelector((state) => state.reverseOrderHandoverAllName);

  // user list state
  const { user_list, loading: user_loading } = useSelector(
    (state) => state.userList
  );

  // edit reverse handover
  const {
    edit_reversed_order,
    loading: reversed_edit_loading,
    isRedirect,
  } = useSelector((state) => state.editReverseHandover);

  useEffect(() => {
    // get all handover Name
    dispatch(
      getAllHandoverNameReverseOrderSummaryActions(
        "get_reverse_handovername_list",
        token
      )
    );
    // get all users
    dispatch(getUserListActions("get_users_list", token));
  }, [dispatch, token]);

  //  pagination code starts here
  const [reversed_list, setReversed_list] = useState([]);
  const [filtersT, setFiltersT] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(0);

  let limit = 10;

  const fetchNextOrderReverseSummary = (page) => {
    setIsLoading(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "get_reversed_handovers_list",
        page,
        token,
        limit,
      })
      .then((response) => {
        setReversed_list(response?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handlePage = (page) => {
    setPagination(page);
    setPage(page);
    if (!filtersT && pagination >= 0) {
      fetchNextOrderReverseSummary(page);
    }
  };

  const fetchReversedList = useCallback(() => {
    reversed_handover_data &&
      setReversed_list(reversed_handover_data && reversed_handover_data?.data);
  }, [reversed_handover_data]);

  useEffect(() => {
    fetchReversedList();
  }, [fetchReversedList]);

  let reverse_handover_name =
    reverse_order_summary_handover_name &&
    reverse_order_summary_handover_name?.data;
  let users = user_list && user_list?.data;

  // filter code goes here
  const [unique_reversal_handover_name, setUnique_reversal_handover_name] =
    useState("");
  const [id, setId] = useState("");

  const [created_by, setCreated_by] = useState("");
  const [created_by_name, setCreated_by_name] = useState("");

  const [created_on, setCreated_on] = useState("");

  const handleHandoverName = (val) => {
    setFiltersT(true);
    setUnique_reversal_handover_name(val?.value);
    setId(val?.id);
  };

  const handleCreatedBy = (val) => {
    setFiltersT(true);
    setCreated_by_name(val?.value);
    setCreated_by(val?.id);
  };

  const handleDate = (date) => {
    setFiltersT(true);
    setCreated_on(date);
  };

  let filters = useMemo(() => {
    let created = moment(created_on).format("yyyy-MM-DD");
    const dataToSend = {
      id,
      created_by,
      created,
    };
    // Filtering empty value
    const filter_data = Object.fromEntries(
      Object.entries(dataToSend).filter(
        ([_, value]) =>
          value !== "" && value !== null && value !== "Invalid date"
      )
    );
    return filter_data;
  }, [id, created_by, created_on]);

  const [filterTotal_pages, setFilter_total_pages] = useState(0);
  const [filter_Loading, setFilter_loading] = useState(false);

  const fetchFilters = useCallback(() => {
    setFilter_loading(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "get_reversed_handovers_list",
        token,
        page,
        limit,
        filters,
      })
      .then((response) => {
        setFilter_total_pages(response?.data?.total_pages);
        setReversed_list(response?.data?.data);
        setFilter_loading(false);
      })
      .catch((err) => {
        console.log(err);
        setFilter_loading(false);
      });
  }, [token, page, filters, limit]);
  // filter code ends here

  useEffect(() => {
    if (filtersT === true) {
      fetchFilters();
    } else if (filtersT === false && pagination === 0) {
      dispatch(
        getReversedHandoverOrderActions(
          "get_reversed_handovers_list",
          token,
          1,
          10
        )
      );
    }
  }, [dispatch, token, filtersT, fetchFilters, pagination]);

  const handleEditHandover = (reverse_handover_id) => {
    dispatch(
      editReverseHandoverSummaryActions(
        "get_reverse_handover_edit_data",
        token,
        reverse_handover_id
      )
    );
  };

  useEffect(() => {
    if (
      edit_reversed_order &&
      edit_reversed_order?.status === 1 &&
      isRedirect
    ) {
      const handoverData = {
        handover_id: edit_reversed_order && edit_reversed_order?.data?.id,
      };
      setHandover_id(handoverData);
      const unique_reversal_handover_name = {
        unique_reversal_handover_name:
          edit_reversed_order &&
          edit_reversed_order?.data?.unique_reversal_handover_name,
      };
      setunique_reversal_handover_name(unique_reversal_handover_name);
      navigate(`/scan-orders`);
      dispatch({
        type: EDIT_REVERSE_HANDOVER_ORDER_RESET,
      });
    }
    if (
      edit_reversed_order &&
      edit_reversed_order?.status === 0 &&
      isRedirect
    ) {
      alert.error(edit_reversed_order && edit_reversed_order?.msg);
      dispatch({
        type: EDIT_REVERSE_HANDOVER_ORDER_RESET,
      });
    }
  }, [
    dispatch,
    edit_reversed_order,
    alert,
    navigate,
    setHandover_id,
    setunique_reversal_handover_name,
    isRedirect,
  ]);

  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loadingIdCsv, setLoadingCSVId] = useState(false);
  const handleCSVDownload = async () => {
    setLoadingCSV(true);
    try {
      const response = await axios.post(`${BASE_URL}`, {
        service_name: "get_reversed_handovers_list_csv",
        token,
      });
      const csvFile = response?.data?.data;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
      }

      setLoadingCSV(false);
    } catch (err) {
      alert.error(err);
      setLoadingCSV(false);
    }
  };

  const handleReverseCsvIds = async (reverse_handover_id) => {
    setLoadingCSVId(true);
    try {
      const response = await axios.post(`${BASE_URL}`, {
        service_name: "get_reverse_handover_csv",
        token,
        reverse_handover_id,
      });
      const csvFile = response?.data?.data?.download_url;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
      }

      setLoadingCSVId(false);
    } catch (err) {
      alert.error(err);
      setLoadingCSVId(false);
    }
  };

  const resteFilter = () => {
    setUnique_reversal_handover_name("");
    setId("");
    setCreated_by("");
    setCreated_by_name("");
    setCreated_on("");
  };

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          {loading ? (
            <LoaderAlone />
          ) : (
            <>
              {status === 0 ? (
                <NoDataFromApi
                  height={true}
                  title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                />
              ) : (
                <>
                  <Headline
                    onData={handleCSVDownload}
                    title="Reverse Handover Summary"
                    btnTitle="Downloads Reverse Handover Summary"
                    loading={loadingCSV}
                  />

                  {/* dropdown */}
                  <div className="dropdown-grid-row">
                    <div>
                      <Dropdown
                        placeholder="Handover Name"
                        dropdown={
                          reverse_handover_name && reverse_handover_name
                        }
                        value={unique_reversal_handover_name}
                        onData={handleHandoverName}
                        noShow={true}
                        loading={handover_name_loading}
                      />
                    </div>
                    <div className="date_handover">
                      <DatePicker
                        className="date-picker"
                        selected={created_on}
                        placeholderText="Created On"
                        onChange={(date) => handleDate(date)}
                      />
                      {/* <input
                        type="date"
                        name="handover_date"
                        id="handover_date"
                        value={created_on}
                        onChange={(e) => handleDate(e)}
                      /> */}
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Created By"
                        dropdown={users && users}
                        value={created_by_name}
                        onData={handleCreatedBy}
                        noShow={true}
                        loading={user_loading}
                      />
                    </div>
                    <div>
                      <div className="clear-filter">
                        <Button onClick={() => resteFilter()}>
                          Reset Filter
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* dropdown */}

                  {/* table component starts here */}
                  <div className="table-wrapper-pagination">
                    <div className="table-container-pagination">
                      <div className="header-limit-checkbox">
                        <div></div>
                        <div>
                          <p className="result-out">
                            *Result {page} in{" "}
                            {filtersT
                              ? filterTotal_pages
                              : reversed_handover_data &&
                                reversed_handover_data?.total_pages}
                          </p>
                        </div>
                      </div>
                      <DataTable
                        reverse_order_summary={reversed_list && reversed_list}
                        header={reverse_handover_order_header}
                        takeReverseId={handleEditHandover}
                        reversed_edit_loading={reversed_edit_loading}
                        handleReverseCsvIds={handleReverseCsvIds}
                        loadingIdCsv={loadingIdCsv}
                      />
                    </div>

                    <div className="rendering-pagination">
                      <Pagination
                        uniqueKey={`ScreenA-${page}`}
                        page={page}
                        isLoading={isLoading ? isLoading : filter_Loading}
                        totalPage={
                          filtersT
                            ? filterTotal_pages
                            : reversed_handover_data &&
                              reversed_handover_data?.total_pages
                        }
                        onPageChange={handlePage}
                      />
                    </div>
                  </div>
                  {/* table component ends here */}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReverseHandoverSummary;
