import KeyPairs from "../../../Components/KeyPairs/KeyPairs";
import InputField from "../../../Components/InputField/InputField";
import ModalContainer from "../../../Components/Modal/Modal";
import { useState } from "react";

const AuditUnitTransactions = () => {
  const [isopen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(17);

  const closeModal = () => {
    setIsOpen(false);
  };

  const renderModal = () => {
    if (isopen) {
      return (
        <ModalContainer
          Isopen={isopen}
          index={modalIndex}
          closeModal={closeModal}
          onData={closeModal}
          title={"Conclude"}
          message={
            "Do you want to conclude the audit transaction? If you choose to conclude it, you won't be able to scan any other boxes in this audit transaction."
          }
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderModal()}
      <div className="summary-box">
        <div className="grid-delivery-partner-info">
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={"BOX_12345"}
              label="Scan Box"
              placeholder="Scan Box"
              id="scanbox"
              scan={true}
              // handover={true}
            />
          </div>
          <div>
            <button className="randomBtn">Select Random</button>
          </div>
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <KeyPairs data={[{ key: "Total Quantity", value: "300" }]} />
        </div>
        <div className="grid-delivery-partner-info">
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={"JVC123456"}
              label="Scan Serial Code"
              placeholder="Scan Serial Code"
              id="scanbox"
              scan={true}
              // handover={true}
            />
          </div>
        </div>
        <div>
          <KeyPairs data={[{ key: "Total Audit Quantity", value: "260" }]} />
        </div>
        <div className="mt-3 btn-container">
          <button
            className="submit-btn"
            style={{
              margin: "0",
              maxWidth: "200px",
              backgroundColor: "#26A6FE",
            }}
          >
            Mark 40 Units Lost & Move to Next Box
          </button>
          <button
            className="submit-btn"
            style={{
              margin: "0",
              maxWidth: "200px",
              backgroundColor: "#FF0000",
            }}
            onClick={() => {
              setIsOpen(true);
              setModalIndex(17);
            }}
          >
            Conclude Audit Transaction
          </button>
        </div>
        <div className="mt-3">
          <p className="para" style={{ color: "#ff0000" }}>
            The quantity recorded in the system is 2,000, while the quantity
            verified during the audit is 1,500 reflecting a 75% accuracy and a
            25% discrepancy.
          </p>
        </div>
      </div>
    </>
  );
};

export default AuditUnitTransactions;
