import React from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { useCallback } from "react";
import "./CancelModal.css";
import { Button } from "@material-ui/core";
import { CancelPicklistAction } from "../../../Actions/PickerMgmt/PickerMgmtActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { useEffect } from "react";
import { useAlert } from "react-alert";
import { CANCEL_PICKLIST_RESET } from "../../../Constants/PickerMgmt/PickerManagementConstants";
import { useState } from "react";

const CancelModal = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { cancel_pick, loading } = useSelector((state) => state.cancelPicklist);
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const { close, picklist_id } = props;
  const closeModal = useCallback(() => {
    close(null);
  }, [close]);

  const [isHit, setIshit] = useState(false);

  const handleCancelPicklist = () => {
    setIshit(true);
    dispatch(CancelPicklistAction("cancel_picklist", token, picklist_id));
  };

  useEffect(() => {
    if (cancel_pick && cancel_pick?.status === 1 && isHit) {
      setIshit(false);
      alert.success("Picklist Cancelled Successfully...");
      closeModal();
      dispatch({
        type: CANCEL_PICKLIST_RESET,
      });
    } else if (cancel_pick && cancel_pick?.status === 0 && isHit) {
      setIshit(false);
      alert.error(cancel_pick && cancel_pick?.msg);
      closeModal();
      dispatch({
        type: CANCEL_PICKLIST_RESET,
      });
    }
  }, [dispatch, cancel_pick, alert, closeModal, isHit]);

  // console.log(picklist_id)

  return (
    <>
      <HeadingTitle
        colorRed={true}
        title="Cancel Picklist"
        closeModal={close}
      />
      <h3 className="cancel-picklist-head">
        ARE YOU SURE YOU WANT TO CANCEL PICKLIST?
      </h3>
      <div className="flex-cancel-cta">
        <Button onClick={handleCancelPicklist}>
          {loading ? <Loader /> : "YES"}
        </Button>
        <Button onClick={closeModal}>NO</Button>
      </div>
    </>
  );
};

export default CancelModal;
