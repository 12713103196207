import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./Modal.css";
import CrossIcon from "../../Assets/Icons/SideBar-Icons/cross.png";
import AddTote from "../ModalContent/AddTote/AddTote";
import DeleteTote from "../ModalContent/DeleteTote/DeleteTote";
import { useEffect } from "react";
import { useState } from "react";
import PicklistAssignmentUser from "../ModalContent/PicklistAssignmentUser/PicklistAssignmentUser";
import OrderHandoverAlert from "../ModalContent/OrderHandoverAlert/OrderHandoverAlert";
import ImageModal from "../ModalContent/ImageModal/ImageModal";
import Message from "../ModalContent/Message/Message";
import Audio from "../ModalContent/AudioModal/AudioModal";
import Printers from "../ModalContent/Printers/Printers";
import CreateOrderTable from "../ModalContent/CreateOrderTable/CreateOrderTable";
import CancelModal from "../ModalContent/CancelModal/CancelModal";
import BaseUrl from "../ModalContent/BaseUrl/BaseUrl";
import ChangeStatus from "../ModalContent/ChangeStatus/ChangeStatus";
import AddPredefinedProblem from "../ModalContent/AddPredefinedProblem/AddPredefinedProblem";
import Requestcount from "../ModalContent/RequestCount/Requestcount";
import AcceptWithoutAudit from "../ModalContent/AcceptWithoutAudit/AcceptWithoutAudit";
import AuditAlerts from "../ModalContent/AuditAlerts/AuditAlerts";
import FirstSecondBox from "../ModalContent/FirstSecondBox/FirstSecondBox";
import SecondFirstBox from "../ModalContent/SecondFirstBox/SecondFirstBox";
import ViewDetails from "../ModalContent/ViewDetails/ViewDetail";
import AlertPopup from "../ModalContent/Alert/Alert";

const ModalContainer = (props) => {
  const { index, tote_id } = props;
  const [width, setWidth] = useState(364);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: 0,
    transform: "translate(-50%, -50%)",
    width: index === 4 ? "initial" : index === 8 ? "700px" : width,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: index === 4 ? 0 : 2,
    borderRadius: 1,
    height: index === 8 ? "400px" : "auto",
    overflowY: index === 4 ? "initial" : index === 8 ? "auto" : "auto",
    border: "none",
    outline: "none",
  };

  const componentMap = {
    0: <AddTote close={props.closeModal} />,
    1: <DeleteTote id={props.id} tote_id={tote_id} close={props.closeModal} />,
    2: (
      <PicklistAssignmentUser
        order_id={props.order_id}
        picklist_id={props.isMulti ? props.order_ids : props.picklist_id}
        modalNo={props.modalNo}
        close={props.closeModal}
        handleCheckBoxRefresh={props.handleCheckBoxRefresh}
      />
    ),
    3: (
      <OrderHandoverAlert
        isMessage={props.isMessage}
        isScanned={props.isScanned}
        close={props.closeModal}
      />
    ),
    4: (
      <ImageModal
        imageuri={props.imageuri}
        close={props.closeModal}
        id={props.id}
      />
    ),
    5: (
      <Message
        message={props.message}
        problem={props.problem}
        close={props.closeModal}
        id={props.id}
      />
    ),
    6: <Audio audio={props.audio} close={props.closeModal} id={props.id} />,
    7: (
      <Printers
        printer={props.printer}
        sendDataFromParent={props.sendDataFromParent}
        close={props.closeModal}
      />
    ),
    8: (
      <CreateOrderTable
        close={props.closeModal}
        orderId={props.orderId}
        isData={props.isData}
      />
    ),
    9: <CancelModal picklist_id={props.picklist_id} close={props.closeModal} />,
    10: <BaseUrl close={props.closeModal} />,
    11: (
      <ChangeStatus
        picklist_id={props.picklist_id}
        status={props.status}
        close={props.closeModal}
      />
    ),
    12: (
      <AddPredefinedProblem
        problemId={props.problemId}
        probText={props.probText}
        close={props.closeModal}
      />
    ),
    13: (
      <Requestcount
        close={props.closeModal}
        product_id={props.product_id}
        subLocation_id={props.subLocation_id}
        Inventory_Picklist_id={props.Inventory_Picklist_id}
      />
    ),
    14: (
      <AcceptWithoutAudit
        close={props.closeModal}
        product_id={props.product_id}
        subLocation_id={props.subLocation_id}
        handleAuditAlert={props.handleAuditAlert}
      />
    ),
    15: (
      <AuditAlerts
        close={props.closeModal}
        sku_code={props.sku_code}
        Sku_Img={props.Sku_Img}
        sku_Units={props.sku_Units}
        prod_id={props.prod_id}
        subLoca_id={props.subLoca_id}
        title={props.title}
        audit_id={props.audit_id}
      />
    ),

    16: (
      <FirstSecondBox
        id={props.id}
        close={props.closeModal}
        message={props.message}
        box_no_1={props.box_no_1}
        box_no_2={props.box_no_2}
      />
    ),
    17: (
      <SecondFirstBox
        id={props.id}
        close={props.closeModal}
        message={props.message}
        box_no_1={props.box_no_1}
        box_no_2={props.box_no_2}
      />
    ),
    18: (
      <ViewDetails
        id={props.id}
        close={props.closeModal}
        box_id={props.box_id}
        product_id={props.product_id}
        details={props.details}
      />
    ),
    19: (
      <AlertPopup
        close={props.closeModal}
        title={props.title}
        message={props.message}
        boxLabel={props.boxLabel}
        handleSubmit={props.handleSubmit}
      />
    ),
  };

  useEffect(() => {
    if (index === 1) {
      setWidth(352);
    }
    if (index === 2 || index === 15) {
      setWidth(394);
    }
    if (index === 13 || index === 16 || index === 14) {
      setWidth(950);
    }
    if (index === 16 || index === 17 || index === 18) {
      setWidth(600);
    }
  }, [index]);

  return (
    <Modal
      open={props.Isopen}
      onClose={props.closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      className="modal-container"
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      style={{ overflow: "scroll" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{componentMap[index]}</Box>
    </Modal>
  );
};

export default ModalContainer;

export const HeadingTitle = (props) => {
  return (
    <div className="header-cross-title">
      <Typography
        style={{ color: props.colorRed ? "#FF0000" : "#1C1C1C" }}
        id="modal-modal-title"
        variant="h6"
        component="h2"
      >
        {props.title}
      </Typography>
      <div className="bg-cross" onClick={props.closeModal}>
        <img src={CrossIcon} alt="" />
      </div>
    </div>
  );
};
