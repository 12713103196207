import React, { useCallback, useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import DataTable from "../../Components/Table/DataTable";
import {
  home_date,
  orderWiseHeader,
} from "../../DummyArray/TableArray/TableData";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCourierWiseActions } from "../../Actions/PickerMgmt/PickerMgmtActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import { Pagination } from "../../Components/Pagination/Pagination";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import { useMemo } from "react";
import MetaData from "../../Components/Helmet/Helmet";
import Dropdown from "../../Components/Dropdown/Dropdown";

const CourierManagement = () => {
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { courier_wise_orders, loading, status } = useSelector(
    (state) => state.orderwise
  );

  // courier name list state
  const { courierName, loading: courier_loading } = useSelector(
    (state) => state.carriers
  );

  const [from_time, setfrom_time] = useState(home_date && home_date[0]?.id);
  const [from_time_label, setfrom_time_label] = useState(
    home_date && home_date[0]?.name
  );

  const [filtersTrue, setFilterTrue] = useState(false);
  const [pagination, setPagination] = useState(0);
  const [filterTotal_pages, setFilter_total_pages] = useState(0);

  const [courier_name, setCourier_name] = useState("");

  const handleCourier = (val) => {
    setFilterTrue(true);
    setCourier_name(val?.value);
  };

  let filters = useMemo(() => {
    const dataToSend = {
      from_time,
      courier_name,
    };
    // Filtering empty value
    const filter_data = Object.fromEntries(
      Object.entries(dataToSend).filter(
        ([_, value]) =>
          value !== "" && value !== null && value !== "Invalid date"
      )
    );
    return filter_data;
  }, [from_time, courier_name]);

  const onChangeFilter = (val, label) => {
    setfrom_time(val);
    setfrom_time_label(label);
  };

  //   pagination code
  const [courier_orders, setCourier_orders] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  let limit = 10;

  const fetchNextCouriers = useCallback(
    (page) => {
      setIsLoading(true);
      axios
        .post(`${BASE_URL}`, {
          service_name: "courier_wise_orders_list",
          page,
          token,
          limit,
          filters,
        })
        .then((response) => {
          setFilter_total_pages(response?.data?.total_pages);
          setCourier_orders(response?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    },
    [filters, limit, token]
  );

  useEffect(() => {
    if (filtersTrue === true) {
      fetchNextCouriers();
    } else if (filtersTrue === false && pagination === 0) {
      dispatch(
        getCourierWiseActions("courier_wise_orders_list", 1, token, 10, filters)
      );
    }
  }, [dispatch, token, filters, filtersTrue, pagination, fetchNextCouriers]);

  const handlePage = (page) => {
    setPagination(page);
    setPage(page);
    if (!filtersTrue && pagination >= 0) {
      fetchNextCouriers(page);
    }
  };

  const fetchCourierOrders = useCallback(() => {
    courier_wise_orders &&
      setCourier_orders(courier_wise_orders && courier_wise_orders?.data);
  }, [courier_wise_orders]);

  useEffect(() => {
    fetchCourierOrders();
  }, [fetchCourierOrders]);

  const [loadingCSV, setLoadingCsv] = useState(false);

  const handlePendency = async () => {
    setLoadingCsv(true);
    try {
      const response = await axios.post(`${BASE_URL}`, {
        service_name: "csv_courier_wise_orders_list",
        token,
        filters,
      });
      const csvFile = response?.data?.file;

      if (csvFile && csvFile !== "") {
        window.open(csvFile, "_blank", "noopener,noreferrer");
      } else {
        alert.error("No File Found");
      }

      setLoadingCsv(false);
    } catch (err) {
      alert.error(err);
      setLoadingCsv(false);
    }
  };

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Courier Management`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />

        <div className="wrapper-picker-container">
          {loading ? (
            <LoaderAlone />
          ) : (
            <>
              {status === 0 ? (
                <NoDataFromApi
                  height={true}
                  title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                />
              ) : (
                <>
                  <p className="portal-management">Courier Management</p>
                  <Headline
                    title="Order Wise"
                    btnTitle="Download Total Pendency"
                    select={true}
                    from_time_label={from_time_label}
                    home_date={home_date}
                    onChangeFilter={onChangeFilter}
                    handleData={handlePendency}
                    loadingCSV={loadingCSV}
                    width={true}
                  />

                  <div className="dropdown-grid-row">
                    <div>
                      <Dropdown
                        placeholder="Courier Name"
                        dropdown={courierName && courierName?.data}
                        value={courier_name}
                        onData={handleCourier}
                        // showId={true}
                        noShow={true}
                        loading={courier_loading}
                      />
                    </div>
                  </div>

                  {/* order wise table */}
                  <div className="table-wrapper">
                    <div className="table-container">
                      <DataTable
                        header={orderWiseHeader}
                        combinationofOrderWiseandProductWise={
                          courier_orders && courier_orders
                        }
                      />
                    </div>
                  </div>

                  <div className="rendering-pagination">
                    <Pagination
                      uniqueKey={`ScreenA-${page}`}
                      page={page}
                      isLoading={isLoading}
                      totalPage={
                        filtersTrue
                          ? filterTotal_pages
                          : courier_wise_orders &&
                            courier_wise_orders?.total_pages
                      }
                      onPageChange={handlePage}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourierManagement;
