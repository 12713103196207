import React, { useEffect, useRef, useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import MetaData from "../../Components/Helmet/Helmet";
import "./Bypass.css";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  add_bypassActions,
  remove_bypassActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import { useAlert } from "react-alert";
import Loader from "../../Components/Loader/Loader";

const Bypass = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  const [code, setCode] = useState("");

  // add_bypass state
  const { by_pass_add, loading: add_loading } = useSelector(
    (state) => state.bypassadd
  );

  // remove_bypass state
  const { by_pass_remove, loading: remove_loading } = useSelector(
    (state) => state.bypassremove
  );

  const inputRef = useRef(null);

  const [add_by_pass_hit, setAdd_by_pass_hit] = useState(false);
  const [remove_by_pass_hit, setremove_by_pass_hit] = useState(false);

  const handleAdd = () => {
    if (!code && code === "") {
      return alert.error("Please Enter Order Code...");
    }
    setAdd_by_pass_hit(true);
    dispatch(add_bypassActions("add_bypass_order", token, code));
  };

  const handleRemove = () => {
    if (!code && code === "") {
      return alert.error("Please Enter Order Code...");
    }
    setremove_by_pass_hit(true);
    dispatch(remove_bypassActions("remove_bypass_order", token, code));
  };

  useEffect(() => {
    if (by_pass_add && by_pass_add?.status === 0 && add_by_pass_hit) {
      alert.error(by_pass_add && by_pass_add?.msg);
    } else if (by_pass_add && by_pass_add?.status === 1 && add_by_pass_hit) {
      alert.success(by_pass_add && by_pass_add?.msg);
    }
  }, [by_pass_add, alert, add_by_pass_hit]);

  useEffect(() => {
    if (by_pass_remove && by_pass_remove?.status === 0 && remove_by_pass_hit) {
      alert.error(by_pass_remove && by_pass_remove?.msg);
    } else if (
      by_pass_remove &&
      by_pass_remove?.status === 1 &&
      remove_by_pass_hit
    ) {
      alert.success(by_pass_remove && by_pass_remove?.msg);
    }
  }, [by_pass_remove, alert, remove_by_pass_hit]);

  // console.log("By_Pass_add: ", by_pass_add);
  // console.log("By_Pass_remove: ", by_pass_remove);

  // console.log("Added Remove BYpass Api");

  useEffect(() => {
    // Focus the input field when the modal is opened
    inputRef.current.focus();
  }, []);
  return (
    <div className="grid-sidebar-row">
      <MetaData title={`Add/Remove ByPass`} />
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          <Headline title="Add remove bypass" hide={true} />

          <div className="by-pass-container">
            <div className="by-pass-fluid">
              <label htmlFor="code">Enter Code</label> <br />
              <input
                ref={inputRef}
                type="text"
                id="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <div className="add-remove-cta-container">
                <Button onClick={() => handleAdd()}>
                  {add_loading ? (
                    <Loader />
                  ) : (
                    <>
                      <AddIcon />
                      &nbsp; ADD
                    </>
                  )}
                </Button>
                <Button onClick={() => handleRemove()}>
                  {remove_loading ? (
                    <Loader />
                  ) : (
                    <>
                      <DeleteIcon />
                      &nbsp; DELETE
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bypass;
