import React, { Fragment, useEffect, useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import TabModule from "../../Components/TabModule/TabModule";
import MetaData from "../../Components/Helmet/Helmet";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getSettingsRequestActions,
  SettingsRequestActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import { useAlert } from "react-alert";
import Loader from "../../Components/Loader/Loader";
import Dropdown from "../../Components/Dropdown/Dropdown";
import LoaderAlone from "../../Components/Loader/LoaderAlone";

const selection_arr = [
  {
    id: 1,
    name: "Yes",
  },
  {
    id: 0,
    name: "No",
  },
];

const Settings = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [isToggle, setIsToggle] = useState(false);

  //   Setting State Reducers
  const { setting_data, loading } = useSelector(
    (state) => state.SettingsRequest
  );

  //   Get Settings State Reducers
  const { getsetting_data, loading: getSetting_loading } = useSelector(
    (state) => state.settingsGet
  );

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  const [value, setValue] = useState("");

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  const handleSelection = (val) => {
    setValue(val?.id);
  };

  const [isUpdated, setIsUpdated] = useState(false);

  const handleSubmit = () => {
    setIsUpdated(true);
    let arr = [
      {
        name: "bypass_secondary_picking_validation",
        value: value,
      },
    ];
    dispatch(SettingsRequestActions("update_settings", token, arr));
  };

  useEffect(() => {
    if (setting_data && setting_data?.status === 1 && isUpdated) {
      alert.success("Settings Value Updated");
      setIsUpdated(false);
    } else if (setting_data && setting_data?.status === 0 && isUpdated) {
      alert.error(setting_data?.msg);
      setIsUpdated(false);
    }
  }, [setting_data, isUpdated, alert]);

  useEffect(() => {
    if (token) {
      dispatch(getSettingsRequestActions("get_settings", token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (getsetting_data && getsetting_data?.status === 1) {
      alert.success("Settings Fetched Successfully!");
      setValue(getsetting_data && getsetting_data?.data[0]?.value);
    } else if (getsetting_data && getsetting_data?.status === 0) {
      alert.error(getsetting_data && getsetting_data?.msg);
    }
  }, [getsetting_data, alert]);

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          <MetaData title={"ByPass Secondary Picking"} />
          <Headline title="Settings" hide={true} />

          {/* Tab Module */}
          <div className="Tab-module">
            <TabModule singleOne={true} />
          </div>
          <div className="summary-box">
            {getSetting_loading ? (
              <LoaderAlone />
            ) : (
              <Fragment>
                <div className="summary-box-grid-row settings-grid-row">
                  <div>
                    <label htmlFor="bypasssecondary">
                      Bypass Secondary Picking validation if all order items are
                      not picked in secondary picking
                    </label>{" "}
                    <br />
                    <div>
                      <Dropdown
                        dropdown={selection_arr}
                        onData={handleSelection}
                        placeholder="Bypass Secondary Picking validation if all order items are not
                  picked in secondary picking"
                        value={
                          parseInt(value) === 1
                            ? "Yes"
                            : parseInt(value) === 0
                            ? "No"
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="settings-cta-container">
                  <Button onClick={handleSubmit}>
                    {loading ? <Loader /> : "Submit"}
                  </Button>
                </div>
              </Fragment>
            )}
            {/* summary Table */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
