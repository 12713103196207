import React, { useEffect } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { ReadCommunicationCountActions } from "../../../Actions/PickerMgmt/PickerMgmtActions";
import { useCallback } from "react";
import { useAlert } from "react-alert";
import { READ_COMMUNICATION_MODAL_RESET } from "../../../Constants/PickerMgmt/PickerManagementConstants";
import { useState } from "react";

const Message = (props) => {
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  const dispatch = useDispatch();
  const { communication_read_count } = useSelector(
    (state) => state.readCommunication
  );
  const { close, id } = props;

  const [show, setShow] = useState(true);

  // console.log(props.problem, props.message);

  const readCommunication = useCallback(async () => {
    if (
      props.problem !== undefined &&
      props.problem !== "" &&
      props.message !== undefined &&
      props.message !== ""
    ) {
      // console.log("1St Run");
      await dispatch(
        ReadCommunicationCountActions(
          "read_communication_alerts",
          token,
          id,
          "text"
        )
      );
      dispatch(
        ReadCommunicationCountActions(
          "read_communication_alerts",
          token,
          id,
          "problem"
        )
      );
    }
    if (props.problem !== undefined && props.problem !== "" && !props.message) {
      // console.log("2nd Run");
      dispatch(
        ReadCommunicationCountActions(
          "read_communication_alerts",
          token,
          id,
          "problem"
        )
      );
    } else if (props.message !== undefined && !props.problem) {
      // console.log("3Rd Run");
      dispatch(
        ReadCommunicationCountActions(
          "read_communication_alerts",
          token,
          id,
          "text"
        )
      );
    }
  }, [dispatch, token, id, props.message, props.problem]);

  useEffect(() => {
    readCommunication();
  }, [readCommunication]);

  useEffect(() => {
    if (
      communication_read_count &&
      communication_read_count?.status === 0 &&
      show
    ) {
      alert.error(communication_read_count && communication_read_count?.msg);
      setShow(false);
      dispatch({
        type: READ_COMMUNICATION_MODAL_RESET,
      });
    }
  }, [communication_read_count, alert, dispatch, show]);

  // console.log("Communication Id: ", communication_read_count);

  return (
    <>
      <HeadingTitle colorRed={true} title="Message" closeModal={close} />
      <div className="message">
        <h3>{props.problem}</h3>
        <p>{props.message}</p>
      </div>
    </>
  );
};

export default Message;
