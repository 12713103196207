import React, { useCallback, useEffect, useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import Headline from "../../Components/HeadlineSpecial/Headline";
import TabModule from "../../Components/TabModule/TabModule";
import "./LabelGenerator.css";
import { useDispatch, useSelector } from "react-redux";
import { getMultiOrderLabelGeneratorActions } from "../../Actions/PickerMgmt/PickerMgmtActions";
import InputField from "../../Components/InputField/InputField";
import { useAlert } from "react-alert";
import { multi_label_generator_heading } from "../../DummyArray/TableArray/TableData";
import DataTable from "../../Components/Table/DataTable";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import QzTray from "../../Components/QZTray/QzTray";
import ModalContainer from "../../Components/Modal/Modal";
import qz from "qz-tray";
import axios from "axios";
import MetaData from "../../Components/Helmet/Helmet";

const MultiLabelGenerator = () => {
  const alert = useAlert();

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  const dispatch = useDispatch();
  const { multi_label, loading } = useSelector((state) => state.multiLabel);

  const [serial_code, setSerial_code] = useState("");

  const [hit, setHit] = useState(false);

  const handleScanSerialCode = (event) => {
    if (event.key === "Enter") {
      setHit(true);
      dispatch(
        getMultiOrderLabelGeneratorActions(
          "multi_order_label_generator",
          token,
          serial_code
        )
      );
    }
  };

  useEffect(() => {
    if (multi_label && multi_label?.status === 0 && hit) {
      alert.error(multi_label && multi_label?.msg);
    } else if (multi_label && multi_label?.status === 1 && hit) {
      alert.success("Data Fetched Successfully...");
    }
  }, [multi_label, alert, hit]);

  let multi_label_data =
    serial_code && serial_code !== "" && hit
      ? multi_label && multi_label?.data
      : null;

  let items_list = multi_label_data && multi_label_data?.items;

  const [selectedPrinter, setSelectedPrinter] = useState("");

  const sendDatafromChild = (data) => {
    setSelectedPrinter(data);
    localStorage.setItem("Printer", data);
  };

  const handlePdf = () => {};

  const [printer, setPrinter] = useState([]);
  const [isIndex, setIsIndex] = useState(null);

  const closeModal = useCallback(() => {
    setIsIndex(null);
  }, []);

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          printer={printer}
          sendDataFromParent={sendDatafromChild}
        />
      );
    }
    return null;
  };

  const handleData = () => {
    // if (!qz.websocket.isActive()) {
    //   qz.websocket
    //     .connect()
    //     .then(() => {
    //       return qz.printers.find();
    //     })
    //     .then((printers) => {
    //       if (!printer.includes(printers)) {
    //         setPrinter(printers);
    //       }
    //     })
    //     .then(() => {
    //       setIsIndex(7);
    //     })
    //     .catch((err) => {
    //       alert.error(err.message);
    //     });
    // }
    if (qz.websocket.isActive()) {
      return qz.websocket.disconnect().then(() => {
        qz.websocket
          .connect()
          .then(() => {
            return qz.printers.find();
          })
          .then((printers) => {
            if (!printer.includes(printers)) {
              setPrinter(printers);
            }
          })
          .then(() => {
            setIsIndex(7);
          })
          .catch((err) => {
            alert.error(err.message);
          });
      });
    }
  };

  // signed certificate for qz tray
  useEffect(() => {
    qz.security.setCertificatePromise(function (resolve, reject) {
      //Preferred method - from server
      fetch("digital-certificate.txt", {
        cache: "no-store",
        headers: { "Content-Type": "text/plain" },
      }).then(function (data) {
        data.ok ? resolve(data.text()) : reject(data.text());
      });

      //Alternate method 1 - anonymous
      //        resolve();  // remove this line in live environment

      //Alternate method 2 - direct
      resolve(
        "-----BEGIN CERTIFICATE-----\n" +
          "MIID+TCCAuGgAwIBAgIUFJx1QkZbDOHgHa+ec24vw3o1txIwDQYJKoZIhvcNAQEL\n" +
          "BQAwgYoxCzAJBgNVBAYTAklOMQ8wDQYDVQQIDAZQdW5qYWIxETAPBgNVBAcMCEx1\n" +
          "ZGhpYW5hMQ8wDQYDVQQKDAZTZWVrZXgxEzARBgNVBAsMClNldmVucm9ja3MxDDAK\n" +
          "BgNVBAMMA0dydjEjMCEGCSqGSIb3DQEJARYUZ3J2LndlYmRldkBnbWFpbC5jb20w\n" +
          "IBcNMjMwOTIzMDUwNjMxWhgPMjA1NTAzMTgwNTA2MzFaMIGKMQswCQYDVQQGEwJJ\n" +
          "TjEPMA0GA1UECAwGUHVuamFiMREwDwYDVQQHDAhMdWRoaWFuYTEPMA0GA1UECgwG\n" +
          "U2Vla2V4MRMwEQYDVQQLDApTZXZlbnJvY2tzMQwwCgYDVQQDDANHcnYxIzAhBgkq\n" +
          "hkiG9w0BCQEWFGdydi53ZWJkZXZAZ21haWwuY29tMIIBIjANBgkqhkiG9w0BAQEF\n" +
          "AAOCAQ8AMIIBCgKCAQEAjit0o5WvhQInxccqj7/Xtqgroq6X61gWI/gSQRfpbdMM\n" +
          "6U1MqGW8qzzR5bX+VBURXcdEFokpcxY3/oz17VjPQ0D/bMensZ+EqQubQqmM56zS\n" +
          "BdMlrITCoSbT+cNvA0gB4U4d8UQ58vzZHY0zj6lNFjCZuDfHO+DG+rD9EwjngZzU\n" +
          "+rS8jOb2y7xMu5tuncku/EeEESv1LndGhAmRAfidOUDAL4zbTdLQvz9zP8xcGzas\n" +
          "UhsTBfQ9r4e1b2LKgyIm08oY/eg2qjGWyfPfbrXrzsWpnyJwdHldHMUAAnvdcCxH\n" +
          "bcXSl1upy1wAq1WO4OpPkRlnRLqC09qGWnRAyU4fUQIDAQABo1MwUTAdBgNVHQ4E\n" +
          "FgQU/AtLKKkV14bzAAu99sUVO64c3DYwHwYDVR0jBBgwFoAU/AtLKKkV14bzAAu9\n" +
          "9sUVO64c3DYwDwYDVR0TAQH/BAUwAwEB/zANBgkqhkiG9w0BAQsFAAOCAQEAAA8B\n" +
          "aLZoOZ1w8SVNVDlpSSgq4+Py917Wp1Vp7D6GN6QL0PIO0Y+k6osFXQuWCNzjjSYp\n" +
          "BO7xnr4eHxSlJ9xh4s8d5RlVhCG+F8oRNeIAFsUjj4RLy/kvJWnJI5jcmjZCKhsS\n" +
          "zKGAiki6D4vhePGU+kzezOubGN0YJC5pVpwvTdJLXdiu77IwfTYSMRuxOu9wvhhk\n" +
          "oq/HKpBIYDr5b6frej6H9/o4mCN92qZulhF0bsYhDkvE/4S9cwDaX0i9bV7Ojt17\n" +
          "FCabjHwxjgycLavqJiXCFyfRoF8CyckAVzqMyuVcCP8/3wCM603KB41+LP+c6Hds\n" +
          "D1DnIqq9EEUabJvMyQ==\n" +
          "-----END CERTIFICATE-----\n"
      );
    });

    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        // Preferred method - from server
        axios
          .get(
            "https://erp.sevenrocks.in/app/webroot/qz-local/sign-message.php",
            {
              params: { request: toSign },
              headers: { "Content-Type": "text/plain" },
            }
          )
          .then(function (response) {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              reject(response.data);
            }
          })
          .catch(function (error) {
            reject(error);
          });

        // Alternate method - unsigned
        // resolve(); // remove this line in the live environment
      };
    });
  }, []);
  // signed certificate for qz tray

  useEffect(() => {
    // Check if a connection is already open
    if (!qz.websocket.isActive()) {
      qz.websocket.connect().then(() => {
        return qz.printers.find().catch((err) => alert.error(err.message));
      });
    } else {
      // Handle the case when a connection is already open
      // You can choose to do nothing, show a message, or perform other actions here
      // alert.show("A connection with QZ Tray is already open.");
    }
  }, [alert]);

  return (
    <div className="grid-sidebar-row">
      <MetaData title={`Multi Label Generator`} />
      <div>
        <SideBar />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          <Headline
            title="Label Generator"
            btnTitle="Select Printers"
            handleData={handleData}
          />

          {/* Tab Module */}
          <div className="Tab-module">
            <TabModule ind={1} label={true} />
          </div>
          <div className="summary-box">
            <div className="grid-delivery-partner-info">
              <div className="date">
                <label htmlFor="tote_code">Scan Serial Code</label>
                <input
                  type="text"
                  value={serial_code}
                  id="tote_code"
                  onChange={(e) => setSerial_code(e.target.value)}
                  onKeyPress={handleScanSerialCode}
                />
              </div>
              <div className="date">
                <InputField
                  darked={true}
                  label="Order number"
                  value={
                    multi_label_data && multi_label_data?.order_code
                      ? multi_label_data?.order_code
                      : 0
                  }
                />
              </div>
              <div className="date">
                <InputField
                  darked={true}
                  label="AWB Number"
                  value={
                    multi_label_data && multi_label_data?.awb_number
                      ? multi_label_data?.awb_number
                      : 0
                  }
                />
              </div>
              <div className="date">
                <InputField
                  darked={true}
                  label="Portal"
                  value={
                    multi_label_data && multi_label_data?.portal
                      ? multi_label_data?.portal
                      : 0
                  }
                />
              </div>
              <div className="date">
                <InputField
                  darked={true}
                  label="Total order number qty."
                  value={
                    multi_label_data && multi_label_data?.order_qty
                      ? multi_label_data?.order_qty
                      : 0
                  }
                />
              </div>
              <div className="date">
                <InputField
                  darked={true}
                  label="Courier"
                  value={
                    multi_label_data && multi_label_data?.courier
                      ? multi_label_data?.courier
                      : 0
                  }
                />
              </div>
            </div>
            <QzTray
              selectedPrinter={selectedPrinter}
              btnTitle="Download Label and Invoice"
              onHandle={handlePdf}
              multi_pdf={
                multi_label_data?.invoice !== "" &&
                multi_label_data?.invoice !== null &&
                multi_label_data?.invoice !== undefined
                  ? multi_label_data?.invoice
                  : null
              }
              multi_pdf_label={
                multi_label_data?.label !== "" &&
                multi_label_data?.label !== null &&
                multi_label_data?.label !== undefined
                  ? multi_label_data?.label
                  : null
              }
              serial_code={serial_code}
            />
          </div>

          {loading ? (
            <LoaderAlone />
          ) : (
            <div
              className="table-wrapper-pagination"
              style={{ marginTop: "20px" }}
            >
              <div className="table-container-pagination">
                <DataTable
                  header={multi_label_generator_heading}
                  multi_products_items={items_list && items_list}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default MultiLabelGenerator;
