import React from "react";
import "./ActiveUsers.css";
import { Link } from "react-router-dom";
import { IMG_DEFAULT } from "../../Base_URL/BaseURL";

const ActiveUsers = ({ data }) => {
  return (
    <div>
      {/* main wrapper */}
      <div className="wrapper-user-grid-row">
        {data &&
          data.map((i) => {
            return (
              <div className="user" key={i.user_id}>
                <Link to={`/user-details/${i.user_id}`}>
                  <div className="tag">
                    <p>Active Users</p>
                    <div className="triangle"></div>
                  </div>
                  <div className="user-img">
                    {i.user_img ? (
                      <img
                        src={i.user_img}
                        alt=""
                        onError={(e) => (e.target.src = IMG_DEFAULT)}
                      />
                    ) : (
                      <div className="img-default">
                        <p>
                          {i?.name?.slice(0, 1)}
                          {i?.name?.split(" ")[1]?.charAt(0)}
                        </p>
                      </div>
                    )}
                  </div>
                  {/* <div className="user-details"> */}
                  <div className="user-details">
                    <div>
                      <p>User Id</p>
                    </div>
                    <div>
                      <p key={i}>:</p>
                    </div>
                    <div>
                      <p>{i.user_id}</p>
                    </div>
                  </div>
                  <div className="user-details">
                    <div>
                      <p>User Name</p>
                    </div>
                    <div>
                      <p key={i}>:</p>
                    </div>
                    <div>
                      <p>{i.name}</p>
                    </div>
                  </div>
                  <div className="user-details">
                    <div>
                      <p>Current Speed</p>
                    </div>
                    <div>
                      <p key={i}>:</p>
                    </div>
                    <div>
                      <p className="unique-color">{i.current_speed}</p>
                    </div>
                  </div>
                  <div className="user-details">
                    <div>
                      <p>Last Scan</p>
                    </div>
                    <div>
                      <p key={i}>:</p>
                    </div>
                    <div>
                      <p>{i.last_scan !== "" ? i.last_scan : 0}</p>
                    </div>
                  </div>
                  <div className="user-details">
                    <div>
                      <p>Idle Since</p>
                    </div>
                    <div>
                      <p key={i}>:</p>
                    </div>
                    <div>
                      <p>{i.idle_since !== "" ? i.idle_since : 0}</p>
                    </div>
                  </div>
                  <div className="user-details">
                    <div>
                      <p>Total Picked</p>
                    </div>
                    <div>
                      <p key={i}>:</p>
                    </div>
                    <div>
                      <p>{i.scan_qty}</p>
                    </div>
                  </div>
                  <div className="user-details">
                    <div>
                      <p>Pending Qty.</p>
                    </div>
                    <div>
                      <p key={i}>:</p>
                    </div>
                    <div>
                      <p>{i.pending_qty}</p>
                    </div>
                  </div>
                  <div className="user-details">
                    <div>
                      <p>Required Qty.</p>
                    </div>
                    <div>
                      <p key={i}>:</p>
                    </div>
                    <div>
                      <p>{i.request_qty}</p>
                    </div>
                  </div>
                  <div className="user-details">
                    <div>
                      <p>Current Location</p>
                    </div>
                    <div>
                      <p key={i}>:</p>
                    </div>
                    <div>
                      <p>
                        {i.current_location !== ""
                          ? i.current_location
                          : "No Location.."}
                      </p>
                    </div>
                  </div>
                  <div className="user-details">
                    <div>
                      <p>Next Location</p>
                    </div>
                    <div>
                      <p key={i}>:</p>
                    </div>
                    <div>
                      <p>
                        {i.next_location !== ""
                          ? i.next_location
                          : "No Location..."}
                      </p>
                    </div>
                  </div>
                </Link>
                {/* actions */}
                {/* <div className="action-btn message-top">
                  <Button>
                    <img src={MicroPhoneIcon} alt="" />
                    <p>Voice Message</p>
                  </Button>
                  <Button>
                    <img src={Comment} alt="" />
                    <p>Comments</p>
                  </Button>
                </div> */}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ActiveUsers;
