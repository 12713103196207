import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import SideBar from "../../Components/SideBar/SideBar";
import MetaData from "../../Components/Helmet/Helmet";
import Headline from "../../Components/HeadlineSpecial/Headline";
import "./LostSubLocation.css";
import BoxToBoxTransfer from "./TabContents/BoxToBoxTransfer";
import BoxQuantityAddition from "./TabContents/BoxQuantityAddition";
import BoxToSubLocationTransfer from "./TabContents/BoxToSublocationTransfer";
import SubLocationToBoxTransfer from "./TabContents/SubLocationToBoxTransfer";
import SubLocationToSubLocationTransfer from "./TabContents/SubLocationToSubLocationTransfer";

const AdvanceSubLocationTransfer = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    "Box to Box Transfer",
    "Box Quantity Additions",
    "Box to Sub Location Transfer",
    "Sub Location to Box Transfer",
    "Sub Location to Sub Location Transfer",
  ];

  const tabContent = [
    <BoxToBoxTransfer />,
    <BoxQuantityAddition />,
    <BoxToSubLocationTransfer />,
    <SubLocationToBoxTransfer />,
    <SubLocationToSubLocationTransfer />,
  ];

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Advance Sub Location Transfer`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header isToggle={isToggle} />
        <div className="wrapper-picker-container">
          <Headline
            title="Advance Sub Location Transfer"
            hide={true}
            createBtn={"Create New Session"}
            newPath={"/new"}
          />

          {/* Tab Module Component starts here */}
          <div className="Tab-module">
            <nav
              className="update-tab-module-grid-row-2"
              aria-label="Tabs"
              role="tablist"
              aria-orientation="horizontal"
            >
              {tabs.map((tab, index) => (
                <button
                  key={index}
                  type="button"
                  className={`tab-button ${
                    activeTab === index ? "active" : ""
                  }`}
                  id={`card-type-tab-item-${index + 1}`}
                  aria-selected={activeTab === index}
                  role="tab"
                  onClick={() => setActiveTab(index)}
                >
                  {tab}
                </button>
              ))}
            </nav>
          </div>

          <div>
            {tabContent.map((content, index) => (
              <div
                id={`card-type-tab-${activeTab}`}
                role="tabpanel"
                aria-labelledby={`card-type-tab-item-${activeTab}`}
                className={activeTab === index ? "" : "hidden"}
              >
                {content}
              </div>
            ))}
          </div>
          {/* Tab Module Component ends here */}
        </div>
      </div>
    </div>
  );
};

export default AdvanceSubLocationTransfer;
