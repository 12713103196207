import DataTable from "../../../Components/Table/DataTable";
import Select from "react-select";
import InputField from "../../../Components/InputField/InputField";
import { customStylesSKU } from "../../../Components/DropdownStyles/DropdownStyles";
import KeyPairs from "../../../Components/KeyPairs/KeyPairs";
import { useCallback, useEffect, useState } from "react";
import ModalContainer from "../../../Components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../Base_URL/BaseURL";

const BoxToBoxTransfer = () => {
  const navigate = useNavigate();
  const [boxName, setBoxName] = useState("");
  const [box1Data, setBox1Data] = useState({});
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [box2Name, setBox2Name] = useState("");
  const [box2Data, setBox2Data] = useState({});

  let token = localStorage.getItem("Web-api-v2-token");

  const fetchBoxDetails = useCallback(
    async (box_name, type) => {
      try {
        const { data } = await axios.post(
          `${BASE_URL.replace("/picker-portal-api", "")}/v2-web-api`,
          {
            service_name: "get_box_details",
            token,
            box_name,
          }
        );
        if (data.status === 1) {
          if (type === 1) {
            setBox1Data(data.data);
            setError("");
          } else {
            setBox2Data(data.data);
            setError2("");
          }
        } else {
          if (type === 1) {
            setError(data.msg);
          } else {
            setError2(data.msg);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  useEffect(() => {
    fetchBoxDetails(boxName, 1);
  }, [boxName, fetchBoxDetails]);

  useEffect(() => {
    fetchBoxDetails(box2Name, 2);
  }, [box2Name, fetchBoxDetails]);

  const handleChange = (name, value) => {
    if (name === "boxName") {
      setBoxName(value);
    } else {
      setBox2Name(value);
    }
  };

  const [isopen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(18);
  const [modalBoxId, setModalBoxId] = useState("");
  const [modalDetail, setModalDetail] = useState("Box");
  const [modalProductId, setModalProductId] = useState("");
  const [message, setMessage] = useState("");

  const handleModal = (i) => {
    setModalIndex(i);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const renderModal = () => {
    if (isopen) {
      return (
        <ModalContainer
          Isopen={isopen}
          index={modalIndex}
          closeModal={closeModal}
          details={modalDetail}
          box_id={modalBoxId}
          product_id={modalProductId}
          message={message}
          box_no_1={boxName}
          box_no_2={box2Name}
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderModal()}
      <div className="summary-box">
        <div className="grid-delivery-partner-info">
          <div>
            <label htmlFor="courierName">Choose Session Id</label>
            <Select
              placeholder={`Session Id`}
              //   options={
              //     carriers &&
              //     carriers.map((option) => ({
              //       value: option.name,
              //       label: option.name,
              //       id: option.id,
              //     }))
              //   }
              className="select"
              id="courierName"
              //value={carier_id}
              styles={customStylesSKU}
              // onChange={(selectedOption) => handleCourierName(selectedOption)}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              //   value={unique_handover_name}
              label="Created By"
              placeholder="Created By"
              id="created_by"
              darked={true}
              // handover={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={"Jun 15, 2024 12:34 PM"}
              label="Start Date and Time"
              placeholder="Date & Time"
              id="datetime"
              darked={true}
              // handover={true}
            />
          </div>
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={boxName}
              label="Scan First Box"
              placeholder="Scan First Box"
              id="scanbox"
              scan={true}
              readOnly={false}
              onChange={handleChange}
              field={"boxName"}
              error={boxName !== "" && error}
              // handover={true}
            />
          </div>
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <KeyPairs
            data={[
              {
                key: "Current Quantity",
                value:
                  box1Data?.box_info?.current_qty ||
                  box1Data?.box_info?.current_qty === 0
                    ? box1Data?.box_info?.current_qty
                    : "Scan Box First",
              },
              {
                key: "Initial Quantity",
                value:
                  box1Data?.box_info?.qty || box1Data?.box_info?.qty === 0
                    ? box1Data?.box_info?.qty
                    : "Scan Box First",
              },
              {
                key: "Complete Location",
                value: box1Data?.box_info?.SubLocation
                  ? `${box1Data?.box_info?.SubLocation}>>${box1Data?.box_info?.Location}`
                  : "Scan Box First",
              },
            ]}
          />
        </div>
        <div className="table-container-pagination overflow-hidden">
          <div className="overflow-auto">
            {box1Data?.products && (
              <DataTable
                header={["SKU Code", "Qty", "Action"]}
                boxboxtransfer={Object.entries(box1Data?.products).map(
                  ([product_id, product]) => ({
                    product_id,
                    box_id: box1Data?.box_info?.id,
                    details: "Box",
                    ...product,
                  })
                )}
                onData={handleModal}
                onDetail={setModalDetail}
                onHeadingIndex={setModalBoxId}
                assign_Picklist_id={setModalProductId}
              />
            )}
          </div>
        </div>
      </div>
      <div className="summary-box mt-3">
        <div className="grid-delivery-partner-info">
          <InputField
            // loading={handoverNameLoading}
            value={box2Name}
            label="Scan Second Box"
            placeholder="Scan Second Box"
            id="scanbox"
            scan={true}
            readOnly={false}
            onChange={handleChange}
            field={"box2Name"}
            error={box2Name !== "" && error2}
          />
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <KeyPairs
            data={[
              {
                key: "Current Quantity",
                value:
                  box2Data?.box_info?.current_qty ||
                  box2Data?.box_info?.current_qty === 0
                    ? box2Data?.box_info?.current_qty
                    : "Scan Box First",
              },
              {
                key: "Initial Quantity",
                value:
                  box2Data?.box_info?.qty || box2Data?.box_info?.qty === 0
                    ? box2Data?.box_info?.qty
                    : "Scan Box First",
              },
              {
                key: "Complete Location",
                value: box2Data?.box_info?.SubLocation
                  ? `${box2Data?.box_info?.SubLocation}>>${box2Data?.box_info?.Location}`
                  : "Scan Box First",
              },
            ]}
          />
        </div>
        <div className="table-container-pagination overflow-hidden">
          <div className="overflow-auto">
            {box2Data?.products && (
              <DataTable
                header={["SKU Code", "Qty", "Action"]}
                boxboxtransfer={Object.entries(box2Data?.products).map(
                  ([product_id, product]) => ({
                    product_id,
                    details: "Box",
                    box_id: box2Data?.box_info?.id,
                    ...product,
                  })
                )}
                onData={handleModal}
                onDetail={setModalDetail}
                onHeadingIndex={setModalBoxId}
                assign_Picklist_id={setModalProductId}
              />
            )}
          </div>
        </div>
      </div>
      <div className="mt-3 btn-container">
        <button
          className="submit-btn"
          onClick={() => {
            setIsOpen(true);
            setModalIndex(16);
            setMessage(
              `Are you sure you want to move ${
                box1Data?.box_info?.current_qty
              } units from Box ${boxName} to Box ${box2Name}, resulting in a total of ${
                Number(box2Data?.box_info?.current_qty) +
                Number(box1Data?.box_info?.current_qty)
              } units in Box ${box2Name}?`
            );
          }}
          disabled={boxName === "" || box2Name === ""}
          style={{ backgroundColor: "#26A6FE", margin: "0", maxWidth: "200px" }}
        >
          Put Content of First Box into Second Box
        </button>
        <button
          className="submit-btn"
          onClick={() => {
            setIsOpen(true);
            setModalIndex(17);
            setMessage(
              `Are you sure you want to move ${
                box2Data?.box_info?.current_qty
              } units from Box ${box2Name} to Box ${boxName}, resulting in a total of ${
                Number(box2Data?.box_info?.current_qty) +
                Number(box1Data?.box_info?.current_qty)
              } units in Box ${boxName}?`
            );
          }}
          style={{ margin: "0", maxWidth: "200px" }}
          disabled={boxName === "" || box2Name === ""}
        >
          Put Content of Second Box into First Box
        </button>
        <button
          className="submit-btn"
          onClick={() =>
            navigate(
              `/content-in-third-box/${boxName}/${box1Data?.box_info?.current_qty}/${box2Name}/${box2Data?.box_info?.current_qty}`
            )
          }
          style={{ backgroundColor: "#916FDF", margin: "0", maxWidth: "200px" }}
          disabled={boxName === "" || box2Name === ""}
        >
          Put Content of Both Boxes into Third Box
        </button>
      </div>
    </>
  );
};

export default BoxToBoxTransfer;
