import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import SideBar from "../../Components/SideBar/SideBar";
import "./PickerManagement.css";
import DataTable from "../../Components/Table/DataTable";
import { PaginatedHeaderTable } from "../../DummyArray/TableArray/TableData";
import { useDispatch, useSelector } from "react-redux";
import {
  add_permissionActions,
  getAllCommunicationAlertCountActions,
  getAllFiltersPickingModuleActions,
  getPickerManagementTable,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import { Pagination } from "../../Components/Pagination/Pagination";
import { useCallback } from "react";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import { useAlert } from "react-alert";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { useMemo } from "react";
import ModalContainer from "../../Components/Modal/Modal";
import { Button } from "@material-ui/core";
import MetaData from "../../Components/Helmet/Helmet";

const PickerManagement = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { pickerMgmt, loading, status } = useSelector(
    (state) => state.pickerManagement
  );
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  // pagination api will starts here
  const [pickers, setPickers] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [pagination, setPagination] = useState(0);

  const [filtersTrue, setFilterTrue] = useState(false);
  const [isFetchActive, setIsFetchActive] = useState(false);

  const fetchData = useCallback(
    (page) => {
      setIsFetchActive(true);
      setIsLoading(true);
      axios
        .post(`${BASE_URL}`, { service_name: "picker_management", page, token })
        .then((response) => {
          if (response?.data?.status === 1) {
            setPickers(response?.data?.data);
            setIsLoading(false);
          } else {
            alert.error(response?.data?.msg);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
          setIsLoading(false);
        });
    },
    [alert, token]
  );

  const handlePage = useCallback(
    (page, paginate) => {
      setPagination(page);
      setPage(page);
      if (!filtersTrue && pagination >= 0 && paginate) {
        fetchData(page);
      }
    },
    [fetchData, filtersTrue, pagination]
  );

  const fetchPickerManagement = useCallback(() => {
    pickerMgmt && setPickers(pickerMgmt && pickerMgmt?.data);
  }, [pickerMgmt]);

  useEffect(() => {
    fetchPickerManagement();
  }, [fetchPickerManagement]);

  // filter code starts here
  const [user_id, setUser_id] = useState("");

  const [picker_id, setPicker_id] = useState("");
  const [picker_name, setPicker_name] = useState("");

  const [filterTotal_pages, setFilter_total_pages] = useState(0);
  const [filter_Loading, setFilter_loading] = useState(false);
  const [isIndex, setIsIndex] = useState(null);

  const handleUserId = (e) => {
    setFilterTrue(true);
    setUser_id(e.target.value);
    handlePage(1, false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      fetchFilters();
      handlePage(1, false);
    }
  };

  const handlePickers = (val) => {
    setFilterTrue(true);
    setPicker_name(val?.value);
    setPicker_id(val?.id);
    handlePage(1, false);
  };

  let filters = useMemo(() => {
    const dataToSend = {
      picker_id,
      user_id,
    };
    // Filtering empty value
    const filter_data = Object.fromEntries(
      Object.entries(dataToSend).filter(
        ([_, value]) => value !== "" && value !== null
      )
    );
    return filter_data;
  }, [picker_id, user_id]);

  const fetchFilters = useCallback(() => {
    setFilter_loading(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "picker_management",
        token,
        page,
        filters,
      })
      .then((response) => {
        if (response && response?.data?.status === 0) {
          alert.error(response && response?.data?.msg);
        } else {
          setFilter_total_pages(response?.data?.total_pages);
          setPickers(response?.data?.data);
          setFilter_loading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setFilter_loading(false);
      });
  }, [token, page, filters, alert]);

  useEffect(() => {
    if (filtersTrue === true) {
      fetchFilters();
    } else if (filtersTrue === false && pagination === 0) {
      dispatch(getPickerManagementTable("picker_management", 1, token));
    }
  }, [dispatch, token, filtersTrue, fetchFilters, pagination]);

  let All_Filters = useMemo(() => {
    const dataToSend = ["pickers"];
    return dataToSend;
  }, []);

  const { all_filters, loading: allFilter_loading } = useSelector(
    (state) => state.allPickingModuleFilters
  );

  let pickers_list = all_filters && all_filters?.pickers;

  useEffect(() => {
    if (!filtersTrue && pagination === 0) {
      dispatch(
        getAllFiltersPickingModuleActions("filters", token, All_Filters)
      );
    }
  }, [dispatch, token, All_Filters, filtersTrue, pagination]);

  const [imageuri, setImage_uri] = useState("");

  const handleImageGallery = (image) => {
    setImage_uri(image);
    setIsIndex(4);
  };

  const [problem, setProblem] = useState("");
  const [mssg, setMssg] = useState("");
  const [id, setId] = useState("");
  const handleMessage = (problem, mssg, id) => {
    setProblem(problem);
    setMssg(mssg);
    setIsIndex(5);
    // console.log("Communication Id: ", id);
  };

  const handleCommunicationId = (id) => {
    setId(id);
  };

  const [audio, setAudio] = useState("");

  const handleAudio = (audio) => {
    setAudio(audio);
    setIsIndex(6);
  };

  const handleDesc = (mssg) => {
    setMssg(mssg);
  };

  const closeModal = () => {
    setIsIndex(null);
  };
  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          imageuri={imageuri}
          problem={problem}
          message={mssg}
          audio={audio}
          id={id}
        />
      );
    }
    return null;
  };

  const resteFilter = () => {
    setPicker_id("");
    setPicker_name("");
    setUser_id("");
  };

  useEffect(() => {
    if (isIndex === null && !isFetchActive) {
      // dispatch(getPickerManagementTable("picker_management", 1, token));
    }
  }, [isIndex, dispatch, token, isFetchActive]);

  useEffect(() => {
    if (isFetchActive || isIndex === null) {
      // fetchData(page);
      dispatch(
        getAllCommunicationAlertCountActions(
          "communication_alerts_count",
          token
        )
      );
    }
  }, [dispatch, isFetchActive, fetchData, page, isIndex, token]);

  // useEffect(() => {
  //   if (picker_id || picker_name || user_id) {
  //     handlePage(1);
  //   }
  // }, [picker_id, picker_name, user_id, handlePage]);

  // console.log("Page User on: ", page, isFetchActive);

  // permission state
  const { permission_list_data, loading: permission_loading } = useSelector(
    (state) => state.permissions_data
  );

  useEffect(() => {
    if (token) {
      dispatch(add_permissionActions("permissions", token));
    }
  }, [dispatch, token]);

  let permission_list_arr =
    permission_list_data && Array.isArray(permission_list_data?.data)
      ? permission_list_data?.data
      : [];

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Picker Management`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          {loading ? (
            <LoaderAlone />
          ) : (
            <>
              {status === 0 ? (
                <NoDataFromApi
                  height={true}
                  title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                />
              ) : (
                <>
                  {/* select dropdown */}
                  <h1>Picker Management</h1>
                  {/* filter dropdown code starts here */}
                  <div className="input-actions">
                    <div>
                      <Dropdown
                        placeholder="Picker Name"
                        dropdown={pickers_list && pickers_list}
                        value={picker_name}
                        onData={handlePickers}
                        showId={true}
                        noShow={false}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div className="date_handover">
                      <input
                        type="number"
                        name="user_id"
                        id="user_id"
                        min={0}
                        value={user_id}
                        onChange={(e) => handleUserId(e)}
                        placeholder="User Id"
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                    <div>
                      <div className="clear-filter">
                        <Button onClick={() => resteFilter()}>
                          Reset Filter
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="table-wrapper">
                    <div className="table-container">
                      <p className="result-out-only-out">
                        *Result {page} in{" "}
                        {filtersTrue
                          ? filterTotal_pages
                          : pickerMgmt && pickerMgmt?.total_pages}
                      </p>
                      <DataTable
                        PickerManagement={pickers && pickers}
                        header={PaginatedHeaderTable}
                        openGallery={handleImageGallery}
                        openMessage={handleMessage}
                        openDesc={handleDesc}
                        openAudio={handleAudio}
                        communication_id={handleCommunicationId}
                        permission_list_arr={permission_list_arr}
                        permission_loading={permission_loading}
                      />
                    </div>
                  </div>
                  <div className="rendering-pagination">
                    <Pagination
                      uniqueKey={`ScreenB-${page}`}
                      page={page}
                      isLoading={isLoading ? isLoading : filter_Loading}
                      totalPage={
                        filtersTrue
                          ? filterTotal_pages
                          : pickerMgmt && pickerMgmt?.total_pages
                      }
                      onPageChange={handlePage}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default PickerManagement;
