import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../Components/Header/Header";
import SideBar from "../../Components/SideBar/SideBar";
import Headline from "../../Components/HeadlineSpecial/Headline";
import { useNavigate } from "react-router-dom";
import TabModule from "../../Components/TabModule/TabModule";
import "./CreateReverseHandover.css";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  getReverse_inventory_invoiceListActions,
  getUniqueHandoverReversalNameActions,
  saveReversalHandoverActions,
} from "../../Actions/OrderHandoverActions/OrderHandoverActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import { useAlert } from "react-alert";
import { clearErrors } from "../../Actions/PickerMgmt/PickerMgmtActions";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import { useData } from "../../DataContext/DataContext";
import { SAVE_REVERSAL_HANDOVER_RESET } from "../../Constants/OrderHandoverConstants/GetCourierNameConstants";
import Loader from "../../Components/Loader/Loader";
import MetaData from "../../Components/Helmet/Helmet";

const CreateReverseHandover = () => {
  const { setHandover_id, setunique_reversal_handover_name } = useData();
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();

  // get unique name reversal handover state
  const { unique_handover_name, loading, status } = useSelector(
    (state) => state.uniquehandoverReversalName
  );

  // save reversal handover state
  const {
    reversal_handover,
    loading: save_reversal_loading,
    isSaved,
  } = useSelector((state) => state.saveReversal);

  const navigate = useNavigate();
  const [unique_reversal_handover_name, setUnique_reversal_handover_name] =
    useState("");
  const [remarks, setRemarks] = useState("");
  let tab_name = "reverse_handover";

  const handleRedirect = () => {
    navigate("/reverse-handover-summary");
  };

  useEffect(() => {
    dispatch(
      getUniqueHandoverReversalNameActions(
        "get_unique_reverse_handover_no",
        token
      )
    );
  }, [dispatch, token]);

  useEffect(() => {
    if (unique_handover_name && unique_handover_name?.status === 0) {
      alert.error(unique_handover_name && unique_handover_name?.msg);
      dispatch(clearErrors());
    }
  }, [dispatch, unique_handover_name, alert]);

  const fetchReversalName = useCallback(() => {
    unique_handover_name &&
      unique_handover_name?.data &&
      setUnique_reversal_handover_name(
        unique_handover_name &&
          unique_handover_name?.data?.unique_reversal_handover_name
      );
  }, [unique_handover_name]);

  useEffect(() => {
    fetchReversalName();
  }, [fetchReversalName]);

  // inventory code
  const { reverse_invoice_list_handover, loading: inventory_loading } =
    useSelector((state) => state.reverse_inventories_list);
  const [showDiv, setShowDiv] = useState(false);
  const divRef = useRef(null);

  const [search_keyword, setsearch_keyword] = useState("");

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setsearch_keyword(inputValue);

    // Check if the last character is a backspace and the input field is empty
    if (
      inputValue === "" &&
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      // Remove the last item from the ids array
      setids((prevIds) => prevIds.slice(0, -30));
      setInventory_name((prevIds) => prevIds.slice(0, -2000));
    }
  };

  // Function to handle clicks outside the div
  const handleClickOutside = (e) => {
    if (divRef.current && !divRef.current.contains(e.target)) {
      setShowDiv(false);
      setsearch_keyword("");
    }
  };

  useEffect(() => {
    if (showDiv) {
      // Add event listener when showDiv is true
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Remove event listener when showDiv is false
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDiv]);

  useEffect(() => {
    if (search_keyword.length >= 3) {
      setShowDiv(true);
    } else {
      setShowDiv(false);
    }
  }, [search_keyword, showDiv]);

  const [ids, setids] = useState([]);
  const [inventory_name, setInventory_name] = useState([]);

  // const handleIndex = (id, name, party_id) => {
  //   if (inventory_name.some((i) => i.party_id === party_id)) {
  //     return alert.error("Party Id");
  //   }
  //   if (!ids.includes(id) || !inventory_name.some((item) => item.id === id)) {
  //     setids((prevIds) => [...prevIds, id]);
  //     setInventory_name((prevNames) => [...prevNames, { id, name, party_id }]);
  //   } else {
  //     const filteredIds = ids.filter((i) => i !== id);
  //     const filteredNames = inventory_name.filter((item) => item.id !== id);
  //     setids(filteredIds);
  //     setInventory_name(filteredNames);
  //   }
  // };

  const handleIndex = (id, name, party_id) => {
    const isIdSelected = ids.includes(id);

    if (isIdSelected) {
      // If id is selected, deselect the item
      const updatedIds = ids.filter((i) => i !== id);
      const updatedNames = inventory_name.filter((item) => item.id !== id);

      setids(updatedIds);
      setInventory_name(updatedNames);
    } else {
      // If id is not selected, check for existing party_id
      if (inventory_name.some((item) => item.party_id === party_id)) {
        alert.error("Invoice For Selected Party Already Exists.");
        return;
      }

      // Select the item
      setids((prevIds) => [...prevIds, id]);
      setInventory_name((prevNames) => [...prevNames, { id, name, party_id }]);
    }
  };

  useEffect(() => {
    if (search_keyword.length >= 3) {
      dispatch(
        getReverse_inventory_invoiceListActions(
          "get_reverse_inventories_list",
          token,
          search_keyword
        )
      );
    }
  }, [dispatch, token, search_keyword]);
  // inventory code

  const saveReverseHandover = () => {
    if (unique_reversal_handover_name === "" || remarks === "") {
      alert.error("Please Fill all the Fields...");
    } else {
      dispatch(
        saveReversalHandoverActions(
          "save_reversal_handover_no",
          token,
          unique_reversal_handover_name,
          tab_name,
          remarks,
          ids
        )
      );
    }
  };

  let handover_id = reversal_handover && reversal_handover?.data?.id;
  let unique_reversal_handover_names =
    reversal_handover && reversal_handover?.data?.unique_reversal_handover_name;

  useEffect(() => {
    if (isSaved) {
      if (
        handover_id &&
        handover_id !== "" &&
        unique_reversal_handover_names &&
        unique_reversal_handover_names !== ""
      ) {
        const handoverid = { handover_id: handover_id };
        setHandover_id(handoverid);
        const reversal_name = {
          unique_reversal_handover_name: unique_reversal_handover_names,
        };
        setunique_reversal_handover_name(reversal_name);
        navigate(`/scan-orders`);
        alert.success(`Data Saved Successfully...`);
        dispatch({
          type: SAVE_REVERSAL_HANDOVER_RESET,
        });
      }
      if (reversal_handover && reversal_handover?.status === 0) {
        alert.error(reversal_handover && reversal_handover?.msg);
        dispatch(clearErrors());
      }
    }
  }, [
    dispatch,
    isSaved,
    handover_id,
    unique_reversal_handover_names,
    alert,
    navigate,
    reversal_handover,
    setHandover_id,
    setunique_reversal_handover_name,
  ]);

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Create Reverse Handover`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          {loading ? (
            <LoaderAlone />
          ) : (
            <>
              {status === 0 ? (
                <NoDataFromApi
                  height={true}
                  title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                />
              ) : (
                <>
                  <Headline
                    onData={handleRedirect}
                    title="Order Reverse"
                    btnTitle="View Summary"
                  />

                  {/* Tab Module */}
                  <div className="Tab-module">
                    <TabModule id={handover_id} ind={0} reverse={true} />
                  </div>

                  {/* summary box */}

                  <div className="summary-box">
                    <div className="create-reverse-handover">
                      <div className="date">
                        <label htmlFor="reverse_handover_unique_name">
                          Reverse handover unique name
                        </label>
                        <input
                          type="text"
                          id="reverse_handover_unique_name"
                          value={unique_reversal_handover_name}
                          onChange={(e) =>
                            setUnique_reversal_handover_name(e.target.value)
                          }
                        />
                      </div>
                      <div className="date inventory">
                        <label htmlFor="search_inventory">
                          Select Invoices
                        </label>{" "}
                        <br />
                        <input
                          placeholder="Search For Invoices"
                          type="text"
                          id="search_inventory"
                          value={search_keyword}
                          onChange={(e) => handleChange(e)}
                        />
                        {/* <button onClick={() => setShowDiv(!showDiv)}>
                          Toggle Div
                        </button> */}
                        <>
                          {showDiv && (
                            <>
                              {search_keyword?.length >= 3 && (
                                <div
                                  ref={divRef}
                                  className="background-white-list"
                                >
                                  {inventory_loading ? (
                                    <Loader />
                                  ) : (
                                    <>
                                      {reverse_invoice_list_handover &&
                                      reverse_invoice_list_handover?.data
                                        ?.length > 0 ? (
                                        reverse_invoice_list_handover?.data.map(
                                          (i) => (
                                            <div key={i.id}>
                                              <div>
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    ids.includes(i.id)
                                                      ? true
                                                      : false
                                                  }
                                                  id="checkbox"
                                                />
                                                <label
                                                  onClick={() =>
                                                    handleIndex(
                                                      i.id,
                                                      i.name,
                                                      i.party_id
                                                    )
                                                  }
                                                  htmlFor="checkbox"
                                                ></label>
                                              </div>
                                              <p
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  handleIndex(
                                                    i.id,
                                                    i.name,
                                                    i.party_id
                                                  )
                                                }
                                              >
                                                {i.name}
                                              </p>
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <p className="no-search">
                                          No Data Found...
                                        </p>
                                      )}
                                    </>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      </div>
                      <div className="list-inventory">
                        {inventory_name &&
                          inventory_name?.map((i) => (
                            <div key={i}>
                              <Button onClick={() => handleIndex(i.id, i.name)}>
                                <CloseIcon />
                              </Button>
                              <p>{i.name}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="textarea">
                      <label htmlFor="Remarks">Remarks</label>
                      <textarea
                        onChange={(e) => setRemarks(e.target.value)}
                        value={remarks}
                        id="Remarks"
                        cols="10"
                        rows="5"
                      ></textarea>
                    </div>
                    <div className="delivery-cta">
                      <Button
                        disabled={save_reversal_loading ? true : false}
                        onClick={saveReverseHandover}
                        type="submit"
                        className="save-delivery-cta"
                      >
                        {save_reversal_loading ? <Loader /> : "Submit"}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateReverseHandover;
