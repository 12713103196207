import KeyPairs from "../../../Components/KeyPairs/KeyPairs";

const BoxAudit = () => {
  return (
    <>
      <div className="summary-box">
        <div>
          <KeyPairs
            data={[
              { key: "Total No of Sub Locations", value: "438" },
              {
                key: "Total No of Boxes in this Sub Location",
                value: "5000 (2000000 Units)",
              },
            ]}
          />
        </div>
        <div className="mt-3 btn-container">
          <button
            className="submit-btn"
            style={{ margin: "0", maxWidth: "200px" }}
          >
            Start Audit Transactions
          </button>
        </div>
      </div>
    </>
  );
};

export default BoxAudit;
