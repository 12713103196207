import { useEffect, useState } from "react";
import "./KeyPairs.css";

const KeyPairs = ({ data }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {data.map((item, i) => (
        <>
          <div className="pair-container" key={i}>
            <p className="key">
              {item?.key}
              {isSmallScreen && ":"}
            </p>
            {!isSmallScreen && <p className="key">:</p>}
            <p className="key value">{item?.value}</p>
          </div>
          {data.length !== i + 1 && (
            <div
              style={{
                height: "1px",
                width: "100%",
                backgroundColor: "#e5e7eb",
              }}
            />
          )}
        </>
      ))}
    </div>
  );
};

export default KeyPairs;
