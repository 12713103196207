import React from "react";
import Loader from "../../Assets/Loader/loader-one.gif";

const LoaderAlone = (props) => {
  return (
    <div className="loader-alone">
      <img
        style={{ width: props.small ? "80px" : "initial" }}
        src={Loader}
        alt=""
      />
    </div>
  );
};

export default LoaderAlone;
