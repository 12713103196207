import React, { useEffect, useState } from "react";
import Select from "react-select-virtualized";
import { customStyles } from "../DropdownStyles/DropdownStyles";

const Dropdown = (props) => {
  const {
    dropdown,
    value,
    onData,
    handleHandoverName,
    showId,
    placeholder,
    noShow,
  } = props;

  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (val) => {
    onData && onData(val);
    handleHandoverName && handleHandoverName(val);
  };

  const clearFilter = () => {
    handleChange(null); // Clear the selected value
    setTimeout(() => {
      setSelectedValue(placeholder);
    }, 500);
  };

  let options = [];

  if (dropdown && dropdown.length > 0) {
    options = dropdown.map((option) => ({
      value: showId
        ? `${option.name} - (${option.id})`
        : option.name ||
          option.unique_handover_name ||
          option.unique_reversal_handover_name ||
          `${option.firstname} ${option.lastname}`,
      label: showId
        ? `${option.name} - (${option.id})`
        : option.name ||
          option.unique_handover_name ||
          option.unique_reversal_handover_name ||
          `${option.firstname} ${option.lastname}`,
      id: option.id,
    }));
  }

  // console.log("Dropdown: ", selectedValue, value);

  useEffect(() => {
    if (value !== null || value !== "") {
      setSelectedValue(
        value?.length > 12 && noShow ? value.substring(0, 12) + "..." : value
      );
    } else {
      setSelectedValue(placeholder);
    }
  }, [value, placeholder, noShow]);

  // Wrap the Select component with virtualized Select
  return (
    <div className="filter-dropdown-cross">
      <Select
        placeholder={selectedValue ? selectedValue : placeholder}
        options={options}
        value={selectedValue}
        backspaceRemoves={false}
        styles={customStyles}
        onChange={(selectedOption) => handleChange(selectedOption)}
        isLoading={props.loading}
      />
      {selectedValue && selectedValue !== placeholder && (
        <button onClick={clearFilter} className="filter-icon">
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-tj5bde-Svg"
          >
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
          </svg>
        </button>
      )}
    </div>
  );
};

export default Dropdown;
