import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import SideBar from "../../Components/SideBar/SideBar";
import MetaData from "../../Components/Helmet/Helmet";
import Headline from "../../Components/HeadlineSpecial/Headline";
import Dropdown from "../../Components/Dropdown/Dropdown";
import "./PackingMaterial.css";
import DataTable from "../../Components/Table/DataTable";

const ConsumptionReport = () => {
  const [daysValue, setDaysValue] = useState("Days");

  const onChangeFilter = (value, label) => {
    setDaysValue(value);
  };

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Consumption Report`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header isToggle={isToggle} />
        <div className="wrapper-picker-container">
          <Headline
            title="Consumption Report"
            btnTitle="Export CSV"
            fromtorange={{ from: "", to: "" }}
            days={true}
            daysValue={daysValue}
            onChangeFilter={onChangeFilter}
          />

          {/* Dropdown Row Starts here */}
          <div className="dropdown-container">
            <Dropdown
              placeholder="Select Portal"
              showId={true}
              noShow={false}
            />
          </div>
          {/* Dropdown Row ends here */}

          <div class="grid-container">
            <div class="card card1">
              <h3 class="title">2000</h3>
              <p class="description">Flipkart Packaging Cost Per Parcel</p>
            </div>
            <div class="card card2">
              <h3 class="title">2000</h3>
              <p class="description">Amazon Packaging Cost Per Parcel</p>
            </div>
            <div class="card card3">
              <h3 class="title">2000</h3>
              <p class="description">Myntra Packaging Cost Per Parcel</p>
            </div>
            <div class="card card4">
              <h3 class="title">2000</h3>
              <p class="description">Meesho Packaging Cost Per Parcel</p>
            </div>
            <div class="card card5">
              <h3 class="title">2000</h3>
              <p class="description">Ajio Packaging Cost Per Parcel</p>
            </div>
          </div>

          <div className="table-container">
            <DataTable
              header={[
                "Date",
                "Packing Material Name",
                "SKU",
                "Qty",
                "Total Cost",
                "Total SKU Cost",
              ]}
              consumptionReport={[
                {
                  date: "15 July 2024",
                  name: "Label",
                  sku: "SKU123",
                  qty: 10,
                  cost: 100,
                  skucost: 120,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumptionReport;
