import React from "react";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import TabModule from "../../Components/TabModule/TabModule";
import { useData } from "../../DataContext/DataContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Headline from "../../Components/HeadlineSpecial/Headline";
import { useState } from "react";
import InputField from "../../Components/InputField/InputField";
import DataTable from "../../Components/Table/DataTable";
import { reverse_handover_scanned_item_list_header } from "../../DummyArray/TableArray/TableData";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  closeReversalHandoverByIdActions,
  getCourierNameByIdActions,
  getScannedItemReversedListActions,
  scanOrderWithAwbNoRevarsalActions,
} from "../../Actions/OrderHandoverActions/OrderHandoverActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import { clearErrors } from "../../Actions/PickerMgmt/PickerMgmtActions";
import { Button } from "@material-ui/core";
import { CLOSE_REVERSAL_HANDOVER_BY_ID_RESET } from "../../Constants/OrderHandoverConstants/GetCourierNameConstants";
import { IMG_DEFAULT } from "../../Base_URL/BaseURL";
import MetaData from "../../Components/Helmet/Helmet";
import Loader from "../../Components/Loader/Loader";
// import axios from "axios";
// import secureLocalStorage from "react-secure-storage";

const ScanOrders = () => {
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const { handover_id, unique_reversal_handover_name } = useData();

  // let pass = secureLocalStorage.getItem("MNSEXESPS");
  // let user_name = secureLocalStorage.getItem("MXESNUSERN");

  // scanned item state
  const { scanned_item_images_filed, loading } = useSelector(
    (state) => state.scanReversalOrder
  );

  // get carier_name by id
  const { courier_By_Id, loading: carrier_loading } = useSelector(
    (state) => state.courierById
  );

  // get scanned item list
  const { scanned_item_list_reverse_order, loading: scanned_loading } =
    useSelector((state) => state.scanItemReverseOrderList);

  const [scanned_code, setScanned_code] = useState("");

  let id = handover_id && handover_id?.handover_id;
  let name =
    unique_reversal_handover_name &&
    unique_reversal_handover_name?.unique_reversal_handover_name;

  useEffect(() => {
    if (!id && id === null && !name && name === null) {
      navigate("/create-reverse-handover");
    }
  }, [id, name, navigate]);

  const [hit, setHit] = useState(false);
  const handleAwbNo = (event) => {
    if (event.key === "Enter") {
      setHit(true);
      if ((id && id !== "") || (scanned_code && scanned_code !== "")) {
        dispatch(
          scanOrderWithAwbNoRevarsalActions(
            "reversal_handover_order_awb_no",
            token,
            id,
            scanned_code
          )
        );
      } else {
        alert.error("Something Went Wrong");
        navigate("/create-reverse-handover");
      }
    }
  };

  let filed_data =
    scanned_item_images_filed &&
    scanned_item_images_filed?.data?.record?.order_data;

  let handover_data = filed_data && filed_data?.handover_data?.Handover;

  useEffect(() => {
    if (
      scanned_item_images_filed &&
      scanned_item_images_filed?.status === 0 &&
      hit
    ) {
      alert.error(scanned_item_images_filed && scanned_item_images_filed?.msg);
      // alert.show("ok")
      dispatch(clearErrors());
    } else if (
      scanned_item_images_filed &&
      scanned_item_images_filed?.data?.status === 0 &&
      hit
    ) {
      alert.error(
        scanned_item_images_filed &&
          scanned_item_images_filed?.data?.record?.message
      );
      dispatch(clearErrors());
    } else if (
      scanned_item_images_filed &&
      scanned_item_images_filed?.data?.status === 1 &&
      hit
    ) {
      dispatch(
        getCourierNameByIdActions(
          "get_carier_by_id",
          token,
          handover_data?.carier_id
        )
      );
      dispatch(
        getScannedItemReversedListActions(
          "get_reversed_scanned_items_list",
          token,
          id
        )
      );
      alert.success(
        scanned_item_images_filed &&
          scanned_item_images_filed?.data?.record?.message
      );
    }
  }, [
    dispatch,
    scanned_item_images_filed,
    alert,
    hit,
    token,
    id,
    handover_data?.carier_id,
  ]);

  // console.log("RR,scanned_item_images_filed && scanned_item_images_filed")

  // const [fetchInvoice_loading, setFetch_Invoice_loading] = useState(false);

  // let url = BASE_URL && BASE_URL?.replace(/\/[^/]*$/, "");

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setFetch_Invoice_loading(true);
  //     try {
  //       const batchBundleSerialCodes =
  //         scanned_item_images_filed &&
  //         scanned_item_images_filed?.data?.record?.bbsc_data
  //           ?.batch_bundle_serial_codes;

  //       // Loop through each code and make API call
  //       for (const code of batchBundleSerialCodes) {
  //         const apiData = {
  //           data: {
  //             box_no:
  //               scanned_item_images_filed &&
  //               scanned_item_images_filed?.data?.record?.bbsc_data?.box_no, // You might want to use the box_no from the first response or customize it
  //             code,
  //             inventory_id:
  //               scanned_item_images_filed &&
  //               scanned_item_images_filed?.data?.record?.bbsc_data
  //                 ?.inventory_id,
  //             sub_location_code:
  //               scanned_item_images_filed &&
  //               scanned_item_images_filed?.data?.record?.bbsc_data
  //                 ?.sub_location_code,
  //           },
  //           service_name: "inventory_scanable_incoming_save",
  //           password: pass,
  //           username: user_name,
  //         };

  //         // Make API call using Axios
  //         const response = await axios.post(`${url}/web-api`, apiData);

  //         // Process the response as needed
  //         if (
  //           response &&
  //           response?.data?.status === 1 &&
  //           response?.data?.msg === ""
  //         ) {
  //           alert.success("Serial Code In Success");
  //         } else if (
  //           response &&
  //           response?.data?.status === 1 &&
  //           response?.data?.msg !== ""
  //         ) {
  //           alert.success(response && response?.data?.msg);
  //         } else if (response?.data?.status === 0) {
  //           alert.error(response && response?.data?.msg);
  //         }
  //         // console.log(response.data);
  //         setFetch_Invoice_loading(false);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setFetch_Invoice_loading(false);
  //     }
  //   };

  //   if (
  //     scanned_item_images_filed &&
  //     scanned_item_images_filed?.data?.status === 1 &&
  //     hit
  //   ) {
  //     fetchData();
  //   }
  // }, [scanned_item_images_filed, pass, user_name, hit, url, alert]);

  useEffect(() => {
    if (
      handover_data &&
      handover_data?.carier_id &&
      handover_data?.carier_id !== ""
    ) {
      dispatch(
        getCourierNameByIdActions(
          "get_carier_by_id",
          token,
          handover_data?.carier_id
        )
      );
    }
  }, [dispatch, handover_data, token]);

  useEffect(() => {
    if (id && id !== "") {
      dispatch(
        getScannedItemReversedListActions(
          "get_reversed_scanned_items_list",
          token,
          id
        )
      );
    }
  }, [dispatch, token, id]);

  let carrier_name = courier_By_Id && courier_By_Id?.data;
  let name_carrier = carrier_name && carrier_name !== null && carrier_name[0];

  let reversed_scan_item_list =
    scanned_item_list_reverse_order && scanned_item_list_reverse_order?.data;

  let images = filed_data && filed_data?.images;

  const { close_reversed_handover_id, loading: close_loading } = useSelector(
    (state) => state.closeReversal_order
  );

  const [isHitClosed, SetIsHitClosed] = useState(false);

  const closeReversal_order = () => {
    SetIsHitClosed(true);
    dispatch(
      closeReversalHandoverByIdActions(
        "close_reversal_handover",
        token,
        handover_id
      )
    );
  };

  useEffect(() => {
    if (
      close_reversed_handover_id &&
      close_reversed_handover_id?.status === 1 &&
      isHitClosed
    ) {
      alert.success("Reverse Handover Closed Successfully...");
      dispatch({
        type: CLOSE_REVERSAL_HANDOVER_BY_ID_RESET,
      });
      SetIsHitClosed(false);
      navigate("/reverse-handover-summary");
    } else if (
      close_reversed_handover_id &&
      close_reversed_handover_id?.status === 0 &&
      isHitClosed
    ) {
      alert.error(
        close_reversed_handover_id && close_reversed_handover_id?.msg
      );
      SetIsHitClosed(false);
    }
  }, [close_reversed_handover_id, alert, dispatch, isHitClosed, navigate]);

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Scan Orders`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          {scanned_loading ? (
            <LoaderAlone />
          ) : (
            <>
              <div className="order-reverals-header">
                <Headline title="Order Reverse" hide={true} />
                <Button
                  disabled={close_loading ? true : false}
                  className="close-reversal-order"
                  onClick={closeReversal_order}
                >
                  {close_loading ? <Loader /> : "Close Reverse handover"}
                </Button>
              </div>
              <div
              // className={`${fetchInvoice_loading && "tab-module-flex-row"}`}
              >
                <div className="Tab-module">
                  <TabModule ind={1} reverse={true} />
                </div>
                {/* {fetchInvoice_loading && (
                  <div className="please-wait">
                    <p>Please Wait...</p>
                    <Loader invoice={true} />
                  </div>
                )} */}
              </div>

              <div className="summary-box">
                <div className="create-reverse-handover">
                  <div className="date">
                    <label htmlFor="awb_number">
                      Order Number/AWB Number Scan
                    </label>
                    <input
                      type="text"
                      id="awb_number"
                      value={scanned_code}
                      onChange={(e) => setScanned_code(e.target.value)}
                      onKeyPress={handleAwbNo}
                    />
                  </div>
                  <div className="date">
                    <InputField
                      darked={true}
                      label="RVS Handover Number"
                      value={name && name}
                    />
                  </div>
                </div>

                {/* images grid col starts here */}

                <div className="images-grid-reversal-order">
                  {Array.isArray(images) && images?.length > 0
                    ? images.map((i, index) => (
                        <div key={index}>
                          <img
                            onError={(e) => (e.target.src = IMG_DEFAULT)}
                            src={i.image_url}
                            alt=""
                          />
                          <p>{i.sku_code}</p>
                        </div>
                      ))
                    : null}
                </div>
              </div>

              <div className="new-order-awb-no-data">
                <div className="create-reverse-handover">
                  <InputField
                    loading={carrier_loading}
                    darked={true}
                    handover={true}
                    label="Courier Name"
                    value={
                      name_carrier && name_carrier.name !== ""
                        ? name_carrier.name
                        : 0
                    }
                  />
                  <InputField
                    darked={true}
                    label="Handover Name"
                    handover={true}
                    value={
                      handover_data &&
                      handover_data?.unique_handover_name !== ""
                        ? handover_data?.unique_handover_name
                        : 0
                    }
                  />
                  <InputField
                    handover={true}
                    darked={true}
                    label="Date"
                    value={
                      handover_data && handover_data?.handover_date !== ""
                        ? handover_data?.handover_date
                        : 0
                    }
                  />
                </div>
              </div>

              {/* Table component */}
              {loading ? (
                <LoaderAlone />
              ) : (
                <div
                  className="table-wrapper-pagination"
                  style={{ marginTop: "30px" }}
                >
                  <div className="table-container-pagination">
                    <DataTable
                      header={reverse_handover_scanned_item_list_header}
                      reversed_scanned_item_list={
                        reversed_scan_item_list && reversed_scan_item_list
                      }
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScanOrders;
