import {
  CLOSE_HANDOVER_BAG_FAILURE,
  CLOSE_HANDOVER_BAG_REQUEST,
  CLOSE_HANDOVER_BAG_RESET,
  CLOSE_HANDOVER_BAG_SUCCESS,
  CLOSE_HANDOVER_ORDER_FAILURE,
  CLOSE_HANDOVER_ORDER_REQUEST,
  CLOSE_HANDOVER_ORDER_RESET,
  CLOSE_HANDOVER_ORDER_SUCCESS,
  CLOSE_REVERSAL_HANDOVER_BY_ID_FAILURE,
  CLOSE_REVERSAL_HANDOVER_BY_ID_REQUEST,
  CLOSE_REVERSAL_HANDOVER_BY_ID_RESET,
  CLOSE_REVERSAL_HANDOVER_BY_ID_SUCCESS,
  CREATE_NEW_HANDOVER_BAG_FAILURE,
  CREATE_NEW_HANDOVER_BAG_REQUEST,
  CREATE_NEW_HANDOVER_BAG_RESET,
  CREATE_NEW_HANDOVER_BAG_SUCCESS,
  EDIT_HANDOVER_ITEM_BY_BOTH_ID_FAILURE,
  EDIT_HANDOVER_ITEM_BY_BOTH_ID_REQUEST,
  EDIT_HANDOVER_ITEM_BY_BOTH_ID_RESET,
  EDIT_HANDOVER_ITEM_BY_BOTH_ID_SUCCESS,
  EDIT_REVERSE_HANDOVER_ORDER_FAILURE,
  EDIT_REVERSE_HANDOVER_ORDER_REQUEST,
  EDIT_REVERSE_HANDOVER_ORDER_RESET,
  EDIT_REVERSE_HANDOVER_ORDER_SUCCESS,
  GET_ALL_BAG_NAME_FAILURE,
  GET_ALL_BAG_NAME_REQUEST,
  GET_ALL_BAG_NAME_SUCCESS,
  GET_ALL_HANDOVER_NAME_FILTER_FAILURE,
  GET_ALL_HANDOVER_NAME_FILTER_REQUEST,
  GET_ALL_HANDOVER_NAME_FILTER_SUCCESS,
  GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_FAILURE,
  GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_REQUEST,
  GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_SUCCESS,
  GET_BAG_MANIFEST_FAILURE,
  GET_BAG_MANIFEST_REQUEST,
  GET_BAG_MANIFEST_RESET,
  GET_BAG_MANIFEST_SUCCESS,
  GET_COUNT_OF_TOTAL_BAG_SCAN_FAILURE,
  GET_COUNT_OF_TOTAL_BAG_SCAN_REQUEST,
  GET_COUNT_OF_TOTAL_BAG_SCAN_SUCCESS,
  GET_COURIER_NAME_BY_ID_FAILURE,
  GET_COURIER_NAME_BY_ID_REQUEST,
  GET_COURIER_NAME_BY_ID_SUCCESS,
  GET_COURIER_NAME_FAILURE,
  GET_COURIER_NAME_REQUEST,
  GET_COURIER_NAME_SUCCESS,
  GET_CURRENT_BAG_QUANTITY_FAILURE,
  GET_CURRENT_BAG_QUANTITY_REQUEST,
  GET_CURRENT_BAG_QUANTITY_SUCCESS,
  GET_HANDOVER_ORDER_AWB_FAILURE,
  GET_HANDOVER_ORDER_AWB_REQUEST,
  GET_HANDOVER_ORDER_AWB_RESET,
  GET_HANDOVER_ORDER_AWB_SUCCESS,
  GET_INVENTORY_LIST_FAILURE,
  GET_INVENTORY_LIST_REQUEST,
  GET_INVENTORY_LIST_SUCCESS,
  GET_INVOICE_LIST_FOR_MATCH_FAILURE,
  GET_INVOICE_LIST_FOR_MATCH_REQUEST,
  GET_INVOICE_LIST_FOR_MATCH_SUCCESS,
  GET_ORDER_SUMMARY_FAILURE,
  GET_ORDER_SUMMARY_REQUEST,
  GET_ORDER_SUMMARY_SUCCESS,
  GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_FAILURE,
  GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_REQUEST,
  GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_SUCCESS,
  GET_REVERSE_INVENTORY_LIST_FAILURE,
  GET_REVERSE_INVENTORY_LIST_REQUEST,
  GET_REVERSE_INVENTORY_LIST_SUCCESS,
  GET_REVERSE_ORDER_HANDOVER_SUMMARY_FAILURE,
  GET_REVERSE_ORDER_HANDOVER_SUMMARY_REQUEST,
  GET_REVERSE_ORDER_HANDOVER_SUMMARY_SUCCESS,
  GET_SCAN_ITEM_LIST_FAILURE,
  GET_SCAN_ITEM_LIST_REQUEST,
  GET_SCAN_ITEM_LIST_SUCCESS,
  GET_UNIQUE_HANDOVER_NAME_FAILURE,
  GET_UNIQUE_HANDOVER_NAME_REQUEST,
  GET_UNIQUE_HANDOVER_NAME_SUCCESS,
  GET_UNIQUE_REVERSE_HANDOVER_NAME_FAILURE,
  GET_UNIQUE_REVERSE_HANDOVER_NAME_REQUEST,
  GET_UNIQUE_REVERSE_HANDOVER_NAME_SUCCESS,
  GET_USER_LIST_FAILURE,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  PRINT_LABEL_HANDOVER_FAILURE,
  PRINT_LABEL_HANDOVER_REQUEST,
  PRINT_LABEL_HANDOVER_SUCCESS,
  REVERSE_HANDOVER_BY_ITEM_FAILURE,
  REVERSE_HANDOVER_BY_ITEM_REQUEST,
  REVERSE_HANDOVER_BY_ITEM_SUCCESS,
  SAVE_DELIVERY_PARTNER_FAILURE,
  SAVE_DELIVERY_PARTNER_REQUEST,
  SAVE_DELIVERY_PARTNER_RESET,
  SAVE_DELIVERY_PARTNER_SUCCESS,
  SAVE_REVERSAL_HANDOVER_FAILURE,
  SAVE_REVERSAL_HANDOVER_REQUEST,
  SAVE_REVERSAL_HANDOVER_RESET,
  SAVE_REVERSAL_HANDOVER_SUCCESS,
  SCAN_ORDER_AWB_ORDER_NO_FAILURE,
  SCAN_ORDER_AWB_ORDER_NO_REQUEST,
  SCAN_ORDER_AWB_ORDER_NO_SUCCESS,
} from "../../Constants/OrderHandoverConstants/GetCourierNameConstants";
import { CLEAR_ERRORS } from "../../Constants/PickerMgmt/PickerManagementConstants";

// get carriers name
export const getCarrierReducers = (state = { courierName: [] }, action) => {
  switch (action.type) {
    case GET_COURIER_NAME_REQUEST:
      return {
        loading: true,
      };
    case GET_COURIER_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        courierName: action.payload,
        status: action.payload.status,
      };
    case GET_COURIER_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Unique Handover name
export const getUniqueHandoverName = (state = { handoverName: {} }, action) => {
  switch (action.type) {
    case GET_UNIQUE_HANDOVER_NAME_REQUEST:
      return {
        loading: true,
      };
    case GET_UNIQUE_HANDOVER_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        handoverName: action.payload,
        status: action.payload.status,
      };
    case GET_UNIQUE_HANDOVER_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Save Delivery Partner Reducers
export const saveDeliveryPartnerReducers = (
  state = { deliveryPartnerData: {} },
  action
) => {
  switch (action.type) {
    case SAVE_DELIVERY_PARTNER_REQUEST:
      return {
        loading: true,
      };
    case SAVE_DELIVERY_PARTNER_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryPartnerData: action.payload,
        status: action.payload.status,
        isSaved: true,
      };
    case SAVE_DELIVERY_PARTNER_RESET:
      return {
        ...state,
        loading: false,
        isSaved: false,
      };
    case SAVE_DELIVERY_PARTNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get order summary
export const getOrderHandoverSummaryReducers = (
  state = { handoverSumaary: [] },
  action
) => {
  switch (action.type) {
    case GET_ORDER_SUMMARY_REQUEST:
      return {
        loading: true,
      };
    case GET_ORDER_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        handoverSumaary: action.payload,
        status: action.payload.status,
      };
    case GET_ORDER_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Courier Name By Id
export const getCourierNameByIdReducers = (
  state = { courier_By_Id: [] },
  action
) => {
  switch (action.type) {
    case GET_COURIER_NAME_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case GET_COURIER_NAME_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        courier_By_Id: action.payload,
        status: action.payload.status,
      };
    case GET_COURIER_NAME_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Courier Name By Id
export const getScanItemListReducers = (
  state = { Scan_item_list: [] },
  action
) => {
  switch (action.type) {
    case GET_SCAN_ITEM_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_SCAN_ITEM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        Scan_item_list: action.payload,
        status: action.payload.status,
      };
    case GET_SCAN_ITEM_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Create Handover Bag
export const createHandoverBagReducers = (
  state = { create_handover: [] },
  action
) => {
  switch (action.type) {
    case CREATE_NEW_HANDOVER_BAG_REQUEST:
      return {
        loading: true,
      };
    case CREATE_NEW_HANDOVER_BAG_SUCCESS:
      return {
        ...state,
        loading: false,
        create_handover: action.payload,
        status: action.payload.status,
        isPosted: true,
      };
    case CREATE_NEW_HANDOVER_BAG_RESET:
      return {
        ...state,
        isPosted: false,
      };
    case CREATE_NEW_HANDOVER_BAG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Current Bag Quantity
export const getCurrentBagQuantityReducers = (
  state = { bag_qty: {} },
  action
) => {
  switch (action.type) {
    case GET_CURRENT_BAG_QUANTITY_REQUEST:
      return {
        loading: true,
      };
    case GET_CURRENT_BAG_QUANTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        bag_qty: action.payload.data,
        status: action.payload.status,
      };
    case GET_CURRENT_BAG_QUANTITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Current Bag Quantity
export const getAllBagReducers = (state = { all_bags_name: [] }, action) => {
  switch (action.type) {
    case GET_ALL_BAG_NAME_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_BAG_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        all_bags_name: action.payload,
        status: action.payload.status,
      };
    case GET_ALL_BAG_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Total Bag scan Quantity Reducers
export const getTotalBagScanQuantityReducers = (
  state = { total_bags_scanned: {} },
  action
) => {
  switch (action.type) {
    case GET_COUNT_OF_TOTAL_BAG_SCAN_REQUEST:
      return {
        loading: true,
      };
    case GET_COUNT_OF_TOTAL_BAG_SCAN_SUCCESS:
      return {
        ...state,
        loading: false,
        total_bags_scanned: action.payload,
        status: action.payload.status,
      };
    case GET_COUNT_OF_TOTAL_BAG_SCAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// handle order awb no reducers
export const getOrderHandoverAwbReducers = (
  state = { order_awb_no: {} },
  action
) => {
  switch (action.type) {
    case GET_HANDOVER_ORDER_AWB_REQUEST:
      return {
        loading: true,
      };
    case GET_HANDOVER_ORDER_AWB_SUCCESS:
      return {
        ...state,
        loading: false,
        order_awb_no: action.payload,
        status: action.payload.status,
        isScanned: true,
      };
    case GET_HANDOVER_ORDER_AWB_RESET:
      return {
        ...state,
        loading: false,
        isScanned: false,
      };
    case GET_HANDOVER_ORDER_AWB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get user list reducers
export const getUserListReducers = (state = { user_list: {} }, action) => {
  switch (action.type) {
    case GET_USER_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        user_list: action.payload,
        status: action.payload.status,
      };
    case GET_USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// reverse handover by id reducers
export const reverseHandoverByIdReducers = (
  state = { reverse_handover: {} },
  action
) => {
  switch (action.type) {
    case REVERSE_HANDOVER_BY_ITEM_REQUEST:
      return {
        loading: true,
      };
    case REVERSE_HANDOVER_BY_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        reverse_handover: action.payload,
        status: action.payload.status,
      };
    case REVERSE_HANDOVER_BY_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Edit Handover Item reducers
export const editHandoverItemReducers = (state = { id_item: {} }, action) => {
  switch (action.type) {
    case EDIT_HANDOVER_ITEM_BY_BOTH_ID_REQUEST:
      return {
        loading: true,
      };
    case EDIT_HANDOVER_ITEM_BY_BOTH_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        id_item: action.payload,
        status: action.payload.status,
        isRedirected: true,
      };
    case EDIT_HANDOVER_ITEM_BY_BOTH_ID_RESET:
      return {
        ...state,
        loading: false,
        isRedirected: false,
      };
    case EDIT_HANDOVER_ITEM_BY_BOTH_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Reverse Order Handover Summary Reducers
export const getReverseOrderHandoverReducers = (
  state = { reversed_handover_data: [] },
  action
) => {
  switch (action.type) {
    case GET_REVERSE_ORDER_HANDOVER_SUMMARY_REQUEST:
      return {
        loading: true,
      };
    case GET_REVERSE_ORDER_HANDOVER_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        reversed_handover_data: action.payload,
        status: action.payload.status,
      };
    case GET_REVERSE_ORDER_HANDOVER_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get unique reverse handover Reducers
export const getUniqueReverseHandoverReducers = (
  state = { unique_handover_name: {} },
  action
) => {
  switch (action.type) {
    case GET_UNIQUE_REVERSE_HANDOVER_NAME_REQUEST:
      return {
        loading: true,
      };
    case GET_UNIQUE_REVERSE_HANDOVER_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        unique_handover_name: action.payload,
        status: action.payload.status,
      };
    case GET_UNIQUE_REVERSE_HANDOVER_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// save Reversal Handover Reducers
export const SaveReversalHandoverReducers = (
  state = { reversal_handover: {} },
  action
) => {
  switch (action.type) {
    case SAVE_REVERSAL_HANDOVER_REQUEST:
      return {
        loading: true,
      };
    case SAVE_REVERSAL_HANDOVER_SUCCESS:
      return {
        ...state,
        loading: false,
        reversal_handover: action.payload,
        status: action.payload.status,
        isSaved: true,
      };
    case SAVE_REVERSAL_HANDOVER_RESET:
      return {
        ...state,
        loading: false,
        isSaved: false,
      };
    case SAVE_REVERSAL_HANDOVER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// scan order with awb or order no Reducers
export const ScanorderWithAwbNoReducers = (
  state = { scanned_item_images_filed: {} },
  action
) => {
  switch (action.type) {
    case SCAN_ORDER_AWB_ORDER_NO_REQUEST:
      return {
        loading: true,
      };
    case SCAN_ORDER_AWB_ORDER_NO_SUCCESS:
      return {
        ...state,
        loading: false,
        scanned_item_images_filed: action.payload,
        status: action.payload.status,
      };
    case SCAN_ORDER_AWB_ORDER_NO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get all handover name Reducers
export const getAllHandoverNameReducers = (
  state = { handover_name_list: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_HANDOVER_NAME_FILTER_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_HANDOVER_NAME_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        handover_name_list: action.payload,
        status: action.payload.status,
      };
    case GET_ALL_HANDOVER_NAME_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// close Bag Reducers
export const CloseHandoverBagReducers = (state = { close_bag: {} }, action) => {
  switch (action.type) {
    case CLOSE_HANDOVER_BAG_REQUEST:
      return {
        loading: true,
      };
    case CLOSE_HANDOVER_BAG_SUCCESS:
      return {
        ...state,
        loading: false,
        close_bag: action.payload,
        status: action.payload.status,
        isClosed: true,
      };
    case CLOSE_HANDOVER_BAG_RESET:
      return {
        ...state,
        loading: false,
        isClosed: false,
      };
    case CLOSE_HANDOVER_BAG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// close Bag Reducers
export const getAllHandoverNameReverseOrderReducers = (
  state = { reverse_order_summary_handover_name: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        reverse_order_summary_handover_name: action.payload,
        status: action.payload.status,
      };
    case GET_ALL_HANDOVER_NAME_REVERSE_ORDER_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get scanned Item reverse Orders Reducers
export const getScannedItemReverseOrderReducers = (
  state = { scanned_item_list_reverse_order: {} },
  action
) => {
  switch (action.type) {
    case GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_REQUEST:
      return {
        loading: true,
      };
    case GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        scanned_item_list_reverse_order: action.payload,
        status: action.payload.status,
      };
    case GET_REVERSED_SCANNED_ITEM_LIST_CONSTANTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Edit Reverse Handover Reducers
export const editReverseHandoverReducers = (
  state = { edit_reversed_order: {} },
  action
) => {
  switch (action.type) {
    case EDIT_REVERSE_HANDOVER_ORDER_REQUEST:
      return {
        loading: true,
      };
    case EDIT_REVERSE_HANDOVER_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        edit_reversed_order: action.payload,
        status: action.payload.status,
        isRedirect: true,
      };
    case EDIT_REVERSE_HANDOVER_ORDER_RESET:
      return {
        ...state,
        isRedirect: false,
      };
    case EDIT_REVERSE_HANDOVER_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Close Handover Order Reducers
export const CloseHandoverOrderReducers = (
  state = { close_handover_order: {} },
  action
) => {
  switch (action.type) {
    case CLOSE_HANDOVER_ORDER_REQUEST:
      return {
        loading: true,
      };
    case CLOSE_HANDOVER_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        close_handover_order: action.payload,
        status: action.payload.status,
        isRedirect: true,
      };
    case CLOSE_HANDOVER_ORDER_RESET:
      return {
        ...state,
        isRedirect: false,
      };
    case CLOSE_HANDOVER_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Download Handover CSV By Id Reducers
// export const DownloadHandoverCSVByIdReducers = (
//   state = { csv_handover_order: {} },
//   action
// ) => {
//   switch (action.type) {
//     case DOWNLOAD_ORDER_HANDOVER_SUMMARY_BY_ID_REQUEST:
//       return {
//         loading: true,
//       };
//     case DOWNLOAD_ORDER_HANDOVER_SUMMARY_BY_ID_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         csv_handover_order: action.payload,
//         status: action.payload.status,
//       };
//     case DOWNLOAD_ORDER_HANDOVER_SUMMARY_BY_ID_FAILURE:
//       return {
//         ...state,
//         loading: false,
//         error: action.payload.message,
//       };
//     case CLEAR_ERRORS:
//       return {
//         ...state,
//         error: null,
//       };
//     default:
//       return state;
//   }
// };

// Close Reverse Handover By Id Reducers
export const CloseReverseHandoverByIdReducers = (
  state = { close_reversed_handover_id: {} },
  action
) => {
  switch (action.type) {
    case CLOSE_REVERSAL_HANDOVER_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case CLOSE_REVERSAL_HANDOVER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        close_reversed_handover_id: action.payload,
        status: action.payload.status,
        isReversedClosed: true,
      };
    case CLOSE_REVERSAL_HANDOVER_BY_ID_RESET:
      return {
        ...state,
        loading: false,
        isReversedClosed: false,
      };
    case CLOSE_REVERSAL_HANDOVER_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Bag Manifest Reducers
export const getBagManifestReducers = (
  state = { bag_manifest: {} },
  action
) => {
  switch (action.type) {
    case GET_BAG_MANIFEST_REQUEST:
      return {
        loading: true,
      };
    case GET_BAG_MANIFEST_SUCCESS:
      return {
        ...state,
        loading: false,
        bag_manifest: action.payload,
        status: action.payload.status,
        isPrinted: true,
      };
    case GET_BAG_MANIFEST_RESET:
      return {
        ...state,
        loading: false,
        isPrinted: false,
      };
    case GET_BAG_MANIFEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Bag Manifest Reducers
export const getInventoryListReducers = (
  state = { inventory_list: [] },
  action
) => {
  switch (action.type) {
    case GET_INVENTORY_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_INVENTORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        inventory_list: action.payload,
        status: action.payload.status,
      };
    case GET_INVENTORY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Print Label bag Reducers
export const printlabelbagReducers = (state = { label_bag: {} }, action) => {
  switch (action.type) {
    case PRINT_LABEL_HANDOVER_REQUEST:
      return {
        loading: true,
      };
    case PRINT_LABEL_HANDOVER_SUCCESS:
      return {
        ...state,
        loading: false,
        label_bag: action.payload,
        status: action.payload.status,
      };
    case PRINT_LABEL_HANDOVER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Inventories for order handover Reducers
export const getInvoiceListForMatchReducers = (
  state = { invoice_list_handover: [] },
  action
) => {
  switch (action.type) {
    case GET_INVOICE_LIST_FOR_MATCH_REQUEST:
      return {
        loading: true,
      };
    case GET_INVOICE_LIST_FOR_MATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice_list_handover: action.payload,
        status: action.payload.status,
      };
    case GET_INVOICE_LIST_FOR_MATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Inventories for reverse order handover Reducers
export const getReverse_inventory_invoiceListReducers = (
  state = { reverse_invoice_list_handover: [] },
  action
) => {
  switch (action.type) {
    case GET_REVERSE_INVENTORY_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_REVERSE_INVENTORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        reverse_invoice_list_handover: action.payload,
        status: action.payload.status,
      };
    case GET_REVERSE_INVENTORY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
