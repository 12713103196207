import React, { Fragment, useMemo, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import "./DataTable.css";
import { Button, TableHead } from "@material-ui/core";
import DownloadIcon from "../../Assets/Icons/SideBar-Icons/document-download.png";
import MicrophoneIcon from "../../Assets/Icons/SideBar-Icons/microphone-2.png";
import MessageIcon from "../../Assets/Icons/SideBar-Icons/message-text.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Select from "react-select";
import DeleteIcon from "@material-ui/icons/Delete";
import InputField from "../InputField/InputField";
import {
  getOrderHandoverActions,
  getUserListActions,
} from "../../Actions/OrderHandoverActions/OrderHandoverActions";
// import TrashIcon from "../../Assets/Icons/trash.png";
import VacantIcon from "../../Assets/Icons/Order Handover/box-emp.png";
import DonwloadFileIcon from "../../Assets/Icons/Users/document-download.png";
import EditIcon from "../../Assets/Icons/Users/pencil.png";
import UserIcon from "../../Assets/Icons/Users/Ellipse5.png";
import RepeatIcon from "../../Assets/Icons/repeat.png";
import GalleryIcon from "../../Assets/Icons/SideBar-Icons/gallery.png";
import Loader from "../Loader/Loader";
import {
  allowed_status_arr,
  allowed_status_arr_if_completed,
} from "../../DummyArray/TableArray/TableData";
import { customStylesSKU } from "../DropdownStyles/DropdownStyles";
import { IMG_DEFAULT } from "../../Base_URL/BaseURL";
import LoaderAlone from "../Loader/LoaderAlone";

// xxxxxxxxxxxxxxxxxx

const DataTable = React.memo(
  ({
    data,
    header,
    dataOrders,
    PickerManagement,
    PickListManagement,
    picklistView,
    picklistData,
    PortalManagementArr,
    combinationofOrderWiseandProductWise,
    activityData,
    Actportal,
    toteData,
    onData,
    orderTable,
    orderHandoverSummary,
    onDelete,
    onSelect,
    isIndexSelectAll,
    isOk,
    onHeadingIndex,
    communication_list,
    PickListId,
    scannedItems,
    IdData,
    loading_reverse,
    TakeId,
    openGallery,
    editHandover_loading,
    reverse_order_summary,
    reversed_scanned_item_list,
    takeReverseId,
    reversed_edit_loading,
    products_items,
    openMessage,
    openDesc,
    multi_products_items,
    onId,
    loadingDownload,
    assign_Picklist_id,
    onModalIndex,
    openAudio,
    onVacant,
    loading_vacant,
    handleOrder,
    orderDetails_data,
    communication_id,
    handleStatus,
    picklist_status,
    order_items_list,
    problems_data,
    deleteProblems,
    edit_problem,
    handleReverseCsvIds,
    loadingIdCsv,
    picklist_detail_table_data,
    tote_wise_details,
    serial_code_wise_data,
    mark_lost_arr,
    handleRequestCount,
    requestCount,
    handleWithoutAudit,
    auditData,
    handleConfirmation,
    start_audit,
    handleStartAudit,
    Auditindex,
    start_audit_loading,
    permission_list_arr,
    permission_loading,
    qtyConsumptionData,
    handleQty,
    handleSKU,
    consumptionReport,
    boxboxtransfer,
    viewdetails,
    onDetail,
    sublocationTransferSummary,
    sessionSummary,
    auditTransactionSummary,
    handlePDFDownload,
    LoadingPDF,
  }) => {
    const dispatch = useDispatch();
    const { courierName } = useSelector((state) => state.carriers);
    const { user_list } = useSelector((state) => state.userList);
    // const [isOpen, setIsOpen] = useState(false);

    const handleModalOpen = (index, i, picklist_id) => {
      // setIsOpen(isOpen);
      onData(index);
      onHeadingIndex(i);
      assign_Picklist_id(picklist_id);
    };

    const handleViewDetails = (index, id, product_id, detail) => {
      onData(index);
      onHeadingIndex(id);
      onDetail(detail);
      assign_Picklist_id(product_id);
    };

    const handleSecondaryAssignment = (i, mN, id_order) => {
      onModalIndex(i, mN, id_order);
    };

    const handleCancel = (i, index) => {
      PickListId(i, index);
    };

    const [isChecked, setIsChecked] = useState(false);
    const [isIndex, setIsIndex] = useState([]);

    const handleCheckBox = () => {
      setIsChecked(!isChecked);
    };

    const [vacantIndex, setVacantIndex] = useState();

    const VacantTote = (code, index) => {
      setVacantIndex(index);
      onVacant(code);
    };

    const handleIndex = (index) => {
      onSelect(index);
      if (isIndex?.includes(index) || isIndexSelectAll?.includes(index)) {
        setIsIndex((prevNum) => prevNum.filter((num) => num !== index));
      } else {
        setIsIndex((prev) => [...prev, index]);
      }
    };

    // let isAuthenticated = JSON.parse(localStorage.getItem("user"));
    // let token = isAuthenticated && isAuthenticated?.token;

    const token = useMemo(() => {
      const isAuthenticated = JSON.parse(localStorage.getItem("user"));
      return isAuthenticated?.token;
    }, []);

    useEffect(() => {
      console.log("Rendering with token:", token);
      if (token) {
        console.log("API called");
        dispatch(getOrderHandoverActions("get_cariers", token));
        dispatch(getUserListActions("get_users_list", token));
      }
    }, [dispatch, token]);

    let carriers = courierName && courierName?.data;

    const findCarrierNameById = (carier_id) => {
      const foundObject =
        carriers &&
        carriers?.length > 0 &&
        carriers.find((obj) => obj.id === carier_id);
      return foundObject ? foundObject.name : "";
    };

    const updatedArray =
      orderHandoverSummary &&
      orderHandoverSummary.map((i) => ({
        ...i,
        carier_id: findCarrierNameById(i.Handover.carier_id),
      }));

    useEffect(() => {
      if (isOk) {
        setIsIndex([]);
      }
    }, [isOk]);

    let userList_arr = user_list && user_list?.data;

    // problems

    // const [isProblem_loading, setIsProblem_loading] = useState(false);
    // const problemsModifiedData = (id, m_id) => {
    //   setIsProblem_loading(true);
    //   const foundObject =
    //     userList_arr &&
    //     userList_arr?.length > 0 &&
    //     userList_arr.find((obj) => obj.id === id || obj.id === m_id);
    //   setIsProblem_loading(false);
    //   return foundObject
    //     ? foundObject.firstname + " " + foundObject.lastname
    //     : "";
    // };

    // const updatedProblemArray =
    //   problems_data &&
    //   problems_data.map((i) => ({
    //     ...i,
    //     created_by: problemsModifiedData(i?.created_by),
    //     modified_by: problemsModifiedData(i?.modified_by),
    //   }));

    const [isProblem_loading, setIsProblem_loading] = useState(true);
    const [updatedProblemArray, setUpdatedProblemArray] = useState([]);

    useEffect(() => {
      const problemsModifiedData = (userListArr, id, m_id) => {
        const foundObject =
          userListArr &&
          userListArr.length > 0 &&
          userListArr.find((obj) => obj.id === id || obj.id === m_id);

        return foundObject
          ? foundObject.firstname + " " + foundObject.lastname
          : "";
      };

      // Assuming userListArr is obtained from props or state

      const updatedArray =
        problems_data &&
        problems_data.map((i) => ({
          ...i,
          created_by: problemsModifiedData(userList_arr, i?.created_by),
          modified_by: problemsModifiedData(userList_arr, i?.modified_by),
        }));

      setUpdatedProblemArray(updatedArray);
      setTimeout(() => {
        setIsProblem_loading(false);
      }, 1000);
    }, [problems_data, userList_arr]);

    const findCreatedByandModifiedBy = (id, m_id) => {
      const foundObject =
        userList_arr &&
        userList_arr?.length > 0 &&
        userList_arr.find((obj) => obj.id === id || obj.id === m_id);
      return foundObject
        ? foundObject.firstname + " " + foundObject.lastname
        : "";
    };

    const updatedUsersArray =
      scannedItems &&
      scannedItems.map((i) => ({
        ...i,
        created_by_id: findCreatedByandModifiedBy(
          i?.ScannedHandoverOrder?.created_by
        ),
        modified_by_id: findCreatedByandModifiedBy(
          i?.ScannedHandoverOrder?.modified_by
        ),
      }));

    // const updatedScannedReverseArray =
    //   reversed_scanned_item_list &&
    //   reversed_scanned_item_list.map((i) => ({
    //     ...i,
    //     created_by_id: findCreatedByandModifiedBy(
    //       i?.ScannedReverseHandoverOrder?.created_by
    //     ),
    //     modified_by_id: findCreatedByandModifiedBy(
    //       i?.ScannedReverseHandoverOrder?.modified_by
    //     ),
    //   }));

    const [isMatched, setIsMatched] = useState();

    const handleHandoverId = (id, index) => {
      IdData(id);
      setIsMatched(index);
    };

    const handleImage = (image, id) => {
      openGallery(image);
      communication_id(id);
    };

    const handleAudio = (audio, id) => {
      openAudio(audio);
      communication_id(id);
    };

    const handleMessage = (problem, desc, id) => {
      openMessage(problem);
      openDesc(desc);
      communication_id(id);
    };

    const [handover_edit_index, setEdit_handover_index] = useState();
    const [reverse_handover_edit_index, setReverse_handover_edit_index] =
      useState();

    const handleUpdatedArrayId = (id, index) => {
      TakeId(id);
      setEdit_handover_index(index);
    };

    const handleReversedUpdatedArrayId = (id, index) => {
      takeReverseId(id);
      setReverse_handover_edit_index(index);
    };

    // reversed order summary
    const checkCreatedBy = (id) => {
      const foundObject =
        userList_arr &&
        userList_arr?.length > 0 &&
        userList_arr.find((obj) => obj.id === id);
      return foundObject
        ? foundObject.firstname + " " + foundObject.lastname
        : "";
    };

    const update_Reversed_summary =
      reverse_order_summary &&
      reverse_order_summary.map((i) => ({
        ...i,
        created_by: checkCreatedBy(i?.ReverseHandover?.created_by),
      }));

    const [ActiveIndex, setActiveIndex] = useState();

    const handleHandoveIdPass = (id, index) => {
      onId(id);
      setActiveIndex(index);
    };

    const handleOrderData = (data, index) => {
      handleOrder(data, index);
    };

    const [statusIndex, setStatus_index] = useState();

    const handleStatusChange = (option, index, id) => {
      handleStatus(option, id);
      setStatus_index(index);
    };

    const handleDelete = (id) => {
      deleteProblems(id);
    };

    const handleEditModal = (pro, id) => {
      edit_problem(pro, id);
    };

    const [csvIdIndex, setcsvIdIndex] = useState();

    const handleReverseCsvId = (id, index) => {
      handleReverseCsvIds(id);
      setcsvIdIndex(index);
    };

    // console.log("Permission_List_Data: ", isProblem_loading);

    const handleCountLost = (
      index,
      sub_location_id,
      product_id,
      inventory_picklist_id
    ) => {
      handleRequestCount(
        index,
        sub_location_id,
        product_id,
        inventory_picklist_id
      );
    };

    const handleWAudit = (index, sub_location_id, product_id) => {
      handleWithoutAudit(index, sub_location_id, product_id);
    };

    const handleMarkLost = (img, sku, units, prod_id, subLoc_id, index) => {
      handleConfirmation &&
        handleConfirmation(img, sku, units, prod_id, subLoc_id);
      handleStartAudit && handleStartAudit(prod_id, subLoc_id, index);
    };

    const [activeIndexDownload, setActiveIndexDownload] = useState("");

    const handlePicklistPDF = (user_id, picklist_id, index) => {
      setActiveIndexDownload(index);
      handlePDFDownload(user_id, picklist_id, index);
    };

    if (isProblem_loading) {
      return <LoaderAlone />;
    }

    return (
      <>
        <TableContainer>
          <Table className="table" aria-label="dynamic table">
            <TableHead>
              <TableRow>
                {header &&
                  header.map((i, index) => (
                    <TableCell className="header" key={index}>
                      {i}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.picklist_id}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.created_on}</TableCell>
                    <TableCell>{row.portal}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.total_qty}</TableCell>
                    <TableCell>{row.picked_qty}</TableCell>
                    <TableCell>{row.pending_qty}</TableCell>
                    <TableCell>{row.provide_type}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell className="action-btns">
                      <img
                        onClick={() => alert(row.id)}
                        src={DownloadIcon}
                        alt=""
                      />
                    </TableCell>
                  </TableRow>
                ))}

              {dataOrders &&
                dataOrders.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.order_code}
                    </TableCell>
                    <TableCell>{row.tracking_no}</TableCell>
                    <TableCell>{row.total_qty}</TableCell>
                    <TableCell>{row.picked_qty}</TableCell>
                    <TableCell>{row.courier_name}</TableCell>
                    <TableCell>{row.portal}</TableCell>
                    <TableCell className="action-btns">
                      <img
                        onClick={() => alert(row.id)}
                        src={DownloadIcon}
                        alt=""
                      />
                    </TableCell>
                  </TableRow>
                ))}

              {picklistView &&
                picklistView.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <img
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                        }}
                        src={row.image}
                        alt=""
                        onError={(e) => (e.target.src = IMG_DEFAULT)}
                      />
                    </TableCell>
                    <TableCell>{row.sku}</TableCell>
                    <TableCell>{row.parent_sku}</TableCell>
                    <TableCell>{row.required}</TableCell>
                    <TableCell>{row.picked}</TableCell>
                    <TableCell>{row.pending}</TableCell>
                    <TableCell className="action-btns">
                      <img
                        // onClick={() => alert(row.SKU)}
                        src={DownloadIcon}
                        alt=""
                      />
                    </TableCell>
                  </TableRow>
                ))}

              {order_items_list &&
                order_items_list.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.order_id}
                    </TableCell>
                    <TableCell>{row.order_code}</TableCell>
                    <TableCell>{row.tracking_no}</TableCell>
                    <TableCell>{row.sku}</TableCell>
                    <TableCell>{row.party_sku}</TableCell>
                    <TableCell>{row.old_sku}</TableCell>
                    <TableCell>{row.facility_code}</TableCell>
                    <TableCell>{row.sub_order_code}</TableCell>
                    <TableCell>{row.courier_name}</TableCell>
                  </TableRow>
                ))}

              {PickerManagement &&
                PickerManagement.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Link
                        to={`/user-details/${row.user_id}`}
                        className="img-col"
                      >
                        <img
                          src={row.user_img}
                          onError={(e) => (e.target.src = IMG_DEFAULT)}
                          alt=""
                        />{" "}
                        <p>{row.name}</p> -<p>{row.user_id}</p>
                      </Link>
                    </TableCell>
                    <TableCell>{!row.scan_qty ? "0" : row.scan_qty}</TableCell>
                    <TableCell>
                      {!row.pending_qty ? "0" : row.pending_qty}
                    </TableCell>
                    <TableCell>
                      {!row.request_qty ? "0" : row.request_qty}
                    </TableCell>
                    <TableCell>
                      {!row.current_speed ? "0" : row.current_speed}
                    </TableCell>
                    <TableCell>
                      {!row.last_scan ? "0" : row.last_scan}
                    </TableCell>
                    <TableCell>
                      {!row.idle_since ? "0" : row.idle_since}
                    </TableCell>
                    <TableCell>
                      <Link className="communication-alert-cta">
                        {row?.image?.length <= 0 ? null : (
                          <Button
                            className="image-commu CTA-1"
                            onClick={() =>
                              handleImage(row.image, row.report_id)
                            }
                          >
                            <img src={GalleryIcon} alt="" />
                            {row.image_seen === 1 ? null : (
                              <div>{row?.image?.length}</div>
                            )}
                          </Button>
                        )}
                        {row.audio === "" ? null : (
                          <Button
                            className="image-commu CTA-2"
                            onClick={() =>
                              handleAudio(row.audio, row.report_id)
                            }
                          >
                            <img src={MicrophoneIcon} alt="" />
                            {row.audio_seen === 1 ? null : (
                              <div>{row.audio !== "" ? 1 : 0}</div>
                            )}
                          </Button>
                        )}
                        {row.problem !== "" || row.text !== "" ? (
                          <Button
                            className="image-commu CTA-3"
                            onClick={() =>
                              handleMessage(
                                row.problem,
                                row.text,
                                row.report_id
                              )
                            }
                          >
                            <img src={MessageIcon} alt="" />
                            {row.text_seen === 1 ||
                            row.problem_seen === 1 ? null : (
                              <div>
                                {row.problem !== "" || row.text !== "" ? 1 : 0}
                              </div>
                            )}
                          </Button>
                        ) : null}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}

              {PickListManagement &&
                PickListManagement.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.is_cancel === 1 || row.completed === 1 ? null : (
                        <>
                          <input
                            onChange={() => handleCheckBox(row.picklist_id)}
                            type="checkbox"
                            checked={
                              isIndex.includes(row.picklist_id) ||
                              isIndexSelectAll.includes(row.picklist_id)
                                ? true
                                : false
                            }
                            id="checkbox"
                          />
                          <label
                            onClick={() => handleIndex(row.picklist_id)}
                            htmlFor="checkbox"
                          ></label>
                        </>
                      )}
                      &nbsp;&nbsp;
                      {row.user_id}
                    </TableCell>
                    <TableCell>
                      <Link
                        className="img-col break-word-user"
                        to={`${
                          row.assigned === 0
                            ? ""
                            : `/user-details/${row.user_id}`
                        }`}
                      >
                        {row.assigned === 0 ? null : (
                          <>
                            {row.user_img ? (
                              <img
                                className="user_img"
                                src={row.user_img}
                                alt=""
                                onError={(e) => (e.target.src = IMG_DEFAULT)}
                              />
                            ) : (
                              <div
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  borderRadius: "50%",
                                  backgroundColor: "#D9D9D9",
                                }}
                              ></div>
                            )}{" "}
                          </>
                        )}
                        <p>
                          {row.assigned === 0 ? "Not Assigned" : row.user_name}
                        </p>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        className="img-col break-wrap-word"
                        to={`/picklist-view/${row.picklist_id}`}
                      >
                        {row.picklist_id} / {row.name}
                      </Link>{" "}
                      <br /> {row.type} <br /> <br />
                      {row.created_by}
                    </TableCell>
                    <TableCell>{row.request_qty}</TableCell>
                    <TableCell>{row.scan_qty}</TableCell>
                    <TableCell>{row.pending_qty}</TableCell>
                    <TableCell>{row.qty}</TableCell>
                    <TableCell>{row.portal}</TableCell>
                    <TableCell>
                      {" "}
                      {row.created_on.split(" ")[0]} <br />
                      {row.created_on.split(" ").slice(1).join(" ")}{" "}
                    </TableCell>
                    <TableCell>{row.provide_type}</TableCell>
                    <TableCell>
                      <Link className="break-wrap-word">
                        {row.lost_marked_sublocation}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <div className="actions-btns all-btns">
                        {row.assignable === 1 && (
                          <>
                            {row.assigned === 0 ? (
                              <Button
                                disabled={row.is_cancel === 1 ? true : false}
                                onClick={() =>
                                  handleModalOpen(2, 0, row.picklist_id)
                                }
                                className={`${
                                  row.assigned === 1
                                    ? "not-assing-btn"
                                    : "assign-btn"
                                }`}
                              >
                                {row.assigned === 0
                                  ? "Assign"
                                  : row.assigned === 1
                                  ? "ReAssign"
                                  : null}
                              </Button>
                            ) : row.assigned === 1 ? (
                              <Button
                                // disabled={
                                //   row.is_cancel === 1 || row.completed === 1
                                //     ? true
                                //     : false
                                // }
                                onClick={() =>
                                  handleModalOpen(2, 1, row.picklist_id)
                                }
                                style={{
                                  display:
                                    row.is_cancel === 1 || row.completed === 1
                                      ? "none"
                                      : "block",
                                }}
                                className={`${
                                  row.assigned === 1
                                    ? "not-assing-btn"
                                    : "assign-btn"
                                }`}
                              >
                                {row.assigned === 0
                                  ? "Assign"
                                  : row.assigned === 1
                                  ? "ReAssign"
                                  : null}
                              </Button>
                            ) : null}
                          </>
                        )}
                        <Button
                          className="download-pdf-cta"
                          onClick={() =>
                            handlePicklistPDF(
                              row.user_id,
                              row.picklist_id,
                              index
                            )
                          }
                          // disabled={row.pdf ? false : true}
                        >
                          {/* <Link className="pdf" to={row.pdf} target="_blank"> */}
                          {activeIndexDownload === index && LoadingPDF ? (
                            <Loader />
                          ) : (
                            "Download"
                          )}
                          {/* </Link> */}
                        </Button>
                        {permission_list_arr &&
                        permission_list_arr?.find(
                          (item) => item.name === "cancel_picklist"
                        ) ? (
                          <Button
                            className="cancel-cta"
                            disabled={
                              row.is_cancel === 1 || row.completed === 1
                                ? true
                                : false
                            }
                            style={{
                              display: row.completed === 1 ? "none" : "block",
                            }}
                            type="submit"
                            onClick={() => handleCancel(row.picklist_id, index)}
                          >
                            {permission_loading ? (
                              <Loader />
                            ) : (
                              <>
                                {row.is_cancel === 1 || row.completed === 1
                                  ? "Cancelled"
                                  : "Cancel"}
                              </>
                            )}
                          </Button>
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Select
                        className={`picklist-status ${
                          row.status === "Completed" || row.status === "Closed"
                            ? "pick-comp"
                            : row.status === "Active"
                            ? "pick-act"
                            : row.status === "Hold" || row.status === "Pending"
                            ? "pick-hold"
                            : null
                        }`}
                        placeholder={`${
                          picklist_status !== "" && statusIndex === index
                            ? picklist_status
                            : row.status
                        }`}
                        options={
                          row.status === "Completed" ||
                          row.status === "Cancelled"
                            ? allowed_status_arr_if_completed &&
                              allowed_status_arr_if_completed.map((option) => ({
                                value: option,
                                label: option,
                              }))
                            : allowed_status_arr &&
                              allowed_status_arr.map((option) => ({
                                value: option,
                                label: option,
                              }))
                        }
                        value={`${
                          picklist_status !== "" || statusIndex === index
                            ? picklist_status
                            : row.status
                        }`}
                        styles={customStylesSKU}
                        onChange={(selected) =>
                          handleStatusChange(selected, index, row.picklist_id)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}

              {picklistData &&
                picklistData.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {row.is_cancel === 0 && (
                          <>
                            <input
                              onChange={() => handleCheckBox(row.id)}
                              type="checkbox"
                              checked={
                                isIndex.includes(row.id) ||
                                isIndexSelectAll.includes(row.id)
                                  ? true
                                  : false
                              }
                              id="checkbox"
                            />
                            <label
                              onClick={() => handleIndex(row.id)}
                              htmlFor="checkbox"
                            ></label>
                            &nbsp;&nbsp;{row.id}
                          </>
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Link
                          className="img-col"
                          to={`/user-details/${row.user_id}`}
                        >
                          <img
                            onError={(e) => (e.target.src = IMG_DEFAULT)}
                            src={row.user_img}
                            alt=""
                          />{" "}
                          <p>{row.name}</p>
                          {row.user_id !== null && <p> - {row.user_id}</p>}
                        </Link>
                      </TableCell>
                      <TableCell>{row.portal}</TableCell>
                      <TableCell>{row.courier_name}</TableCell>
                      <TableCell>{row.tracking_no}</TableCell>
                      <TableCell>{row.order_code}</TableCell>
                      <TableCell>{row.request_qty}</TableCell>
                      <TableCell>{row.scan_qty}</TableCell>
                      <TableCell>{row.pending_qty}</TableCell>
                      <TableCell>{row.totes}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>
                        {row.assigned === 0 ? (
                          <Button
                            disabled={row.is_cancel === 1 ? true : false}
                            onClick={() =>
                              handleSecondaryAssignment(2, 0, row.id)
                            }
                            className={`${
                              row.assigned === 1
                                ? "Reassign-secondary"
                                : "assign-secondary"
                            }`}
                          >
                            {row.assigned === 1 ? "ReAssign" : "Assign"}
                          </Button>
                        ) : row.pending_qty === 0 ? null : (
                          <Button
                            disabled={row.is_cancel === 1 ? true : false}
                            onClick={() =>
                              handleSecondaryAssignment(2, 1, row.id)
                            }
                            className={`${
                              row.assigned === 1
                                ? "Reassign-secondary"
                                : "assign-secondary"
                            }`}
                          >
                            {row.assigned === 1 ? "ReAssign" : "Assign"}
                          </Button>
                        )}
                        <Button
                          style={{ marginLeft: "10px" }}
                          onClick={() => handleOrderData(row.id, 8)}
                          className="repeat-icon"
                        >
                          <img src={RepeatIcon} alt="" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}

              {PortalManagementArr &&
                PortalManagementArr.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.party_name}
                      </TableCell>
                      <TableCell>
                        <p>
                          <span className="border-before">
                            {row.orders_count}
                          </span>
                        </p>{" "}
                        <p>{row.total_units_count}</p>
                      </TableCell>
                      <TableCell>
                        {row.orders_picked + "/" + row.orders_count}
                      </TableCell>
                      <TableCell>
                        {row.label_orders_count + "/" + row.orders_picked}
                      </TableCell>
                      <TableCell>
                        {row.handover_orders_count +
                          "/" +
                          row.label_orders_count}
                      </TableCell>
                      <TableCell>
                        {row.multiorders_count + "/" + row.orders_count}
                      </TableCell>
                      <TableCell>{row.multiorder_units_count}</TableCell>
                      {/* <TableCell className="action-btns">
                      <img
                        onClick={() => alert(row.TotalOrdersCount)}
                        src={DownloadIcon}
                        alt=""
                      />
                    </TableCell> */}
                    </TableRow>
                  );
                })}

              {combinationofOrderWiseandProductWise &&
                combinationofOrderWiseandProductWise.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.courier_name}
                      </TableCell>
                      <TableCell>
                        <p>
                          <span className="border-before">
                            {row.orders_count}
                          </span>
                        </p>{" "}
                        <p>{row.total_units_count}</p>
                      </TableCell>
                      <TableCell>
                        {row.orders_picked + "/" + row.orders_count}
                      </TableCell>
                      <TableCell>
                        {row.label_orders_count + "/" + row.orders_picked}
                      </TableCell>
                      <TableCell>
                        {row.handover_orders_count +
                          "/" +
                          row.label_orders_count}
                      </TableCell>
                      <TableCell>
                        {row.multiorders_count + "/" + row.orders_count}
                      </TableCell>
                      <TableCell>{row.multiorder_units_count}</TableCell>
                      {/* <TableCell className="action-btns">
                      <img
                        onClick={() => alert(row.CourierName)}
                        src={DownloadIcon}
                        alt=""
                      />
                    </TableCell> */}
                    </TableRow>
                  );
                })}

              {toteData &&
                toteData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.code}
                    </TableCell>
                    <TableCell>
                      {row.picklist_id !== null && row.picklist_id !== ""
                        ? row.picklist_id
                        : 0}
                    </TableCell>
                    <TableCell>
                      {row.picklist_type !== "" && row.picklist_type !== null
                        ? row.picklist_type
                        : 0}
                    </TableCell>
                    <TableCell className="img-col">
                      <img
                        onError={(e) => (e.target.src = IMG_DEFAULT)}
                        src={row.user_img}
                        alt=""
                      />{" "}
                      {row.user_name !== "" && row.user_name !== null
                        ? row.user_name
                        : "No Name..."}
                    </TableCell>
                    <TableCell>
                      {row.sub_location !== "" && row.sub_location !== null
                        ? row.sub_location
                        : "No Location..."}
                    </TableCell>
                    <TableCell>
                      {row.scan_qty !== null && row.scan_qty !== ""
                        ? row.scan_qty
                        : "0"}
                    </TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>
                      <Link style={{ display: "flex", gap: "10px" }}>
                        {/* <Button
                        onClick={() => deleteModal(row.tote_id)}
                        className="trash-icon"
                      >
                        <img src={TrashIcon} alt="" />
                      </Button> */}
                        <Button
                          disabled={
                            loading_vacant && vacantIndex === index
                              ? true
                              : false
                          }
                          onClick={() => VacantTote(row.code, index)}
                          className="trash-icon"
                        >
                          {loading_vacant && vacantIndex === index ? (
                            <Loader />
                          ) : (
                            <img
                              className="vacant-icon"
                              src={VacantIcon}
                              alt=""
                            />
                          )}
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}

              {activityData &&
                activityData.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      {/* <TableCell component="th" scope="row">
                      {row.user_id}
                    </TableCell> */}
                      <TableCell>
                        <Link
                          to={`/user-details/${row.user_id}`}
                          className="img-col"
                        >
                          <img
                            onError={(e) => (e.target.src = IMG_DEFAULT)}
                            src={row.user_img}
                            alt=""
                          />{" "}
                          <p>{row.user_name}</p> - <p>{row.user_id}</p>
                        </Link>
                      </TableCell>
                      <TableCell>
                        {Actportal ? (
                          <Link
                            className="img-col break-wrap-word"
                            to={`/picklist-view/${row.picklist_id}`}
                          >
                            {row.picklist_id} / {row.picklist_name}
                          </Link>
                        ) : (
                          <Link
                            className="img-col break-wrap-word"
                            to={`/picklist-view/${row.picklist_id}`}
                          >
                            {row.picklist_id} / {row.name}
                          </Link>
                        )}
                      </TableCell>
                      {!Actportal && <TableCell>{row.serial_code}</TableCell>}
                      <TableCell>{row.sku}</TableCell>
                      <TableCell>{row.sub_location}</TableCell>
                      <TableCell>{row.aisle}</TableCell>
                      <TableCell>{row.zone}</TableCell>
                      <TableCell>
                        {Actportal
                          ? row.lost_qty
                          : new Date(row.created_on).toLocaleString()}
                      </TableCell>
                      {Actportal && <TableCell>{row.system_qty}</TableCell>}
                    </TableRow>
                  );
                })}

              {updatedArray &&
                updatedArray.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.Handover?.unique_handover_name}
                    </TableCell>
                    <TableCell>{row?.Handover?.handover_date}</TableCell>
                    <TableCell>{row?.carier_id}</TableCell>
                    <TableCell>{row?.Handover?.created_by}</TableCell>
                    <TableCell>{row?.Handover?.scanned_order_count}</TableCell>
                    <TableCell>{row?.Handover?.no_items_in_handover}</TableCell>
                    <TableCell>{row?.Handover?.bag_count}</TableCell>
                    <TableCell>
                      <Link className="action-btns">
                        <Button
                          disabled={
                            loadingDownload && ActiveIndex === index
                              ? true
                              : false
                          }
                          onClick={() =>
                            handleHandoveIdPass(row?.Handover?.id, index)
                          }
                        >
                          {loadingDownload && ActiveIndex === index ? (
                            <Loader />
                          ) : (
                            <img src={DonwloadFileIcon} alt="" />
                          )}
                        </Button>
                        <Button
                          disabled={
                            row?.Handover?.is_closed ||
                            (handover_edit_index === index &&
                              editHandover_loading)
                              ? true
                              : false
                          }
                          type="submit"
                          onClick={() =>
                            handleUpdatedArrayId(row?.Handover?.id, index)
                          }
                        >
                          {handover_edit_index === index &&
                          editHandover_loading ? (
                            <Loader />
                          ) : (
                            <img src={EditIcon} alt="" />
                          )}
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}

              {update_Reversed_summary &&
                update_Reversed_summary.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.ReverseHandover?.unique_reversal_handover_name}
                    </TableCell>
                    <TableCell>
                      {row?.ReverseHandover?.reverse_scanned_order_count}
                    </TableCell>
                    <TableCell>
                      {row?.ReverseHandover?.no_items_in_rev_handover}
                    </TableCell>
                    <TableCell>{row?.ReverseHandover?.created}</TableCell>
                    <TableCell>{row?.created_by}</TableCell>
                    <TableCell>
                      <Link className="action-btns">
                        <Button
                          disabled={
                            loadingIdCsv && csvIdIndex === index ? true : false
                          }
                          onClick={() =>
                            handleReverseCsvId(row?.ReverseHandover?.id, index)
                          }
                        >
                          {loadingIdCsv && csvIdIndex === index ? (
                            <Loader />
                          ) : (
                            <img src={DonwloadFileIcon} alt="" />
                          )}
                        </Button>
                        <Button
                          type="submit"
                          disabled={
                            row?.ReverseHandover?.is_closed ? true : false
                          }
                          onClick={() =>
                            handleReversedUpdatedArrayId(
                              row?.ReverseHandover?.id,
                              index
                            )
                          }
                        >
                          {reverse_handover_edit_index === index &&
                          reversed_edit_loading ? (
                            <Loader />
                          ) : (
                            <img src={EditIcon} alt="" />
                          )}
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}

              {orderTable &&
                orderTable.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.show_checkbox === 1 && (
                        <>
                          <input
                            onChange={() => handleCheckBox(row.id)}
                            type="checkbox"
                            checked={
                              isIndex.includes(row.id) ||
                              isIndexSelectAll.includes(row.id)
                                ? true
                                : false
                            }
                            id="checkbox"
                          />
                          <label
                            onClick={() => handleIndex(row.id)}
                            htmlFor="checkbox"
                          ></label>
                        </>
                      )}
                      &nbsp;&nbsp;{row.id}
                    </TableCell>
                    <TableCell>{row.order_code}</TableCell>
                    <TableCell>{row.portal}</TableCell>
                    <TableCell>{row.purchase_date}</TableCell>
                    <TableCell>{row.created}</TableCell>
                    <TableCell>{row.tracking_no}</TableCell>
                    <TableCell>
                      {row?.picklists?.map((i) => (
                        <Link
                          key={i.id}
                          className="img-col"
                          to={`/picklist-view/${encodeURIComponent(
                            i.id.trim()
                          )}`}
                        >
                          {i.id} / {i.name},
                        </Link>
                      ))}
                      {/* <Link
                      className="img-col"
                      to={`/picklist-view/${row.picklist_id}`}
                    >
                      {row.picklist_id}{" "}
                      {row.picklist_id && row.picklist_name ? "/" : null}{" "}
                      {row.picklist_name}
                    </Link> */}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleOrderData(row.id, 8)}
                        className="repeat-icon"
                      >
                        <img src={RepeatIcon} alt="" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}

              {communication_list &&
                communication_list.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Link className="img-col">
                        {row.user_id}{" "}
                        <img
                          onError={(e) => (e.target.src = IMG_DEFAULT)}
                          src={row.user_img}
                          alt=""
                        />{" "}
                        <p>{row.name}</p>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        className="img-col"
                        to={`/picklist-view/${row.picklist_id}`}
                      >
                        {row.picklist_id} / {row.picklist_name}
                      </Link>
                    </TableCell>
                    <TableCell>{row.portal}</TableCell>
                    <TableCell>{row.created_on}</TableCell>
                    <TableCell>
                      <Link className="communication-alert-cta">
                        {row?.image?.length <= 0 ? null : (
                          <Button
                            className="image-commu CTA-1"
                            onClick={() => handleImage(row.image, row.id)}
                          >
                            <img src={GalleryIcon} alt="" />
                            {row.image_seen === 1 ? null : (
                              <div>{row?.image?.length}</div>
                            )}
                          </Button>
                        )}
                        {row.audio === "" ? null : (
                          <Button
                            className="image-commu CTA-2"
                            onClick={() => handleAudio(row.audio, row.id)}
                          >
                            <img src={MicrophoneIcon} alt="" />
                            {row.audio_seen === 1 ? null : (
                              <div>{row.audio !== "" ? 1 : 0}</div>
                            )}
                          </Button>
                        )}
                        {row.problem !== "" || row.text !== "" ? (
                          <Button
                            className="image-commu CTA-3"
                            onClick={() =>
                              handleMessage(row.problem, row.text, row.id)
                            }
                          >
                            <img src={MessageIcon} alt="" />
                            {row.text_seen === 1 ||
                            row.problem_seen === 1 ? null : (
                              <div>
                                {row.problem !== "" || row.text !== "" ? 1 : 0}
                              </div>
                            )}
                          </Button>
                        ) : null}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}

              {reversed_scanned_item_list &&
                reversed_scanned_item_list.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.courier_name}
                    </TableCell>
                    <TableCell>{row?.awb_number}</TableCell>
                    <TableCell>{row?.order_number}</TableCell>
                    <TableCell>{row?.forword_handover_name}</TableCell>
                    <TableCell>{row?.created_by}</TableCell>
                    <TableCell>{row?.created_on}</TableCell>
                    <TableCell>{row?.modified_by}</TableCell>
                    <TableCell>{row?.modified_on}</TableCell>
                    <TableCell>{row?.qty}</TableCell>
                  </TableRow>
                ))}

              {tote_wise_details &&
                tote_wise_details.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.tote_code}
                    </TableCell>
                    <TableCell>{row?.sub_location}</TableCell>
                    <TableCell>{row?.qty}</TableCell>
                    <TableCell>{row?.sku}</TableCell>
                    <TableCell>{row?.location}</TableCell>
                  </TableRow>
                ))}

              {serial_code_wise_data &&
                serial_code_wise_data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.tote_code}
                    </TableCell>
                    <TableCell>{row?.sub_location}</TableCell>
                    <TableCell>{row?.sku}</TableCell>
                    <TableCell>{row?.old_sku}</TableCell>
                    <TableCell>{row?.party_sku}</TableCell>
                    <TableCell>{row?.serial_code}</TableCell>
                    <TableCell>{row?.location}</TableCell>
                    <TableCell>{row?.sub_order_code}</TableCell>
                  </TableRow>
                ))}

              {updatedUsersArray &&
                updatedUsersArray.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.Handover?.unique_handover_name}
                    </TableCell>
                    <TableCell>{row?.CourierBag?.bag_name}</TableCell>
                    <TableCell>{row?.ScannedHandoverOrder?.order_no}</TableCell>
                    <TableCell>{row?.inventory_name}</TableCell>
                    <TableCell>
                      {parseInt(row?.ScannedHandoverOrder?.handover_status) ===
                      0
                        ? "No"
                        : parseInt(
                            row?.ScannedHandoverOrder?.handover_status
                          ) === 1 && "Yes"}
                    </TableCell>
                    <TableCell>
                      {parseInt(
                        row?.ScannedHandoverOrder?.reversal_handover_status
                      ) === 0
                        ? "No"
                        : parseInt(
                            row?.ScannedHandoverOrder?.reversal_handover_status
                          ) === 1 && "Yes"}
                    </TableCell>
                    <TableCell>{row?.ScannedHandoverOrder?.created}</TableCell>
                    <TableCell>{row?.created_by_id}</TableCell>
                    <TableCell>{row?.ScannedHandoverOrder?.modified}</TableCell>
                    <TableCell>{row?.modified_by_id}</TableCell>
                    <TableCell>
                      <Button
                        disabled={
                          parseInt(
                            row?.ScannedHandoverOrder?.reversal_handover_status
                          ) === 1
                            ? true
                            : false
                        }
                        onClick={() =>
                          handleHandoverId(row?.ScannedHandoverOrder?.id, index)
                        }
                        className="reverse-cta"
                      >
                        {loading_reverse && isMatched === index ? (
                          <Loader />
                        ) : (
                          "Reverse"
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}

              {products_items &&
                products_items.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <img
                        onError={(e) => (e.target.src = IMG_DEFAULT)}
                        className="product_pic"
                        src={row?.product_images[0]?.thumbnail}
                        alt=""
                      />
                    </TableCell>
                    <TableCell>{row?.order_id}</TableCell>
                    <TableCell>{row?.sequence}</TableCell>
                    <TableCell>{row?.sku}</TableCell>
                    <TableCell>{row?.parent_sku}</TableCell>
                    <TableCell>{row.serial_code}</TableCell>
                    <TableCell>{row.qty}</TableCell>
                    <TableCell>{row?.picked_by}</TableCell>
                  </TableRow>
                ))}

              {picklist_detail_table_data &&
                picklist_detail_table_data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row?.id}</TableCell>
                    <TableCell>{row?.qty}</TableCell>
                    <TableCell>{row?.found_qty}</TableCell>
                    <TableCell>{row?.lost_qty}</TableCell>
                    <TableCell>{row.scan_qty}</TableCell>
                    <TableCell>{row.sku}</TableCell>
                    <TableCell>{row?.sub_location}</TableCell>
                  </TableRow>
                ))}

              {multi_products_items &&
                multi_products_items.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <img
                        onError={(e) => (e.target.src = IMG_DEFAULT)}
                        className="product_pic"
                        src={row?.images[0]?.thumbnail}
                        alt=""
                      />
                    </TableCell>
                    <TableCell>{row?.picked_by}</TableCell>
                    <TableCell>{row?.picked_on}</TableCell>
                    <TableCell>{row?.tote}</TableCell>
                    <TableCell>{row?.serial_code}</TableCell>
                    <TableCell>Null</TableCell>
                    <TableCell>{row?.sku}</TableCell>
                  </TableRow>
                ))}

              {updatedProblemArray &&
                updatedProblemArray.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.text}</TableCell>
                    <TableCell>{row.created_by}</TableCell>
                    <TableCell>{row.modified_by}</TableCell>
                    <TableCell>{row.created}</TableCell>
                    <TableCell>{row.modified}</TableCell>
                    <TableCell>
                      <Link style={{ display: "flex", gap: "10px" }}>
                        <Button
                          onClick={() => handleEditModal(row.text, row.id)}
                          className="edit-icon"
                        >
                          <img className="vacant-icon" src={EditIcon} alt="" />
                        </Button>
                        <Button
                          className="trash-icon"
                          onClick={() => handleDelete(row.id)}
                        >
                          <DeleteIcon style={{ color: "white" }} />
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}

              {orderDetails_data &&
                orderDetails_data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.sub_order_code}
                    </TableCell>
                    <TableCell>{row?.party_sku}</TableCell>
                    <TableCell>{row?.old_sku}</TableCell>
                    <TableCell>{row?.product}</TableCell>
                    <TableCell>{row?.serial_code}</TableCell>
                  </TableRow>
                ))}

              {mark_lost_arr &&
                mark_lost_arr.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.location}
                    </TableCell>
                    <TableCell>
                      <Link className="img-no-border">
                        <img className="user_img" src={row.sku_image} alt="" />{" "}
                        {row.sku}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link className="img-col">
                        <img
                          className="user_img"
                          src={row.requested_by_img}
                          alt=""
                        />{" "}
                        {row?.requested_by_name}
                      </Link>
                    </TableCell>
                    <TableCell>{row?.requested_on}</TableCell>
                    <TableCell>
                      <Link
                        onClick={() =>
                          handleCountLost(
                            13,
                            row.sublocation_id,
                            row.product_id,
                            row.inventory_pick_list_id
                          )
                        }
                      >
                        {row?.request_count}
                      </Link>
                    </TableCell>
                    <TableCell>{row?.request_age}</TableCell>
                    <TableCell>
                      <Link className="action-btn-lost-req">
                        <Button
                          style={{
                            display:
                              parseInt(row?.status) !== 0 ? "none" : "block",
                          }}
                          onClick={() =>
                            handleWAudit(14, row.sublocation_id, row.product_id)
                          }
                        >
                          Without Audit
                        </Button>
                        <Link
                          style={{
                            display:
                              parseInt(row?.status) !== 0 ? "none" : "block",
                          }}
                          to={`/accept-request-audit/${row.product_id}/${row.sublocation_id}`}
                        >
                          <Button>With Audit</Button>
                        </Link>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}

              {requestCount &&
                requestCount.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.request_id}
                    </TableCell>
                    <TableCell>{row?.requested_by_name}</TableCell>
                    <TableCell>{row?.requested_on}</TableCell>
                    <TableCell>{row?.request_age}</TableCell>
                    <TableCell>{row?.requested_lost_qty}</TableCell>
                  </TableRow>
                ))}

              {auditData &&
                auditData?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.sku}
                    </TableCell>
                    <TableCell>{row?.location}</TableCell>
                    <TableCell>{row?.total_current_qty}</TableCell>
                    <TableCell>
                      {row?.current_qty_of_lost_request_sku}
                    </TableCell>
                    <TableCell>
                      {row?.qty_of_lost_req_sku_at_the_time_of_request}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          handleMarkLost(
                            row.sku_image,
                            row.sku,
                            row.current_qty_of_lost_request_sku,
                            row.product_id,
                            row.sublocation_id,
                            index
                          )
                        }
                        className="mark-lost-cta"
                      >
                        {start_audit_loading && Auditindex === index ? (
                          <Loader />
                        ) : (
                          <Fragment>
                            {start_audit ? "Start Audit" : "Mark Lost"}
                          </Fragment>
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}

              {qtyConsumptionData &&
                qtyConsumptionData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.name}
                    </TableCell>
                    <TableCell>
                      <Select
                        placeholder={`Select SKU`}
                        options={[
                          { label: "SKU123", value: "SKU123" },
                          { label: "SKU321", value: "SKU321" },
                        ]}
                        className="select"
                        id="courierName"
                        value={row?.sku}
                        styles={customStylesSKU}
                        onChange={(selectedOption) =>
                          handleSKU(selectedOption, index)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <InputField
                        value={row?.qty}
                        onChange={(field, value) => handleQty(value, index)}
                        // label="Enter quantity"
                        placeholder="Enter quantity"
                        id="scanbox"
                        readOnly={false}
                        field={"qty"}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              {consumptionReport &&
                consumptionReport.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.date}
                    </TableCell>
                    <TableCell>{row?.name}</TableCell>
                    <TableCell>{row?.sku}</TableCell>
                    <TableCell>{row?.qty}</TableCell>
                    <TableCell>{row?.cost}</TableCell>
                    <TableCell>{row?.skucost}</TableCell>
                  </TableRow>
                ))}

              {boxboxtransfer &&
                boxboxtransfer.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Link className="img-no-border">
                        <img className="user_img" src={row?.image} alt="img" />{" "}
                        {row?.sku}
                      </Link>
                    </TableCell>

                    <TableCell>{row?.qty}</TableCell>
                    <TableCell>
                      <Link className="action-btn-lost-req">
                        <Button
                          onClick={() =>
                            handleViewDetails(
                              18,
                              row?.details === "Box"
                                ? row?.box_id
                                : row?.sub_location_id,
                              row?.product_id,
                              row?.details
                            )
                          }
                        >
                          View Details
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}

              {viewdetails &&
                viewdetails.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <img className="user_img" src={row?.image} alt="img" />
                    </TableCell>
                    <TableCell>{row?.code}</TableCell>
                    <TableCell>{row?.sku}</TableCell>
                    <TableCell>{row?.qty}</TableCell>
                  </TableRow>
                ))}

              {sublocationTransferSummary &&
                sublocationTransferSummary.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.session_id}
                    </TableCell>
                    <TableCell>
                      <Link className="img-no-border">
                        <img className="user_img" src={UserIcon} alt="img" />
                        {row?.createdby}
                      </Link>
                    </TableCell>
                    <TableCell>{row?.date}</TableCell>
                    <TableCell>{row?.type}</TableCell>
                    <TableCell>{row?.name}</TableCell>
                    <TableCell>{row?.sublocation}</TableCell>
                    <TableCell>{row?.name2}</TableCell>
                    <TableCell>{row?.sublocation2}</TableCell>
                    <TableCell>{row?.description}</TableCell>
                    <TableCell>{row?.descriptionSublocation}</TableCell>
                    <TableCell>
                      <Link className="action-btn-lost-req">
                        <Button>View Details</Button>
                        <Button className="openSession">Open Session</Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}

              {sessionSummary &&
                sessionSummary.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.session_id}
                    </TableCell>
                    <TableCell>
                      <Link className="img-no-border">
                        <img className="user_img" src={UserIcon} alt="img" />
                        {row?.createdby}
                      </Link>
                    </TableCell>
                    <TableCell>{row?.date}</TableCell>
                    <TableCell>{row?.total}</TableCell>
                    <TableCell>
                      <Link className="action-btn-lost-req">
                        <Button>View Details</Button>
                        <Button className="openSession">Open Session</Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}

              {auditTransactionSummary &&
                auditTransactionSummary.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row?.transaction_no}
                    </TableCell>
                    <TableCell>{row?.location}</TableCell>
                    <TableCell>{row?.boxes}</TableCell>
                    <TableCell>{row?.sublocation}</TableCell>
                    <TableCell>{row?.type}</TableCell>
                    <TableCell>{row?.created_by}</TableCell>
                    <TableCell>{row?.date}</TableCell>
                    <TableCell>{row?.qtyAudit}</TableCell>
                    <TableCell>{row?.unitqtyAudit}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
);

export default DataTable;
