import React, { Fragment, useEffect, useRef, useState } from "react";
import SideBar from "../../Components/SideBar/SideBar";
import MetaData from "../../Components/Helmet/Helmet";
import ArrowIcon from "../../Assets/Icons/SideBar-Icons/arrow-left.png";
import Header from "../../Components/Header/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "../../Components/Table/DataTable";
import { WithoutAuditHeader } from "../../DummyArray/TableArray/TableData";
import { useDispatch, useSelector } from "react-redux";
import { getWithWoutAuditActions } from "../../Actions/OrderHandoverActions/OrderHandoverActions";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import { Button } from "@mui/material";
import {
  ScanAuditRequestActions,
  StartAuditRequestActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import { useAlert } from "react-alert";
import Loader from "../../Components/Loader/Loader";
import ModalContainer from "../../Components/Modal/Modal";

const AcceptRequestWithAudit = () => {
  const alert = useAlert();
  const navigate = useNavigate();

  const { product_id, subLocation_id } = useParams();

  const dispatch = useDispatch();

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  //   Without Table Data Audit state
  const { Audit_data, loading } = useSelector((state) => state.getAudit);

  const [Audit_row, setAudit_row] = useState([]);

  const [sku_image, setSku_image] = useState("");
  const [sku_Units, setSKU_Units] = useState("");
  const [sku_code, setsku_code] = useState("");
  const [audit_id, setAudit_id] = useState("");

  const [total_qty_scanned, setTotal_qty_scanned] = useState(0);
  const [scan_quantity_sku_req, setScan_quantity_sku_req] = useState(0);
  const [pending_qty, setPending_qty] = useState(0);

  useEffect(() => {
    dispatch(
      getWithWoutAuditActions(
        "mark_lost_request_without_audit",
        token,
        product_id,
        subLocation_id
      )
    );
  }, [dispatch, token, product_id, subLocation_id]);

  useEffect(() => {
    if (Audit_data && Audit_data?.status === 1) {
      console.log("AUDIT DATA: ", Audit_data);
      setAudit_row([Audit_data?.data]);
      setsku_code(Audit_data && Audit_data?.data?.sku);
      setSku_image(Audit_data && Audit_data?.data?.sku_image);
      setSKU_Units(
        Audit_data && Audit_data?.data?.current_qty_of_lost_request_sku
      );
    }
  }, [Audit_data]);

  // Start Audit State
  const { start_audit, loading: start_audit_loading } = useSelector(
    (state) => state.StartAudit
  );

  const [Index, setIndex] = useState("");
  const [isStarted, setIsStarted] = useState(false);

  const handleStartAudit = (i, j, index) => {
    // console.log("Product ID:", i);
    // console.log("Sub Location ID:", j);
    setIsStarted(true);
    setIndex(index);
    dispatch(
      StartAuditRequestActions("start_picklist_request_audit", token, i, j)
    );
  };

  useEffect(() => {
    if (start_audit && start_audit?.status === 0 && isStarted) {
      alert.error(start_audit && start_audit?.msg);
      setIsStarted(false);
    }
    if (start_audit && start_audit?.status === 1 && isStarted) {
      console.log(
        "NEW:",
        start_audit && start_audit?.data?.total_qty_scanned,
        start_audit && start_audit?.data?.scan_qty_of_sku_req_for_lost_mark,
        start_audit && start_audit?.data?.pending_qty_of_lost_marking
      );
      setTotal_qty_scanned(start_audit && start_audit?.data?.total_qty_scanned);
      setScan_quantity_sku_req(
        start_audit && start_audit?.data?.scan_qty_of_sku_req_for_lost_mark
      );
      setPending_qty(
        start_audit && start_audit?.data?.pending_qty_of_lost_marking
      );
      setAudit_id(start_audit && start_audit?.data?.id);
      alert.success("Success");
      setIsStarted(false);
    }
  }, [start_audit, isStarted, alert]);

  // console.log("START AUDIT: ", start_audit);

  const [sublocation_code, setsublocation_code] = useState("");
  const [bbsc_code, setbbsc_code] = useState("");
  const [isScanning, setIsScanning] = useState(false);

  // Scan Audit State
  const { scan_audit, loading: scan_audit_loading } = useSelector(
    (state) => state.ScanAudit
  );

  console.log("OKOK: ", scan_audit?.data);

  const Metrics_Row = [
    {
      count: total_qty_scanned,
      title: "Total Quantity Scanned",
      bgColor: "rgba(157, 228, 242, 1)",
    },
    {
      count: scan_quantity_sku_req,
      title: "Scan Quantity of SKU Requested for Lost Mark",
      bgColor: "rgba(242, 174, 36, 1)",
    },
    {
      count: pending_qty,
      title: "Pending Quantity for Lost Marking",
      bgColor: "rgba(242, 48, 121, 1)",
    },
  ];

  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      if (sublocation_code && bbsc_code) {
        setIsScanning(true);
        dispatch(
          ScanAuditRequestActions(
            "picklist_request_audit_scan",
            token,
            start_audit?.data?.id,
            sublocation_code,
            bbsc_code
          )
        );
      }
    }, 1000); // 1-second delay after typing stops

    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [sublocation_code, bbsc_code, dispatch, start_audit?.data?.id, token]);

  useEffect(() => {
    if (scan_audit && scan_audit?.status === 0 && isScanning) {
      alert.error(scan_audit && scan_audit?.msg);
      setIsScanning(false);
    }
    if (scan_audit && scan_audit?.status === 1 && isScanning) {
      setTotal_qty_scanned(scan_audit && scan_audit?.data?.total_qty_scanned);
      setScan_quantity_sku_req(
        scan_audit && scan_audit?.data?.scan_qty_of_sku_req_for_lost_mark
      );
      setPending_qty(
        scan_audit && scan_audit?.data?.pending_qty_of_lost_marking
      );
      alert.success("Success");
      setIsScanning(false);
    }
  }, [scan_audit, alert, isScanning]);

  // console.log("SCAN AUDIT: ", scan_audit);

  // Render Modal Component starts here
  const [isIndex, setIsIndex] = useState(null);

  const handleModal = (index) => {
    setIsIndex(index);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          title="Complete Audit"
          Sku_Img={sku_image}
          sku_code={sku_code}
          sku_Units={sku_Units}
          audit_id={audit_id}
        />
      );
    }
    return null;
  };

  // Render Modal Component ends here

  return (
    <Fragment>
      <div className="grid-sidebar-row">
        <MetaData title={`Mark Lost Request`} />
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
          <div className="wrapper-picker-container">
            {/* Header Title Component row starts here */}
            <Link onClick={() => navigate(-1)} className="go-back-row">
              <div>
                <img src={ArrowIcon} alt="" />
              </div>
              <div>
                <p>Accept Request with Audit</p>
              </div>
            </Link>
            {/* Header Title Component row ends here */}

            {/* Table Component starts here */}
            <div className="table-wrapper-pagination">
              <div className="table-container-pagination">
                {loading ? (
                  <LoaderAlone />
                ) : (
                  <DataTable
                    header={WithoutAuditHeader}
                    auditData={Audit_row}
                    start_audit={true}
                    handleStartAudit={handleStartAudit}
                    Auditindex={Index}
                    start_audit_loading={start_audit_loading}
                  />
                )}
              </div>
            </div>

            {/* Sub Location container row starts here */}
            <div className="sub-location-row">
              {/* Input Row starts here */}
              <div className="input-grid-container">
                <div>
                  <label htmlFor="">Scan Sub Location</label>
                  <br />
                  <input
                    type="text"
                    value={sublocation_code}
                    onChange={(e) => setsublocation_code(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="">Scan Serial Code</label>
                  <br />
                  <input
                    type="text"
                    value={bbsc_code}
                    onChange={(e) => setbbsc_code(e.target.value)}
                  />
                </div>
                <div className="scanning-loading">
                  {scan_audit_loading && (
                    <div>
                      <Loader />
                      <p>Please wait, Scanning...</p>
                    </div>
                  )}
                </div>
              </div>
              {/* Input Row ends here */}

              {/* Card Grid Row */}
              <div className="sub-location-grid-row">
                {Metrics_Row &&
                  Metrics_Row?.map((i, index) => (
                    <Fragment key={index}>
                      <div style={{ backgroundColor: i.bgColor }}>
                        <h2>{i.count}</h2>
                        <p>{i.title}</p>
                      </div>
                    </Fragment>
                  ))}
              </div>

              <div className="CTA-complete-audit">
                <Button
                  disabled={audit_id === "" ? true : false}
                  onClick={() => handleModal(15)}
                >
                  Complete Audit
                </Button>
              </div>

              {/* Card Grid Row */}
            </div>
            {/* Sub Locationg container row ends here */}
          </div>
        </div>
      </div>
      {renderModal()}
    </Fragment>
  );
};

export default AcceptRequestWithAudit;
