import React, { Fragment, useEffect } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { GetRequestCountActions } from "../../../Actions/PickerMgmt/PickerMgmtActions";
import LoaderAlone from "../../Loader/LoaderAlone";
import DataTable from "../../Table/DataTable";
import { RequestCountHeader } from "../../../DummyArray/TableArray/TableData";

const Requestcount = ({
  close,
  product_id,
  subLocation_id,
  Inventory_Picklist_id,
}) => {
  const dispatch = useDispatch();

  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;

  // Request Count State
  const { Request_count, loading } = useSelector((state) => state.RequestCount);

  useEffect(() => {
    dispatch(
      GetRequestCountActions(
        "get_picklist_request_details",
        token,
        product_id,
        subLocation_id,
        Inventory_Picklist_id
      )
    );
  }, [dispatch, token, product_id, subLocation_id, Inventory_Picklist_id]);

  // console.log("REQUEST COUNT: ", Request_count);

  return (
    <Fragment>
      <HeadingTitle title={`Request Count`} closeModal={close} />

      {/* Table Component starts here */}
      <div className="table-wrapper-pagination">
        <div className="table-container-pagination">
          {loading ? (
            <LoaderAlone />
          ) : (
            <DataTable
              header={RequestCountHeader}
              requestCount={Request_count?.data}
            />
          )}
        </div>
      </div>
      {/* Table Component ends here */}
    </Fragment>
  );
};

export default Requestcount;
