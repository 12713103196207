import React from "react";
import { HeadingTitle } from "../../Modal/Modal";

const OrderHandoverAlert = (props) => {
  const { close, isScanned, isMessage } = props;
  // console.log(isMessage);
  return (
    <>
      <HeadingTitle
        title={
          isMessage === "Order Handovered Successfully." ? (
            <span>Handover Success</span>
          ) : isScanned === 0 ? (
            <span>Already Scan</span>
          ) : isScanned === 1 ? (
            <span style={{ color: "#FF0000" }}>Alert</span>
          ) : null
        }
        closeModal={close}
      />
      <p
        style={{
          fontSize: "14px",
          fontFamily: "Poppins_Regular",
          color: "#1C1C1C",
          marginTop: "10px",
          textAlign: "center",
        }}
      >
        {isMessage}
      </p>
    </>
  );
};

export default OrderHandoverAlert;
