import React from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

const DatePicker = (props) => {
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  const handleDashboard_dataFilter = (val) => {
    props.dataRange(val);
  };

  return (
    <div>
      <DateRangePicker
        calendarAriaLabel="Toggle calendar"
        clearAriaLabel="Clear value"
        dayAriaLabel="Day"
        monthAriaLabel="Month"
        nativeInputAriaLabel="Date"
        yearAriaLabel="Year"
        onChange={handleDashboard_dataFilter}
        value={props.dashboard_filter_data}
        minDate={oneYearAgo}
        // maxDate={new Date()}
      />
    </div>
  );
};

export default DatePicker;
