import KeyPairs from "../../../Components/KeyPairs/KeyPairs";
import InputField from "../../../Components/InputField/InputField";

const AuditTransactions = () => {
  return (
    <>
      <div className="summary-box">
        <div className="grid-delivery-partner-info">
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={"BA-Aman_4802_06082024-1"}
              // label="Scan Sub Location"
              //placeholder="Scan Sub Location"
              id="scanbox"
              darked={true}
              // handover={true}
            />
          </div>
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <KeyPairs
            data={[
              { key: "Box Audited", value: "4000 / 5000" },
              {
                key: "Sub Location Audited",
                value: "300 / 438",
              },
            ]}
          />
        </div>
        <div className="grid-delivery-partner-info">
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={"Ground Floor"}
              label="Scan Sub Location"
              placeholder="Scan Sub Location"
              id="scanbox"
              scan={true}
              // handover={true}
            />
          </div>
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <KeyPairs data={[{ key: "Total Boxes Available", value: "07" }]} />
        </div>
        <div className="grid-delivery-partner-info">
          <div className="mt-56">
            <InputField
              // loading={handoverNameLoading}
              value={"BX123456"}
              label="Scan Box"
              placeholder="Scan Box"
              id="scanbox"
              scan={true}
              // handover={true}
            />
          </div>
        </div>
        <div>
          <KeyPairs data={[{ key: "Total Current Quantity", value: "70" }]} />
        </div>
      </div>
    </>
  );
};

export default AuditTransactions;
